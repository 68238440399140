import type { AppDispatch } from "src/store/store";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useCompany } from "src/store/slices/companySlice";
import { useCard } from "src/store/slices/cardsSlice";
import {
  AccountsReceivableManagementTableByCo,
  AccountsReceivableManagementTableAllInvoices,
  Callout,
  Dropdown,
  ModalPieChartWithLabels,
  Overlay,
  Tab,
  ErrorDisplay,
  Loader,
} from "src/components";
import { cardConfig } from "src/utils/constants/CardConfig";
import { resetInvoices } from "src/store/slices/invoicesSlice";
import { isModalDataEmpty, renderTestId } from "src/utils/HelperFunctions";
import { useGetModalQuery } from "src/store/serviceApi";

const CARD_ID = "4af4d499-4063-4bc9-b860-2755933bff03";
const MODAL_ID = cardConfig[CARD_ID].modalId;

interface Props {
  span?: 1 | 2 | 3 | 4 | 6;
}

const AccountsReceivableManagementModal = ({ span }: Props) => {
  const [filter, setFilter] = useState({
    byCompanies: 0,
    byStatus: 1,
    latestOnTop: true,
  });
  const dispatch = useDispatch<AppDispatch>();
  const { company } = useCompany();
  const card = useCard(CARD_ID);

  const {
    data: modalData,
    isLoading,
    isFetching,
  } = useGetModalQuery({
    id: card?.modal ?? 0,
    companyId: company?.id ?? "",
  });

  if (!modalData) return null;

  const applyFilter = (filterStatus: number) => {
    setFilter({
      byCompanies: 0,
      byStatus: filterStatus,
      latestOnTop: filter.latestOnTop,
    });
  };

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      title={cardConfig[CARD_ID].modalTitle}
      variant="Overlay"
      icon={cardConfig[CARD_ID].icon}
      size="lg"
      className="md:max-w-[1300px]"
      updatedAt={new Date(modalData.updatedAt as string)}
      onClose={() => dispatch(resetInvoices())}
    >
      {isLoading || isFetching ? (
        <Loader variant={"blue"} />
      ) : isModalDataEmpty(modalData.data) ? (
        <ErrorDisplay />
      ) : (
        <div className="flex flex-col gap-4">
          <div>
            <ModalPieChartWithLabels
              span={span}
              data={modalData.data}
              colors={[
                ["orange", "primary"],
                ["#FF6301", "#007AFF"],
              ]}
            />
          </div>
          <div className="flex flex-col md:gap-0 gap-2 md:flex-row justify-between">
            <div className="flex flex-row gap-x-2">
              <Tab
                title="Összes számla"
                isActive={filter.byCompanies === 0}
                onClick={() =>
                  setFilter((s) => ({
                    byCompanies: 0,
                    byStatus: 1,
                    latestOnTop: true,
                  }))
                }
              />
              <Tab
                title="Cégenkénti bontás"
                isActive={filter.byCompanies === 1}
                onClick={() =>
                  setFilter((s) => ({
                    byCompanies: 1,
                    byStatus: 0,
                    latestOnTop: false,
                  }))
                }
              />
            </div>
            <div className="flex flex-row gap-x-2 border border-black/[0.1] p-1 rounded-[10px]">
              {filter.byCompanies === 0 && (
                <>
                  <Tab
                    title="Nem lejárt"
                    variant="Segment"
                    isActive={filter.byStatus === 0}
                    onClick={() => applyFilter(0)}
                  />
                  <Tab
                    title="1-30 napja lejárt"
                    variant="Segment"
                    isActive={filter.byStatus === 1}
                    onClick={() => applyFilter(1)}
                  />
                  <Tab
                    title="31-60 napja lejárt"
                    variant="Segment"
                    isActive={filter.byStatus === 2}
                    onClick={() => applyFilter(2)}
                  />
                  <Tab
                    title="61+ napja lejárt"
                    variant="Segment"
                    isActive={filter.byStatus === 3}
                    onClick={() => applyFilter(3)}
                  />
                </>
              )}
              {filter.byCompanies === 1 && (
                <>
                  <Tab
                    title="Összes"
                    variant="Segment"
                    isActive={filter.byStatus === 0}
                    onClick={() => setFilter((s) => ({ ...s, byStatus: 0 }))}
                  />
                  <Tab
                    title="Faktorálható"
                    variant="Segment"
                    isActive={filter.byStatus === 1}
                    onClick={() => setFilter((s) => ({ ...s, byStatus: 1 }))}
                  />
                  <Tab
                    title="Behajtható"
                    variant="Segment"
                    isActive={filter.byStatus === 2}
                    onClick={() => setFilter((s) => ({ ...s, byStatus: 2 }))}
                  />
                </>
              )}
            </div>
            {filter.byCompanies === 0 && (
              <Dropdown
                label={filter.latestOnTop ? "Legújabb elől" : "Legrégebbi elől"}
                items={[
                  {
                    name: "Legújabb elől",
                    onClick: () =>
                      setFilter((s) => ({ ...s, latestOnTop: true })),
                  },
                  {
                    name: "Legrégebbi elől",
                    onClick: () =>
                      setFilter((s) => ({ ...s, latestOnTop: false })),
                  },
                ]}
              />
            )}
          </div>
          <div>
            {filter.byCompanies === 1 && filter.byStatus === 1 && (
              <Callout variant="info" className="w-9/12">
                A faktorszerződés egy pénzügyi megállapodás, amikor egy vállalat
                (faktor) megvásárolja egy másik vállalat kintlévőségeit az
                ügyfeleitől, cserébe az azonnali készpénzért.
              </Callout>
            )}
            {filter.byCompanies === 1 && filter.byStatus === 2 && (
              <Callout variant="info" className="w-9/12">
                Ne legyenek fizetetlen számláid! Nem kell ügyvéddel egyeztetned,
                mindent online intézhetsz. A számla feltöltése és adatmegadás
                után egy napon belül elindítjuk. Naprakész leszel a státusz és
                teendő kapcsán.
              </Callout>
            )}
          </div>
          <div>
            {filter.byCompanies === 0 ? (
              <AccountsReceivableManagementTableAllInvoices
                {...renderTestId(`${MODAL_ID}-table-all-invoices`)}
                filter={filter.byStatus}
                sort={filter.latestOnTop}
              />
            ) : (
              <AccountsReceivableManagementTableByCo
                {...renderTestId(`${MODAL_ID}-table-by-company`)}
                filter={filter.byStatus}
              />
            )}
          </div>
        </div>
      )}
    </Overlay>
  );
};

export default AccountsReceivableManagementModal;
