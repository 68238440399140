import "src/index.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import reportWebVitals from "src/reportWebVitals";
import { msalConfig } from "src/utils/auth/AuthProvider";
import { persistor, store } from "src/store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </PersistGate>
  </Provider>
);

/* <React.StrictMode> */
/* </React.StrictMode> */
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
