import { emptySplitApi as generatedApi } from "src/api/emptyApi";

export const extendedApi = generatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceOData: builder.query({
      query: ({ companyId, filterStr }) => ({
        url: `/api/odata/invoices${filterStr}`,
        headers: { companyId: companyId },
      }),
    }),
  }),
  overrideExisting: true,
});
export const { useGetInvoiceODataQuery } = extendedApi;
