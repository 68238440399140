import { Button, Overlay, P } from "src/components";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalOpen } from "src/store/slices/modalSlice";

const OverlayDemo = () => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <Button onClick={() => dispatch(setModalOpen("demo-overlay"))}>
        OPEN
      </Button>
      <Overlay
        id="demo-overlay"
        title="Test Parent"
        variant="Overlay"
        icon="deal"
      >
        <div
          className={cn(
            "flex flex-col items-center gap-4 rounded-[20px]",
            "w-xl"
          )}
        >
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
          <P>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla
            illum nesciunt vitae necessitatibus, omnis dolor molestiae fugiat
            impedit reiciendis quibusdam, nam optio ab vero aliquid totam rem
            sint! Quod, mollitia!
          </P>
        </div>
        <div className="sticky right-0 bottom-0 left-0 bg-contrast/30 backdrop-blur-sm p-4">
          <Button
            onClick={() => dispatch(setModalOpen("another-overlay-demo"))}
          >
            OPEN Child
          </Button>
        </div>
      </Overlay>
      <Overlay
        size="xs"
        id="another-overlay-demo"
        title="Test Child"
        variant="Custom"
      >
        <P>Child Modal is opened!</P>
      </Overlay>
    </>
  );
};

export default OverlayDemo;
