import {
  AccountsReceivableManagementCard,
  AnnouncementBar,
  ClosedInvoicesCard,
  CreditLimitCard,
  ErrorModal,
  GeneralLoader,
  H2,
  IncomeCard,
  MinimumStockProductsCard,
  MoneyStockCard,
  OpenBidsCard,
  OpenOrdersCard,
  StockValueCard,
  SupplierDebtCard,
} from "src/components";
import { cardTypesById } from "src/utils/constants/CardTypes";
import { useCompany } from "src/store/slices/companySlice";
import { renderTestId } from "src/utils/HelperFunctions";
import { msalInstance } from "src";
import {
  DataPointApplicationFeaturesCardsQueriesCardResponse,
  useGetCardsQuery,
  useGetCompaniesQuery,
  useGetTenantsQuery,
} from "src/store/serviceApi";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { useEffect } from "react";

const Dashboard = () => {
  const activeUser =
    msalInstance.getActiveAccount() ??
    (msalInstance.getAllAccounts() && msalInstance.getAllAccounts()[0]);
  const { userSetting, company } = useCompany();
  const { data: companiesData, isLoading: companiesAreLoading } =
    useGetCompaniesQuery();
  const navigate = useNavigate();
  const isEmptyStateDisplay =
    !companiesAreLoading && companiesData?.length === 0;

  const { data: dataGetCards, isFetching } = useGetCardsQuery(
    { companyId: company?.id ?? "" },
    { skip: Boolean(!company), refetchOnMountOrArgChange: true }
  );
  const { data: dataGetTenants } = useGetTenantsQuery();

  useEffect(() => {
    if (dataGetTenants?.length === 0) {
      navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION);
    }
  });

  const primaryCards = dataGetCards?.cards
    ? dataGetCards?.cards.filter(
        (card: DataPointApplicationFeaturesCardsQueriesCardResponse) =>
          card.isLocked
      )
    : [];
  const secondaryCards = dataGetCards?.cards
    ? dataGetCards?.cards.filter(
        (card: DataPointApplicationFeaturesCardsQueriesCardResponse) =>
          !card.isLocked
      )
    : [];

  const getColSpan = (type: number): 2 | 3 | 4 | 6 => {
    if (type === 1 && primaryCards.length >= 3) {
      return 6;
    } else if (type === 1 && primaryCards.length === 2) {
      return 4;
    } else if (
      (type === 2 || type === 3 || type === 4) &&
      primaryCards.length === 3
    ) {
      return 3;
    } else {
      return 2;
    }
  };

  const getTimeOfDay = (): string => {
    const hour = new Date().getHours();
    return hour > 4 && hour <= 9
      ? "reggelt"
      : hour > 9 && hour <= 18
      ? "napot"
      : "estét";
  };

  const CardWrapper = ({ id }: { id: string }) => {
    const cardTypeNumber = cardTypesById[id];

    const isMobile = window?.innerWidth < 768;
    const cardTypes: Record<number, React.ReactNode> = {
      1: (
        <IncomeCard
          span={
            isMobile ? 1 : isEmptyStateDisplay ? 6 : getColSpan(cardTypeNumber)
          }
        />
      ),
      2: (
        <AccountsReceivableManagementCard
          span={
            isMobile ? 1 : isEmptyStateDisplay ? 3 : getColSpan(cardTypeNumber)
          }
        />
      ),
      3: (
        <SupplierDebtCard
          span={
            isMobile ? 1 : isEmptyStateDisplay ? 3 : getColSpan(cardTypeNumber)
          }
        />
      ),
      4: <MoneyStockCard span={isMobile ? 1 : getColSpan(cardTypeNumber)} />,
      5: <ClosedInvoicesCard />,
      6: <CreditLimitCard />,
      7: <MinimumStockProductsCard />,
      8: <OpenOrdersCard />,
      9: <StockValueCard />,
      10: <OpenBidsCard />,
    };
    return <>{cardTypes[cardTypeNumber]}</>;
  };

  return (
    <main
      {...renderTestId("dashboard-template")}
      className="flex flex-col items-center w-full md:mx-0 md:my-auto"
    >
      <div className="max-w-screen-2xl">
        {isEmptyStateDisplay && (
          <AnnouncementBar
            mainText="Adatok szinkronizációja folyamatban..."
            subText="Egy kis türelmedet szeretnénk kérni,  eltarthat 1-2 óráig, amíg az újonnan regisztrált céged adatai felszinkronizálódnak az Adatpontra. Köszönjük a türelmed! Fontos: A sikeres adatszinkronizációhoz a legfrissebb Ügyvitel programverzióra van szükséged!"
            className="col-span-xl"
          />
        )}
        <GeneralLoader isOpen={isFetching} />
        <section
          {...renderTestId("dashboard-top-section")}
          className="px-3 md:px-10 md:py-6 pb-3 md:pt-3 pt-0 grid grid-cols-2 md:grid-cols-6 gap-4 md:gap-6"
        >
          {activeUser && activeUser.idTokenClaims && (
            <H2 className="!text-24-32 !font-700 col-span-xl">
              Jó {getTimeOfDay()},{" "}
              {(activeUser?.idTokenClaims as any)?.given_name ??
                "kedves ismeretlen nevű felhasználó"}
              !
            </H2>
          )}
          {!isEmptyStateDisplay &&
            primaryCards &&
            primaryCards.map(
              (card: DataPointApplicationFeaturesCardsQueriesCardResponse) => (
                <CardWrapper key={card.id} id={card.id ?? ""} />
              )
            )}
          {isEmptyStateDisplay &&
            Object.keys(cardTypesById)
              .filter((id: string, index: number) => index < 3)
              .map((cardId: string) => (
                <CardWrapper key={cardId} id={cardId ?? ""} />
              ))}
        </section>
        <section
          {...renderTestId("dashboard-bottom-section")}
          className={cn(
            "p-4 md:px-10 md:py-6 grid grid-cols-2 md:grid-cols-6 gap-4 md:gap-6",
            "bg-contrast",
            "secondary-cards-section"
          )}
        >
          {!isEmptyStateDisplay &&
            secondaryCards.map(
              (
                card: DataPointApplicationFeaturesCardsQueriesCardResponse,
                index: number
              ) => (
                <CardWrapper
                  key={card?.id ?? "" + index}
                  id={card?.id ?? "" + index}
                />
              )
            )}
        </section>
      </div>
      <ErrorModal />
    </main>
  );
};

export default Dashboard;
