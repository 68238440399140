import React from "react";
import cn from "classnames";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  // DropdownMenuCheckboxItem,
  // DropdownMenuRadioItem,
  // DropdownMenuLabel,
  // DropdownMenuSeparator,
  // DropdownMenuShortcut,
  // DropdownMenuGroup,
  // DropdownMenuPortal,
  // DropdownMenuSub,
  // DropdownMenuSubContent,
  // DropdownMenuSubTrigger,
  // DropdownMenuRadioGroup,
} from "src/components/_ui/dropdown-menu";
import { Icon, P } from "src/components";

type Item = {
  name: string;
  onClick: () => void;
  className?: string;
};
interface Props {
  label: string;
  items: Item[];
}

const Dropdown: React.FC<Props> = ({ label = "", items }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex justify-between items-center md:px-4 px-2 md:py-2 py-1 rounded-2xl border focus:outline-none border-light-gray hover:border-blue-500 transition-colors select-none">
          <P className="line-clamp-1 text-sm md:text-base">{label}</P>
          <Icon icon="chevron-down" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" side="bottom" align="start">
        {items?.length > 0 &&
          items.map(({ name, onClick, className }, index) => (
            <DropdownMenuItem
              key={`dropdown-menu-item-${name}-${index}`}
              onClick={onClick}
              className={cn(
                "odd:bg-white even:bg-primary/[0.025] cursor-pointer text-sm md:text-base",
                className
              )}
            >
              {name}
            </DropdownMenuItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Dropdown;
