import React, { HtmlHTMLAttributes } from "react";
import cn from "classnames";
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Tooltip } from "react-tooltip";
import ReactTableBodyCell from "./ReactTableBodyCell";
import ReactTableBodyRow from "./ReactTableBodyRow";
import ReactTableHeadCell from "./ReactTableHeadCell";
import ReactTableHeadRow from "./ReactTableHeadRow";

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  variant?: TableVariant;
  data: unknown[];
  columns: ColumnDef<unknown, any>[];
  selectedRows?: string[];
  sticky?: boolean;
  wrapCells?: boolean;
}

type TableVariant = "Default" | "Collapsible";

const ReactTable: React.FC<Props> = ({
  variant = "Default",
  data,
  columns,
  selectedRows,
  className,
  sticky,
  wrapCells,
  ...rest
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="contents">
      <table
        className={cn(
          "table-auto w-full",
          variant === "Collapsible" && "border-separate border-spacing-0",
          className
        )}
        {...rest}
      >
        <colgroup>
          {columns.map((col, index) => (
            <col
              key={index}
              style={{ width: col.size ? `${col.size}%` : undefined }}
            />
          ))}
        </colgroup>
        <thead>
          {table.getHeaderGroups().map((headerGroup, index) => (
            <ReactTableHeadRow
              key={index}
              headerGroup={headerGroup}
              variant={variant}
            >
              {headerGroup.headers.map((header, index) => (
                <ReactTableHeadCell
                  key={index}
                  header={header}
                  sticky={sticky}
                  variant={variant}
                />
              ))}
            </ReactTableHeadRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <ReactTableBodyRow
              key={index}
              selectedRows={selectedRows}
              variant={variant}
              row={row}
            >
              {row.getVisibleCells().map((cell, index) => (
                <ReactTableBodyCell
                  key={index}
                  wrapCells={wrapCells}
                  cell={cell}
                  sticky={sticky}
                  variant={variant}
                  row={row}
                  className={cell.column.columnDef.meta as string}
                />
              ))}
            </ReactTableBodyRow>
          ))}
        </tbody>

        {/* <tfoot className="px-0 py-1 text-blue-500 border-b-2 border-b-blue-500">
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot> */}
      </table>
      <Tooltip id="my-tooltip" />
    </div>
  );
};

export default ReactTable;
