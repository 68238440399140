import { LabelValue } from "src/models/LabelValue";
import Chart from "react-apexcharts";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: LabelValue[] | undefined;
  colors?: string[];
  size?: 64 | 96 | 104 | 191 | 148 | undefined;
}

const PieChart: React.FC<Props> = ({ data, colors, size = 148 }) => {
  const options = {
    grid: {
      padding: {
        left: size !== 64 ? -5 : 0,
      },
    },
    labels: data ? [data[0]?.label, data[1]?.label] : ["", ""],
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "55%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: data
      ? colors
      : ["rgba(0, 122, 255, 0.10)", "rgba(0, 122, 255, 0.10)"],
    chart: {
      foreColor: "#373d3f",
      selection: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },
    pie: {
      donut: {
        dropShadow: {
          enabled: false,
        },
        labels: {
          show: false,
          name: {
            show: false,
            color: "#000",
          },
          value: {
            show: false,
          },
          total: {
            showAlways: false,
            show: false,
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return (
    <Chart
      options={options as any}
      series={
        data
          ? [(data[0]?.value as number) ?? 1, (data[1]?.value as number) ?? 1]
          : [0, 0.1]
      }
      type="donut"
      width={size}
    />
  );
};

export default PieChart;
