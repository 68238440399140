import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {}

const H1: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <h1
      className={cn(
        "font-primary",
        "text-xl",
        "md:text-2xl",
        "select-none",
        className
      )}
      {...rest}
    >
      {children}
    </h1>
  );
};

export default H1;
