import {
  CompanyIdentificationCard,
  CompanyIdentificationForm,
  CompanyIdentificationLayout,
  CompanyIdentificationNavigation,
} from "src/components";

const CompanyIdentification = () => {
  return (
    <CompanyIdentificationLayout>
      <CompanyIdentificationCard>
        <CompanyIdentificationNavigation />
        <CompanyIdentificationForm />
      </CompanyIdentificationCard>
    </CompanyIdentificationLayout>
  );
};

export default CompanyIdentification;
