import { Fragment, useMemo, useState } from "react";
import { Tab, P, ModuleButton } from "src/components";
import cn from "classnames";
import { ClaimType } from "../../../molecules/ModuleButton/ModuleButton";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: DataElement[];
  handleActiveTabChange: (s: "Soft" | "Medium" | "Hard") => void;
  activeTab: "Soft" | "Medium" | "Hard";
}

export interface DataElement {
  label: "Soft" | "Medium" | "Hard";
  content: React.ReactNode | string;
}

const PayeePackageTabs: React.FC<Props> = ({
  data,
  activeTab,
  handleActiveTabChange,
  className,
}: Props) => {
  const tabContents = {
    Soft: (
      <div className="flex space-x-4">
        <div className="flex-1">
          <div className="border border-primary/10 p-4 rounded-2xl h-full !text-base text-black/70">
            <strong className="text-black">Helyetted kommunikálunk</strong> a
            nemfizető vevőiddel. Ez egy hangnemváltás a vevőd felé, és nagyobb
            eséllyel fizet majd.
          </div>
        </div>
        <div className="flex-1">
          <div className="border border-primary/10 p-4 rounded-2xl h-full text-base text-black/70">
            Vevőd az egyedi{" "}
            <strong className="text-black">biztonságos fizetési oldalán</strong>{" "}
            fizethet átutalással vagy bankkártyával és kérhet részfizetést is.
          </div>
        </div>
        <div className="flex-1">
          <div className="border border-primary/10 p-4 rounded-2xl h-full text-base text-black/70">
            <strong className="text-black">A felszólítás sorozat email</strong>{" "}
            és <strong className="text-black">SMS</strong> csatornákon történik,
            összesen 6 alkalommal, 20 napon át munkanapokon 9 és 19 óra között.
          </div>
        </div>
        <div className="flex-1">
          <div className="border flex flex-col self-stretch items-start gap-[10px] border-primary/10 p-4 rounded-2xl bg-primary/10 h-full text-base">
            <P className="text-primary">
              <strong>Ügyindítási díj</strong>
            </P>
            <P className="text-primary">
              nettó <strong>1 290 Ft</strong>
            </P>
            <P>
              A szolgáltatást bármennyi számlára indítod, a díj nem változik.
            </P>
          </div>
        </div>
      </div>
    ),
    Medium: (
      <div className="flex space-x-4">
        <div className="flex-1">
          <div className="border border-primary/10 p-4 rounded-2xl h-full !text-base text-black/70">
            <strong className="text-black">
              Nem kell bemenned az ügyvédedhez,
            </strong>{" "}
            itt gyorsan és egyszerűen elindíthatod az ügyvédi levelet,
            papírmunka és nélkül.
          </div>
        </div>
        <div className="flex-1">
          <div className="border border-primary/10 p-4 rounded-2xl h-full text-base text-black/70">
            <strong className="text-black">Hivatalos,</strong> a Payee{" "}
            <strong className="text-black">
              ügyvédi partnere által aláírt, tértivevényes postai felszólító
              levél,
            </strong>{" "}
            ami az általad megadott adatokat tartalmazza.
          </div>
        </div>
        <div className="flex-1">
          <div className="border border-primary/10 p-4 rounded-2xl h-full text-base text-black/70">
            Az ügyvédi partner{" "}
            <strong className="text-black">elkészíti és aláírja</strong> a
            levelet, amit{" "}
            <strong className="text-black">36 órán belül postáz</strong> az
            adósodnak. A kézbesítés eredményéről értesítünk fiókodban.
          </div>
        </div>
        <div className="flex-1">
          <div className="border flex flex-col self-stretch items-start gap-[10px] border-primary/10 p-4 rounded-2xl bg-primary/10 h-full text-base">
            <P className="text-primary">
              <strong>Ügyindítási díj</strong>
            </P>
            <P className="text-primary">
              nettó <strong>8 490 Ft</strong>
            </P>
            <P>
              A szolgáltatást bármennyi számlára indítod, a díj nem változik.
            </P>
          </div>
        </div>
      </div>
    ),
    Hard: (
      <div className="flex space-x-4">
        <div className="flex-1">
          <div className="border border-primary/10 p-4 rounded-2xl h-full !text-base text-black/70">
            <strong className="text-black">
              Helyetted beszélünk az ügyvéddel és a hatóságokkal.
            </strong>{" "}
            Az ügyed pontos <strong className="text-black">státuszát</strong> a
            fiókodban követheted nyomon,{" "}
            <strong className="text-black">valós időben.</strong>
          </div>
        </div>
        <div className="flex-1">
          <div className="border border-primary/10 p-4 rounded-2xl h-full text-base text-black/70">
            <strong className="text-black">
              Végrehajtást is (inkasszó, árverés, munkabér letiltás)
            </strong>{" "}
            magába foglalja. Évente félmillió ügy indul ebből, hivatalos, jogi
            eszköz.
          </div>
        </div>
        <div className="flex-1">
          <div className="border border-primary/10 p-4 rounded-2xl h-full text-base text-black/70">
            <strong className="text-black">
              Elkészítjük a kérelmet, beadjuk a hatóságokhoz.
            </strong>{" "}
            Ezután a közjegyző{" "}
            <strong className="text-black">3 napon belül kibocsátja</strong> és{" "}
            <strong className="text-black">postázza</strong> az adós számára.
          </div>
        </div>
        <div className="flex-1">
          <div className="border flex flex-col self-stretch items-start gap-[10px] border-primary/10 p-4 rounded-2xl bg-primary/10 h-full text-base">
            <P className="text-primary">
              <strong>Ügyindítási díj</strong>
            </P>
            <P className="text-primary">
              nettó <strong>24 990 Ft</strong>
            </P>
            <P>
              A szolgáltatást bármennyi számlára indítod, a díj nem változik.
            </P>
          </div>
        </div>
      </div>
    ),
  };

  const tabContent = useMemo(() => tabContents[activeTab], [data, activeTab]);

  if (!data) {
    return null;
  }

  return (
    <div className="flex flex-col justify-start items-start gap-y-8">
      <div
        className={cn(
          "flex flex-row justify-start items-start self-stretch gap-6",
          className
        )}
      >
        {data?.map(({ label, content }, index) => (
          <ModuleButton
            key={`tab-${index}-${label}`}
            onClick={() => handleActiveTabChange(label)}
            isActive={label === activeTab}
            title={label}
            phase={label as ClaimType}
            variant="Claim"
          />
        ))}
      </div>
      {tabContent && (
        <div className="relative flex w-full px-[96px] items-start justify-between">
          <div className="flex w-8 h-8 p-2 justify-center items-center gap-2 shrink-0 rounded-[20px] bg-white shadow-[0_0px_16px_0px_rgba(23,91,165,0.1)] z-10">
            <P className="text-primary !text-sm !font-500">1</P>
          </div>
          <div className="flex w-8 h-8 p-2 justify-center items-center gap-2 shrink-0 rounded-[20px] bg-white shadow-[0_0px_16px_0px_rgba(23,91,165,0.1)] z-10">
            <P className="text-primary !text-sm !font-500">2</P>
          </div>
          <div className="flex w-8 h-8 p-2 justify-center items-center gap-2 shrink-0 rounded-[20px] bg-white shadow-[0_0px_16px_0px_rgba(23,91,165,0.1)] z-10">
            <P className="text-primary !text-sm !font-500">3</P>
          </div>
          <div className="flex w-8 h-8 p-2 justify-center items-center gap-2 shrink-0 rounded-[20px] bg-primary shadow-[0_0px_16px_0px_rgba(23,91,165,0.1)] z-10">
            <P className="text-white !text-sm !font-500">!</P>
          </div>
          <div className="h-[2px] left-[96px] right-[96px] top-4 z-0 bg-primary absolute"></div>
        </div>
      )}
      {tabContent && <div className="w-full">{tabContent}</div>}
    </div>
  );
};

export default PayeePackageTabs;
