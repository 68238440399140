import { useLocation, useNavigate } from "react-router-dom";
import { Button, H1, P } from "src/components";
import SuccessIcon from "src/assets/icons/result-success.svg";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { DataPointApplicationFeaturesTenantCreationRequestsCommandCreateTenantCreationRequestCommand } from "src/store/serviceApi";

interface Props {
  emailTo: DataPointApplicationFeaturesTenantCreationRequestsCommandCreateTenantCreationRequestCommand["emailTo"];
}

const CompanyIdentificationInvitationSuccess: React.FC<Props> = ({
  emailTo,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-col h-full items-center justify-between gap-y-6">
      <div className="flex flex-col gap-y-6 items-center m-auto">
        <img
          src={SuccessIcon}
          alt="Sikeres kérelem küldés"
          className="w-[120px] h-[120px]"
        />

        <H1 className="text-primary font-bold">Sikeres kérelem küldés</H1>

        <P className="text-center">
          <strong>{emailTo}</strong> részére
        </P>

        <P className="text-center">
          Sikeresen megkérted kollégádat, hogy a cégazonosítást elvégezze.
          Amikor az azonosítás megtörtént, emailben értesítünk.
        </P>
      </div>
      <Button
        className="w-full"
        variant="Secondary"
        onClick={() =>
          navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION, {
            state: {
              navigatedFromCompanies: location.state?.navigatedFromCompanies,
            },
          })
        }
      >
        Vissza a termékazonosításhoz
      </Button>
    </div>
  );
};

export default CompanyIdentificationInvitationSuccess;
