import { Icon, P } from "src/components";
import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  mainText?: string;
  subText?: string;
  variant?: "success" | "warning";
  className?: string;
}

const AnnouncementBar: React.FC<Props> = ({
  mainText = "",
  subText = "",
  variant = "success",
  className,
}) => {
  return (
    <div
      className={cn(
        "flex w-full items-center py-6 px-10 gap-4",
        variant === "success"
          ? "bg-success-announcement"
          : "bg-warning-announcement",
        className
      )}
    >
      <Icon
        color={variant === "success" ? "#008F87" : "#FFA167"}
        icon={variant === "success" ? "checkmark" : "info"}
        className="rounded-full bg-contrast/60 p-2 !w-10 !h-10"
      />
      <div className="flex flex-col items-start gap-1">
        <P className="!text-[16px] !leading-[16px]">{mainText}</P>
        <P className="!text-[14px] !leading-[14px]">{subText}</P>
      </div>
    </div>
  );
};

export default AnnouncementBar;
