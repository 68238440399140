import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalClose } from "src/store/slices/modalSlice";
import { Overlay, P, Button, Submit, Fieldset, Input } from "src/components";
import { useValidationSchema } from "src/hooks/useValidationSchema";
import { userInvitationScheme } from "src/validation/schemes";
import { Form } from "react-final-form";
import { useCompany } from "src/store/slices/companySlice";
import { renderTestId } from "src/utils/HelperFunctions";
import { useToast } from "src/components/_ui/toast/use-toast";
import { useAddInviteMutation, useGetInvitesQuery } from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const MODAL_ID = "invite-user-modal";

const initialValues = Object.freeze({
  email: "",
});

const InviteUserModal: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { company } = useCompany();
  const { toast } = useToast();
  const validate = useValidationSchema(userInvitationScheme);
  useGetInvitesQuery(
    {
      companyId: company?.id ?? "",
    },
    { skip: Boolean(!company), refetchOnMountOrArgChange: true }
  );

  const [addInvite] = useAddInviteMutation();

  const handleSubmit = async (email: string) => {
    const payload = {
      body: {
        invitations: [
          {
            tenantId: company?.tenantId ?? "",
            emails: [email],
          },
        ],
      },
    };
    await addInvite(payload)
      .then((res) => {
        if (!(res as any).data) {
          toast({
            title: "Sikeresen meghívtad kollégádat",
            variant: "success",
          });
          closeModal();
        } else
          toast({
            title: "Kollégád meghívása sikertelen",
            variant: "destructive",
          });
      })
      .catch((error) => {
        console.error("INVITE USER MODAL ERROR", error);
      });
  };

  const closeModal = () => dispatch(setModalClose(MODAL_ID));

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      className="md:w-[640px]"
      title="További személyek meghívása"
      variant="Custom"
      size="sm"
    >
      <Form
        validate={validate}
        initialValues={initialValues}
        onSubmit={(e: any) => handleSubmit(e.email)}
      >
        {({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex content-center gap-6 flex-col items-center flex-wrap">
              <P className="text-sm text-left">
                Hívd meg kollegáidat, hogy hozzáférjenek az Adatpont
                szolgáltatásban szereplő adatokhoz.
              </P>
              <div className="flex justify-start self-stretch">
                <Fieldset label="Email" className="self-stretch w-full">
                  <Input placeholder="Írd ide az emailcímet..." name="email" />
                </Fieldset>
              </div>
              <div className="flex content-center md:flex-nowrap flex-wrap-reverse justify-center items-center w-full md:gap-6 gap-3 self-stretch text-center">
                <Button
                  {...renderTestId("cancel-button")}
                  onClick={closeModal}
                  variant="Secondary"
                  className="w-full"
                >
                  <P className="text-primary font-500">Mégse</P>
                </Button>

                <Submit
                  variant="Primary"
                  className="w-full"
                  disabled={invalid || submitting}
                >
                  <P className="text-white font-500">Küldés</P>
                </Submit>
              </div>
            </div>
          </form>
        )}
      </Form>
    </Overlay>
  );
};
export default InviteUserModal;
