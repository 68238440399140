import { Footer, Header } from "src/components";
import cn from "classnames";
import { renderTestId } from "src/utils/HelperFunctions";
import { useGetCompaniesQuery, useGetTenantsQuery } from "src/store/serviceApi";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { silentLogin } from "src/utils/auth/helpers";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  hasFooter?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  hasFooter,
  className,
  ...rest
}) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    if (!isAuthenticated) {
      try {
        silentLogin(instance);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useGetTenantsQuery(undefined, { skip: Boolean(!isAuthenticated) });
  useGetCompaniesQuery(undefined, { skip: Boolean(!isAuthenticated) });

  return (
    <div
      {...renderTestId("master-layout")}
      className={cn(
        "flex flex-col justify-between w-full min-h-screen",
        className
      )}
      {...rest}
    >
      <Header />
      {children}
      {hasFooter && <Footer />}
    </div>
  );
};

export default Layout;
