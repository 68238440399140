import React from "react";
import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLLabelElement> {}

const Label: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <label
      className={cn(
        "text-black text-sm font-medium line-clamp-1 select-none",
        className
      )}
      {...rest}
    >
      {children}
    </label>
  );
};

export default Label;
