import {
  Overlay,
  OpenOrdersModalTable,
  ErrorDisplay,
  Loader,
} from "src/components";
import { useCompany } from "src/store/slices/companySlice";
import { useCard } from "src/store/slices/cardsSlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import { isModalDataEmpty, renderTestId } from "src/utils/HelperFunctions";
import { useGetModalQuery } from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const CARD_ID = "ecb8665c-817c-41ce-8ea9-1ba5cd22c546";
const MODAL_ID = cardConfig[CARD_ID].modalId;

const OpenOrdersModal: React.FC<Props> = () => {
  const { company } = useCompany();
  const card = useCard(CARD_ID);

  const {
    data: modalData,
    isLoading,
    isFetching,
  } = useGetModalQuery({
    id: card?.modal ?? 0,
    companyId: company?.id ?? "",
  });

  if (!modalData) return null;

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      className="md:min-w-[750px] md:max-w-[1100px]"
      title={cardConfig[CARD_ID].modalTitle}
      variant="Overlay"
      updatedAt={new Date(modalData.updatedAt as string)}
      icon={cardConfig[CARD_ID].icon}
      size="md"
    >
      {isLoading || isFetching ? (
        <Loader variant={"blue"} />
      ) : isModalDataEmpty(modalData.data) ? (
        <ErrorDisplay />
      ) : (
        <OpenOrdersModalTable data={modalData} />
      )}
    </Overlay>
  );
};
export default OpenOrdersModal;
