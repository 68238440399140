import { Fragment, useEffect, useState } from "react";
import { useCard } from "src/store/slices/cardsSlice";
import {
  Card,
  CardContent,
  CardHeader,
  P,
  ReactTable,
  CreditLimitModal,
} from "src/components";
import cn from "classnames";
import {
  getDateLabel,
  getDisplayValue,
  renderTestId,
} from "src/utils/HelperFunctions";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalOpen, useModal } from "src/store/slices/modalSlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import { getCardOrEmptyState } from "src/utils/transformers/cardDataTransformer";
import { DataPointApplicationFeaturesCardsQueriesDataResponse } from "src/store/serviceApi";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const CARD_ID = "8e305142-4a61-4b56-ad0d-f95660948a86";

type DataItem = {
  customerName: string;
  nameType: string;
  value: string;
  currency: string;
};

const CreditLimitCard: React.FC<Props> = ({}) => {
  const { isEmpty, card, isNotLoaded } = getCardOrEmptyState(
    CARD_ID,
    useCard(CARD_ID)
  );
  const [groupedData, setGroupedData] = useState<DataItem[] | undefined>();

  const dispatch = useDispatch<AppDispatch>();
  const isOpen = useModal(cardConfig[CARD_ID].modalId) ?? false;
  const openModal = () => {
    !isEmpty && dispatch(setModalOpen(cardConfig[CARD_ID].modalId));
  };

  function groupData(
    originalData: DataPointApplicationFeaturesCardsQueriesDataResponse[]
  ): DataItem[] {
    const customerData = originalData[0];

    if (!customerData) return [];

    const [customerName, ...restValues] = customerData.values?.[0] || [];

    return restValues.reduce((rows: DataItem[], value, i) => {
      if (i % 3 === 0) {
        const nameType = value as string;
        const numericValue = restValues[i + 1];
        const currency = restValues[i + 2] as string;

        rows.push({ customerName, nameType, value: numericValue, currency });
      }
      return rows;
    }, []);
  }

  useEffect(() => {
    if ((card?.data && !isEmpty) || (card?.data && !groupedData)) {
      setGroupedData(groupData(card.data));
    }
  }, [card]);

  const columnHelper = createColumnHelper<DataItem>();
  const columnClass =
    "!text-[12px] !leading-[12px] !font-500 text-primary/60 !text-nowrap";

  const getColumns = (customerName?: string) =>
    [
      columnHelper.accessor("customerName", {
        header: () => (
          <P className={cn(columnClass, "truncate max-w-72")}>{customerName}</P>
        ),
        cell: (row) => {
          return (
            <div className="flex justify-between items-center self-stretch pr-3">
              <P>{row.row.original.nameType}</P>
              <div className="flex gap-2 justify-end">
                {isEmpty ? (
                  <P className={cn("!font-700")}>-</P>
                ) : (
                  <>
                    <P className={cn("!font-700")}>
                      {getDisplayValue(
                        row.row.original.value,
                        row.row.original.currency,
                        true
                      )}
                    </P>
                    <P className="opacity-60 !font-500">
                      {row.row.original.currency}
                    </P>
                  </>
                )}
              </div>
            </div>
          );
        },
      }),
    ] as ColumnDef<unknown, any>[];

  return (
    <Fragment>
      <Card
        {...renderTestId(cardConfig[CARD_ID].testId)}
        onClick={openModal}
        header={
          <CardHeader
            title={cardConfig[CARD_ID].title}
            subtitle={
              <div className={cn("flex", "items-center", "gap-0.5")}>
                <P className={cn("text-xs", "font-400", "opacity-70")}>
                  Frissítve
                </P>
                <P className={cn("text-xs", "font-500", "opacity-80")}>
                  {getDateLabel(card?.updatedAt)}
                </P>
              </div>
            }
            info={cardConfig[CARD_ID].infoText}
            icon={cardConfig[CARD_ID].icon}
          />
        }
        content={
          <CardContent
            isLocked={false}
            isEmpty={isEmpty}
            isNotLoaded={isNotLoaded}
          >
            <ReactTable
              {...renderTestId("credit-limit-table")}
              data={groupedData ?? []}
              columns={getColumns(groupedData && groupedData[0]?.customerName)}
            />
          </CardContent>
        }
        isEmpty={isEmpty}
      />
      {isOpen && <CreditLimitModal />}
    </Fragment>
  );
};

export default CreditLimitCard;
