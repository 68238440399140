import {
  AccountsReceivableManagementCard,
  H1,
  Separator,
} from "src/components";

const StyleGuide = () => {
  return (
    <div className="w-[1200px] max-w-full mx-auto p-4 bg-white rounded-large">
      <Separator className="my-8 opacity-30" />
      <H1 className="mb-4">Overlays</H1>
      <AccountsReceivableManagementCard />
    </div>
  );
};

export default StyleGuide;
