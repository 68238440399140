import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { useUserAccesses } from "src/store/slices/userAccessesSlice";
import { useNavigate } from "react-router-dom";
import { P, Button, IconButton, Icon } from "src/components";
import { setModalOpen } from "src/store/slices/modalSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { renderTestId } from "src/utils/HelperFunctions";
import { useCompany } from "src/store/slices/companySlice";
import {
  DataPointDomainCompaniesCompany,
  useGetMeQuery,
} from "src/store/serviceApi";
import { TranslatedUserRoleValues } from "src/models/Roles";

const UserAccessesHeader = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { userAccesses } = useUserAccesses();
  const { company, tenants } = useCompany();

  const { data: userData } = useGetMeQuery(
    {
      tenantId: company?.tenantId ?? "",
    },
    {
      skip: Boolean(!company),
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <div className="flex flex-wrap items-center md:justify-between self-stretch">
      <div className="flex md:items-center gap-3">
        <IconButton
          {...renderTestId("to-dashboard-button")}
          size="small"
          className="text-[#12121280]"
          iconName="chevron-left"
          onClick={() => navigate(INTERNAL_ROUTE.DASHBOARD)}
        />
        <P className="md:block hidden text-primary !font-500 text-sm">
          Vissza a Dashboardra
        </P>
      </div>
      <div className="md:absolute ml-2 md:ml-0 md:left-1/2 md:transform md:-translate-x-1/2 md:text-center">
        <P className="!font-700 md:text-2xl">Hozzáférések</P>
        <P className="md:text-base text-sm self-stretch justify-center flex">
          {`(${
            tenants?.find((c) => c.id === company?.tenantId)?.name ?? ""
          } Kulcs-Ügyfél)`}
        </P>
      </div>
      <div className="w-full md:w-auto md:flex-grow mt-3 md:mt-0" />
      {userData?.role === TranslatedUserRoleValues.Administrator &&
      userAccesses &&
      userAccesses.length > 1 ? (
        <Button
          {...renderTestId("user-accesses-header")}
          variant="Small"
          className="flex justify-between items-center gap-2 md:w-auto w-full md:mb-0 mb-2"
          onClick={() => dispatch(setModalOpen("invite-user-modal"))}
        >
          <P className="!font-500 !text-sm">Új személy meghívása</P>
          <Icon icon="plus" size={16} />
        </Button>
      ) : null}
    </div>
  );
};

export default UserAccessesHeader;
