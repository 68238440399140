import { useModalData } from "src/store/slices/modalSlice";
import {
  ErrorDisplay,
  Loader,
  MoneyStockModalTable,
  Overlay,
} from "src/components";
import { useCompany } from "src/store/slices/companySlice";
import { useCard } from "src/store/slices/cardsSlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import { isModalDataEmpty, renderTestId } from "src/utils/HelperFunctions";
import { useGetModalQuery } from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const CARD_ID = "21e504e6-eb5f-4228-a671-63bac94cbaa4";
const MODAL_ID = cardConfig[CARD_ID].modalId;

const MoneyStockModal: React.FC<Props> = ({}) => {
  const { company } = useCompany();
  const card = useCard(CARD_ID);

  const {
    data: modalData,
    isLoading,
    isFetching,
  } = useGetModalQuery({
    id: card?.modal ?? 0,
    companyId: company?.id ?? "",
  });

  if (!modalData) return null;

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      className="md:min-w-[640px]"
      title={cardConfig[CARD_ID].title}
      variant="Overlay"
      updatedAt={new Date(modalData.updatedAt as string)}
      icon={cardConfig[CARD_ID].icon}
      size="xs"
    >
      {isLoading || isFetching ? (
        <Loader variant={"blue"} />
      ) : isModalDataEmpty(modalData.data) ? (
        <ErrorDisplay />
      ) : (
        <MoneyStockModalTable data={modalData} />
      )}
    </Overlay>
  );
};
export default MoneyStockModal;
