import { Button } from "src/components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalOpen } from "src/store/slices/modalSlice";
import InvoicesSelectorModal from "./InvoicesSelectorModal";
import { useEffect } from "react";
import {
  setInvoices,
} from "src/store/slices/invoicesSlice";
import { InvoiceOData } from "src/utils/transformers/invoiceDataTransformer";

const InvoicesSelectorDemo = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const testSelectedCompany = {
  //   id: "selectedCo",
  //   name: "Selected Company",
  // };
  const testData: InvoiceOData[] = [];

  useEffect(() => {
    dispatch(setInvoices(testData));
  }, []);

  return (
    <>
      <Button onClick={() => dispatch(setModalOpen("invoices-selector-modal"))}>
        Payee számlák választása
      </Button>
      <InvoicesSelectorModal />
    </>
  );
};

export default InvoicesSelectorDemo;
