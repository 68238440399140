import { setModalData, setModalOpen } from "src/store/slices/modalSlice";
import { AppDispatch } from "src/store/store";
import { useDispatch } from "react-redux";
import { P } from "src/components";
import { DataPointApplicationFeaturesTenantsDtosTenantDto } from "src/store/serviceApi";
import { ReactComponent as LoadingIcon } from "src/assets/icons/item-loading.svg";

interface Props {
  tenantItem: DataPointApplicationFeaturesTenantsDtosTenantDto;
}

const TenantListItem: React.FC<Props> = ({ tenantItem }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleOpenDeleteCompanyModal = () => {
    dispatch(setModalData(tenantItem));
    dispatch(setModalOpen("delete-company-modal"));
  };

  return (
    <div className="flex flex-col items-start gap-4 self-stretch p-4 rounded-3xl border border-black/10">
      <div className="flex justify-between items-start self-stretch">
        <div className="items-center md:flex gap-2">
          <P className="text-sm">
            Adatok szinkronizációja folyamatban a (
            <b>{tenantItem.name ?? ""}</b> Kulcs-Ügyfél) fiókodhoz. Ez eltarthat
            akár 1-2 órát is. Köszönjük a türelmed!
          </P>
        </div>
      </div>
      <div className="flex justify-between self-stretch items-center">
        <div className="flex items-center gap-2">
          <P className="!font-500 !leading-[20px] !text-black/60">Adószám</P>
          <P className="">{tenantItem.taxNumber}</P>
        </div>
        <LoadingIcon />
      </div>
    </div>
  );
};
export default TenantListItem;
