import type {
  DataPointApplicationFeaturesCardsQueriesDataResponse,
  DataPointApplicationFeaturesModalsQueriesModalResponse,
} from "src/store/serviceApi";
import { useEffect, useState } from "react";
import cn from "classnames";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { P, ReactTable } from "src/components";
import { getDisplayValue, renderTestId } from "src/utils/HelperFunctions";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: DataPointApplicationFeaturesModalsQueriesModalResponse;
}

type Data = {
  customerName: string;
  nameType: string;
  value: string;
  currency: string;
};

type DataList = Record<string, Data[]>;

const OpenOrdersModalTable: React.FC<Props> = ({ data }) => {
  const [groupedData, setGroupedData] = useState<DataList | undefined>();

  const columnHelper = createColumnHelper<Data>();

  function groupData(
    originalData: DataPointApplicationFeaturesCardsQueriesDataResponse[]
  ): DataList {
    return originalData.reduce<DataList>((acc, curr) => {
      const entries = curr.values || [];
      entries.forEach((entry) => {
        if (entry) {
          const [customerName, ...restValues] = entry;
          const rows = [];
          for (let i = 0; i < restValues.length; i += 3) {
            const nameType = restValues[i] as string;
            const value = restValues[i + 1];
            const currency = restValues[i + 2] as string;
            rows.push({ customerName, nameType, value, currency });
          }
          acc[customerName] = acc[customerName]
            ? [...acc[customerName], ...rows]
            : rows;
        }
      });
      return acc;
    }, {});
  }

  useEffect(() => {
    if (data?.data) {
      setGroupedData(groupData(data.data));
    }
  }, [data]);

  const getColumns = (customerName?: string) =>
    [
      columnHelper.accessor("customerName", {
        header: () => (
          <P className="text-sm font-500 text-primary break-all whitespace-normal -mb-1.5">
            {customerName}
          </P>
        ),
        cell: (row) => {
          return (
            <div className="flex justify-between items-center self-stretch md:pr-3 md:py-2 py-1 pr-2">
              <P>{row.row.original.nameType}</P>
              <div className="flex gap-2 justify-end">
                <P className={cn("!font-700")}>
                  {getDisplayValue(
                    row.row.original.value,
                    row.row.original.currency
                  )}
                </P>
                <P className="opacity-60 !font-500">
                  {row.row.original.currency}
                </P>
              </div>
            </div>
          );
        },
      }),
    ] as ColumnDef<unknown, any>[];

  const tables =
    groupedData &&
    Object.entries(groupedData).map(([key, data]) => (
      <div className="w-full overflow-hidden">
        <ReactTable
          {...renderTestId(`credit-limit-grouped-data-table-${key}`)}
          key={key}
          data={data}
          columns={getColumns(key)}
          sticky
        />
      </div>
    ));

  return (
    <div className="flex flex-col items-start gap-4 self-stretch">
      <P className="!font-500 text-primary">TOP 10 hitelkeret lista</P>
      {tables}
    </div>
  );
};

export default OpenOrdersModalTable;
