import type { ColumnDef, Row } from "@tanstack/react-table";
import React, { useEffect } from "react";
import cn from "classnames";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Icon, Tooltip } from "src/components";
import { v4 as uuidv4 } from "uuid";
import CollapsibleReactTableHeadCell from "./CollapsibleReactTableHeadCell";

export type CollapsibleData = {
  [key: string]: any;
  collapsible?: {
    [key: string]: any;
  };
};

interface Props {
  data: CollapsibleData[];
  columns: ColumnDef<unknown, any>[];
  renderCollapsibleRow?: ({ row }: { row: Row<any> }) => React.ReactNode;
  variant?: "default" | "non-sticky" | "twocolumn-sticky" | "sticky";
}

type CustomExpandedState = {
  [key: string | number]: boolean;
};

const CollapsibleReactTable: React.FC<Props> = ({
  data,
  columns,
  renderCollapsibleRow,
  variant = "default",
  ...rest
}) => {
  const tooltipId = uuidv4();
  const [expandedRow, setExpandedRow] = React.useState<CustomExpandedState>({});
  const [hoveredRow, setHoveredRow] = React.useState<string | number>();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  const { rows } = table.getRowModel();
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  useEffect(() => {
    setExpandedRow({});
  }, [columns]);

  const handleRowClick = (clickedRow: Row<any>) => {
    if ((clickedRow.original as any)?.collapsible?.rows?.length === 0) return;

    setExpandedRow((s: CustomExpandedState) => ({
      [clickedRow.id]: !s[clickedRow.id],
    }));
  };

  return (
    <div
      ref={tableContainerRef}
      style={{ minHeight: "100px", overflow: "auto", overflowAnchor: "none" }}
      className=""
    >
      <table
        className=" table-auto w-full border-separate border-spacing-0"
        {...rest}
        style={{
          overflow: "auto",
          position: "relative",
          //height: "800px", //should be a fixed height -- original
          minHeight: "100px", //modified
        }}
      >
        <colgroup>
          {columns.map((col, index) => (
            <col key={index} style={col.size ? { width: col.size } : {}} />
          ))}
        </colgroup>
        <thead
          className={cn({
            "bg-[#f5f9fe]": variant === "twocolumn-sticky",
          })}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <CollapsibleReactTableHeadCell
                  key={index + header.id}
                  header={header}
                  variant={variant}
                />
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            position: "relative",
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index];
            return (
              <React.Fragment key={row.id}>
                <tr
                  className={cn(
                    "!py-2",
                    variant === "twocolumn-sticky"
                      ? expandedRow[row.id]
                        ? "  bg-[#e5f2ff]"
                        : "bg-[#fff1]"
                      : expandedRow[row.id]
                      ? "[&>*]:bg-[#e5f2ff]"
                      : "[&>*]:bg-contrast"
                  )}
                  onMouseEnter={() => setHoveredRow(row.id)}
                  onMouseLeave={() => setHoveredRow(undefined)}
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <td
                      key={index + cell.id}
                      className={cn(
                        {
                          "last-of-type:sticky last-of-type:md:relative last:md:shadow-none last-of-type:right-0 last:shadow-[0_0.5rem_0.5em_rgb(0,0,0,0.4)] ":
                            variant === "sticky",
                        },
                        variant === "twocolumn-sticky"
                          ? "first-of-type:sticky first-of-type:md:relative first-of-type:left-0 last-of-type:sticky last-of-type:md:relative last-of-type:right-0"
                          : "bg-primary/10",

                        "tooltip-hover transition-all cursor-pointer py-2 px-5 pr-0 last-of-type:pr-5 border-transparent first-of-type:border-l last-of-type:border-r border-t border-b-0",
                        {
                          "cursor-pointer first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl !border-primary":
                            renderCollapsibleRow && expandedRow[row.id],
                        },
                        cell.column.columnDef.size
                          ? `w-[${cell.column.columnDef.size}%]`
                          : ""
                      )}
                      data-tooltip-id={
                        (row.original as any)?.collapsible?.rows?.length === 0
                          ? undefined
                          : `collapsible-table-row-floater-${tooltipId}`
                      }
                      onClick={() => handleRowClick(row)}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
                {(row.original as any)?.collapsible?.rows?.length === 0 && (
                  <tr>
                    <td
                      className="border border-primary px-2"
                      colSpan={row.getVisibleCells().length ?? 2}
                    ></td>
                  </tr>
                )}

                {renderCollapsibleRow &&
                  Object.keys(expandedRow).length > 0 && (
                    <tr
                      className={cn("collapsible-row", {
                        open: expandedRow[row.id],
                      })}
                    >
                      {/* 2nd row is a custom 1 cell row */}
                      <td
                        colSpan={row.getVisibleCells().length}
                        className={cn(
                          "border border-t-0 border-primary rounded-bl-xl rounded-br-xl"
                        )}
                      >
                        {renderCollapsibleRow({ row })}
                      </td>
                    </tr>
                  )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {renderCollapsibleRow && (
        <Tooltip id={`collapsible-table-row-floater-${tooltipId}`} float>
          {hoveredRow && (
            <div className="hidden md:flex gap-x-3 justify-center items-center">
              <Icon
                icon={
                  Object.keys(expandedRow)[0] === hoveredRow &&
                  Object.values(expandedRow)[0]
                    ? "shrink"
                    : "expand"
                }
                size={16}
                className="text-contrast"
              />
              <span>
                Mutass{" "}
                {Object.keys(expandedRow)[0] === hoveredRow &&
                Object.values(expandedRow)[0]
                  ? "kevesebbet"
                  : "többet"}
              </span>
            </div>
          )}
        </Tooltip>
      )}
    </div>
  );
};

export default CollapsibleReactTable;
