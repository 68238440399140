import React, { useEffect } from "react";
import cn from "classnames";
import { Icon, ModalHeader, P, ReactPortal } from "src/components";
import { getDateLabel } from "src/utils/HelperFunctions";
import { AppDispatch } from "src/store/store";
import { useDispatch } from "react-redux";
import { setModalClose, useModal } from "src/store/slices/modalSlice";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  subTitle?: string | React.ReactNode;
  variant?: modalVariant;
  children: React.ReactNode;
  icon?: React.ComponentProps<typeof Icon>["icon"];
  isOpen?: boolean;
  id: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  headerAdditional?: string | React.ReactNode;
  onClose?: () => void;
  updatedAt?: Date | null | undefined;
}

type modalVariant = "Overlay" | "Custom" | "Profile-menu" | "Empty";

const Overlay: React.FC<Props> = ({
  title = "",
  subTitle,
  variant = "Overlay",
  children,
  icon,
  isOpen = false,
  id,
  size = "md",
  headerAdditional,
  onClose,
  updatedAt,
  className,
  ...rest
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const modal = useModal(id);

  const sizes = {
    xs: "md:w-4/12 lg:w-3/12 md:max-h-[50%] max-h-[90%] w-full",
    sm: "md:w-8/12 lg:w-4/12 md:max-h-[75%] max-h-[90%] w-full",
    md: "md:w-10/12 lg:w-9/12 md:max-h-[80%] max-h-[90%] w-full",
    lg: "md:w-11/12 lg:w-10/12 md:max-h-[90%] max-h-[90%] w-full",
    xl: "lg:w-12/12 md:max-h-[95%] max-h-[90%] md:w-auto w-full",
  };

  useEffect(() => {
    if (isOpen || modal) {
      document.body.classList.add("body-no-scroll");
    } else {
      document.body.classList.remove("body-no-scroll");
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.body.classList.remove("body-no-scroll");
    };
  }, [isOpen, modal]);

  const closeModal = () => {
    dispatch(setModalClose(id));
    onClose?.();
  };

  return (
    <>
      {(isOpen || modal) && (
        <ReactPortal wrapperId={id}>
          <div
            tabIndex={-1}
            aria-hidden="true"
            className={cn(
              "fixed inset-0 z-50 max-h-screen w-screen overflow-y-auto overflow-x-hidden bg-primary/20 backdrop-filter",
              variant === "Profile-menu"
                ? "backdrop-blur-none"
                : "backdrop-blur-sm"
            )}
            onClick={closeModal}
            {...rest}
          >
            <div
              className={cn(
                "flex h-full flex-col w-full",
                variant === "Profile-menu"
                  ? "fixed top-0 right-7"
                  : "justify-center items-center relative"
              )}
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className={cn(
                  "flex flex-col md:gap-y-6 gap-y-2 bg-contrast md:p-6 p-4",
                  variant !== "Profile-menu"
                    ? title === "Cég kiválasztása"
                      ? "rounded-[20px] max-w-72"
                      : "rounded-[20px] m-10 md:relative absolute md bottom-0 md:mb-10 mb-0 md:pb-5 pb-10 md:rounded-b-[20px] rounded-b-[0px]"
                    : "absolute right-0 w-10/12 md:w-5/12 lg:w-4/12 xl:w-3/12 rounded-br-[20px] rounded-bl-[20px]",
                  variant !== "Profile-menu" && sizes[size],
                  className
                )}
              >
                {variant !== "Empty" && (
                  <ModalHeader
                    variant={variant}
                    title={title}
                    icon={icon}
                    additional={headerAdditional}
                    subtitle={
                      variant === "Overlay" ? (
                        <div className={"flex items-center gap-0.5"}>
                          <P className={"text-xs font-400 opacity-70"}>
                            Frissítve
                          </P>
                          <P className={"text-xs !font-500 opacity-80"}>
                            {getDateLabel(updatedAt)}
                          </P>
                        </div>
                      ) : (
                        subTitle
                      )
                    }
                    closeModal={closeModal}
                  />
                )}
                <div
                  className={
                    variant !== "Profile-menu"
                      ? "flex-1 relative overflow-x-auto scrollbar-thumb-primary/50 scrollbar-track-primary/10 scrollbar-thumb-rounded-full scrollbar-thin scrollbar-w-1 scrollbar-gutter"
                      : ""
                  }
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
        </ReactPortal>
      )}
    </>
  );
};

export default Overlay;
