import React from "react";
import { Form as BaseForm } from "react-final-form";
import {
  Checkbox,
  Fieldset,
  Input,
  Password,
  Radio,
  Separator,
  Submit,
} from "src/components";
import { useValidationSchema } from "src/hooks/useValidationSchema";
import { demoValidationScheme } from "src/validation/schemes";

const initialValues = Object.freeze({
  email: "",
  password: "",
  withFieldset: "",
  tickbox: false,
  language: false,
});

const Form = () => {
  const validate = useValidationSchema(demoValidationScheme);

  return (
    <BaseForm
      validateOnBlur
      validate={validate}
      initialValues={initialValues}
      onSubmit={() => alert("FORM SUBMITTED!")}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Input type="email" placeholder="Add your email" name="email" />
          <Separator className="my-4" />
          <Password name="password" />
          <Separator className="my-4" />
          <Fieldset
            label="Very very very long lorem ipsum dolor sit amet title"
            hint="Fill this input"
          >
            <Input placeholder="Add title" name="withFieldset" />
          </Fieldset>
          <Separator className="my-4" />
          <Checkbox name="tickbox" />
          <Separator className="my-4" />

          <div className="flex flex-row gap-x-4">
            <Radio name="language" value="hu" />
            <Radio name="language" value="en" />
            <Radio name="language" value="de" />
          </div>
          <Separator className="my-4" />
          <Submit>Save</Submit>
        </form>
      )}
    </BaseForm>
  );
};

export default Form;
