import type { AppDispatch } from "src/store/store";
import {
  P,
  Button,
  Checkbox,
  Submit,
  Overlay,
  A,
  Buttons,
  Callout,
} from "src/components";
import { useValidationSchema } from "src/hooks/useValidationSchema";
import { startFactoringValidationScheme } from "src/validation/schemes";
import { Form as BaseForm } from "react-final-form";
import { Links } from "src/utils/constants/Links";
import { useCompany } from "src/store/slices/companySlice";
import { renderTestId } from "src/utils/HelperFunctions";
import { useAddFlexibillMutation } from "src/store/serviceApi";
import { useDispatch } from "react-redux";
import { setModalClose } from "src/store/slices/modalSlice";
import { cardConfig } from "src/utils/constants/CardConfig";

const initialValues = Object.freeze({
  tickbox: false,
});

const CARD_ID = "4af4d499-4063-4bc9-b860-2755933bff03";
const PARENT_MODAL_ID = cardConfig[CARD_ID].modalId;
const MODAL_ID = "factoring-start-modal";

const FactoringModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { company } = useCompany();
  const validate = useValidationSchema(startFactoringValidationScheme);
  const [addFlexibill] = useAddFlexibillMutation();
  const links = Links.Flexibill;

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleFlexibill = async () => {
    if (company?.id) {
      await addFlexibill({
        companyId: company?.id,
      })
        .unwrap()
        .then((response) => {
          if (response.ssoLoginUrl) {
            openInNewTab(decodeURIComponent(response.ssoLoginUrl ?? ""));
          } else {
            console.log("response.ssoLoginUrl ????", response);
          }
          dispatch(setModalClose(MODAL_ID));
          dispatch(setModalClose(PARENT_MODAL_ID));
        })
        .catch((error) => {
          console.log("ADD FLEXIBILL ERROR", error);
        });
    }
  };

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      title="Faktorálás indítása"
      variant="Custom"
      className="!w-[680px]"
    >
      <div className="flex flex-col gap-y-4">
        <P className="">
          A digitális faktoring egy a hitelnél okosabb finanszírozási forma,
          amivel hónapok helyett gombnyomásra juthatsz a pénzedhez.
        </P>
        <P className="">
          Mostantól teljesen digitálisan finanszírozzuk meg a vállalkozásod
          aktív számláit, melyeket a partnered ezután nekünk fizet ki.
        </P>
        <P className="">
          Gyakorlatilag “megvesszük” a számláidat, amellyel nem kell megvárnod,
          míg az ügyfeled kiegyenlíti azt.
        </P>
        <P className="">
          Amíg a versenytársaid a pénzükre várnak, te versenyelőnyre tehetsz
          szert és biztosíthatod céged megfelelő likviditását.
        </P>
        <BaseForm
          validate={validate}
          initialValues={initialValues}
          onSubmit={handleFlexibill}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="max-h-[80vh] w-full overflow-auto flex flex-col gap-4">
                <div className="flex px-3 w-full justify-start items-center gap-[10px] self-stretch">
                  <Checkbox
                    {...renderTestId("confirm-checkbox")}
                    variant="grey"
                    name="tickbox"
                  />
                  <span className="text-black/70 ">
                    Elolvastam és elfogadom a Flexibill, azaz a Flexibill Kft{" "}
                    <A
                      {...renderTestId("terms-link")}
                      href={links.TermsAndConditions}
                    >
                      Általános Szolgáltatási Feltételeit
                    </A>{" "}
                    és az{" "}
                    <A
                      {...renderTestId("privacy-link")}
                      href={links.PrivacyPolicy}
                    >
                      Adatkezelési tájékoztatóját.
                    </A>
                  </span>
                </div>
                <div className="flex">
                  <Callout variant="warning">
                    Kérjük, ne indíts faktorálási vagy behajtási folyamatot
                    olyan számlákra, amik már részben vagy teljesen ki vannak
                    egyenlítve. Ennek a felelősége téged terhel, mivel az
                    Adatpont nem rendelkezik a szükséges információkkal más
                    rendszerekben lévő tranzakciókról.
                  </Callout>
                </div>
                <div className="flex justify-end gap-4 self-stretch items-end">
                  <Buttons>
                    <Button
                      {...renderTestId("more-button")}
                      onClick={() => openInNewTab(links.MoreInfo)}
                      variant="Secondary"
                      className="!w-1/2"
                    >
                      <P className="text-primary !font-500 text-base">
                        Bővebb információ és útmutató
                      </P>
                    </Button>
                    <Submit variant="Primary" className="!w-1/2">
                      <P className="text-white !font-500 text-base">
                        Tovább az adatmegadásra
                      </P>
                    </Submit>
                  </Buttons>
                </div>
              </div>
            </form>
          )}
        </BaseForm>
      </div>
    </Overlay>
  );
};
export default FactoringModal;
