import cn from "classnames";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  familyName?: string;
  givenName?: string;
  variant?: "small" | "normal";
}

const Avatar: React.FC<Props> = ({
  variant = "normal",
  onClick,
  children,
  familyName,
  givenName,
  className,
  ...rest
}: Props) => {
  const baseClasses = [
    "p-4",
    "w-14",
    "h-14",
    "bg-contrast",
    "text-primary",
    "font-700",
    "rounded-full",
    "flex",
    "justify-center",
    "items-center",
    "border",
    "border-[#DBDBDB]",
  ];

  const getMonogram = (
    familyName: string | undefined,
    givenName: string | undefined
  ): string => {
    return (familyName ? familyName[0] : "?").concat(
      givenName ? givenName[0] : "?"
    );
  };

  const variants = {
    small: "h-[40px] w-[40px] text-[18px] leading-[24px]",
    normal: "h-[48px] w-[48px] text-[22px] leading-[32px]",
  };

  return (
    <button
      onClick={onClick}
      className={cn(baseClasses, variants[variant])}
      {...rest}
    >
      {children ? children : getMonogram(familyName, givenName)}
    </button>
  );
};

export default Avatar;
