import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {}

const H2: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <h2
      className={cn(
        "font-primary",
        "text-sm",
        "md:text-base",
        "font-medium",
        className
      )}
      {...rest}
    >
      {children}
    </h2>
  );
};

export default H2;
