import type { LabelValue } from "src/models/LabelValue";
import { useEffect, useState } from "react";
import cn from "classnames";
import { P, DataLabel, PieChart, Marker } from "src/components";
import { getDisplayValue } from "src/utils/HelperFunctions";
import { DataPointApplicationFeaturesCardsQueriesDataResponse } from "src/store/serviceApi";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data:
    | DataPointApplicationFeaturesCardsQueriesDataResponse[]
    | null
    | undefined;
  colors?: string[][];
  span?: 1 | 2 | 3 | 4 | 6;
  isEmpty?: boolean;
}

const CardContentPieChartWithLabels: React.FC<Props> = ({
  data,
  colors,
  span = 2,
  isEmpty = false,
}) => {
  const [groupedArray, setGroupedArray] = useState<LabelValue[] | undefined>();

  const getChartSize = () => {
    if (data) {
      if (span === 1) return 96;
      else if (span === 2) {
        if (data[2]) return 104;
        else return 148;
      } else if (span === 3) return 191;
    }
    return undefined;
  };

  useEffect(() => {
    const chartData = data?.find(
      (d: DataPointApplicationFeaturesCardsQueriesDataResponse | undefined) =>
        d?.type === 3
    );

    if (
      chartData &&
      chartData?.headers &&
      chartData?.values &&
      chartData?.values.length > 0
    ) {
      const newGroupedArray: LabelValue[] = [];
      for (let index = 0; index < chartData.values[0].length - 1; index++) {
        const element = {
          label: chartData.headers[index]?.toLowerCase() ?? "",
          value: Number(chartData?.values[0][index].replace(",", ".")) ?? 0,
          uom: chartData?.values[0][2] ?? "",
        } as LabelValue;
        newGroupedArray.push(element);
      }
      setGroupedArray(newGroupedArray);
    } else setGroupedArray(undefined);
  }, [data]);

  const MainDataLabel = data && <DataLabel isEmpty={isEmpty} data={data[0]} />;

  const ExtraDataLabels = data && data[2] && (
    <div
      className={cn(
        "flex md:justify-between justify-normal md:flex-row gap-2 md:gap-0 flex-col items-start",
        { "w-2/3": span !== 2 },
        { "self-stretch": span !== 3 }
      )}
    >
      <DataLabel isEmpty={isEmpty} size="sm" data={data[2]} />
      {data[3] && <DataLabel isEmpty={isEmpty} size="sm" data={data[3]} />}
    </div>
  );

  const Markers = groupedArray?.map((d, i) => (
    <div
      key={i}
      className={cn("flex justify-between items-center self-stretch")}
    >
      <div className={cn("flex items-center gap-1.5")}>
        <Marker
          color={!isEmpty && colors && colors[0] ? colors[0][i] : undefined}
          className={cn("!self-center", isEmpty && "bg-primary/[0.2]")}
        />
        <P className={cn("text-sm md:text-base text-right")}>{d?.label}</P>
      </div>
      <div className={cn("flex justify-end items-baseline gap-1")}>
        <P
          className={cn("text-base font-700", isEmpty && "!text-primary/[0.2]")}
        >
          {isEmpty ? "000 000 000" : getDisplayValue(d?.value, d?.uom, true)}
        </P>
        <P className={cn("font-500 opacity-60")}>{d?.uom}</P>
      </div>
    </div>
  ));

  return (
    <div
      className={cn(
        "flex",

        `items-${span <= 2 || (data && !data[2]) ? "start" : "end"}`,
        { "justify-between": data && !data[2] },
        "gap-2",
        "self-stretch",
        "flex-col",
        "h-full"
      )}
    >
      {span === 2 && MainDataLabel}
      <div className={cn("flex items-center self-stretch")}>
        <span className="md:hidden block">
          <PieChart
            data={!isEmpty ? groupedArray : undefined}
            colors={colors ? colors[1] : undefined}
            size={getChartSize()}
          />
        </span>
        <span className="md:block hidden">
          <PieChart
            data={!isEmpty ? groupedArray : undefined}
            colors={colors ? colors[1] : undefined}
            size={getChartSize()}
          />
        </span>
        <div className={cn("flex flex-col items-start gap-4 flex-[1_0_0]")}>
          {(span === 3 || span === 1) && MainDataLabel}

          {span !== 1 && Markers}
        </div>
      </div>
      {span === 1 && Markers}
      {ExtraDataLabels}
    </div>
  );
};
export default CardContentPieChartWithLabels;
