import { emptySplitApi as api } from "../api/emptyApi";
export const addTagTypes = [
  "adminuser",
  "cards",
  "companies",
  "flexibill",
  "gateway",
  "invitations",
  "invoices",
  "modals",
  "payee",
  "regrequests",
  "tenants",
  "user-settings",
  "users",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAdminUsers: build.query<GetAdminUsersApiResponse, GetAdminUsersApiArg>(
        {
          query: (queryArg) => ({ url: `/api/admin/users/${queryArg.oid}` }),
          providesTags: ["adminuser"],
        }
      ),
      getCards: build.query<GetCardsApiResponse, GetCardsApiArg>({
        query: (queryArg) => ({
          url: `/api/cards`,
          headers: { companyId: queryArg.companyId },
        }),
        providesTags: ["cards"],
      }),
      getCompanies: build.query<GetCompaniesApiResponse, GetCompaniesApiArg>({
        query: () => ({ url: `/api/companies` }),
        providesTags: ["companies"],
      }),
      deleteCompany: build.mutation<
        DeleteCompanyApiResponse,
        DeleteCompanyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/companies/${queryArg.id}`,
          method: "DELETE",
          headers: { tenantId: queryArg.tenantId },
        }),
        invalidatesTags: ["companies"],
      }),
      addFlexibill: build.mutation<AddFlexibillApiResponse, AddFlexibillApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/integrations/flexibill`,
            method: "POST",
            headers: { companyId: queryArg.companyId },
          }),
          invalidatesTags: ["flexibill"],
        }
      ),
      addReport: build.mutation<AddReportApiResponse, AddReportApiArg>({
        query: (queryArg) => ({
          url: `/api/gateway/reports`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["gateway"],
      }),
      getQueries: build.query<GetQueriesApiResponse, GetQueriesApiArg>({
        query: (queryArg) => ({
          url: `/api/gateway/queries`,
          params: { Version: queryArg.version },
        }),
        providesTags: ["gateway"],
      }),
      addInvoice: build.mutation<AddInvoiceApiResponse, AddInvoiceApiArg>({
        query: (queryArg) => ({
          url: `/api/gateway/invoices`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["gateway"],
      }),
      getCompanyByDatabaseGuid: build.query<
        GetCompanyByDatabaseGuidApiResponse,
        GetCompanyByDatabaseGuidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/gateway/companies/${queryArg.databaseguid}`,
        }),
        providesTags: ["gateway"],
      }),
      addCompany: build.mutation<AddCompanyApiResponse, AddCompanyApiArg>({
        query: (queryArg) => ({
          url: `/api/gateway/companies`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["gateway"],
      }),
      addInvite: build.mutation<AddInviteApiResponse, AddInviteApiArg>({
        query: (queryArg) => ({
          url: `/api/invitations`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["invitations"],
      }),
      getInvites: build.query<GetInvitesApiResponse, GetInvitesApiArg>({
        query: (queryArg) => ({
          url: `/api/invitations`,
          headers: { CompanyId: queryArg.companyId },
        }),
        providesTags: ["invitations"],
      }),
      deleteInvite: build.mutation<DeleteInviteApiResponse, DeleteInviteApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/invitations/${queryArg.id}`,
            method: "DELETE",
            headers: { CompanyId: queryArg.companyId },
          }),
          invalidatesTags: ["invitations"],
        }
      ),
      resendInvite: build.mutation<ResendInviteApiResponse, ResendInviteApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/invitations/${queryArg.id}/resend`,
            method: "POST",
            headers: { CompanyId: queryArg.companyId },
          }),
          invalidatesTags: ["invitations"],
        }
      ),
      acceptInvite: build.query<AcceptInviteApiResponse, AcceptInviteApiArg>({
        query: (queryArg) => ({
          url: `/api/invitations/accept`,
          params: {
            State: queryArg.state,
            Id_Token: queryArg.idToken,
            error: queryArg.error,
            error_description: queryArg.errorDescription,
          },
        }),
        providesTags: ["invitations"],
      }),
      acceptRedirect: build.query<
        AcceptRedirectApiResponse,
        AcceptRedirectApiArg
      >({
        query: (queryArg) => ({
          url: `/api/invitations/accept/${queryArg.code}`,
        }),
        providesTags: ["invitations"],
      }),
      getInvoices: build.query<GetInvoicesApiResponse, GetInvoicesApiArg>({
        query: (queryArg) => ({
          url: `/api/invoices`,
          headers: { companyId: queryArg.companyId },
          params: {
            DueDateFrom: queryArg.dueDateFrom,
            DueDateTo: queryArg.dueDateTo,
          },
        }),
        providesTags: ["invoices"],
      }),
      getInvoicesOdata: build.query<
        GetInvoicesOdataApiResponse,
        GetInvoicesOdataApiArg
      >({
        query: (queryArg) => ({
          url: `/api/odata/invoices`,
          headers: { companyId: queryArg.companyId },
        }),
        providesTags: ["invoices"],
      }),
      getModal: build.query<GetModalApiResponse, GetModalApiArg>({
        query: (queryArg) => ({
          url: `/api/modals/${queryArg.id}`,
          headers: { companyId: queryArg.companyId },
        }),
        providesTags: ["modals"],
      }),
      addCase: build.mutation<AddCaseApiResponse, AddCaseApiArg>({
        query: (queryArg) => ({
          url: `/api/integrations/payee/cases`,
          method: "POST",
          body: queryArg.body,
          headers: { companyId: queryArg.companyId },
        }),
        invalidatesTags: ["payee"],
      }),
      addRegrequest: build.mutation<
        AddRegrequestApiResponse,
        AddRegrequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/regrequests`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["regrequests"],
      }),
      getRegrequests: build.query<
        GetRegrequestsApiResponse,
        GetRegrequestsApiArg
      >({
        query: () => ({ url: `/api/regrequests` }),
        providesTags: ["regrequests"],
      }),
      getRegrequest: build.query<GetRegrequestApiResponse, GetRegrequestApiArg>(
        {
          query: (queryArg) => ({ url: `/api/regrequests/${queryArg.id}` }),
          providesTags: ["regrequests"],
        }
      ),
      deleteRegrequest: build.mutation<
        DeleteRegrequestApiResponse,
        DeleteRegrequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/regrequests/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["regrequests"],
      }),
      redirectRegrequest: build.query<
        RedirectRegrequestApiResponse,
        RedirectRegrequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/regrequests/callback`,
          params: {
            State: queryArg.state,
            Id_Token: queryArg.idToken,
            error: queryArg.error,
            error_description: queryArg.errorDescription,
          },
        }),
        providesTags: ["regrequests"],
      }),
      getTenants: build.query<GetTenantsApiResponse, GetTenantsApiArg>({
        query: () => ({ url: `/api/tenants` }),
        providesTags: ["tenants"],
      }),
      addTenant: build.mutation<AddTenantApiResponse, AddTenantApiArg>({
        query: (queryArg) => ({
          url: `/api/tenants`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["tenants"],
      }),
      deleteTenant: build.mutation<DeleteTenantApiResponse, DeleteTenantApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/tenants`,
            method: "DELETE",
            headers: { tenantId: queryArg.tenantId },
          }),
          invalidatesTags: ["tenants"],
        }
      ),
      identifyTenant: build.mutation<
        IdentifyTenantApiResponse,
        IdentifyTenantApiArg
      >({
        query: (queryArg) => ({
          url: `/api/tenants/identify`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["tenants"],
      }),
      getUserSettingsMe: build.query<
        GetUserSettingsMeApiResponse,
        GetUserSettingsMeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-settings/me`,
          headers: { companyId: queryArg.companyId },
        }),
        providesTags: ["user-settings"],
      }),
      updateUserSettingsMe: build.mutation<
        UpdateUserSettingsMeApiResponse,
        UpdateUserSettingsMeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/user-settings/me`,
          method: "POST",
          body: queryArg.body,
          headers: { companyId: queryArg.companyId },
        }),
        invalidatesTags: ["user-settings"],
      }),
      getMe: build.query<GetMeApiResponse, GetMeApiArg>({
        query: (queryArg) => ({
          url: `/api/users/me`,
          headers: { tenantId: queryArg.tenantId },
        }),
        providesTags: ["users"],
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: (queryArg) => ({
          url: `/api/users`,
          headers: { CompanyId: queryArg.companyId },
        }),
        providesTags: ["users"],
      }),
      getUser: build.query<GetUserApiResponse, GetUserApiArg>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}`,
          headers: { CompanyId: queryArg.companyId },
        }),
        providesTags: ["users"],
      }),
      updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
          headers: { CompanyId: queryArg.companyId },
        }),
        invalidatesTags: ["users"],
      }),
      deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}`,
          method: "DELETE",
          headers: { CompanyId: queryArg.companyId },
        }),
        invalidatesTags: ["users"],
      }),
      onboardMe: build.mutation<OnboardMeApiResponse, OnboardMeApiArg>({
        query: () => ({ url: `/api/users/me/onboarded`, method: "POST" }),
        invalidatesTags: ["users"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as serviceApi };
export type GetAdminUsersApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesAdminUsersDtosAdminUserDtoRead;
export type GetAdminUsersApiArg = {
  oid: string;
};
export type GetCardsApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesCardsQueriesCardsResponse;
export type GetCardsApiArg = {
  companyId: string;
};
export type GetCompaniesApiResponse =
  /** status 200 Success */ DataPointDomainCompaniesCompany[];
export type GetCompaniesApiArg = void;
export type DeleteCompanyApiResponse = /** status 204 No Content */ void;
export type DeleteCompanyApiArg = {
  tenantId: string;
  id: string;
};
export type AddFlexibillApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesIntegrationsFlexibillDtosCreaterFlexibillCompanyResponseDto;
export type AddFlexibillApiArg = {
  companyId: string;
};
export type AddReportApiResponse = /** status 201 Created */ void;
export type AddReportApiArg = {
  body: DataPointApplicationFeaturesReportsCommandsCreateReportCommand;
};
export type GetQueriesApiResponse =
  /** status 200 Success */ DataPointDomainQueriesQuery[];
export type GetQueriesApiArg = {
  version: number;
};
export type AddInvoiceApiResponse = /** status 201 Created */ void;
export type AddInvoiceApiArg = {
  body: DataPointApplicationFeaturesInvoicesDtosCreateInvoiceDto;
};
export type GetCompanyByDatabaseGuidApiResponse =
  /** status 200 Success */ DataPointDomainCompaniesCompany;
export type GetCompanyByDatabaseGuidApiArg = {
  databaseguid: string;
};
export type AddCompanyApiResponse = /** status 201 Created */ void;
export type AddCompanyApiArg = {
  body: DataPointApplicationFeaturesCompaniesCommandsCreateCompanyCommand;
};
export type AddInviteApiResponse = /** status 201 Created */ void;
export type AddInviteApiArg = {
  body: DataPointApplicationFeaturesInvitationsCommandsCreateInvitationCommand;
};
export type GetInvitesApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesInvitationsDtosInvitationDto[];
export type GetInvitesApiArg = {
  companyId: string;
};
export type DeleteInviteApiResponse = unknown;
export type DeleteInviteApiArg = {
  companyId: string;
  id: string;
};
export type ResendInviteApiResponse = /** status 200 Success */ void;
export type ResendInviteApiArg = {
  companyId: string;
  id: string;
};
export type AcceptInviteApiResponse = unknown;
export type AcceptInviteApiArg = {
  state?: string;
  idToken?: string;
  error?: string;
  errorDescription?: string;
};
export type AcceptRedirectApiResponse = unknown;
export type AcceptRedirectApiArg = {
  code: string;
};
export type GetInvoicesApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead[];
export type GetInvoicesApiArg = {
  companyId: string;
  dueDateFrom?: string;
  dueDateTo?: string;
};
export type GetInvoicesOdataApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead[];
export type GetInvoicesOdataApiArg = {
  companyId: string;
};
export type GetModalApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesModalsQueriesModalResponse;
export type GetModalApiArg = {
  id: number;
  companyId: string;
};
export type AddCaseApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesIntegrationsPayeeCommandsCreatePayeeCaseResponse;
export type AddCaseApiArg = {
  companyId: string;
  body: DataPointApplicationFeaturesIntegrationsPayeeCommandsCreatePayeeCaseCommand;
};
export type AddRegrequestApiResponse = /** status 201 Created */ void;
export type AddRegrequestApiArg = {
  body: DataPointApplicationFeaturesTenantCreationRequestsCommandCreateTenantCreationRequestCommand;
};
export type GetRegrequestsApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesTenantCreationRequestsDtosTenantCreationRequestDtoRead[];
export type GetRegrequestsApiArg = void;
export type GetRegrequestApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesTenantCreationRequestsDtosTenantCreationRequestDtoRead;
export type GetRegrequestApiArg = {
  id: string;
};
export type DeleteRegrequestApiResponse = /** status 204 No Content */ void;
export type DeleteRegrequestApiArg = {
  id: string;
};
export type RedirectRegrequestApiResponse = unknown;
export type RedirectRegrequestApiArg = {
  state?: string;
  idToken?: string;
  error?: string;
  errorDescription?: string;
};
export type GetTenantsApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesTenantsDtosTenantDto[];
export type GetTenantsApiArg = void;
export type AddTenantApiResponse =
  /** status 200 Success */ DataPointDomainTenantsTenant;
export type AddTenantApiArg = {
  body: DataPointApplicationFeaturesTenantsCommandsCreateTenantCommand;
};
export type DeleteTenantApiResponse = /** status 204 No Content */ void;
export type DeleteTenantApiArg = {
  tenantId: string;
};
export type IdentifyTenantApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse;
export type IdentifyTenantApiArg = {
  body: DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsCommand;
};
export type GetUserSettingsMeApiResponse =
  /** status 200 Success */ DataPointDomainUserSettingsUserSetting;
export type GetUserSettingsMeApiArg = {
  companyId: string;
};
export type UpdateUserSettingsMeApiResponse = unknown;
export type UpdateUserSettingsMeApiArg = {
  companyId: string;
  body: DataPointApplicationFeaturesUserSettingsCommandsUpsertUserSettingCommand;
};
export type GetMeApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesUsersQueriesDtosUserDtoRead;
export type GetMeApiArg = {
  tenantId: string;
};
export type GetUsersApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesUsersQueriesDtosUserDtoRead[];
export type GetUsersApiArg = {
  companyId: string;
};
export type GetUserApiResponse =
  /** status 200 Success */ DataPointApplicationFeaturesUsersQueriesDtosUserDtoRead;
export type GetUserApiArg = {
  companyId: string;
  id: string;
};
export type UpdateUserApiResponse = /** status 204 No Content */ void;
export type UpdateUserApiArg = {
  companyId: string;
  id: string;
  body: DataPointApplicationFeaturesUsersCommandsUpdateUserCommand;
};
export type DeleteUserApiResponse = /** status 204 No Content */ void;
export type DeleteUserApiArg = {
  companyId: string;
  id: string;
};
export type OnboardMeApiResponse = /** status 200 Success */ void;
export type OnboardMeApiArg = void;
export type DataPointDomainUsersUserRole = "Administrator" | "Basic";
export type DataPointDomainTenantsTenantUser = {
  userId?: string;
  role?: DataPointDomainUsersUserRole;
  createdAt?: string;
};
export type DataPointDomainTenantsInvitationStatus = "Sent" | "Accepted";
export type DataPointApplicationFeaturesInvitationsDtosInvitationDto = {
  id?: string;
  from?: string | null;
  to?: string | null;
  createdAt?: string;
  status?: DataPointDomainTenantsInvitationStatus | null;
};
export type DataPointApplicationFeaturesCompaniesDtosAddressDto = {
  country?: string | null;
  countryCode?: string | null;
  zip?: string | null;
  city?: string | null;
  street?: string | null;
  publicDomain?: string | null;
  number?: string | null;
  building?: string | null;
  staircase?: string | null;
  floor?: string | null;
  door?: string | null;
};
export type DataPointApplicationFeaturesCompaniesDtosAddressDtoRead = {
  country?: string | null;
  countryCode?: string | null;
  zip?: string | null;
  city?: string | null;
  street?: string | null;
  publicDomain?: string | null;
  number?: string | null;
  building?: string | null;
  staircase?: string | null;
  floor?: string | null;
  door?: string | null;
  displayAddress?: string | null;
  displayStreet?: string | null;
};
export type DataPointApplicationFeaturesCompaniesDtosTenantCompanyDto = {
  tenantId?: string;
  name?: string | null;
  taxNumber?: string | null;
  address?: DataPointApplicationFeaturesCompaniesDtosAddressDto | null;
  databaseGuid?: string | null;
  isDeleted?: boolean;
  lastSync?: string | null;
};
export type DataPointApplicationFeaturesCompaniesDtosTenantCompanyDtoRead = {
  tenantId?: string;
  name?: string | null;
  taxNumber?: string | null;
  address?: DataPointApplicationFeaturesCompaniesDtosAddressDtoRead | null;
  databaseGuid?: string | null;
  isDeleted?: boolean;
  lastSync?: string | null;
};
export type DataPointApplicationFeaturesAdminUsersDtosAdminUserTenantDto = {
  id?: string | null;
  name?: string | null;
  taxNumber?: string | null;
  companyCode?: string | null;
  users?: DataPointDomainTenantsTenantUser[] | null;
  invitations?:
    | DataPointApplicationFeaturesInvitationsDtosInvitationDto[]
    | null;
  companies?:
    | DataPointApplicationFeaturesCompaniesDtosTenantCompanyDto[]
    | null;
};
export type DataPointApplicationFeaturesAdminUsersDtosAdminUserTenantDtoRead = {
  id?: string | null;
  name?: string | null;
  taxNumber?: string | null;
  companyCode?: string | null;
  users?: DataPointDomainTenantsTenantUser[] | null;
  invitations?:
    | DataPointApplicationFeaturesInvitationsDtosInvitationDto[]
    | null;
  companies?:
    | DataPointApplicationFeaturesCompaniesDtosTenantCompanyDtoRead[]
    | null;
};
export type DataPointApplicationFeaturesAdminUsersDtosAdminUserDto = {
  id?: string;
  objectId?: string;
  email?: string | null;
  familyName?: string | null;
  givenName?: string | null;
  role?: DataPointDomainUsersUserRole | null;
  onboarded?: boolean;
  createdAt?: string | null;
  tenants?:
    | DataPointApplicationFeaturesAdminUsersDtosAdminUserTenantDto[]
    | null;
};
export type DataPointApplicationFeaturesAdminUsersDtosAdminUserDtoRead = {
  id?: string;
  objectId?: string;
  email?: string | null;
  familyName?: string | null;
  givenName?: string | null;
  fullName?: string | null;
  role?: DataPointDomainUsersUserRole | null;
  onboarded?: boolean;
  createdAt?: string | null;
  tenants?:
    | DataPointApplicationFeaturesAdminUsersDtosAdminUserTenantDtoRead[]
    | null;
};
export type MicrosoftAspNetCoreMvcProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type DataPointApplicationFeaturesCardsQueriesDataResponse = {
  displayText?: string | null;
  type?: number;
  dataType?: number;
  headers?: string[] | null;
  values?: string[][] | null;
};
export type DataPointApplicationFeaturesCardsQueriesCardResponse = {
  id?: string;
  updatedAt?: string | null;
  modal?: number | null;
  data?: DataPointApplicationFeaturesCardsQueriesDataResponse[] | null;
  isLocked?: boolean;
};
export type DataPointApplicationFeaturesCardsQueriesCardsResponse = {
  cards?: DataPointApplicationFeaturesCardsQueriesCardResponse[] | null;
};
export type DataPointDomainCompaniesAddress = {
  country?: string | null;
  countryCode?: string | null;
  zip?: string | null;
  city?: string | null;
  street?: string | null;
  publicDomain?: string | null;
  number?: string | null;
  building?: string | null;
  staircase?: string | null;
  floor?: string | null;
  door?: string | null;
};
export type DataPointDomainCompaniesCompany = {
  id?: string;
  tenantId?: string;
  name?: string | null;
  taxNumber?: string | null;
  address?: DataPointDomainCompaniesAddress | null;
  databaseGuid?: string | null;
  isDeleted?: boolean;
};
export type DataPointApplicationFeaturesIntegrationsFlexibillDtosCreaterFlexibillCompanyResponseDto =
  {
    ssoLoginUrl?: string | null;
  };
export type DataPointApplicationFeaturesReportsCommandsCreateReportCommand = {
  dataType?: number;
  headers?: string[] | null;
  values?: string[][] | null;
  databaseVersion?: string | null;
  serviceVersion?: string | null;
  databaseGuid?: string;
};
export type DataPointDomainQueriesQuery = {
  id?: string;
  name?: string | null;
  dataType?: number;
  firebird?: string | null;
  msSql?: string | null;
  minVersion?: number | null;
};
export type DataPointApplicationFeaturesInvoicesDtosAddressDto = {
  country?: string | null;
  countryCode?: string | null;
  zip?: string | null;
  city?: string | null;
  street?: string | null;
  publicDomain?: string | null;
  number?: string | null;
  building?: string | null;
  floor?: string | null;
  staircase?: string | null;
  door?: string | null;
};
export type DataPointApplicationFeaturesInvoicesDtosAddressDtoRead = {
  country?: string | null;
  countryCode?: string | null;
  zip?: string | null;
  city?: string | null;
  street?: string | null;
  publicDomain?: string | null;
  number?: string | null;
  building?: string | null;
  floor?: string | null;
  staircase?: string | null;
  door?: string | null;
  displayAddress?: string | null;
  displayStreet?: string | null;
};
export type DataPointApplicationFeaturesInvoicesDtosCreateInvoiceDto = {
  companyId?: string;
  gueid?: string;
  invoiceNumber?: string | null;
  voucherStatus?: number | null;
  issuerName?: string | null;
  issuerTaxNumber?: string | null;
  issuerAddress?: DataPointApplicationFeaturesInvoicesDtosAddressDto | null;
  customerName?: string | null;
  customerTaxNumber?: string | null;
  privateCustomer?: boolean;
  customerAddress?: DataPointApplicationFeaturesInvoicesDtosAddressDto | null;
  issueDate?: string | null;
  paymentDate?: string | null;
  fulfillmentDate?: string | null;
  netValue?: number;
  taxValue?: number;
  grossValue?: number;
  currency?: string | null;
  currencyRate?: number;
  acquitValue?: number | null;
  rowVersion?: string | null;
};
export type DataPointApplicationFeaturesInvoicesDtosCreateInvoiceDtoRead = {
  companyId?: string;
  gueid?: string;
  invoiceNumber?: string | null;
  voucherStatus?: number | null;
  issuerName?: string | null;
  issuerTaxNumber?: string | null;
  issuerAddress?: DataPointApplicationFeaturesInvoicesDtosAddressDtoRead | null;
  customerName?: string | null;
  customerTaxNumber?: string | null;
  privateCustomer?: boolean;
  customerAddress?: DataPointApplicationFeaturesInvoicesDtosAddressDtoRead | null;
  issueDate?: string | null;
  paymentDate?: string | null;
  fulfillmentDate?: string | null;
  netValue?: number;
  taxValue?: number;
  grossValue?: number;
  currency?: string | null;
  currencyRate?: number;
  acquitValue?: number | null;
  rowVersion?: string | null;
};
export type DataPointApplicationFeaturesCompaniesCommandsCreateCompanyCommand =
  {
    name?: string | null;
    taxNumber?: string | null;
    databaseGuid?: string;
    address?: DataPointDomainCompaniesAddress | null;
  };
export type DataPointApplicationFeaturesInvitationsCommandsCreateInvitationDto =
  {
    tenantId?: string;
    emails?: string[] | null;
  };
export type DataPointApplicationFeaturesInvitationsCommandsCreateInvitationCommand =
  {
    invitations?:
      | DataPointApplicationFeaturesInvitationsCommandsCreateInvitationDto[]
      | null;
  };
export type DataPointApplicationFeaturesInvoicesDtosInvoiceDto = {
  id?: string;
  companyId?: string;
  gueid?: string;
  invoiceNumber?: string | null;
  invoiceStatus?: number | null;
  issuerName?: string | null;
  issuerTaxNumber?: string | null;
  issuerAddress?: DataPointApplicationFeaturesInvoicesDtosAddressDto | null;
  customerName?: string | null;
  customerTaxNumber?: string | null;
  privateCustomer?: boolean;
  customerAddress?: DataPointApplicationFeaturesInvoicesDtosAddressDto | null;
  issueDate?: string | null;
  paymentDate?: string | null;
  fulfillmentDate?: string | null;
  netValue?: number;
  taxValue?: number;
  grossValue?: number;
  currency?: string | null;
  currencyRate?: number;
  payeeStatus?: string | null;
  factoringStatus?: string | null;
  acquitValue?: number;
  rowVersion?: string;
};
export type DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead = {
  id?: string;
  companyId?: string;
  gueid?: string;
  invoiceNumber?: string | null;
  invoiceStatus?: number | null;
  issuerName?: string | null;
  issuerTaxNumber?: string | null;
  issuerAddress?: DataPointApplicationFeaturesInvoicesDtosAddressDtoRead | null;
  customerName?: string | null;
  customerTaxNumber?: string | null;
  privateCustomer?: boolean;
  customerAddress?: DataPointApplicationFeaturesInvoicesDtosAddressDtoRead | null;
  issueDate?: string | null;
  paymentDate?: string | null;
  fulfillmentDate?: string | null;
  netValue?: number;
  taxValue?: number;
  grossValue?: number;
  currency?: string | null;
  currencyRate?: number;
  payeeStatus?: string | null;
  factoringStatus?: string | null;
  acquitValue?: number;
  rowVersion?: string;
};
export type DataPointApplicationFeaturesModalsQueriesModalResponse = {
  updatedAt?: string | null;
  data?: DataPointApplicationFeaturesCardsQueriesDataResponse[] | null;
};
export type DataPointHostInfrastructureDataPointValidationProblemDetails = {
  code?: string | null;
  message?: string | null;
};
export type DataPointHostInfrastructureDataPointProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  validationErrors?:
    | DataPointHostInfrastructureDataPointValidationProblemDetails[]
    | null;
  errorCodes?: string[] | null;
  [key: string]: any;
};
export type MicrosoftAspNetCoreHttpHttpResultsNotFound = {};
export type MicrosoftAspNetCoreHttpHttpResultsNotFoundRead = {
  statusCode?: number;
};
export type DataPointApplicationFeaturesIntegrationsPayeeCommandsCreatePayeeCaseResponse =
  {
    url?: string | null;
  };
export type DataPointApplicationFeaturesIntegrationsPayeePayeeLevels =
  | "Soft"
  | "Medium"
  | "Hard";
export type DataPointApplicationFeaturesIntegrationsPayeeCommandsCreatePayeeCaseCommand =
  {
    level?: DataPointApplicationFeaturesIntegrationsPayeePayeeLevels;
    invoices?: string[] | null;
  };
export type DataPointApplicationFeaturesTenantCreationRequestsCommandCreateTenantCreationRequestCommand =
  {
    emailTo?: string | null;
  };
export type DataPointApplicationFeaturesUsersQueriesDtosUserDto = {
  id?: string;
  objectId?: string;
  email?: string | null;
  familyName?: string | null;
  givenName?: string | null;
  role?: DataPointDomainUsersUserRole | null;
  onboarded?: boolean;
  createdAt?: string | null;
};
export type DataPointApplicationFeaturesUsersQueriesDtosUserDtoRead = {
  id?: string;
  objectId?: string;
  email?: string | null;
  familyName?: string | null;
  givenName?: string | null;
  fullName?: string | null;
  role?: DataPointDomainUsersUserRole | null;
  onboarded?: boolean;
  createdAt?: string | null;
};
export type DataPointDomainTenantCreationRequestsTenantCreationRequestState =
  | "Sent"
  | "Completed";
export type DataPointApplicationFeaturesTenantCreationRequestsDtosTenantCreationRequestDto =
  {
    id?: string;
    from?: DataPointApplicationFeaturesUsersQueriesDtosUserDto | null;
    code?: string | null;
    emailTo?: string | null;
    createdAt?: string;
    state?: DataPointDomainTenantCreationRequestsTenantCreationRequestState | null;
    completedBy?: string;
    assignedTo?: string;
  };
export type DataPointApplicationFeaturesTenantCreationRequestsDtosTenantCreationRequestDtoRead =
  {
    id?: string;
    from?: DataPointApplicationFeaturesUsersQueriesDtosUserDtoRead | null;
    code?: string | null;
    emailTo?: string | null;
    createdAt?: string;
    state?: DataPointDomainTenantCreationRequestsTenantCreationRequestState | null;
    completedBy?: string;
    assignedTo?: string;
  };
export type DataPointApplicationFeaturesTenantsDtosTenantDto = {
  id?: string | null;
  name?: string | null;
  taxNumber?: string | null;
  companyCode?: string | null;
  privacyPolicyAccepted?: boolean;
  termsAndConditionsAccepted?: boolean;
  createdAt?: string;
};
export type DataPointDomainTenantsInvitation = {
  id?: string;
  code?: string | null;
  from?: string | null;
  to?: string | null;
  createdAt?: string;
  status?: DataPointDomainTenantsInvitationStatus | null;
};
export type DataPointDomainTenantsTenant = {
  id?: string;
  name?: string | null;
  taxNumber?: string | null;
  companyCode?: string | null;
  productKeyHash?: string | null;
  users?: DataPointDomainTenantsTenantUser[] | null;
  privacyPolicyAccepted?: boolean;
  termsAndConditionsAccepted?: boolean;
  invitations?: DataPointDomainTenantsInvitation[] | null;
  createdAt?: string;
};
export type DataPointApplicationFeaturesTenantsCommandsCreateTenantCommand = {
  productKey?: string | null;
  taxNumber?: string | null;
  privacyPolicyAccepted?: boolean;
  termsAndConditionsAccepted?: boolean;
  flowCode?: string | null;
};
export type DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse =
  {
    taxNumber?: string | null;
    productKey?: string | null;
    isValid?: boolean;
    companyName?: string | null;
    isExists?: boolean;
    hasConsent?: boolean;
  };
export type DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsCommand =
  {
    taxNumber?: string | null;
    productKey?: string | null;
  };
export type DataPointDomainUserSettingsCardSetting = {
  cardId?: string;
  isVisible?: boolean;
  index?: number;
};
export type DataPointDomainUserSettingsUserSetting = {
  id?: string;
  userId?: string;
  companyId?: string;
  cardSettings?: DataPointDomainUserSettingsCardSetting[] | null;
};
export type DataPointApplicationFeaturesUserSettingsCommandsUpsertUserSettingCommand =
  {
    cardSettings?: DataPointDomainUserSettingsCardSetting[] | null;
  };
export type DataPointApplicationFeaturesUsersCommandsUpdateUserCommand = {
  id?: string;
  familyName?: string | null;
  givenName?: string | null;
  role?: DataPointDomainUsersUserRole;
};
export const {
  useGetAdminUsersQuery,
  useLazyGetAdminUsersQuery,
  useGetCardsQuery,
  useLazyGetCardsQuery,
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useDeleteCompanyMutation,
  useAddFlexibillMutation,
  useAddReportMutation,
  useGetQueriesQuery,
  useLazyGetQueriesQuery,
  useAddInvoiceMutation,
  useGetCompanyByDatabaseGuidQuery,
  useLazyGetCompanyByDatabaseGuidQuery,
  useAddCompanyMutation,
  useAddInviteMutation,
  useGetInvitesQuery,
  useLazyGetInvitesQuery,
  useDeleteInviteMutation,
  useResendInviteMutation,
  useAcceptInviteQuery,
  useLazyAcceptInviteQuery,
  useAcceptRedirectQuery,
  useLazyAcceptRedirectQuery,
  useGetInvoicesQuery,
  useLazyGetInvoicesQuery,
  useGetInvoicesOdataQuery,
  useLazyGetInvoicesOdataQuery,
  useGetModalQuery,
  useLazyGetModalQuery,
  useAddCaseMutation,
  useAddRegrequestMutation,
  useGetRegrequestsQuery,
  useLazyGetRegrequestsQuery,
  useGetRegrequestQuery,
  useLazyGetRegrequestQuery,
  useDeleteRegrequestMutation,
  useRedirectRegrequestQuery,
  useLazyRedirectRegrequestQuery,
  useGetTenantsQuery,
  useLazyGetTenantsQuery,
  useAddTenantMutation,
  useDeleteTenantMutation,
  useIdentifyTenantMutation,
  useGetUserSettingsMeQuery,
  useLazyGetUserSettingsMeQuery,
  useUpdateUserSettingsMeMutation,
  useGetMeQuery,
  useLazyGetMeQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useOnboardMeMutation,
} = injectedRtkApi;
