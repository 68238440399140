import { useLocation, useNavigate } from "react-router-dom";
import { Button, H1, P } from "src/components";
import FailedIcon from "src/assets/icons/result-fail.svg";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";

const CompanyIdentificationInvitationSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-col md:gap-y-6 items-center justify-between">
      <img
        src={FailedIcon}
        alt="Sikertelen cégazonosítás"
        className="w-[120px] h-[120px]"
      />

      <H1 className="text-primary font-bold">Sikertelen kérelem küldés</H1>

      <P className="text-center">
        Az email küldése közben hiba történt. Kérjük próbáld meg{" "}
        <span
          className="text-primary underline cursor-pointer"
          onClick={() =>
            navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION_INVITE_THIRD_PARTY, {
              state: {
                navigatedFromCompanies: location.state?.navigatedFromCompanies,
              },
            })
          }
        >
          újból
        </span>{" "}
        vagy vedd fel a kapcsolatot ügyfélszolgálatunkkal.
      </P>

      <Button
        className="w-full"
        variant="Secondary"
        onClick={() =>
          navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION, {
            state: {
              navigatedFromCompanies: location.state?.navigatedFromCompanies,
            },
          })
        }
      >
        Vissza a termékazonosításhoz
      </Button>
    </div>
  );
};

export default CompanyIdentificationInvitationSuccess;
