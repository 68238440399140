import React, { HtmlHTMLAttributes } from "react";
import { Cell, flexRender } from "@tanstack/react-table";
import cn from "classnames";

interface TableCellProps extends HtmlHTMLAttributes<HTMLTableCellElement> {
  cell: Cell<any, any>;
  sticky: boolean | undefined;
  variant: "Default" | "Collapsible";
  row: any;
  wrapCells?: boolean;
}

const ReactTableBodyCell: React.FC<TableCellProps> = ({
  cell,
  sticky,
  variant,
  row,
  wrapCells,
  className,
}) => {
  return (
    <td
      className={cn(
        sticky &&
          "last-of-type:whitespace-nowrap md:last-of-type:relative last-of-type:sticky last-of-type:right-0 last:shadow-[0_0.5rem_0.5em_rgb(0,0,0,0.4)] md:last:shadow-none",
        variant === "Default"
          ? "py-1"
          : "py-2 md:px-5 px-3 pr-0 last-of-type:pr-5 border-transparent first-of-type:border-l last-of-type:border-r border-t border-b-0",
        {
          "!border-b-2 !border-b-primary !pb-0": (row.original as any)
            .isDivider,
        }
      )}
    >
      <div
        className={cn(
          (row.original as any).isDivider
            ? "text-sm text-primary font-500"
            : "md:text-base text-sm",
          "tooltip-hover",
          wrapCells
            ? "whitespace-normal text-ellipsis break-words"
            : "md:whitespace-normal whitespace-nowrap md:text-ellipsis md:break-words",
          className
        )}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </div>
    </td>
  );
};

export default ReactTableBodyCell;
