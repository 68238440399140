import { CollapsibleReactTable, P } from "src/components";
import cn from "classnames";
import { ColumnDef, Row, createColumnHelper } from "@tanstack/react-table";
import { LabelValue } from "src/models/LabelValue";
import { useCallback, useEffect, useState } from "react";
import { dateFormatOptions, monthLabels } from "src/utils/constants/Variants";
import { getDisplayValue, renderTestId } from "src/utils/HelperFunctions";
import { DataPointApplicationFeaturesModalsQueriesModalResponse } from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  activeTab: "Hónap" | "Nap";
  data: DataPointApplicationFeaturesModalsQueriesModalResponse;
}

type InvoiceData = {
  partner?: string;
  voucherDate?: string;
  fulfillmentDate?: string;
  invoiceNumber?: string;
  value?: number;
  uom?: string;
};

type DataMonthy = {
  year: string;
  sum: number;
  uom: string;

  collapsible: { rows: LabelValue[] };
};

type DataDaily = {
  year: string;
  month: string;
  day: string;
  sum: number;
  uom: string;

  collapsible: { rows: InvoiceData[] };
};

const IncomeModalTable: React.FC<Props> = ({ data, activeTab }) => {
  const [groupedDataByMonths, setGroupedDataByMonths] = useState<
    DataMonthy[] | undefined
  >();
  const [groupedDataByDays, setGroupedDataByDays] = useState<
    DataDaily[] | undefined
  >();

  const groupDataByMonths = useCallback((data: string[][]) => {
    const groupedData: DataMonthy[] = [];

    data.forEach((item) => {
      const year = item[0];
      const month = item[1];
      const label = monthLabels[month]?.long || "";
      const value = Number(item[2]);
      const uom = item[3];

      const labelValue: LabelValue = {
        label,
        value,
        uom,
      };

      let group = groupedData.find((group) => group.year === year);

      if (!group) {
        group = {
          year,
          sum: 0,
          uom: "",
          collapsible: { rows: [] },
        };
        groupedData.push(group);
      }

      group.collapsible.rows.push(labelValue);
      group.sum += value;
      group.uom = uom;
    });

    return groupedData.reverse();
  }, []);

  type MonthData = {
    month: string;
    days: DataDaily[];
    sum: number;
    uom: string;
  };

  type YearData = {
    year: string;
    months: MonthData[];
  };

  const groupDataByDays = useCallback((data: string[][]) => {
    const groupedData: YearData[] = [];

    data.forEach((item) => {
      const [
        year,
        month,
        day,
        partner,
        voucherDateString,
        fulfillmentDateString,
        invoiceNumber,
        valueString,
        uom,
      ] = item;
      const value = Number(valueString);

      let yearGroup = groupedData.find((y) => y.year === year);
      if (!yearGroup) {
        yearGroup = { year, months: [] };
        groupedData.push(yearGroup);
      }

      let monthGroup = yearGroup.months.find((m) => m.month === month);
      if (!monthGroup) {
        monthGroup = { month, days: [], sum: 0, uom: "" };
        yearGroup.months.push(monthGroup);
      }

      const invoiceData: InvoiceData = {
        partner,
        voucherDate: new Date(voucherDateString).toLocaleDateString(
          "hu-HU",
          dateFormatOptions
        ),
        fulfillmentDate: new Date(fulfillmentDateString).toLocaleDateString(
          "hu-HU",
          dateFormatOptions
        ),
        invoiceNumber,
        value,
        uom,
      };

      let dayGroup = monthGroup.days.find((d) => d.day === day);
      if (!dayGroup) {
        dayGroup = {
          year,
          month,
          day,
          sum: value,
          uom,
          collapsible: { rows: [invoiceData] },
        };
        monthGroup.days.push(dayGroup);
      } else {
        dayGroup.collapsible.rows.push(invoiceData);
        dayGroup.sum += value;
      }
      monthGroup.sum += value;
      monthGroup.uom = uom;
    });

    const result: DataDaily[] = [];
    groupedData.reverse().forEach((year) => {
      year.months.reverse().forEach((month) => {
        let sumRow: DataDaily = {
          year: year.year,
          month: monthLabels[month.month]?.long || "",
          day: "",
          sum: month.sum,
          uom: month.uom,
          collapsible: { rows: [] },
        };
        result.push(sumRow);
        month.days.forEach((day) => {
          result.push({
            ...day,
            month: monthLabels[day.month]?.long || "",
          });
        });
      });
    });

    return result;
  }, []);

  useEffect(() => {
    if (
      data.data &&
      data.data[0] &&
      data.data[1] &&
      data.data[0].values &&
      data.data[1].values &&
      data.data[0].values.length > 0 &&
      data.data[1].values.length > 0
    ) {
      setGroupedDataByMonths(groupDataByMonths(data.data[0].values));
      setGroupedDataByDays(groupDataByDays(data.data[1].values));
    }
  }, [data, groupDataByDays, groupDataByMonths]);

  const columnHelperMonth = createColumnHelper<DataMonthy>();
  const columnHelperDay = createColumnHelper<DataDaily>();

  const columnsMonth = [
    columnHelperMonth.accessor("year", {
      header: () => "Dátum",
      size: 50,
      cell: (row) => (
        <P className="line-clamp-1 opacity-60 text-sm md:text-base">
          {row.getValue()}
        </P>
      ),
    }),
    columnHelperMonth.accessor("sum", {
      header: () => <div className="text-right">Összeg</div>,
      cell: (row) => (
        <div className="flex gap-2 justify-end">
          <P className="!font-700 text-sm md:text-base">
            {getDisplayValue(row.getValue(), row.row.original.uom)}
          </P>

          <P className="opacity-60 text-sm md:text-base font-500">
            {row.row.original.uom}
          </P>
        </div>
      ),
    }),
  ] as ColumnDef<unknown, any>[];

  const columnsDay = [
    columnHelperDay.accessor("month", {
      header: () => "Dátum",
      size: 50,
      cell: (row) => {
        if (row.row.original.day === "") {
          return (
            <P className="-mb-2.5 text-primary !text-sm !font-500">{`${row.row.original.year} ${row.row.original.month}`}</P>
          );
        }
        return (
          <P className="line-clamp-1 text-sm md:text-base opacity-60">{`${row.row.original.month} ${row.row.original.day}`}</P>
        );
      },
    }),
    columnHelperDay.accessor("sum", {
      header: () => <div className="text-right">Összeg</div>,
      size: 50,
      cell: (row) => (
        <div className="flex gap-2 justify-end">
          <P
            className={cn("!font-700 text-sm md:text-base", {
              "text-primary !text-sm -mb-2.5": row.row.original.day === "",
            })}
          >
            {getDisplayValue(row.getValue(), row.row.original.uom)}
          </P>

          <P
            className={
              row.row.original.day === ""
                ? "text-sm text-primary -mb-2.5"
                : "opacity-60 text-sm md:text-base"
            }
          >
            {row.row.original.uom}
          </P>
        </div>
      ),
    }),
  ] as ColumnDef<unknown, any>[];

  const renderCollapsibleRowMonth = ({ row }: { row: Row<DataMonthy> }) => {
    const { rows } = row.original.collapsible;

    return (
      <table className="table-auto w-full border-separate border-spacing-0 mb-3">
        <tbody>
          {rows.map((row: LabelValue) => (
            <tr
              className={cn(
                "md:h-12 h-10 px-3 items-center justify-center [&>*]:bg-contrast [&>*]:odd:bg-[#f5f9fe] m-auto last-of-type:rounded-b-large"
              )}
            >
              <td className="w-1/2 items-center py-2 px-2 md:h-12 h-10">
                <div className="text-sm md:text-base opacity-60">
                  {row.label}
                </div>
              </td>
              <td className="flex gap-1 md:h-12 h-10 py-2 px-3 justify-end items-center ">
                <P className="text-sm md:text-base !font-500">
                  {getDisplayValue(row.value, row.uom)}
                </P>
                <P className="text-sm md:text-base !font-500 !text-black/60">
                  {row.uom}
                </P>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderCollapsibleRowDay = ({ row }: { row: Row<DataDaily> }) => {
    const { rows } = row.original.collapsible;
    const headerclass =
      "py-1 px-3 md:text-sm text-xs text-primary font-400 w-[20%]";

    return (
      <table className="w-full overflow-x-auto border-separate border-spacing-0 mb-3">
        <thead>
          <tr className="[&>*]:bg-contrast">
            <th className={cn(headerclass, "text-left")}>Partner</th>
            <th className={cn(headerclass, "text-left")}>Kelte</th>
            <th className={cn(headerclass, "text-left")}>Fizetési határidő</th>
            <th className={cn(headerclass, "text-left")}>Számlaszám</th>
            <th
              className={cn(
                headerclass,
                "text-right sticky right-0 border-r border-primary shadow-[0_0.5rem_0.5em_rgb(0,0,0,0.4)] md:shadow-none"
              )}
            >
              Összeg
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row: InvoiceData, index: number) => (
            <tr
              key={index}
              className={cn(
                "md:h-12 h-10 px-3 last:rounded-b-4xl [&>*]:px-3 items-center bg-contrast odd:bg-[#f5f9fe] [&>*]:bg-contrast [&>*]:odd:bg-[#f5f9fe]"
              )}
            >
              <td
                className={cn(
                  "h-full !min-w-40 md:min-w-auto text-left mt-3 ",
                  {
                    "rounded-bl-lg": index === rows.length - 1,
                  }
                )}
              >
                <div className="text-sm md:text-base tooltip-hover text-ellipsis">
                  <span className="md:line-clamp-1 break-all whitespace-normal">
                    {row.partner}
                    <span className="tooltip-contents !break-all">
                      {row.partner}
                    </span>
                  </span>
                </div>
              </td>
              <td className="text-sm md:text-base text-left ">
                <div className="text-sm md:text-base tooltip-hover text-ellipsis">
                  <span className="md:line-clamp-1 md:break-all md:whitespace-normal whitespace-nowrap">
                    <span className="opacity-60">{row.voucherDate}</span>
                    <span className="tooltip-contents">{row.voucherDate}</span>
                  </span>
                </div>
              </td>
              <td className="text-sm md:text-base font-500 text-left ">
                <div className="text-sm md:text-base tooltip-hover text-ellipsis">
                  <span className="md:line-clamp-1 md:break-all md:whitespace-normal whitespace-nowrap">
                    {row.fulfillmentDate}
                    <span className="tooltip-contents">
                      {row.fulfillmentDate}
                    </span>
                  </span>
                </div>
              </td>
              <td className="text-sm md:text-base text-left ">
                <div className="text-sm md:text-base tooltip-hover text-ellipsis">
                  <span className="md:line-clamp-1 md:break-all md:whitespace-normal whitespace-nowrap">
                    {row.invoiceNumber}
                    <span className="tooltip-contents">
                      {row.invoiceNumber}
                    </span>
                  </span>
                </div>
              </td>
              <td
                className={cn(
                  "border-r border-primary text-sm md:text-base text-right max-w-44 !whitespace-normal sticky right-0 shadow-[0_0.5rem_0.5em_rgb(0,0,0,0.4)] md:shadow-none",
                  { "rounded-br-lg": index === rows.length - 1 }
                )}
              >
                <span className="text-sm md:text-base font-700 mr-1 ">
                  {getDisplayValue(row.value, row.uom)}
                </span>
                <span className="text-black/60 text-sm md:text-base">
                  {row.uom}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return activeTab === "Hónap" ? (
    <CollapsibleReactTable
      variant="non-sticky"
      {...renderTestId("income-modal-months")}
      data={groupedDataByMonths ?? []}
      columns={columnsMonth}
      renderCollapsibleRow={renderCollapsibleRowMonth}
    />
  ) : (
    <CollapsibleReactTable
      variant="twocolumn-sticky"
      {...renderTestId("income-modal-days")}
      data={groupedDataByDays ?? []}
      columns={columnsDay}
      renderCollapsibleRow={renderCollapsibleRowDay}
    />
  );
};
export default IncomeModalTable;
