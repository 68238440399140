import type { AppDispatch } from "src/store/store";
import { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { Button, Buttons, H1, P, PaginationTabs } from "src/components";
import { ReactComponent as FollowYourFinancial } from "src/assets/images/onboarding-images/1st-step-following-financial.svg";
import { ReactComponent as CustomizationFigure } from "src/assets/images/onboarding-images/2nd-step-customization.svg";
import { ReactComponent as InitPayeeFigure } from "src/assets/images/onboarding-images/3rd-step-init-payee.svg";
import { ReactComponent as DigitalfactoringFigure } from "src/assets/images/onboarding-images/4th-step-digital-factoring.svg";
import { renderTestId } from "src/utils/HelperFunctions";
import { useOnboardMeMutation } from "src/store/serviceApi";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { setOnboardDone } from "src/store/slices/userSlice";

const Onboarding = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [onboardMe] = useOnboardMeMutation();
  const [page, setPage] = useState(0);

  const onBoardingStepsContent = [
    {
      content: <FollowYourFinancial className="block h-full w-full" />,
      title: "Kövesse nyomon vállalkozása pénzügyeit",
      explanation:
        "Az Adatkártyák segítségével vállalkozása fontos pénzügyi adatait és kimutatásait tekintheti át egyszerűen, valós időben.",
    },
    {
      content: <CustomizationFigure className="block h-full w-full" />,
      title: "Egyéni beállítások",
      explanation:
        "Saját igényeinek megfelelően módosíthatja az adatkártyák sorrendjét.",
    },
    {
      content: <InitPayeeFigure className="block h-full w-full" />,
      title: "Kevesebb kifizetetlen számla",
      explanation:
        "Az Adatpont felületéről pár lépésben indíthatsz követeléskezelési folyamatot a Payee segítségével, így csökkenteni tudod az ezzel járó adminisztrációs feladatokat és a stresszt.",
    },
    {
      content: <DigitalfactoringFigure className="block h-full w-full" />,
      title: "Digitális faktoring - gyorsabban juthatsz a pénzedhez",
      explanation:
        "A flexibill segítségével nem kell hónapokat várnod a számláid kifizetésére. ",
    },
  ];

  const getOnboardingStepsContent = ({
    content,
    title,
    explanation,
  }: {
    content: ReactNode;
    title: string;
    explanation: string;
  }) => (
    <div
      {...renderTestId("onboarding-template")}
      className="flex flex-col gap-y-6 justify-center items-center"
    >
      {content}
      <div className="flex flex-col gap-y-6 justify-between items-center min-h-[90px]">
        <H1
          className={cn(
            "!text-primary",
            "text-[22px]",
            "leading-ks-32",
            "md:text-24-32",
            "!font-700",
            "!max-w-max"
          )}
        >
          {title}
        </H1>
        <P
          className={cn(
            "!font-14",
            "!break-normal",
            "!max-w-max",
            "!text-center"
          )}
        >
          {explanation}
        </P>
      </div>
    </div>
  );

  const getNextPage = async () => {
    if (page < onBoardingStepsContent.length - 1) {
      setPage(page + 1);
    }
    if (page + 1 === onBoardingStepsContent.length) {
      await handleOnboarding();
    }
  };

  const handleOnboarding = async () => {
    await onboardMe()
      .then(() => {
        dispatch(setOnboardDone());
        navigate(INTERNAL_ROUTE.DASHBOARD);
        console.log("ONBOARDING OK");
      })
      .catch((error) => {
        console.log("ONBOARDING ERROR", error);
      });
  };

  return (
    <div className="flex flex-col w-full md:h-min h-[90vh] md:m-auto">
      <main className="flex items-center justify-center">
        <div className="flex flex-col md:w-7/12 p-4 md:p-10 md:rounded-[20px] justify-center gap-y-6 items-center md:min-h-[673px] bg-contrast">
          <PaginationTabs
            data={onBoardingStepsContent.map((step: any) => {
              return {
                label: "",
                content: getOnboardingStepsContent(step),
              };
            })}
            pageFromParent={page}
            setPageFromParent={setPage}
            className="md:visible invisible"
          />
          <Buttons className="flex-col-reverse">
            <Button
              {...renderTestId("skip-button")}
              variant="Secondary"
              onClick={handleOnboarding}
            >
              Kihagyom
            </Button>
            <Button
              {...renderTestId("next-button")}
              variant="Primary"
              onClick={getNextPage}
            >
              Tovább
            </Button>
          </Buttons>
        </div>
      </main>
    </div>
  );
};

export default Onboarding;
