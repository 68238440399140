import { ComponentProps } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import cn from "classnames";

interface TooltipProps extends ComponentProps<typeof ReactTooltip> {}

// https://react-tooltip.com/docs/options#available-props
const Tooltip: React.FC<TooltipProps> = ({
  children,
  id,
  variant = "dark",
  float,
  className,
}) => {
  return (
    <ReactTooltip
      id={id}
      float={float}
      noArrow={float}
      place={float ? "bottom-start" : "top"}
      offset={float ? 20 : 0}
      className={cn(
        "shadow-[0px_0px_12px_0px_rgba(53,56,82,0.30)] !rounded-xl !p-3 !w-auto",
        className
      )}
      variant={variant}
    >
      <span className="overflow-auto">{children as any}</span>
    </ReactTooltip>
  );
};

export default Tooltip;
