import React from "react";
import cn from "classnames";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  // DropdownMenuCheckboxItem,
  // DropdownMenuRadioItem,
  // DropdownMenuLabel,
  // DropdownMenuSeparator,
  // DropdownMenuShortcut,
  // DropdownMenuGroup,
  // DropdownMenuPortal,
  // DropdownMenuSub,
  // DropdownMenuSubContent,
  // DropdownMenuSubTrigger,
  // DropdownMenuRadioGroup,
} from "src/components/_ui/dropdown-menu";
import { Icon } from "src/components";

type Item = {
  name: string;
  onClick: () => void;
  className?: string;
};
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  items: Item[];
  size?: "sm" | "md";
  color?: "primary" | "black/40";
}

const ActionButton: React.FC<Props> = ({
  items,
  size = "sm",
  color = "text-black/40",
  className,
}) => {
  const sizes = { sm: "w-10 h-10", md: "w-12 h-12" };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div
          className={cn(
            "flex justify-center items-center rounded-xl border border-light-gray hover:border-blue-500 transition-colors select-none",
            sizes[size],
            className
          )}
        >
          <Icon icon="dotburger" className={`text-${color}`} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" side="bottom" align="end">
        {items?.length > 0 &&
          items.map(({ name, onClick, className }, index) => (
            <DropdownMenuItem
              onClick={onClick}
              key={`dropdown-menu-item-${name}-${index}`}
              className={cn(
                "odd:bg-white even:bg-primary/[0.025] cursor-pointer",
                className
              )}
            >
              {name}
            </DropdownMenuItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ActionButton;
