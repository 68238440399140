import {
  ErrorDisplay,
  Loader,
  Overlay,
  P,
  PieChartWithLabels,
  SupplierDebtModalTable,
} from "src/components";
import { useCard } from "src/store/slices/cardsSlice";
import { useCompany } from "src/store/slices/companySlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import { isModalDataEmpty, renderTestId } from "src/utils/HelperFunctions";
import { useGetModalQuery } from "src/store/serviceApi";

const CARD_ID = "de591baa-7e2c-4991-b2bf-23891ae00123";
const MODAL_ID = cardConfig[CARD_ID].modalId;

interface Props {
  span?: 1 | 2 | 3 | 4 | 6;
}

const SupplierDebtModal: React.FC<Props> = ({ span }) => {
  const { company } = useCompany();
  const card = useCard(CARD_ID);

  const {
    data: modalData,
    isLoading,
    isFetching,
  } = useGetModalQuery({
    id: card?.modal ?? 0,
    companyId: company?.id ?? "",
  });

  if (!modalData) return null;

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      className="md:min-w-[660px] md:max-w-[1000px]"
      title={cardConfig[CARD_ID].modalTitle}
      variant="Overlay"
      updatedAt={new Date(modalData.updatedAt as string)}
      icon={cardConfig[CARD_ID].icon}
      size="md"
    >
      {isLoading || isFetching ? (
        <Loader variant={"blue"} />
      ) : isModalDataEmpty(modalData.data) ? (
        <ErrorDisplay />
      ) : (
        <div className="flex gap-4 flex-col">
          <div className="!bg-primary/5 rounded-xl self-stretch">
            <PieChartWithLabels
              className="!h-fit !bg-transparent w-1/2"
              data={modalData.data && [modalData.data[0], modalData.data[1]]}
              span={span}
              colors={[
                ["orange", "green"],
                ["#FF6301", "#008F87"],
              ]}
            />
          </div>
          <P className="text-primary !font-500">TOP 5 szállítói tartozás</P>
          <div className="contents w-full overflow-auto pb-1 mr-1 scrollbar-thumb-primary/50 scrollbar-track-primary/10 scrollbar-thumb-rounded-full scrollbar-thin scrollbar-w-1 ">
            <SupplierDebtModalTable
              data={modalData.data && modalData.data[2]}
            />
          </div>
        </div>
      )}
    </Overlay>
  );
};
export default SupplierDebtModal;
