import { Suspense } from "react";
import { setModalData, setModalOpen } from "src/store/slices/modalSlice";
import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { useCompany } from "src/store/slices/companySlice";
import {
  ReactTable,
  P,
  Tag,
  ActionButton,
  GrantAdminRoleModal,
  SelectInput,
} from "src/components";
import cn from "classnames";
import {
  UserAccess,
  useUserAccesses,
} from "src/store/slices/userAccessesSlice";
import { dateFormatOptions } from "src/utils/constants/Variants";
import { renderTestId } from "src/utils/HelperFunctions";
import { useToast } from "src/components/_ui/toast/use-toast";
import {
  DataPointApplicationFeaturesUsersCommandsUpdateUserCommand,
  useGetMeQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "src/store/serviceApi";
import {
  TranslatedStatuses,
  TranslatedUserRoleLabels,
  TranslatedUserRoleValues,
} from "src/models/Roles";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const cellClass = " px-2";

const roles = [
  {
    label: TranslatedUserRoleLabels.Administrator,
    value: TranslatedUserRoleValues.Administrator,
  },
  {
    label: TranslatedUserRoleLabels.Basic,
    value: TranslatedUserRoleValues.Basic,
  },
];

const UserAccessesTable: React.FC<Props> = () => {
  const columnHelper = createColumnHelper<UserAccess>();
  const dispatch = useDispatch<AppDispatch>();
  const { toast } = useToast();

  const { userAccesses } = useUserAccesses();
  const { company } = useCompany();
  const { data: usersData } = useGetUsersQuery(
    {
      companyId: company?.id || "",
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: userData } = useGetMeQuery(
    {
      tenantId: company?.tenantId || "",
    },
    { refetchOnMountOrArgChange: true }
  );

  const [updateUser] = useUpdateUserMutation();

  const changeRole = async (
    role: DataPointApplicationFeaturesUsersCommandsUpdateUserCommand["role"],
    data: UserAccess
  ) => {
    if (role === data.role) return;
    if (role === TranslatedUserRoleValues.Administrator) {
      handleOpenModal("grant-admin-role-modal", { ...data, role });
    } else {
      await updateUser({
        companyId: company?.id ?? "",
        id: userData?.id ?? "",
        body: {
          ...data,
          role,
        },
      })
        .then((res) => {
          if (!(res as any).data) {
            toast({
              title: "Sikeresen módosítottad a szerepkört",
              variant: "success",
            });
          } else
            toast({
              title: "A szerepkör módosítása sikertelen",
              variant: "destructive",
            });
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: "A szerepkör módosítása sikertelen",
            variant: "destructive",
          });
        });
    }
  };

  const handleOpenModal = (modalId: string, data: any) => {
    dispatch(setModalData(data));
    dispatch(setModalOpen(modalId));
  };

  const columns = [
    columnHelper.accessor("givenName", {
      header: () => "Név / Státusz",

      cell: ({ row }) =>
        row.original.objectId ? (
          <P
            className={cn("md:line-clamp-1", {
              "font-700 text-primary":
                row.original.objectId === userData?.objectId,
            })}
          >
            {`${row.original.familyName} ${row.original.givenName}`}
            <span className="tooltip-contents">{`${row.original.familyName} ${row.original.givenName}`}</span>
          </P>
        ) : (
          <>
            {row.original.status ? (
              <Tag color="orange" className="w-full text-center break-keep">
                {TranslatedStatuses[row.original.status]}
              </Tag>
            ) : (
              "-"
            )}
          </>
        ),
    }),
    columnHelper.accessor("email", {
      header: () => "Email",
      cell: ({ getValue }) => (
        <P className="md:truncate md:max-w-40">
          {getValue()}
          <span className="tooltip-contents whitespace-normal">
            {getValue()}
          </span>
        </P>
      ),
    }),
    columnHelper.accessor("role", {
      header: () => "Szerepkör",
      cell: ({ row }) =>
        !row.original.objectId ? (
          <P
            className={cn(
              cellClass,
              "!text-black/20 md:py-2 md:whitespace-nowrap"
            )}
          >
            Felhasználó
          </P>
        ) : userData?.role === TranslatedUserRoleValues.Administrator ? (
          <div className="w-[150px] md:py-2">
            <SelectInput
              variant="small"
              {...renderTestId("default-company-select")}
              defaultSelectValue={
                {
                  value: row.original?.role ?? "",
                  label: row.original?.role
                    ? TranslatedUserRoleLabels[row.original?.role]
                    : "",
                } as any
              }
              onChangeSelect={(option: any, meta: any) => {
                changeRole(
                  (option as any)
                    .value as DataPointApplicationFeaturesUsersCommandsUpdateUserCommand["role"],
                  row.original
                );
              }}
              options={
                roles?.filter((r) => r.value !== row.original?.role) as any
              }
            />
          </div>
        ) : (
          <P className={cn(cellClass, "md:py-2")}>
            {row.original?.role
              ? TranslatedUserRoleLabels[row.original?.role]
              : ""}
          </P>
        ),
    }),
    columnHelper.accessor("createdAt", {
      header: () => "Hozzáadás dátuma",
      cell: ({ getValue }) => (
        <P className={cn(cellClass, "!text-black/60 min-w-32")}>
          {new Date(getValue()).toLocaleDateString("hu-HU", dateFormatOptions)}
        </P>
      ),
    }),
    columnHelper.accessor("id", {
      header: () => <P className="!text-transparent">.</P>,
      cell: ({ row }) => (
        <div className={cellClass}>
          {userData?.role === TranslatedUserRoleValues.Administrator && (
            <ActionButton
              items={
                row.original.objectId
                  ? [
                      {
                        name: "Felhasználó eltávolítása",
                        onClick: () =>
                          handleOpenModal("delete-user-modal", row.original),
                        className: "!text-destructive !bg-destructive/[0.05]",
                      },
                    ]
                  : [
                      {
                        name: "Meghívás újraküldése",
                        onClick: () =>
                          handleOpenModal(
                            "resend-invitation-modal",
                            row.original
                          ),
                      },
                      {
                        name: "Meghívás visszavonása",
                        onClick: () =>
                          handleOpenModal(
                            "revoke-invitation-modal",
                            row.original
                          ),
                      },
                    ]
              }
            />
          )}
        </div>
      ),
    }),
  ] as ColumnDef<unknown, any>[];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ReactTable
        {...renderTestId("user-accesses-table")}
        data={userAccesses ?? []}
        columns={columns}
        variant="Collapsible"
        sticky={userData?.role === TranslatedUserRoleValues.Administrator}
      />
      <GrantAdminRoleModal />
    </Suspense>
  );
};
export default UserAccessesTable;
