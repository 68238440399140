export enum TranslatedUserRoleLabels {
  Basic = "Felhasználó",
  Administrator = "Admin",
}

export enum TranslatedUserRoleValues {
  Basic = "Basic",
  Administrator = "Administrator",
}

export enum TranslatedStatuses {
  Accepted = "Elfogadva",
  Sent = "Függőben",
}
