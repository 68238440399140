import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { useNavigate } from "react-router-dom";
import { Button, P } from "src/components";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <main className="w-full min-h-screen flex flex-col gap-8 justify-center items-center self-stretch h-full bg-[#F5F9FE]">
      <P className="text-[#C8E2FF] text-center md:text-[180px] md:leading-[150px] text-[130px] leading-[110px] px-4">
        404
      </P>
      <P className="text-center md:text-xl text-lg px-4 font-500">
        Valami hiba történt, és az általad keresett oldal nem található. <br />
        Lehet, hogy elgépelted a webcímet, vagy az oldal áthelyezésre került.
      </P>
      <Button onClick={() => navigate(INTERNAL_ROUTE.DASHBOARD)}>
        Vissza a főoldalra
      </Button>
    </main>
  );
};

export default PageNotFound;
