import { Button } from "src/components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalOpen } from "src/store/slices/modalSlice";
import IncomeModal from "../IncomeModal/IncomeModal";

const IncomeModalDemo = () => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <Button onClick={() => dispatch(setModalOpen("income-modal"))}>
        12 havi árbevétel modal
      </Button>
      <IncomeModal />
    </>
  );
};

export default IncomeModalDemo;
