import { Fragment } from "react";
import DataLabel from "../DataLabel/DataLabel";
import { Separator } from "src/components";
import cn from "classnames";
import { DataPointApplicationFeaturesCardsQueriesDataResponse } from "src/store/serviceApi";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data:
    | DataPointApplicationFeaturesCardsQueriesDataResponse[]
    | null
    | undefined;
  span?: 1 | 2 | 3 | 4 | 6;
  isLocked?: boolean;
  isEmpty?: boolean;
}

const CardContentWithLabels: React.FC<Props> = ({
  data,
  span = 2,
  isLocked = false,
  isEmpty = false,
  className,
}) => {
  return (
    <div
      className={cn(
        "flex flex-col justify-center items-start self-stretch rounded-xl",
        { "bg-primary/5": !isLocked },
        { "gap-4": isLocked },
        className
      )}
    >
      {data && (
        <Fragment>
          <DataLabel isEmpty={isEmpty} size={"md"} data={data[0]} />
          {data[1] && (
            <div className="flex flex-col self-stretch">
              {!data[2] && <Separator className="opacity-30 my-4" />}
              <DataLabel
                isEmpty={isEmpty}
                size={data[2] ? "sm" : "md"}
                data={data[1]}
              />
            </div>
          )}
          {data[2] && <DataLabel isEmpty={isEmpty} size="sm" data={data[2]} />}
        </Fragment>
      )}
    </div>
  );
};

export default CardContentWithLabels;
