import "./App.scss";
import "react-tooltip/dist/react-tooltip.css";
import Router from "./Router";
import { Tooltip } from "src/components";
import { Toaster } from "src/components/_ui/toast/toaster";
import { useEffect } from "react";
import useHotjar from "react-use-hotjar";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-MGS7DKG8",
};

function App() {
  const { initHotjar } = useHotjar();

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    initHotjar(4933522, 6, true);
  }, [initHotjar]);

  return (
    <div className="">
      <Router />
      <Tooltip id="tooltip" />
      <Tooltip id="floater" />
      <Toaster />
    </div>
  );
}

export default App;
