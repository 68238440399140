import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";

interface ChartIntervals {
  chartIntervals: { startDate: string; endDate: string };
}

const initialState: ChartIntervals = {
  chartIntervals: {
    startDate: "",
    endDate: "",
  },
};

const incomeCardSlice = createSlice({
  name: "incomeCard",
  initialState,
  reducers: {
    setChartIntervals: (
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>
    ) => {
      state.chartIntervals.startDate = action.payload.startDate;
      state.chartIntervals.endDate = action.payload.endDate;
    },
  },
});

export const { setChartIntervals } = incomeCardSlice.actions;

export const useIncomeCard = () => {
  const chartIntervals = useSelector(
    (state: RootState) => state.incomeCard.chartIntervals
  );
  return chartIntervals;
};

export default incomeCardSlice.reducer;
