import { useLocation, useNavigate } from "react-router-dom";
import { ModuleButton } from "src/components";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";

const CompanyIdentificationNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-row justify-between items-center md:gap-x-4 gap-x-2">
      <ModuleButton
        variant="Registry"
        title="Van termékszámom"
        isActive={location.pathname === INTERNAL_ROUTE.COMPANY_IDENTIFICATION}
        onClick={() =>
          navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION, {
            state: {
              navigatedFromCompanies: location.state?.navigatedFromCompanies,
            },
          })
        }
      />
      <ModuleButton
        variant="Registry"
        title="Nincs termékszámom"
        isActive={
          location.pathname ===
          INTERNAL_ROUTE.COMPANY_IDENTIFICATION_INVITE_THIRD_PARTY
        }
        onClick={() =>
          navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION_INVITE_THIRD_PARTY, {
            state: {
              navigatedFromCompanies: location.state?.navigatedFromCompanies,
            },
          })
        }
      />
    </div>
  );
};

export default CompanyIdentificationNavigation;
