import { InteractionStatus } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  CompanyIdentificationCard,
  CompanyIdentificationForm,
  CompanyIdentificationLayout,
  CompanyIdentificationByThirdPartyIntro,
  Loader,
} from "src/components";
import { useGetRegrequestsQuery } from "src/store/serviceApi";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";

const CompanyIdentificationByThirdPartyPage = () => {
  const { inProgress } = useMsal();
  const navigate = useNavigate();
  const { data: regrequestsData } = useGetRegrequestsQuery();

  if (regrequestsData && regrequestsData.length === 0) {
    navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION);
  }

  return inProgress === InteractionStatus.None ? (
    <>
      <AuthenticatedTemplate>
        <CompanyIdentificationLayout>
          <CompanyIdentificationCard>
            <CompanyIdentificationByThirdPartyIntro />
            <CompanyIdentificationForm />
          </CompanyIdentificationCard>
        </CompanyIdentificationLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to={INTERNAL_ROUTE.WELCOME} />
      </UnauthenticatedTemplate>
    </>
  ) : (
    <div className="my-auto">
      <Loader variant="blue" />
    </div>
  );
};

export default CompanyIdentificationByThirdPartyPage;
