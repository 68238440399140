import type { DataPointApplicationFeaturesTenantCreationRequestsCommandCreateTenantCreationRequestCommand } from "src/store/serviceApi";
import { Form } from "react-final-form";
import { Fieldset, Input, H1, P, Submit } from "src/components";
import { useValidationSchema } from "src/hooks/useValidationSchema";
import { companyIdentificationValidationInvitationSchema } from "src/validation/schemes";
import { useLocation, useNavigate } from "react-router-dom";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { useAddRegrequestMutation } from "src/store/serviceApi";
import { useToast } from "src/components/_ui/toast/use-toast";

const initialValues = {
  emailTo: "",
};

const CompanyIdentificationInvitationForm = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const validate = useValidationSchema(
    companyIdentificationValidationInvitationSchema
  );
  const [addRegrequest] = useAddRegrequestMutation();

  const sendInvitation = async (
    values: DataPointApplicationFeaturesTenantCreationRequestsCommandCreateTenantCreationRequestCommand
  ) => {
    const { emailTo } = values;

    if (emailTo) {
      const payload = {
        body: { emailTo },
      };

      await addRegrequest(payload)
        .then(() => {
          navigate(
            INTERNAL_ROUTE.COMPANY_IDENTIFICATION_INVITE_THIRD_PARTY_RESULT,
            {
              state: {
                emailTo,
                navigatedFromCompanies: location.state?.navigatedFromCompanies,
              },
            }
          );
        })
        .catch((error) => {
          console.log("COMPANY IDENTIFICATION INVITATION FORM ERROR", error);
          navigate(
            INTERNAL_ROUTE.COMPANY_IDENTIFICATION_INVITE_THIRD_PARTY_RESULT,
            {
              state: {
                emailTo: "",
                navigatedFromCompanies: location.state?.navigatedFromCompanies,
              },
            }
          );
        });
    } else {
      toast({
        title: "Hiányzó email cím",
        variant: "warning",
      });
    }
  };

  return (
    <div className="flex flex-col gap-y-4">
      <H1 className="text-primary text-center font-bold">Kérelem küldése</H1>
      <P className="text-center">
        Amennyiben a termékszámot nem ismeri, úgy az azonosítás befejezésére
        hamarosan e-mailben küldhető kérelem a szoftver rendszergazdájának vagy
        az illetékes személynek.
      </P>
      <Form
        validate={validate}
        initialValues={initialValues}
        onSubmit={sendInvitation}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-y-4">
              <Fieldset title="Email">
                <Input name="emailTo" placeholder="pl.: kisserika@gmail.com" />
              </Fieldset>
              <Submit className="self-end md:w-auto w-full">Küldés</Submit>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default CompanyIdentificationInvitationForm;
