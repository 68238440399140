import type { AppDispatch } from "src/store/store";
import { useDispatch } from "react-redux";
import {
  Card,
  CardContentWithLabels,
  CardHeader,
  P,
  OpenOrdersModal,
  CardContent,
} from "src/components";
import cn from "classnames";

import { getDateLabel, renderTestId } from "src/utils/HelperFunctions";
import { useCard } from "src/store/slices/cardsSlice";
import { setModalOpen, useModal } from "src/store/slices/modalSlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import { getCardOrEmptyState } from "src/utils/transformers/cardDataTransformer";

const CARD_ID = "ecb8665c-817c-41ce-8ea9-1ba5cd22c546";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  span?: 2 | 4;
}

const OpenOrdersCard: React.FC<Props> = ({ span = 4 }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isOpen = useModal(cardConfig[CARD_ID].modalId) ?? false;
  const { isEmpty, card, isNotLoaded } = getCardOrEmptyState(
    CARD_ID,
    useCard(CARD_ID)
  );

  const openModal = () => {
    !isEmpty && dispatch(setModalOpen(cardConfig[CARD_ID].modalId));
  };

  return (
    <>
      <Card
        {...renderTestId(cardConfig[CARD_ID].testId)}
        onClick={openModal}
        header={
          <CardHeader
            title={cardConfig[CARD_ID].title}
            subtitle={
              <div className={cn("flex", "items-center", "gap-0.5")}>
                <P className={cn("text-xs", "font-400", "opacity-70")}>
                  Frissítve
                </P>
                <P className={cn("text-xs", "font-500", "opacity-80")}>
                  {getDateLabel(card?.updatedAt)}
                </P>
              </div>
            }
            info={cardConfig[CARD_ID].infoText}
            icon={cardConfig[CARD_ID].icon}
          />
        }
        content={
          <CardContent
            isLocked={false}
            isEmpty={isEmpty}
            isNotLoaded={isNotLoaded}
          >
            <CardContentWithLabels
              data={card?.data}
              span={span}
              isLocked={false}
              isEmpty={isEmpty}
              className="p-4"
            />
          </CardContent>
        }
        isEmpty={isEmpty}
      />
      {isOpen && <OpenOrdersModal />}
    </>
  );
};

export default OpenOrdersCard;
