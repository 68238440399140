import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { useNavigate } from "react-router-dom";
import { P, Button, IconButton, Icon } from "src/components";
import { useUser } from "src/store/slices/userSlice";
import { TranslatedUserRoleValues } from "src/models/Roles";
import { useGetMeQuery } from "src/store/serviceApi";
import { useCompany } from "src/store/slices/companySlice";

const CompaniesHeader = () => {
  const { company, tenants } = useCompany();
  const { data: userData } = useGetMeQuery(
    {
      tenantId: company?.tenantId || "",
    },
    { refetchOnMountOrArgChange: true }
  );
  const navigate = useNavigate();

  const navigateToIdentifyCompany = () => {
    navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION, {
      state: {
        navigatedFromCompanies: true,
      },
    });
  };
  return (
    <div className="flex flex-wrap items-center md:justify-between self-stretch">
      <div className="flex md:items-center gap-3">
        <IconButton
          size="small"
          className="text-[#12121280]"
          iconName="chevron-left"
          onClick={() => navigate(INTERNAL_ROUTE.DASHBOARD)}
        />
        <P className="hidden mg:block text-primary !font-500 text-sm">
          Vissza a Dashboardra
        </P>
      </div>
      <P className="md:absolute md:ml-0 ml-2 md:left-1/2 md:transform md:-translate-x-1/2 font-700 text-2xl">
        Cégeim
      </P>
      <div className="w-full md:w-auto md:flex-grow mt-3 md:mt-0" />
      {userData?.role === TranslatedUserRoleValues.Administrator ? (
        <Button
          variant="Small"
          className="flex justify-between items-center gap-2 md:w-auto w-full md:mb-0 mb-2"
          onClick={navigateToIdentifyCompany}
        >
          <P className="!font-500 !text-sm">Új hozzáadása</P>
          <Icon icon="plus" size={16} />
        </Button>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default CompaniesHeader;
