import React, { HtmlHTMLAttributes } from "react";
import cn from "classnames";
import { HeaderGroup } from "@tanstack/react-table";

interface TableCellProps extends HtmlHTMLAttributes<HTMLTableRowElement> {
  headerGroup: HeaderGroup<unknown>;
  variant: "Default" | "Collapsible";
}

const ReactTableHeadRow: React.FC<TableCellProps> = ({
  headerGroup,
  variant,
  className,
  children,
}) => {
  return (
    <tr
      key={headerGroup.id}
      className={cn(
        "whitespace-nowrap md:whitespace-normal",
        variant === "Default"
          ? "text-blue-500 border-b-2 border-b-blue-500"
          : "text-left md:p-5 p-3 last-of-type:pr-5 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl text-primary font-400 text-sm",
        className
      )}
    >
      {children}
    </tr>
  );
};

export default ReactTableHeadRow;
