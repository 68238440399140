import React, { HtmlHTMLAttributes } from "react";
import { Cell, flexRender, Header } from "@tanstack/react-table";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";

interface TableCellProps extends HtmlHTMLAttributes<HTMLTableCellElement> {
  header: Header<unknown, unknown>;
  variant: string;
}

const CollapsibleReactTableHeadCell: React.FC<TableCellProps> = ({
  header,
  variant,
  className,
}) => {
  const id = uuidv4();
  return (
    <th
      key={id}
      className={cn(
        {
          "last-of-type:md:relative last-of-type:sticky last-of-type:right-0 last:md:shadow-none last:shadow-[0_0.5rem_0.5em_rgb(0,0,0,0.4)]":
            variant === "sticky",
        },
        variant === "twocolumn-sticky"
          ? "first-of-type:md:relative first-of-type:sticky first-of-type:left-0 last-of-type:sticky last-of-type:md:relative last:md:shadow-none last-of-type:right-0"
          : "bg-[#f5f9fe]",
        "text-left md:p-5 p-3 last-of-type:pr-5 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl text-primary  font-400 text-sm",
        header.colSpan,
        className
      )}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
    </th>
  );
};

export default CollapsibleReactTableHeadCell;
