import { P } from "src/components";
import cn from "classnames";
import { ReactComponent as XIcon } from "src/assets/icons/result-fail.svg";

const ErrorDisplay = () => {

  return (
      <div className={cn("flex flex-col items-center gap-y-6 rounded-[20px]")}>
        <XIcon />
        <P>Sajnos nem sikerült az adatokat betölteni, próbálja meg később!</P>
      </div>
  );
};

export default ErrorDisplay;
