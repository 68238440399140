import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import {
  ModalData,
  setModalClose,
  useModalData,
} from "src/store/slices/modalSlice";
import { Overlay, P, Button } from "src/components";
import { ReactComponent as XIcon } from "src/assets/icons/result-fail.svg";
import { renderTestId } from "src/utils/HelperFunctions";
import { useToast } from "src/components/_ui/toast/use-toast";
import { useNavigate } from "react-router-dom";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import {
  useDeleteCompanyMutation,
  useGetCompaniesQuery,
  useGetTenantsQuery,
} from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const MODAL_ID = "delete-company-modal";

const DeleteCompanyModal: React.FC<Props> = ({}) => {
  const dispatch = useDispatch<AppDispatch>();
  const closeModal = () => dispatch(setModalClose(MODAL_ID));
  const modalData = useModalData();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { data: companies } = useGetCompaniesQuery();
  const { refetch } = useGetTenantsQuery();
  const [deleteCompany] = useDeleteCompanyMutation();

  const handleSubmit = async () => {
    deleteCompany({
      tenantId: (modalData as ModalData).tenantId,
      id: (modalData as ModalData).id,
    })
      .then((res) => {
        if (!(res as any).data) {
          toast({
            title: "Sikeresen eltávolítottad a céget",
            variant: "success",
          });

          closeModal();
        } else
          toast({
            title: "A cég eltávolítása sikertelen",
            variant: "destructive",
          });

        refetch()
          .then(() => {
            const updatedCompanies =
              companies?.filter(
                (c) => c.id !== (modalData as ModalData).id && !c.isDeleted
              ) ?? [];
            if (updatedCompanies.length === 0)
              navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION, {
                state: { navigatedFromCompanies: true },
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log("DELETE COMPANY MODAL ERROR", error);
        toast({
          title: "A cég eltávolítása sikertelen",
          variant: "destructive",
        });
      });
  };

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      className="w-[640px]"
      title="Cég törlése"
      id={MODAL_ID}
      variant="Custom"
      size="sm"
    >
      <div className="flex !content-center gap-6 flex-col items-center flex-wrap">
        <XIcon />
        <div className="flex justify-center items-center gap-2 self-stretch">
          <P className="!font-700">{modalData?.name ?? ""}</P>
        </div>
        <div className="flex justify-center items-center gap-2 self-stretch text-center">
          <P>Biztos vagy benne, hogy törölni szeretnéd a következő céget?</P>
        </div>

        <div className="flex content-center justify-center items-center w-full gap-6 self-stretch text-center">
          <Button
            {...renderTestId("cancel-button")}
            onClick={closeModal}
            variant="Secondary"
            className="w-full"
          >
            <P className="text-primary !font-500 text-base">Mégse</P>
          </Button>

          <Button
            {...renderTestId("submit-button")}
            variant="Primary"
            onClick={handleSubmit}
            className="w-full"
          >
            <P className="text-white !font-500 text-base">Eltávolítás</P>
          </Button>
        </div>
      </div>
    </Overlay>
  );
};
export default DeleteCompanyModal;
