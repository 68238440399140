import { useEffect, useState } from "react";
import { Form as BaseForm } from "react-final-form";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { setSelectedCompany, useCompany } from "src/store/slices/companySlice";
import { setModalOpen } from "src/store/slices/modalSlice";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { handleLogin, handleSignUp } from "src/utils/auth/AuthActions";
import { renderTestId } from "src/utils/HelperFunctions";
import {
  ActionButton,
  Avatar,
  Button,
  EditDatacardOverlay,
  IconButton,
  P,
  ProfileMenuModal,
  SelectInput,
} from "src/components";
import { msalInstance } from "src";
import {
  DataPointDomainCompaniesCompany,
  useGetCompaniesQuery,
} from "src/store/serviceApi";
import { ReactComponent as DatapointLogo } from "src/assets/images/adatpont-logo.svg";
import { ReactComponent as ByIcon } from "src/assets/images/by-logo.svg";
import { ReactComponent as KSLogo } from "src/assets/images/kulcs-soft-logo.svg";
import { SingleValue } from "react-select";
import ChangeCompanyOverlay from "../ChangeCompanyOverlay/ChangeCompanyOverlay";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const Header: React.FC<Props> = () => {
  const [scrollState, setScrollState] = useState<"up" | "down">("up");
  const { company } = useCompany();
  const isAuthenticated = useIsAuthenticated();
  const { data: companiesData, isLoading: companiesIsLoading } =
    useGetCompaniesQuery(undefined, { skip: Boolean(!isAuthenticated) });
  const activeUser =
    msalInstance.getActiveAccount() ??
    (msalInstance.getAllAccounts() && msalInstance.getAllAccounts()[0]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDashboard = [INTERNAL_ROUTE.DASHBOARD, INTERNAL_ROUTE.START].some(
    (route) => route === window.location.pathname
  );
  const isCompanySelectNeeded = [
    INTERNAL_ROUTE.ONBOARDING,
    INTERNAL_ROUTE.WELCOME,
    INTERNAL_ROUTE.FLEXIBILL,
    INTERNAL_ROUTE.KULCSUSER,
    INTERNAL_ROUTE.PAYEE,
  ].some(
    (route) =>
      route === window.location.pathname ||
      window.location.pathname.includes("company-identification")
  );

  const isMobileMenuNeeded =
    isAuthenticated &&
    [
      INTERNAL_ROUTE.START,
      INTERNAL_ROUTE.DASHBOARD,
      INTERNAL_ROUTE.USERS,
      INTERNAL_ROUTE.COMPANIES,
    ].some((route) => route === window.location.pathname);

  const scrollFunction = () => {
    if (window.scrollY > 40) {
      setScrollState("down");
    } else {
      setScrollState("up");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollFunction);

    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  const baseClasses = "w-full self-stretch justify-between flex ";

  const variants = {
    up: "md:px-10 px-3 md:pt-4 pt-2 md:pb-4 pb-2 h-[88px] bg-transparent justify-between items-start shrink-0 ",
    down: "md:px-10 px-3 pt-1 pb-1 h-16 bg-contrast fixed top-0 left-0 z-50 items-center",
  };

  const changeSelectedCompany = (id: string) => {
    const selectedCompany =
      companiesData?.find(
        (c: DataPointDomainCompaniesCompany) => c.id === id
      ) ?? undefined;
    if (selectedCompany) {
      dispatch(setSelectedCompany(selectedCompany));
    }
  };

  return (
    <header id="header" className={cn(baseClasses, variants[scrollState])}>
      <div
        {...renderTestId("logo")}
        className={"flex h-[60px] items-center md:gap-9 gap-2 cursor-pointer"}
        onClick={() => navigate(INTERNAL_ROUTE.DASHBOARD)}
      >
        <DatapointLogo
          className={cn(
            {
              "md:h-[39px] md:w-[127px] h-[31px] w-[99px]":
                scrollState === "up",
            },
            {
              "md:h-[31px] md:w-[99px] h-[27px] w-[90px]":
                scrollState === "down",
            }
          )}
        />
        <div className="flex items-center gap-2">
          <ByIcon
            className={cn(
              {
                "md:h-[14px] md:w-[17px] h-[14px] w-[17px]":
                  scrollState === "up",
              },
              {
                "md:h-[11px] md:w-[14px] h-[11px] w-[14px]":
                  scrollState === "down",
              }
            )}
            width={scrollState === "up" ? 17 : 14}
            height={scrollState === "up" ? 14 : 11}
          />
          <KSLogo
            width={scrollState === "up" ? 87 : 68}
            height={scrollState === "up" ? 27 : 21}
          />
        </div>
      </div>
      {isAuthenticated ? (
        <div className="flex items-center gap-4 h-full">
          {!isCompanySelectNeeded &&
            companiesData &&
            companiesData?.filter((c) => !c.isDeleted).length > 0 && (
              <div
                className={cn(
                  "md:block hidden",
                  companiesData?.filter((c) => !c.isDeleted).length > 1
                    ? "w-[250px]"
                    : "max-w-[250px]"
                )}
              >
                {companiesData?.filter((c) => !c.isDeleted).length > 1 ? (
                  <SelectInput
                    variant={scrollState === "up" ? "normal" : "small"}
                    {...renderTestId("default-company-select")}
                    defaultSelectValue={
                      {
                        label: company?.name ?? "",
                        value: company?.id ?? "",
                      } as any
                    }
                    onChangeSelect={(
                      option: SingleValue<string>,
                      meta: any
                    ) => {
                      changeSelectedCompany((option as any).value);
                    }}
                    options={
                      companiesData
                        ?.filter((c) => !c.isDeleted && c.id !== company?.id)
                        .map((c: DataPointDomainCompaniesCompany) => ({
                          value: c.id ?? "",
                          label: c.name ?? "",
                        })) as any
                    }
                  />
                ) : (
                  <P
                    className={cn(
                      "font-500 line-clamp-1",
                      scrollState === "up"
                        ? "text-xl text-primary/80"
                        : "text-lg text-primary/60"
                    )}
                  >
                    {company?.name ?? ""}
                  </P>
                )}
              </div>
            )}
          <>
            {isMobileMenuNeeded &&
              companiesData &&
              companiesData?.filter((c) => !c.isDeleted).length > 0 && (
                <span className="md:hidden block">
                  <ActionButton
                    color="primary"
                    size={scrollState === "up" ? "md" : "sm"}
                    className={scrollState === "up" ? "bg-white" : ""}
                    items={[
                      {
                        name: "Cég kiválasztása",
                        onClick: () =>
                          dispatch(setModalOpen("change-company-modal")),
                      },
                      {
                        name: "Kártyák szerkesztése",
                        onClick: () =>
                          dispatch(setModalOpen("Adatkártyák szerkesztése")),
                      },
                    ]}
                  />
                  <EditDatacardOverlay />
                  <ChangeCompanyOverlay />
                </span>
              )}
            {isDashboard &&
              companiesData &&
              companiesData?.filter((c) => !c.isDeleted).length > 0 && (
                <>
                  <span className="md:block hidden">
                    <IconButton
                      {...renderTestId("edit-cards-button")}
                      iconName="card-list"
                      size={scrollState === "up" ? "normal" : "small"}
                      onClick={() =>
                        dispatch(setModalOpen("Adatkártyák szerkesztése"))
                      }
                    />
                    <EditDatacardOverlay />
                  </span>
                </>
              )}
          </>
          <Avatar
            familyName={(activeUser?.idTokenClaims as any)?.family_name}
            givenName={(activeUser?.idTokenClaims as any)?.given_name}
            variant={scrollState === "up" ? "normal" : "small"}
            onClick={() => dispatch(setModalOpen("profile-menu-modal"))}
          />
          <ProfileMenuModal />
        </div>
      ) : (
        <div className="md:flex hidden h-full items-center justify-center gap-4 ">
          <Button
            {...renderTestId("login-button")}
            onClick={handleLogin}
            variant="Secondary"
            className={cn(
              "border border-primary text-primary ",
              {
                "!py-2 !px-4 text-sm": scrollState === "down",
              },
              { "!py-3 !px-5": scrollState === "up" }
            )}
          >
            Bejelentkezés
          </Button>
          <Button
            {...renderTestId("registration-button")}
            onClick={handleSignUp}
            className={cn(
              "border",
              {
                "!py-2 !px-5 text-sm": scrollState === "down",
              },
              { "!py-3 !px-6": scrollState === "up" }
            )}
          >
            Regisztráció
          </Button>
        </div>
      )}
    </header>
  );
};
export default Header;
