import { Button } from "src/components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalData, setModalOpen } from "src/store/slices/modalSlice";
import PayeeModal from "../PayeePackagesModal/PayeePackagesModal";

const PayeePackagesModalDemo = () => {
  const dispatch = useDispatch<AppDispatch>();

  // const parentModal = { title: "Követeléskezelés indítása" };

  return (
    <>
      <Button
        onClick={() => {
          dispatch(setModalData({ asd: { prop1: "asd1", prop2: "asd2" } }));
          dispatch(setModalOpen("payee-packages-modal"));
        }}
      >
        Követeléskezelés indítása
      </Button>
      <PayeeModal />
    </>
  );
};

export default PayeePackagesModalDemo;
