import { ReactComponent as Error } from "src/assets/icons/result-fail.svg";
import { ReactComponent as Success } from "src/assets/icons/result-success.svg";
import { ReactComponent as Warning } from "src/assets/icons/toast-warning.svg";

interface Props {
  variant: "default" | "destructive" | "success" | "warning";
}

const ToastIcon: React.FC<Props> = ({ variant = "default" }: Props) => {
  const getIcon = () => {
    switch (variant) {
      case "destructive":
        return <Error className="h-10 w-10" />;
      case "success":
        return <Success className="h-10 w-10" />;
      case "warning":
        return <Warning className="h-10 w-10" />;
      default:
        return <></>;
    }
  };
  return <span className="h-10 w-10">{getIcon()}</span>;
};

export default ToastIcon;
