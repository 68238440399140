import React, { Fragment } from "react";
import { Icon, P, Tooltip } from "src/components";
import { v4 as uuidv4 } from "uuid";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  additional?: React.ReactNode;
  icon?: React.ComponentProps<typeof Icon>["icon"];
  info?: string;
  subtitle: React.ReactNode;
  title?: string;
}

const CardHeader: React.FC<Props> = ({
  additional,
  icon,
  info,
  subtitle,
  title,
}) => {
  const tooltipId = uuidv4();

  return (
    <Fragment>
      <div className="flex justify-between items-start">
        <div className="flex md:items-center gap-x-3">
          {icon && <Icon icon={icon} variant="Container" />}
          <div>
            <P className="!font-500">{title}</P>
            {typeof subtitle === "string" ? (
              <P className="!text-xs text-black/50">{subtitle}</P>
            ) : (
              subtitle
            )}
          </div>
        </div>
        <div className="flex justify-end items-center gap-x-2 text-[13px]">
          {additional && (
            <div className="hidden md:flex gap-x-3 relative top-[1px] text-black/60">
              {Array.isArray(additional)
                ? additional.map((a) => <div>{a}</div>)
                : additional}
            </div>
          )}
          <div
            data-tooltip-id={`card-header-info-tooptip-${tooltipId}`}
            data-tooltip-content={info}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <Icon icon="info" className="text-primary" />
          </div>
          <Tooltip id={`card-header-info-tooptip-${tooltipId}`} />
        </div>
      </div>
      {additional && (
        <div className="flex md:hidden text-sm justify-end gap-x-3 relative top-[1px] text-black/60">
          {Array.isArray(additional)
            ? additional.map((a) => <div>{a}</div>)
            : additional}
        </div>
      )}
    </Fragment>
  );
};

export default CardHeader;
