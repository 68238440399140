import React from "react";
import cn from "classnames";
import { COLOR } from "src/utils/constants/Enums";
import { fontSizeVariants } from "src/utils/constants/Variants";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color?: COLOR | "primary" | "green" | "orange" | "white" | "black" | "purple";
}

const Tag = ({ color, children, className, onClick }: Props) => {
  const colorVariants = {
    primary: "bg-primary/10 text-primary",
    green: "bg-green/10 text-green",
    orange: "bg-orange/10 text-orange",
    white: "bg-white/10 text-white",
    black: "bg-black/10 text-black",
    light: "bg-black/10 text-black",
    purple: "bg-[#7B61FF]/20 text-[#7B61FF]",
  };

  return (
    <div
      className={cn(
        "pb-1 pt-1 pr-2 pl-2 w-fit rounded-medium md:text-base text-sm md:font-medium font-normal",
        `${colorVariants[color ?? "primary"]}`,
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Tag;
