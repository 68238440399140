import { msalInstance } from "src";
import { useNavigate } from "react-router-dom";
import { P, Button, Overlay, Separator, Icon } from "src/components";
import { useUser } from "src/store/slices/userSlice";
import { handleLogout } from "src/utils/auth/AuthActions";
import { renderTestId } from "src/utils/HelperFunctions";
import cn from "classnames";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { setModalClose } from "src/store/slices/modalSlice";
import { useDispatch } from "react-redux";
import { useGetCompaniesQuery, useGetTenantsQuery } from "src/store/serviceApi";
import { persistor } from "src/store/store";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const ProfileMenuModal = (props: Props) => {
  const activeUser =
    msalInstance.getActiveAccount() ??
    (msalInstance.getAllAccounts() && msalInstance.getAllAccounts()[0]);
  const navigate = useNavigate();
  const helpLink = "https://kulcsonline.kulcs-soft.hu/support/tickets/new";
  const { data: companiesData } = useGetCompaniesQuery();
  const dispatch = useDispatch();
  const { data: tenants } = useGetTenantsQuery();

  const ButtonContainer = ({
    label,
    icon,
    onClick,
    disabled,
  }: {
    label: string;
    icon: string;
    onClick: () => void;
    disabled: boolean;
  }) => (
    <div
      className={cn("flex flex-col gap-y-4", {
        "opacity-50 pointer-events-none": disabled,
      })}
    >
      <Button
        {...renderTestId(`profile-menu-button-${label}`)}
        variant="Default"
        className="flex gap-x-4 items-center"
        onClick={() => onClick()}
      >
        <Icon variant="Container" icon={icon} />
        <P className="!font-500">{label}</P>
      </Button>
      <Separator color="black" className="opacity-10" />
    </div>
  );

  return (
    <Overlay
      {...renderTestId("profile-menu-modal")}
      title="Profil"
      id="profile-menu-modal"
      variant="Profile-menu"
    >
      <div className="flex flex-col gap-y-6 w-full box-content">
        <div className="">
          <P className="!text-[18px] !font-700">
            {(activeUser?.idTokenClaims as any)?.family_name ?? ""}{" "}
            {(activeUser?.idTokenClaims as any)?.given_name ?? ""}
          </P>
          <P className="!text-[14px] !font-400 !text-font-main/[0.5]">
            {((activeUser?.idTokenClaims as any)?.emails &&
              (activeUser?.idTokenClaims as any)?.emails[0]) ??
              ""}
          </P>
        </div>
        <div className="flex flex-col gap-y-4">
          {[
            {
              label: "Cégeim",
              icon: "store",
              onClick: () => {
                navigate(INTERNAL_ROUTE.COMPANIES);
                dispatch(setModalClose("profile-menu-modal"));
              },
              disabled: tenants?.length === 0,
            },
            {
              label: "Hozzáférések",
              icon: "user",
              onClick: () => {
                dispatch(setModalClose("profile-menu-modal"));
                navigate(INTERNAL_ROUTE.USERS);
              },
              disabled: companiesData?.filter((c) => !c.isDeleted).length === 0,
            },
            {
              label: "Segítséget kérek",
              icon: "help",
              onClick: () => window.open(helpLink, "_blank", "_noreferrer"),
              disabled: false,
            },
          ].map(
            (button: {
              label: string;
              icon: string;
              onClick: () => void;
              disabled: boolean;
            }) => (
              <ButtonContainer
                key={button.label}
                label={button.label}
                icon={button.icon}
                onClick={button.onClick}
                disabled={button.disabled}
              />
            )
          )}
        </div>

        <Button
          {...renderTestId("logout-button")}
          variant="Default"
          className="flex justify-center gap-x-3 rounded-[16px] border px-6 py-4 border-primary/[0.1]"
          onClick={() => {
            persistor.purge();
            handleLogout();
          }}
        >
          <Icon icon="logout" color="primary" />
          <P className="gap-x-4 !font-500 !text-primary">Kijelentkezés</P>
        </Button>
      </div>
    </Overlay>
  );
};
export default ProfileMenuModal;
