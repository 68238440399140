import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { PURGE } from "redux-persist";
import { RootState } from "../store";
import { cardTypesById } from "src/utils/constants/CardTypes";
import {
  DataPointApplicationFeaturesTenantsDtosTenantDto,
  DataPointDomainCompaniesCompany,
  DataPointDomainUserSettingsCardSetting,
  DataPointDomainUserSettingsUserSetting,
  serviceApi,
} from "../serviceApi";

interface CompanyState {
  company?: DataPointDomainCompaniesCompany;
  userSetting?: DataPointDomainUserSettingsUserSetting;
  tenants?: DataPointApplicationFeaturesTenantsDtosTenantDto[];
}

const initialState: CompanyState = {
  company: undefined,
  userSetting: undefined,
  tenants: undefined,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setSelectedCompany: (
      state,
      action: PayloadAction<DataPointDomainCompaniesCompany | undefined>
    ) => {
      state.company = action.payload;
    },
    setCardsOrder: (
      state,
      action: PayloadAction<DataPointDomainUserSettingsCardSetting[]>
    ) => {
      if (state.userSetting) {
        const deepCopyOfCardSettings = JSON.parse(
          JSON.stringify(state.userSetting?.cardSettings)
        );
        const settingsToBeChanged = action.payload;
        const remainingSettings = deepCopyOfCardSettings.filter(
          (setting: DataPointDomainUserSettingsCardSetting) =>
            !settingsToBeChanged
              .map((s: DataPointDomainUserSettingsCardSetting) => s.cardId)
              .includes(setting.cardId)
        );
        state.userSetting!.cardSettings = [
          ...remainingSettings,
          ...settingsToBeChanged,
        ].sort(function (a, b) {
          return a.index - b.index;
        });
      }
    },
    toggleCardVisibility: (state, action: PayloadAction<any>) => {
      if (state.userSetting?.cardSettings) {
        const deepCopyOfCardSettings = JSON.parse(
          JSON.stringify(state.userSetting?.cardSettings)
        );
        const settingsToBeChanged = state.userSetting.cardSettings.find(
          (c: DataPointDomainUserSettingsCardSetting) =>
            c.cardId === action.payload
        );
        if (settingsToBeChanged) {
          settingsToBeChanged.isVisible = !settingsToBeChanged?.isVisible;
          const remainingSettings = deepCopyOfCardSettings.filter(
            (setting: DataPointDomainUserSettingsCardSetting) =>
              settingsToBeChanged.cardId !== setting.cardId
          );
          state.userSetting!.cardSettings = [
            ...remainingSettings,
            settingsToBeChanged,
          ].sort(function (a, b) {
            return a.index - b.index;
          });
        }
      }
    },
    setTenants: (
      state,
      action: PayloadAction<DataPointApplicationFeaturesTenantsDtosTenantDto[]>
    ) => {
      state.tenants = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => {
        return initialState;
      })
      .addMatcher(
        serviceApi.endpoints.getTenants.matchFulfilled,
        (state, action) => {
          state.tenants =
            (action.payload as DataPointApplicationFeaturesTenantsDtosTenantDto[]) ??
            undefined;
        }
      )
      .addMatcher(
        serviceApi.endpoints.getUserSettingsMe.matchFulfilled,
        (state, action) => {
          const data = { ...action.payload };
          state.userSetting = {
            id: data.id,
            companyId: data.companyId,
            userId: data.userId,
            cardSettings: data.cardSettings?.map(
              (setting: DataPointDomainUserSettingsCardSetting) => ({
                cardId: setting.cardId,
                isVisible: setting.isVisible,
                index:
                  setting.cardId && cardTypesById[setting.cardId] <= 4
                    ? cardTypesById[setting.cardId] - 1
                    : setting.index,
              })
            ),
          };
        }
      )
      .addMatcher(
        serviceApi.endpoints.getCompanies.matchFulfilled,
        (state, action) => {
          if (action.payload && !state.company) {
            state.company = (
              action.payload as DataPointDomainCompaniesCompany[]
            )[0];
          }
        }
      );
  },
});

export const {
  setSelectedCompany,
  setCardsOrder,
  toggleCardVisibility,
  setTenants,
} = companySlice.actions;

export const useCompany = () => {
  const company = useSelector((state: RootState) => state.company);
  return company;
};

export const useTenants = () => {
  const tenants = useSelector((state: RootState) => state.company);
  return tenants;
};

export default companySlice.reducer;
