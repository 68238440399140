import type { ColumnDef, Row } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { Button, CollapsibleReactTable, Icon, P } from "src/components";

type Collapsible = {
  taxId: string;
  address: string;
  action?: React.ReactNode;
};

type Data = {
  invoiceId: string;
  client: string;
  createdAt: string;
  deadlineAt: string;
  expiredAt: string;
  status?: "Finanszírozott" | "Fizetetlen";
  sum: number;
  collapsible: Collapsible;
};

const defaultData: Data[] = [
  {
    invoiceId: "RE00286/2023",
    client: "ZöldGyöngy Kertépítés Kft.",
    createdAt: "2024. jan. 12.",
    deadlineAt: "2024. febr. 11.",
    expiredAt: "30 nap",
    sum: 1345068,
    collapsible: {
      taxId: "12345678-2-12",
      address: "1055 Buga Jakab utca 1, Budapest",
    },
  },
  {
    invoiceId: "RE00286/2023",
    client: "Fény reklámügynökség Zrt.",
    createdAt: "2024. jan. 12.",
    deadlineAt: "2024. febr. 11.",
    expiredAt: "29 nap",
    status: "Finanszírozott",
    sum: 1345068,
    collapsible: {
      taxId: "29186956-4-51",
      address: "1203 Frankel Leó utca 24, Budapest",
      action: (
        <Button variant="Inner" onClick={() => alert("Faktoráljunk!")}>
          <Icon icon="flexibill" size={16} />
          <P>Faktorálás</P>
        </Button>
      ),
    },
  },
  {
    invoiceId: "RE00286/2023",
    client: "Aranyhegy Szolgáltató Bt.",
    createdAt: "2024. jan. 12.",
    deadlineAt: "2024. febr. 11.",
    expiredAt: "28 nap",
    sum: 1345068,
    collapsible: {
      taxId: "12345678-2-12",
      address: "1055 Buga Jakab utca 1, Budapest",
    },
  },
];

const columnHelper = createColumnHelper<Data>();

const columns = [
  columnHelper.accessor("invoiceId", {
    header: () => "Számla sorszám",
    cell: (row) => <P className="line-clamp-1">{row.getValue()}</P>,
  }),
  columnHelper.accessor("client", {
    header: () => "Vevő neve",
    cell: (row) => <P className="line-clamp-1">{row.getValue()}</P>,
  }),
  columnHelper.accessor("createdAt", {
    header: () => "Kelte",
    cell: (row) => <P className="line-clamp-1">{row.getValue()}</P>,
  }),
  columnHelper.accessor("deadlineAt", {
    header: () => "Fiz. hat.",
    cell: (row) => <P className="line-clamp-1">{row.getValue()}</P>,
  }),
  columnHelper.accessor("expiredAt", {
    header: () => "Lejárat",
    cell: (row) => <P className="line-clamp-1">{row.getValue()}</P>,
  }),
  columnHelper.accessor("status", {
    header: () => "Státusz",
    cell: (row) => <P className="line-clamp-1">{row.getValue()}</P>,
  }),
  columnHelper.accessor("sum", {
    header: () => <div className="text-right">Összeg</div>,
    cell: (row) => (
      <P className="text-right">
        <span className="font-bold mr-1">
          {row.getValue().toLocaleString("hu-HU", { minimumFractionDigits: 0 })}
        </span>
        Ft
      </P>
    ),
  }),
] as ColumnDef<unknown, any>[];

const renderCollapsibleRow = ({ row }: { row: Row<Data> }) => {
  const { taxId, address, action } = row.original.collapsible;

  return (
    <div className="flex flex-row gap-x-5 p-5">
      <div>
        <h6 className="uppercase text-xs text-black/50">Adószám</h6>
        <P className="line-clamp-1">{taxId}</P>
      </div>
      <div>
        <h6 className="uppercase text-xs text-black/50">Cím</h6>
        <P className="line-clamp-1">{address}</P>
      </div>
      {action && (
        <div className="flex flex-1 justify-end items-center">{action}</div>
      )}
    </div>
  );
};

const CollapsibleTable = () => {
  return (
    <CollapsibleReactTable
      data={defaultData}
      columns={columns}
      renderCollapsibleRow={renderCollapsibleRow}
    />
  );
};

export default CollapsibleTable;
