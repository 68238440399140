import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, H1, P } from "src/components";
import { useGetRegrequestsQuery } from "src/store/serviceApi";
import { persistor } from "src/store/store";
import { renderTestId } from "src/utils/HelperFunctions";
import { handleLogout } from "src/utils/auth/AuthActions";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";

const CompanyIdentificationByThirdParty = () => {
  const navigate = useNavigate();

  const handleBack = async () => {
    persistor.purge();
    await handleLogout();
    navigate(INTERNAL_ROUTE.WELCOME);
  };

  return (
    <>
      <H1 className="text-primary font-bold text-center">
        Ha nem rendelkezel termékszámmal
      </H1>
      <P className="text-center">
        Vedd fel a kapcsolatot azzal, aki felkért arra, hogy a cégazonosítási
        folyamatot elvégezd, hogy mihamarabb használatba vehesse céged az
        Adatpontot.
      </P>
      <Button
        {...renderTestId("back-button")}
        variant="Secondary"
        className="flex-1"
        onClick={handleBack}
      >
        Vissza a főoldalra
      </Button>
    </>
  );
};

export default CompanyIdentificationByThirdParty;
