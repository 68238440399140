import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { useEffect } from "react";
import {
  DeleteUserModal,
  EmptyUserAccessesMessage,
  InviteUserModal,
  Loader,
  ResendInvitationModal,
  RevokeInvitationModal,
  UserAccessesHeader,
  UserAccessesTable,
} from "src/components";
import {
  UserAccess,
  setUserAccesses,
  useUserAccesses,
} from "src/store/slices/userAccessesSlice";
import { renderTestId } from "src/utils/HelperFunctions";
import {
  useGetInvitesQuery,
  useGetMeQuery,
  useGetUsersQuery,
} from "src/store/serviceApi";
import { useCompany } from "src/store/slices/companySlice";
import { InvitationStatus } from "src/models/Invitations";
import { TranslatedUserRoleValues } from "src/models/Roles";

const UserAccessesPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { userAccesses } = useUserAccesses();

  const { company } = useCompany();
  const { data: usersData, isLoading: usersAreLoading } = useGetUsersQuery(
    {
      companyId: company?.id ?? "",
    },
    { skip: Boolean(!company), refetchOnMountOrArgChange: true }
  );
  const { data: userData, isLoading: userIsLoading } = useGetMeQuery(
    {
      tenantId: company?.tenantId ?? "",
    },
    { skip: Boolean(!company), refetchOnMountOrArgChange: true }
  );
  const { data: invitationsData, isLoading: invitationsAreLoading } =
    useGetInvitesQuery(
      {
        companyId: company?.id ?? "",
      },
      { skip: Boolean(!company), refetchOnMountOrArgChange: true }
    );

  useEffect(() => {
    const mergedUsers: UserAccess[] = (usersData ?? []).map((user) => ({
      id: user.id,
      objectId: user.objectId,
      givenName: user.givenName,
      familyName: user.familyName,
      status: undefined,
      email: user.email,
      role: user.role,
      onboarded: user.onboarded,
      createdAt: user.createdAt ? new Date(user.createdAt) : undefined,
    }));

    const mergedInvitations: UserAccess[] = (invitationsData || [])
      .filter((i) => i.status === InvitationStatus.Sent || !i.status)
      .map((invitation) => ({
        id: invitation.id,
        objectId: undefined,
        givenName: undefined,
        familyName: undefined,
        status: invitation.status,
        email: invitation.to,
        role: "Basic",
        onboarded: false,
        createdAt: new Date(invitation.createdAt as string),
      }));

    //display active user in the first row
    const indexToRemove = mergedUsers.findIndex(
      (u) => u.objectId === userData?.objectId
    );
    let firstUser = null;
    if (indexToRemove !== -1) {
      firstUser = mergedUsers.splice(indexToRemove, 1)[0];
    }

    const mergedData = firstUser
      ? [firstUser, ...mergedUsers, ...mergedInvitations]
      : [...mergedUsers, ...mergedInvitations];

    dispatch(setUserAccesses(mergedData));
  }, [usersData, invitationsData]);

  if (usersAreLoading || userIsLoading || invitationsAreLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader variant="blue" />
      </div>
    );
  }

  return (
    <main
      {...renderTestId("user-accesses-template")}
      className="flex flex-col w-full md:mx-0 md:p-6 p-4 md:mt-6 md:mb-auto self-stretch"
    >
      <div
        className="flex flex-col md:p-6 p-4 items-center gap-6 rounded-xl md:rounded-[20px] bg-contrast 
      md:self-stretch md:w-[60vw] md:min-w-[735px] md:m-auto md:max-h-[60vh] md:relative"
      >
        <UserAccessesHeader />
        <div className="md:self-stretch w-full overflow-auto min-h-[35%] scrollbar-thumb-primary/50 scrollbar-track-primary/10 scrollbar-thumb-rounded-full scrollbar-thin scrollbar-w-1 scrollbar-gutter">
          <UserAccessesTable />
        </div>
        {userAccesses?.length === 1 &&
          userData?.role === TranslatedUserRoleValues.Administrator && (
            <EmptyUserAccessesMessage />
          )}
      </div>

      <DeleteUserModal />
      <InviteUserModal />
      <RevokeInvitationModal />
      <ResendInvitationModal />
    </main>
  );
};
export default UserAccessesPage;
