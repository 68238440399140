import React from "react";
import { Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import { A, Checkbox, H1, P, Reset, Submit, TextBox } from "src/components";
import { useValidationSchema } from "src/hooks/useValidationSchema";
import { companyIdentificationConfirmationSchema } from "src/validation/schemes";
import SuccessIcon from "src/assets/icons/result-success.svg";
import FailedIcon from "src/assets/icons/result-fail.svg";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { Links } from "src/utils/constants/Links";
import {
  DataPointApplicationFeaturesTenantsCommandsCreateTenantCommand,
  DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse,
} from "src/store/serviceApi";

const initialValues = {
  termsAndConditionsAccepted: false,
  privacyPolicyAccepted: false,
};

interface Props {
  result?: DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse[];
  flowCode?: DataPointApplicationFeaturesTenantsCommandsCreateTenantCommand["flowCode"];
}

const CompanyIdentificationConfirmed: React.FC<Props> = ({
  result,
  flowCode = null,
}) => {
  const validate = useValidationSchema(companyIdentificationConfirmationSchema);
  const navigate = useNavigate();
  const location = useLocation();
  const links = Links.General;

  const handleConfirm = () => {
    // TODO: IF WE NEED TO HANDLE MORE THAN ONE PRODUCT KEY
    // FINISH THIS HANDLER, COPY FROM CompanyIdentificationConfirmed
  };

  const handleReset = () => {
    navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION, {
      state: { navigatedFromCompanies: location.state?.navigatedFromCompanies },
    });
  };

  return (
    <div className="flex flex-col gap-y-6 items-center justify-between">
      <H1 className="text-primary font-bold">Cégazonosítás</H1>

      <TextBox variant="Info" className="w-full text-center ">
        <div className="flex flex-col gap-y-4">
          {result &&
            result.map(
              (item) =>
                !item.isExists && (
                  <div className="flex flex-col items-start gap-y-2">
                    <div className="flex items-center gap-x-2">
                      <img
                        src={item.isValid ? SuccessIcon : FailedIcon}
                        alt=""
                        className="w-8 h-8"
                      />

                      <P>{item.companyName}</P>
                    </div>

                    <P
                      className={cn("text-xs", {
                        "text-deep-green": item.isValid,
                        "text-destructive": !item.isValid,
                      })}
                    >
                      {item.isValid
                        ? "Sikeres cégazonosítás"
                        : "Sikertelen cégazonosítás"}
                    </P>
                  </div>
                )
            )}
        </div>
      </TextBox>

      <P className="text-sm text-center">
        A sikeres cégazonosítás után a Kulcs-Ügyvitel adatai szinkronizálásra
        kerülnek az Adatpont szolgáltatással.
      </P>

      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleConfirm}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className="flex flex-col gap-y-6">
              <div className="flex items-center gap-x-2">
                <Checkbox name="termsAndConditionsAccepted" />
                <P>Elolvastam és elfogadom az</P>{" "}
                <A href={links.TermsAndConditions}>
                  Általános Szerződési feltételeket
                </A>
              </div>
              <div className="flex items-center gap-x-2">
                <Checkbox name="privacyPolicyAccepted" />
                <P>Elolvastam és elfogadom az</P>{" "}
                <A href={links.PrivacyPolicy}>Adatvédelmi Tájékoztatót</A>
              </div>
              <div className="flex flex-col w-full gap-y-2.5">
                <Submit>Tovább az azonosított cég validálásához</Submit>
                <Reset>Sikertelen cégazonosítás újrapróbálása</Reset>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default CompanyIdentificationConfirmed;
