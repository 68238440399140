import { LabelValue } from "src/models/LabelValue";
import { useEffect, useState } from "react";
import cn from "classnames";
import { P, DataLabel, PieChart, Marker } from "src/components";
import { getDisplayValue } from "src/utils/HelperFunctions";
import { DataPointApplicationFeaturesCardsQueriesDataResponse } from "src/store/serviceApi";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data:
    | DataPointApplicationFeaturesCardsQueriesDataResponse[]
    | null
    | undefined;
  colors?: string[][];
  span?: 1 | 2 | 3 | 4 | 6;
}

const PieChartWithLabels: React.FC<Props> = ({
  data,
  colors,
  span = 2,
  className,
}) => {
  const [groupedArray, setGroupedArray] = useState<LabelValue[] | undefined>(
    undefined
  );

  const getChartSize = () => {
    if (data) {
      if (span === 1) return 96;
      else if (span === 2) {
        if (data[2]) return 104;
        else return 148;
      } else if (span >= 3) return 191;
    }
    return undefined;
  };

  useEffect(() => {
    const chartData = data?.find(
      (d: DataPointApplicationFeaturesCardsQueriesDataResponse | undefined) =>
        d?.type === 3
    );

    if (
      chartData &&
      chartData?.headers &&
      chartData?.values &&
      chartData?.values.length > 0
    ) {
      const newGroupedArray: LabelValue[] = [];
      for (let index = 0; index < chartData.values[0].length - 1; index++) {
        const element = {
          label: chartData.headers[index]?.toLowerCase() ?? "",
          value: Number(chartData?.values[0][index].replace(",", ".")) ?? 0,
          uom: chartData?.values[0][2] ?? "",
        } as LabelValue;
        newGroupedArray.push(element);
      }
      setGroupedArray(newGroupedArray);
    } else setGroupedArray(undefined);
  }, [data]);

  const MainDataLabel = data && (
    <div>
      <DataLabel data={data[0]} />
    </div>
  );

  const ExtraDataLabels = data && data[2] && (
    <div
      className={cn(
        "flex justify-between items-start",
        { "w-2/3": span !== 2 },
        { "self-stretch": span < 3 }
      )}
    >
      <DataLabel size="sm" data={data[2]} />
      {data[3] && <DataLabel size="sm" data={data[3]} />}
    </div>
  );

  const Markers = groupedArray?.map((d, i) => (
    <div
      key={i}
      className={cn(
        "flex md:max-w-[350px] justify-between items-center self-stretch"
      )}
    >
      <div className={cn("flex items-center gap-1.5")}>
        <Marker
          color={colors && colors[0] ? colors[0][i] : undefined}
          className={cn("!self-center")}
        />
        <P className={cn("font-400 text-right")}>{d?.label}</P>
      </div>
      <div className={cn("flex justify-end items-baseline gap-1")}>
        <P className={cn("font-700")}>
          {getDisplayValue(d?.value, d?.uom, true)}
        </P>
        <P className={cn("font-500 opacity-60 text-xs")}>{d?.uom}</P>
      </div>
    </div>
  ));

  return (
    <div
      className={cn(
        "flex p-3 bg-contrast/60 rounded-[10px] gap-2 self-stretch flex-col md:w-auto w-full h-full",
        `items-${span <= 2 || (data && !data[2]) ? "start" : "end"}`,
        { "justify-between": data && !data[2] },
        className
      )}
    >
      {span === 2 && MainDataLabel}
      <div className={cn("flex items-center self-stretch")}>
        <span className="md:hidden block">
          <PieChart
            data={groupedArray}
            colors={colors ? colors[1] : undefined}
            size={96}
          />
        </span>
        <span className="md:block hidden">
          <PieChart
            data={groupedArray}
            colors={colors ? colors[1] : undefined}
            size={getChartSize()}
          />
        </span>

        <div className={cn("flex flex-col items-start gap-4 flex-[1_0_0]")}>
          {(span >= 3 || span === 1) && MainDataLabel}

          {span !== 1 && Markers}
        </div>
      </div>
      {span === 1 && Markers}
      {ExtraDataLabels}
    </div>
  );
};
export default PieChartWithLabels;
