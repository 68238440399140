import type { AppDispatch } from "src/store/store";
import { Button, Overlay, P, TextBox } from "src/components";
import resultWarning from "src/assets/icons/result-warning.svg";
import { useDispatch } from "react-redux";
import { setModalClose, useModalData } from "src/store/slices/modalSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { renderTestId } from "src/utils/HelperFunctions";
import { DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse } from "src/store/serviceApi";

const MODAL_ID = "company-identification-is-exists-modal";

const CompanyIdentificationModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { tenants } = useModalData() as {
    tenants: DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse[];
  };
  const location = useLocation();

  const handleNavigate = (
    tenants: DataPointApplicationFeaturesTenantsCommandsIdentifyTenantsResponse[]
  ) => {
    navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION_RESULT, {
      state: {
        result: tenants,
        navigatedFromCompanies: location.state?.navigatedFromCompanies,
      },
    });
  };

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      size="sm"
      id={MODAL_ID}
      title="Korábban regisztrált cég"
      variant="Custom"
    >
      <div className="flex flex-col items-center gap-y-4">
        <img
          src={resultWarning}
          alt="Figyelem!"
          className="w-[120px] h-[120px] max-w-full"
        />
        {tenants &&
          tenants.map(
            ({ companyName, productKey, isExists }, index) =>
              isExists && (
                <TextBox
                  key={`existing-company-item-${index}`}
                  variant="Info"
                  className="text-center w-full"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={productKey}
                >
                  {companyName}
                </TextBox>
              )
          )}
        <P className="text-center">
          Ez a cég már regisztrálva van az Adatpont rendszerében. Regisztrációja
          folytatásához kérjük forduljon a céges adminisztrátorhoz.
        </P>
        <Button
          {...renderTestId("submit-button")}
          onClick={() => {
            dispatch(setModalClose(MODAL_ID));
            if (tenants?.some((item) => !item.isExists)) {
              handleNavigate(tenants);
            }
          }}
          className="self-end"
        >
          Rendben
        </Button>
      </div>
    </Overlay>
  );
};

export default CompanyIdentificationModal;
