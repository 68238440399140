import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {}

const Buttons: React.FC<Props> = ({ children, className }: Props) => {
  return (
    <div
      className={cn(
        "flex w-full md:flex-row md:gap-4 md:justify-center flex-col gap-2",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Buttons;
