import React from "react";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";
import { Icon, Tooltip } from "src/components";

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, "content"> {
  content: React.ReactNode;
  footer?: React.ReactNode; // hidden unless you need
  header?: React.ReactNode;
  span?: 1 | 2 | 3 | 4 | 6;
  variant?: "default" | "highlighted";
  isEmpty?: boolean;
}

const Card: React.FC<Props> = ({
  content,
  className,
  footer,
  header,
  span = 2,
  onClick,
  variant = "default",
  isEmpty = false,
  ...rest
}) => {
  const variants = {
    default: "border-primary bg-white",
    highlighted: "border-transparent bg-primary/5",
  };

  const spanClasses = {
    1: "col-span-xl ks-md:col-span-md ks-lg:col-span-sm",
    2: "col-span-xl ks-md:col-span-md ks-lg:col-span-sm",
    3: "col-span-xl ks-lg:col-span-md",
    4: "col-span-xl ks-lg:col-span-lg",
    6: "col-span-xl",
  };
  const tooltipId = uuidv4();

  return (
    <>
      <div
        className={cn(
          "min-w-[340px]",
          "flex p-4 gap-4 flex-col flex-[1_0_0] self-stretch h-full rounded-xl border justify-between",
          spanClasses[span],
          { "cursor-pointer": !isEmpty && onClick },
          variants[variant],
          className
        )}
        data-tooltip-id={
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          !isEmpty &&
          (
            header as React.ReactElement<
              any,
              string | React.JSXElementConstructor<any>
            >
          ).props.title !== "Készletérték"
            ? `card-floater-${tooltipId}`
            : undefined
        }
        onClick={onClick}
        {...rest}
      >
        {header && <div>{header}</div>}
        {content}
        {footer && <div>{footer}</div>}
      </div>
      <Tooltip id={`card-floater-${tooltipId}`} float>
        <div className="hidden md:flex gap-x-3 justify-center items-center">
          <Icon icon={"expand"} size={16} className="text-contrast" />
          <span>Mutass többet</span>
        </div>
      </Tooltip>
    </>
  );
};

export default Card;
