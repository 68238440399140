import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";

import { serviceApi } from "../serviceApi";

interface Modal {
  [key: string]: boolean;
}

export interface ModalData {
  [key: string]: any;
}

interface ModalState {
  modals: Modal;
  modalData?: ModalData;
  failedModalId?: number;
}

const initialState: ModalState = {
  modals: {},
  modalData: {},
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalOpen: (state, action: PayloadAction<string>) => {
      state.modals[action.payload] = true;
    },
    setModalClose: (state, action: PayloadAction<string>) => {
      state.modals[action.payload] = false;
    },
    toggleModal: (state, action: PayloadAction<string>) => {
      state.modals[action.payload] = !state.modals[action.payload];
    },
    setModalData: (state, action: PayloadAction<ModalData>) => {
      state.modalData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(serviceApi.endpoints.getModal.matchPending, (state, action) =>
        console.log("Modal is loading")
      )
      .addMatcher(
        serviceApi.endpoints.getModal.matchFulfilled,
        (state, action) => {
          state.modalData = action.payload;
          state.failedModalId = undefined;
        }
      )
      .addMatcher(
        serviceApi.endpoints.getModal.matchRejected,
        (state, action) => {
          // TODO action FetchBaseQueryError refact
          if (state.modalData) {
            const { id, modal } = state.modalData;
            state.failedModalId = modal;
            state.modals[id] = true;
          }
        }
      );
  },
});

// Controls modal opening
export const { setModalOpen, setModalClose, toggleModal, setModalData } =
  modalSlice.actions;

export const useModal = (id: string) => {
  const modal = useSelector((state: RootState) => state.modal.modals[id]);

  return modal;
};

// Controls modal data
export const useModalData = () => {
  const modalData = useSelector((state: RootState) => state.modal.modalData);
  return modalData;
};

// Returns the failed modal id in order to reload
export const useFailedModalId = () => {
  const failedModalId = useSelector(
    (state: RootState) => state.modal.failedModalId
  );
  return failedModalId;
};

export default modalSlice.reducer;
