export const cardTypesById: { [key: string]: number } = {
  "4f89bd1c-f8d0-40de-be6b-21c95f97bd4e": 1,
  "4af4d499-4063-4bc9-b860-2755933bff03": 2,
  "de591baa-7e2c-4991-b2bf-23891ae00123": 3,
  "21e504e6-eb5f-4228-a671-63bac94cbaa4": 4,
  "d142537b-d201-4a9f-a89f-9fd1a1452ea2": 5,
  "8e305142-4a61-4b56-ad0d-f95660948a86": 6,
  "3943b20c-a7ea-4bc2-b0c9-3e777aff70eb": 7,
  "ecb8665c-817c-41ce-8ea9-1ba5cd22c546": 8,
  "cb398bf0-0e2b-47af-a017-7cbe156d80e1": 9,
  "242e51be-eca8-4663-9472-ea4cac347b64": 10,
};
