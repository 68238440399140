import type {
  DataPointApplicationFeaturesCardsQueriesDataResponse,
  DataPointApplicationFeaturesModalsQueriesModalResponse,
} from "src/store/serviceApi";
import { useEffect, useState } from "react";
import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import { P, ReactTable, Tabs } from "src/components";
import { getDisplayValue, renderTestId } from "src/utils/HelperFunctions";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: DataPointApplicationFeaturesModalsQueriesModalResponse;
}

type DataItem = {
  product: string;
  invoices: string;
  uom: string;
  sum: string;
  currency: string;
};

type DataList = Record<string, DataItem[]>;

const columnHelper = createColumnHelper<DataItem>();

const columns = [
  columnHelper.accessor("product", {
    header: () => "Termék",
    size: 30,
    cell: (row) => (
      <P className="md:text-base text-sm line-clamp-1 pr-2 py-1 break-all">
        {row.getValue()}
        <span className="tooltip-contents">{row.getValue()}</span>
      </P>
    ),
  }),
  columnHelper.accessor("invoices", {
    header: () => "Kiállított számlák száma",
    cell: (row) => (
      <P className="md:text-base text-sm pr-2">{`${row.getValue()} ${
        row.row.original.uom
      }`}</P>
    ),
  }),
  columnHelper.accessor("sum", {
    header: () => (
      <div className="text-right md:text-base text-sm ">Számlák összértéke</div>
    ),

    cell: (row) => (
      <div className="flex gap-2 justify-end pl-2 p-1 whitespace-normal">
        <P className="md:text-base text-sm font-700">
          {getDisplayValue(row.getValue(), row.row.original.currency)}
        </P>
        <P className="md:text-base text-sm opacity-60 font-500">
          {row.row.original.currency}
        </P>
      </div>
    ),
  }),
] as ColumnDef<unknown, any>[];

const ClosedInvoicesModalTabs: React.FC<Props> = ({ data }) => {
  const [groupedData, setGroupedData] = useState<DataList | undefined>();

  const groupData = (
    originalData: DataPointApplicationFeaturesCardsQueriesDataResponse[]
  ): DataList => {
    return originalData.reduce<DataList>((result, dataItem) => {
      const dataListKey = dataItem.displayText || "";

      if (dataItem.values && dataItem.values.length > 0) {
        const transformedValues = dataItem.values.map((value) => ({
          product: value[0],
          invoices: value[1],
          uom: value[2],
          sum: value[3],
          currency: value[4],
        }));

        result[dataListKey] = transformedValues;
      }

      return result;
    }, {});
  };

  useEffect(() => {
    if (data && data.data) {
      setGroupedData(groupData(data.data));
    }
  }, [data]);

  const tabs =
    groupedData &&
    Object.entries(groupedData).map(([key, data]) => ({
      label: key ?? "",
      content: (
        <div className="flex flex-col items-start gap-4 ">
          <P className="!font-500 text-primary md:text-base text-sm self-stretch">
            TOP 10 kiállított számla termékenként
          </P>
          <div className="w-full overflow-auto md:contents pb-1 mr-1 scrollbar-thumb-primary/50 scrollbar-track-primary/10 scrollbar-thumb-rounded-full scrollbar-thin scrollbar-w-1 ">
            <ReactTable
              {...renderTestId(`closed-invoices-table-${key}`)}
              key={key}
              variant="Collapsible"
              data={data}
              columns={columns}
              sticky
            />
          </div>
        </div>
      ),
    }));
  return <Tabs data={tabs} />;
};

export default ClosedInvoicesModalTabs;
