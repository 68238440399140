import { P, ReactTable, Separator } from "src/components";
import { useEffect, useState } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useExchangeRates } from "src/store/slices/exchangeRatesSlice";
import {
  getDisplayValue,
  getDisplayValueWithCurrency,
  renderTestId,
} from "src/utils/HelperFunctions";
import { DataPointApplicationFeaturesModalsQueriesModalResponse } from "src/store/serviceApi";
import getSymbolFromCurrency from "currency-symbol-map";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: DataPointApplicationFeaturesModalsQueriesModalResponse;
}

interface Data {
  name: string;
  systemValue: string;
  value: string;
  systemCurrency: string;
  currency: string;
}

const columnHelper = createColumnHelper<Data>();

const columns = [
  columnHelper.accessor("name", {
    header: () => "Pénztár / Bankszámla",
    size: 50,
    cell: (row) => (
      <P className="pr-2 py-1 md:line-clamp-1 whitespace-normal break-all">
        {row.getValue()}
        <span className="tooltip-contents">{row.getValue()}</span>
      </P>
    ),
  }),

  columnHelper.accessor("value", {
    header: () => <div className="text-right">Összeg</div>,
    cell: (row) => (
      <div className="flex gap-2 justify-end">
        <P className="!font-700">
          {getDisplayValue(row.getValue(), row.row.original.currency)}
        </P>

        <P className="opacity-60">
          {getSymbolFromCurrency(row.row.original.currency)}
        </P>
      </div>
    ),
  }),
] as ColumnDef<unknown, any>[];

const MoneyStockModalTable: React.FC<Props> = ({ data }) => {
  const [groupedData, setGroupedData] = useState<Data[] | undefined>();
  const [sum, setSum] = useState<number | undefined>();
  const exchangeRates = useExchangeRates();

  const groupData = (): Data[] => {
    if (!data || !data.data || !data.data[0] || !data.data[0].values) return [];

    let bankAccounts = data.data.find((d) => d.dataType === 25);
    if (!bankAccounts || !bankAccounts.values) return [];

    let sumResult = 0;
    const groupedData: Data[] = bankAccounts.values.map((item) => {
      sumResult =
        sumResult +
        getCurrencyValueInHUF(item[4], parseFloat(item[2]?.replace(",", ".")));
      return {
        name: item[0],
        systemValue: item[1],
        value: item[2],
        systemCurrency: item[3],
        currency: item[4],
      };
    });
    setSum(sumResult);

    return groupedData;
  };

  const getCurrencyValueInHUF = (currency: string, value: number) => {
    if (exchangeRates.exchangeRates && exchangeRates.exchangeRates[currency])
      return exchangeRates.exchangeRates[currency] * value;
    return 0;
  };

  useEffect(() => {
    if (data && data.data) setGroupedData(groupData());
  }, [data]);

  return (
    <div className="mt-4 flex-col self-stretch gap-2">
      <ReactTable
        {...renderTestId("money-stock-modal-table")}
        columns={columns}
        data={groupedData ?? []}
        variant="Collapsible"
      />
      <Separator />
      <div className="flex justify-between items-center self-stretch">
        <P className="text-primary text-sm">Összesen</P>
        <span className="flex gap-2">
          <P className="font-700">{getDisplayValueWithCurrency(sum, "HUF")}</P>
          <P className="font-500 opacity-60">Ft</P>
        </span>
      </div>
    </div>
  );
};

export default MoneyStockModalTable;
