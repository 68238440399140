import { LabelValue } from "src/models/LabelValue";
import { useEffect, useState } from "react";
import cn from "classnames";
import { P, DataLabel, PieChart, Marker } from "src/components";
import { getDisplayValue } from "src/utils/HelperFunctions";
import { DataPointApplicationFeaturesCardsQueriesDataResponse } from "src/store/serviceApi";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data:
    | DataPointApplicationFeaturesCardsQueriesDataResponse[]
    | null
    | undefined;
  colors?: string[][];
  size?: "sm" | "md";
  span?: 1 | 2 | 3 | 4 | 6;
}

const ModalPieChartWithLabels: React.FC<Props> = ({
  data,
  colors,
  size = "sm",
  span = 4,
}) => {
  const [groupedArray, setGroupedArray] = useState<LabelValue[] | undefined>();

  const chartSizes = {
    sm: 64,
    md: 191,
  };

  useEffect(() => {
    const chartData = data?.find(
      (d: DataPointApplicationFeaturesCardsQueriesDataResponse | undefined) =>
        d?.type === 3
    );

    if (
      chartData &&
      chartData?.headers &&
      chartData?.values &&
      chartData?.values.length > 0
    ) {
      const newGroupedArray: LabelValue[] = [];
      for (let index = 0; index < chartData.values[0].length - 1; index++) {
        const element = {
          label: chartData.headers[index]?.toLowerCase() ?? "",
          value: Number(chartData?.values[0][index].replace(",", ".")) ?? 0,
          uom: chartData?.values[0][2] ?? "",
        } as LabelValue;
        newGroupedArray.push(element);
      }

      setGroupedArray(newGroupedArray);
    } else setGroupedArray(undefined);
  }, [data]);

  const MainDataLabel = data && <DataLabel data={data[0]} />;

  const ExtraDataLabels = data && data[2] && (
    <div
      className={cn(
        "flex md:flex-row flex-col md:gap-0 gap-2",
        "justify-between",
        "items-center",
        "w-2/3",
        "self-stretch",
        "bg-primary/5",
        "rounded-[7px]",
        "p-4 w-full"
      )}
    >
      <DataLabel size="sm" data={data[0]} />
      <DataLabel size="sm" data={data[2]} />
      {size === "sm" && data[3] && <DataLabel size="sm" data={data[3]} />}
    </div>
  );

  const Markers = groupedArray?.map((d, i) => (
    <div
      key={i}
      className={cn("flex justify-between items-center self-stretch")}
    >
      <div className={cn("flex items-center gap-1.5")}>
        <Marker
          color={colors && colors[0] ? colors[0][i] : undefined}
          className={cn("!self-center")}
        />
        <P className={cn("text-base !font-400 text-right")}>{d?.label}</P>
      </div>
      <div className={cn("flex justify-end items-baseline gap-1")}>
        <P className={cn("text-base !font-700")}>
          {getDisplayValue(d?.value, d?.uom, true)}
        </P>
        <P className={cn("text-base !font-500 opacity-60")}>{d?.uom}</P>
      </div>
    </div>
  ));

  return (
    <div
      className={cn(
        //half-solution for as long as we don't have tablet-size
        { flex: window.innerWidth > 1000 },
        "rounded-[10px] items-start gap-4 self-stretch h-full",
        { "bg-primary/5": size === "md" }
      )}
    >
      <div
        className={cn(
          "flex items-center self-stretch md:w-1/3 w-full",
          { "bg-primary/5": size === "sm" },
          "rounded-[7px]",
          "p-4"
        )}
      >
        <span className="md:hidden block">
          <PieChart
            data={groupedArray}
            colors={colors ? colors[1] : undefined}
            size={96}
          />
        </span>
        <span className="md:block hidden">
          <PieChart
            data={groupedArray}
            colors={colors ? colors[1] : undefined}
            size={
              chartSizes[size] as React.ComponentProps<typeof PieChart>["size"]
            }
          />
        </span>
        <div className={cn("flex flex-col items-start flex-[1_0_0] gap-4")}>
          {size === "md" && MainDataLabel}
          {Markers}
        </div>
      </div>

      {size === "sm" && ExtraDataLabels}
    </div>
  );
};
export default ModalPieChartWithLabels;
