type Statuses = Record<
  number,
  {
    title: string;
    message: string;
  }
>;

export const statuses: Statuses = {
  200: {
    title: "🆗",
    message: "OK",
  },
  201: {
    title: "✅",
    message: "Created",
  },
  202: {
    title: "👍",
    message: "A kérést befogadtuk.",
  },
  203: {
    title: "🤥",
    message: "Nem hiteles információ",
  },
  204: {
    title: "🤔",
    message: "Hiányzó tartalom",
  },
  205: {
    title: "🔄",
    message: "Tartalom visszaállítása",
  },
  206: {
    title: "🧩",
    message: "Részleges tartalom",
  },
  207: {
    title: "🗂",
    message: "Multi-Status (WebDAV)",
  },
  208: {
    title: "📨",
    message: "Korábban jelentett (WebDAV)",
  },
  226: {
    title: "🕓",
    message: "A-IM használva",
  },
  300: {
    title: "🫘",
    message: "Egynél több lehetséges válasz.",
  },
  301: {
    title: "👉🏻",
    message: "A kért erőforrás véglegesen át lett helyezve.",
  },
  302: {
    title: "👉🏻",
    message: "A kért erőforrás ideiglenesen át lett helyezve.",
  },
  303: {
    title: "👉🏻",
    message:
      "Az átirányítások nem magára a kért erőforrásra hivatkoznak, hanem egy másik oldalra",
  },
  304: {
    title: "🤷🏾",
    message: "Nincs szükség a kért erőforrások újraküldésére.",
  },
  305: {
    title: "🤷🏾",
    message: "Use proxy.",
  },
  306: {
    title: "🤷🏾",
    message: "Nem használt státusz",
  },
  307: {
    title: "⤴️",
    message: "A kért erőforrás ideiglenesen át lett irányítva.",
  },
  308: {
    title: "🧳",
    message: "A kért erőforrás véglegesen át lett irányítva.",
  },
  400: {
    title: "😨",
    message:
      "A kiszolgáló nem tudja vagy nem fogja feldolgozni a kérést valami miatt, amelyet kliens oldali hibaként észlel.",
  },
  401: {
    title: "⛔",
    message:
      "Az kliens oldali kérelem nem fejeződött be, mert hiányoznak az érvényes bejelentkezési adatok a kért erőforráshoz.",
  },
  402: {
    title: "💳",
    message:
      "A kért tartalom nem érhető el addig, amíg az előfizetést nem újítják meg.",
  },
  403: {
    title: "🚫",
    message: "Az adat nem hozzáférhető!",
  },
  404: {
    title: "🔍",
    message: "Nem találtunk a kéréshez kapcsolható adatot a rendszerben.",
  },
  405: {
    title: "🙅‍♂️",
    message:
      "A kiszolgáló ismeri a kérési módszert, de a célerőforrás nem támogatja ezt a módszert.",
  },
  406: {
    title: "🥸",
    message:
      "A kiszolgáló nem tud olyan választ adni, amely megfelel a kérés proaktív tartalom-egyeztetési fejlécében meghatározott elfogadható értékek listájának, és a kiszolgáló nem hajlandó alapértelmezett megjelenítést megadni.",
  },
  407: {
    title: "🚫",
    message:
      "Hiányoznak az érvényes hitelesítési adatok a böngésző és a kért erőforráshoz hozzáférő kiszolgáló között lévő proxykiszolgálóhoz.",
  },
  408: {
    title: "⌛",
    message:
      "A válasz hiánya miatt, a kiszolgáló szeretné leállítani ezt a nem használt kapcsolatot",
  },
  409: {
    title: "😤",
    message:
      "A kiszolgáló kérésütközést jelez a célerőforrás aktuális állapotával.",
  },
  410: {
    title: "😭",
    message:
      "A célerőforráshoz már nem lehet hozzáférni az eredeti kiszolgálón, és ez az állapot valószínűleg állandó.",
  },
  411: {
    title: "🚫",
    message:
      "A kiszolgáló megtagadta a kérés elfogadását meghatározott 'Content-Length' fejléc nélkül.",
  },
  412: {
    title: "🚫",
    message: "A célerőforráshoz való hozzáférés megtagadva.",
  },
  413: {
    title: "🦣",
    message:
      "A kéréssel küldött adatcsomag nagyobb, mint a kiszolgáló által meghatározott korlátok.",
  },
  414: {
    title: "😵‍💫",
    message:
      "A kliens oldal által kért hivatkozás hosszabb, mint amennyit a kiszolgáló hajlandó értelmezni",
  },
  415: {
    title: "❓",
    message:
      "A kiszolgáló nem hajlandó elfogadni a kérést, mert a küldött adatformátum nem támogatott formátumú.",
  },
  416: {
    title: "🤨",
    message: "A kiszolgáló nem tudta kiszolgálni a kért tartományokat.",
  },
  417: {
    title: "👎🏻",
    message: "A kérés fejlécében megadott elvárás Expect nem teljesül.",
  },
  418: {
    title: "🫖",
    message:
      "A kiszolgáló nem hajlandó kávét főzni, mert az véglegesen teáskanna.",
  },
  420: {
    title: "🧘🏻",
    message: "Fokozza a nyugalmát. (Twitter)",
  },
  421: {
    title: "🥴",
    message:
      "A kérést olyan kiszolgálóhoz irányították, amely nem tud választ adni.",
  },
  422: {
    title: "🧐",
    message:
      "A kiszolgáló megérti a kérés tartalomtípusát, és a kérés szintaxisa helyes, de nem tudta feldolgozni a benne foglalt utasításokat.",
  },
  423: {
    title: "🔒",
    message:
      "A feltételesen megcélzott erőforrások zárolva vannak, vagy nem érhetők el.",
  },
  424: {
    title: "💔",
    message:
      "A metódus nem hajtható végre az erőforráson, mert a kért művelet egy másik művelettől függött, és ez a művelet meghiúsult.",
  },
  425: {
    title: "⛔",
    message:
      "A kiszolgáló nem hajlandó megkockáztatni egy esetlegesen visszajátszható kérés feldolgozását, ami újabb támadás lehetőségét teremti meg.",
  },
  426: {
    title: "🚧",
    message:
      "A kiszolgáló megtagadta a kérés végrehajtását az aktuális protokoll használatával. Protokollfrissítésre van szükség.",
  },
  428: {
    title: "🔀",
    message: "A kiszolgáló megköveteli, hogy a kérés feltételes legyen.",
  },
  429: {
    title: "🚦",
    message: "A kliens oldal túl sok kérést küldött adott időn belül.",
  },
  431: {
    title: "",
    message:
      "A kiszolgáló megtagadta a kérés feldolgozását, mert a kérés HTTP-fejléce túl hosszú.",
  },
  444: {
    title: "🤐",
    message:
      "Nincs választ az Nginx kiszolgálótól. Vegye fel a kapcsolatot a rendszergazdával.",
  },
  449: {
    title: "✋",
    message:
      "A kiszolgáló nem tudja teljesíteni a kérést, mert a kliens oldal nem adta meg a szükséges információkat.",
  },
  450: {
    title: "🔞",
    message:
      "A kiszolgáló által visszaadott hiba jelzi, hogy a szülői felügyelet be van kapcsolva, és blokkolja a hozzáférést a kért erőforráshoz.",
  },
  451: {
    title: "🏛️",
    message:
      "A kliens oldal olyan erőforrást kért, amely jogi okok miatt nem érhető el.",
  },
  499: {
    title: "🎟️",
    message: "ArcGIS token szükséges.",
  },
  500: {
    title: "🔥",
    message:
      "A kiszolgáló olyan váratlan körülménybe ütközött, amely megakadályozta a kérés teljesítésében.",
  },
  501: {
    title: "❌",
    message:
      "A kiszolgáló nem támogatja a kérés teljesítéséhez szükséges funkcionalitást.",
  },
  502: {
    title: "🚧",
    message:
      "A kiszolgáló érvénytelen választ kapott a felfelé irányuló kiszolgálótól.",
  },
  503: {
    title: "🚫",
    message:
      "A kiszolgáló nem áll készen a kérés kezelésére, mert az erőforrás nem érhető el.",
  },
  504: {
    title: "⌛",
    message:
      "A kiszolgáló nem kapott időben olyan választ a felfelé irányuló kiszolgálótól, amelyre szüksége volt a kérés teljesítéséhez.",
  },
  505: {
    title: "🕸",
    message: "A kérésben használt HTTP-verziót a kiszolgáló nem támogatja.",
  },
  506: {
    title: "🤕",
    message: "Egy megadott végpont nem érvényes HTTP-végpont.",
  },
  507: {
    title: "🤏",
    message:
      "Egy metódust nem lehetett végrehajtani, mert a kiszolgáló nem tudja tárolni a kérés sikeres végrehajtásához szükséges reprezentációt.",
  },
  508: {
    title: "🔃",
    message:
      "A kiszolgáló leállított egy műveletet, mert végtelen ciklusba ütközött.",
  },
  509: {
    title: "🚀",
    message: "A webtárhely-ügyfél túllépte a kiszolgáló oldali sávszélességet.",
  },
  510: {
    title: "❗",
    message: "Not Extended",
  },
  511: {
    title: "👮🏻‍♂️",
    message: "A kliensnek hitelesítenie kell magát a hálózati hozzáféréshez.",
  },
  598: {
    title: "⌛",
    message:
      "A proxy mögött hálózati olvasási időtúllépés történt a proxy előtti HTTP-kliens felé.",
  },
  599: {
    title: "⌛",
    message:
      "A HTTP-kapcsolat időtúllépése történt a proxy mögött a proxy előtti HTTP-kliens felé.",
  },
};
