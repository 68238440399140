import React, { HtmlHTMLAttributes } from "react";
import { Cell, flexRender } from "@tanstack/react-table";
import cn from "classnames";

interface TableCellProps extends HtmlHTMLAttributes<HTMLTableRowElement> {
  selectedRows: string[] | undefined;
  variant: "Default" | "Collapsible";
  row: any;
}

const ReactTableBodyRow: React.FC<TableCellProps> = ({
  selectedRows,
  variant,
  row,
  className,
  children,
}) => {
  return (
    <tr
      key={row.id}
      className={cn(
        "border-b border-b-blue-500/20 last-of-type:border-b-0",
        "[&>*]:bg-contrast",
        {
          "[&>*]:even:bg-[#f5f9fe]": variant === "Collapsible",
        },
        selectedRows &&
          selectedRows.includes(row.id) &&
          "!bg-[#f5f9fe]  !first-of-type:rounded-tl-xl !last-of-type:rounded-tr-xl !border-primary",
        className
      )}
    >
      {children}
    </tr>
  );
};

export default ReactTableBodyRow;
