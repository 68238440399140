import React from "react";
import cn from "classnames";
import { Icon, P } from "src/components";
import { renderTestId } from "src/utils/HelperFunctions";

type Variants = {
  [key: string]: {
    className: string | string[];
    icon: {
      name: React.ComponentProps<typeof Icon>["icon"];
      color: string;
    };
  };
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "default" | "info" | "success" | "warning" | "destructive";
}

const Callout: React.FC<Props> = ({
  children,
  variant = "default",
  className,
  ...rest
}) => {
  const variants: Variants = {
    default: {
      className: "bg-light-gray",
      icon: {
        name: "announcement",
        color: "text-black/40",
      },
    },
    info: {
      className: "bg-primary/5",
      icon: {
        name: "info",
        color: "text-primary",
      },
    },
    success: {
      className: "bg-jade/30",
      icon: {
        name: "thumb-up",
        color: "text-jade",
      },
    },
    warning: {
      className: "bg-lemon/10",
      icon: {
        name: "alert",
        color: "text-warning",
      },
    },
    destructive: {
      className: "bg-destructive/20",
      icon: {
        name: "destructive",
        color: "text-destructive",
      },
    },
  };

  return (
    <div
      {...renderTestId(`callout-${variant}`)}
      className={cn(
        "flex flex-col sm:flex-row justify-start items-center gap-2 sm:gap-4 rounded-2xl px-4 py-2",
        variants[variant].className,
        className
      )}
      {...rest}
    >
      <div className="flex-shrink-0">
        <Icon
          icon={variants[variant].icon.name}
          className={variants[variant].icon.color}
        />
      </div>
      <div>
        <P className="text-center sm:text-left">{children}</P>
      </div>
    </div>
  );
};

export default Callout;
