import { useEffect, useState } from "react";
import DataLabel from "../DataLabel/DataLabel";
import IncomeChart from "../../atoms/IncomeChart/IncomeChart";
import IconButton from "../../atoms/IconButton/IconButton";
import { monthLabels } from "src/utils/constants/Variants";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setChartIntervals } from "src/store/slices/incomeCardSlice";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { DataPointApplicationFeaturesCardsQueriesDataResponse } from "src/store/serviceApi";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data:
    | DataPointApplicationFeaturesCardsQueriesDataResponse[]
    | null
    | undefined;
  span?: 1 | 2 | 3 | 4 | 6;
  isEmpty?: boolean;
  isNotLoaded?: boolean;
}

const CardContentBarChartWithLabels: React.FC<Props> = ({
  data,
  span = 2,
  isEmpty = false,
  isNotLoaded = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [sumData, setSumData] = useState<
    DataPointApplicationFeaturesCardsQueriesDataResponse | undefined
  >();
  const [chunkStartIndex, setChunkStartIndex] = useState<number | undefined>();

  const [currentDataChunk, setCurrentDataChunk] = useState<
    string[][] | undefined
  >();

  useEffect(() => {
    if (data && data[0] && data[0].values && data[0].values.length > 0) {
      let initialChunkSize = 0;
      if (data[0].values.length >= 12) {
        initialChunkSize = 12;
      } else initialChunkSize = data[0].values.length;
      setChunkStartIndex(data[0].values.length - initialChunkSize);
    }
  }, []);

  useEffect(() => {
    if (data && chunkStartIndex !== undefined) {
      const chunk = data[0]?.values?.slice(
        chunkStartIndex,
        chunkStartIndex + 12
      );
      setCurrentDataChunk(chunk);
      if (chunk) {
        dispatch(
          setChartIntervals({
            startDate: `${chunk[0][0]}. ${monthLabels[
              chunk[0][1]
            ]?.short?.toLowerCase()}`,
            endDate: `${chunk[chunk.length - 1][0]}. ${monthLabels[
              chunk[chunk.length - 1][1]
            ]?.short?.toLowerCase()}`,
          })
        );
      }
    }
  }, [chunkStartIndex]);

  useEffect(() => {
    if (currentDataChunk && currentDataChunk?.length > 0) {
      setSumData({
        displayText: "",
        dataType: 11,
        headers: ["sum", "currency"],
        values: [
          [
            sumValues(currentDataChunk).toString(),
            currentDataChunk[0][3] ?? "",
          ],
        ],
      } as DataPointApplicationFeaturesCardsQueriesDataResponse);
    }
  }, [currentDataChunk]);

  const sumValues = (values: string[][]) => {
    let sum = 0;
    values.forEach((num) => {
      sum += Number(num[2]);
    });

    return sum;
  };

  const handleClick = (value: number, e: any) => {
    e.stopPropagation();
    if (chunkStartIndex === undefined) return;
    setChunkStartIndex(chunkStartIndex + value);
  };

  return (
    <div className="flex self-stretch flex-col h-full relative gap-4">
      {isEmpty && (
        <ErrorMessage className="py-4">
          {isNotLoaded
            ? "Sajnos még nem sikerült betölteni az adatokat"
            : "Nincs megjelenítendő adat"}
        </ErrorMessage>
      )}
      <div className="bg-contrast/60 rounded-[10px] p-3">
        <DataLabel isEmpty={isEmpty} data={sumData} />
        <div className="w-full">
          <IncomeChart data={currentDataChunk} span={span} />
        </div>
      </div>
      {data && data[0] && data[0].values && chunkStartIndex !== undefined && (
        <div className="flex self-stretch items-center justify-between">
          <div className="flex items-center gap-2">
            {chunkStartIndex >= 12 && (
              <IconButton
                size="small"
                iconName="double-chevron-left"
                onClick={(e) => handleClick(-12, e)}
                className="text-[#12121299]"
              />
            )}
            {chunkStartIndex > 0 && (
              <IconButton
                size="small"
                iconName="chevron-left"
                onClick={(e) => handleClick(-1, e)}
                className="text-[#12121299]"
              />
            )}
          </div>

          <div className="flex w-[88px] items-center justify-end gap-2">
            {chunkStartIndex + 12 < data[0].values.length && (
              <IconButton
                size="small"
                iconName="chevron-right"
                onClick={(e) => handleClick(1, e)}
                className="text-[#12121299]"
              />
            )}
            {chunkStartIndex + 24 <= data[0].values.length && (
              <IconButton
                size="small"
                iconName="double-chevron-right"
                onClick={(e) => handleClick(12, e)}
                className="text-[#12121299]"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CardContentBarChartWithLabels;
