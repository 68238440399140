import { Button, Buttons, Overlay, P } from "src/components";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalClose, useFailedModalId } from "src/store/slices/modalSlice";
import { ReactComponent as XIcon } from "src/assets/icons/result-fail.svg";
import { useCompany } from "src/store/slices/companySlice";
import { renderTestId } from "src/utils/HelperFunctions";
import { useGetModalQuery } from "src/store/serviceApi";

const MODAL_ID = "error-data-loading-modal";

const ErrorModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const failedModalId = useFailedModalId();
  const { company } = useCompany();

  const { data: modalData, isLoading } = useGetModalQuery(
    {
      id: failedModalId ?? 0,
      companyId: company?.id ?? "",
    },
    {
      skip: Boolean(!failedModalId),
    }
  );
  if (!modalData) return null;

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      title="Sikertelen adatlekérdezés"
      variant="Custom"
      size="sm"
      className="!w-[640px]"
    >
      <div className={cn("flex flex-col items-center gap-y-6 rounded-[20px]")}>
        <XIcon />
        <P>Sajnos nem sikerült az adatokat betölteni, próbálja meg később!</P>
        <Buttons>
          <Button
            {...renderTestId("cancel-button")}
            variant="Secondary"
            className="w-3/6"
            onClick={() => dispatch(setModalClose("error-data-loading-modal"))}
          >
            Mégse
          </Button>
          <Button
            {...renderTestId("reload-button")}
            variant="Primary"
            className="w-3/6"
            onClick={() => {
              dispatch(setModalClose("error-data-loading-modal"));
            }}
          >
            Újratöltés
          </Button>
        </Buttons>
      </div>
    </Overlay>
  );
};

export default ErrorModal;
