import React from "react";
import cn from "classnames";
import { COLOR } from "src/utils/constants/Enums";
import { backgroundColorVariants } from "src/utils/constants/Variants";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color?: COLOR | "primary" | "green" | "orange" | "white" | "black";
}

const Separator = ({ color = "primary", className, children }: Props) => {
  return (
    <div
      className={cn(
        "relative",
        "self-stretch",
        "w-full",
        children ? "h-0.5" : "h-px",
        `${backgroundColorVariants[color]}`,
        "rounded-small",
        className
      )}
    >
      {children ? (
        <span className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 px-4 bg-contrast font-medium text-sm text-black/30">
          {children}
        </span>
      ) : null}
    </div>
  );
};

export default Separator;
