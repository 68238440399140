import { HtmlHTMLAttributes } from "react";
import { SurveyStep } from "src/components";
import cn from "classnames";

export type SurveyStepContent = {
  status: "inactive" | "active" | "done";
  children: React.ReactNode;
};

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  steps: SurveyStepContent[];
}

const SurveySteps = ({ steps, className }: Props) => {
  return (
    <div className={cn("flex md:flex-col md:gap-y-6 gap-y-4", className)}>
      {steps
        .filter((step) => step !== undefined)
        .map((step, index) => (
          <SurveyStep key={index} number={index + 1} status={step.status}>
            {step.children}
          </SurveyStep>
        ))}
    </div>
  );
};

export default SurveySteps;
