import { Button } from "src/components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalOpen } from "src/store/slices/modalSlice";
import CompanyIdentificationInfoModal from "../CompanyIdentificationInfoModal/CompanyIdentificationInfoModal";

const CompanyIdentificationInfoModalDemo = () => {
  const dispatch = useDispatch<AppDispatch>();

  const parentModal = { title: "Cég azonosításának folyamata" };

  return (
    <>
      <Button
        onClick={() =>
          dispatch(setModalOpen("company-identification-info-modal"))
        }
      >
        Cég azonosításának folyamata
      </Button>
      <CompanyIdentificationInfoModal />
    </>
  );
};

export default CompanyIdentificationInfoModalDemo;
