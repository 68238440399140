import {
  DndContext,
  KeyboardSensor,
  useSensor,
  useSensors,
  closestCorners,
  TouchSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DataCardListItem } from "src/components";
import { useDispatch } from "react-redux";
import { IconType } from "../..";
import { useCardsSeparately } from "src/store/slices/cardsSlice";
import {
  setCardsOrder,
  toggleCardVisibility,
  useCompany,
} from "src/store/slices/companySlice";
import { SmartPointerSensor } from "src/utils/SmartPointerSensor";
import { cardTypesById } from "src/utils/constants/CardTypes";
import { cardConfig } from "src/utils/constants/CardConfig";
import { AppDispatch } from "src/store/store";
import { renderTestId } from "src/utils/HelperFunctions";
import {
  DataPointDomainUserSettingsCardSetting,
  useGetCardsQuery,
  useUpdateUserSettingsMeMutation,
} from "src/store/serviceApi";

interface Props { }

interface DNDCardSettingFormat {
  id: number;
  setting: DataPointDomainUserSettingsCardSetting;
}

const DNDContainer: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { primaryCards } = useCardsSeparately();
  const companySelector = useCompany();
  const userSetting = companySelector.userSetting;
  const [updateUserSettings] = useUpdateUserSettingsMeMutation();
  const { refetch: refetchCards } = useGetCardsQuery({
    companyId: companySelector?.company?.id || "",
  });
  const visibleSecondaryCardSettings = userSetting?.cardSettings?.filter(
    (setting: DataPointDomainUserSettingsCardSetting) =>
      setting.isVisible && setting.cardId && cardTypesById[setting.cardId] > 4
  );

  const tasks = visibleSecondaryCardSettings
    ? visibleSecondaryCardSettings.map(
      (set: DataPointDomainUserSettingsCardSetting, index: number) => ({
        id: index + 5,
        setting: set,
      })
    )
    : [];

  const sensors = useSensors(
    useSensor(SmartPointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const updateIndexOfList = (tasks: DNDCardSettingFormat[]) => {
    if (!tasks || tasks.length === 0 || !primaryCards) {
      return;
    }

    const indexCounter = primaryCards.length;
    const updatedCardSettings = tasks.map(
      (task: DNDCardSettingFormat, index: number) => ({
        cardId: task.setting.cardId,
        isVisible: task.setting.isVisible,
        index: indexCounter + index,
      })
    );
    dispatch(setCardsOrder(updatedCardSettings));
  };

  const getTaskPos = (id: number) =>
    tasks.findIndex((task: DNDCardSettingFormat) => task.id === id);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id === over.id) {
      return;
    }

    const originalPos = getTaskPos(active.id);
    const newPos = getTaskPos(over.id);
    updateIndexOfList(arrayMove(tasks, originalPos, newPos));
  };

  const handleClick = async (task: DNDCardSettingFormat) => {
    dispatch(toggleCardVisibility(task.setting.cardId));

    const cardSettings = JSON.parse(
      JSON.stringify(userSetting?.cardSettings ?? [])
    );

    const updatedCardSetting = cardSettings.map(
      (c: DataPointDomainUserSettingsCardSetting) => {
        if (c.cardId === task.setting.cardId) {
          c.isVisible = !c.isVisible;
        }
        return c;
      }
    );

    await updateUserSettings({
      companyId: companySelector.company?.id ?? "",
      body: { cardSettings: updatedCardSetting },
    })
      .then(() => {
        refetchCards()
          .then(() => {
            console.log("DND CARDS REFETCHED SUCCESSFULLY");
          })
          .catch((error) => {
            console.log("DND REFETCH CARDS ERROR", error);
          });
      })
      .catch((error) => {
        console.log("DND CONTAINER ERROR", error);
      });
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
      >
        <div className="flex flex-col gap-y-3">
          <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
            {tasks.sort(function (a: DNDCardSettingFormat, b: DNDCardSettingFormat) {
              const _a = a.setting.index ?? 0;
              const _b = b.setting.index ?? 0;
              return _a - _b;
            })
              .map((task: DNDCardSettingFormat) => {
                const card =
                  cardConfig[
                  (task.setting.cardId ?? "") as keyof typeof cardConfig
                  ];
                return (
                  <DataCardListItem
                    {...renderTestId(`sortable-item-${task.id}`)}
                    key={task.id}
                    id={task.id}
                    icon={card?.icon as IconType}
                    children={card?.title}
                    isLocked={false}
                    isAdded={true}
                    className="md:touch-auto touch-none"
                    onClick={() => handleClick(task)}
                  />
                );
              })}
          </SortableContext>
        </div>
      </DndContext>
    </>
  );
};

export default DNDContainer;
