import React from "react";

import {
  CompanyIdentificationCard,
  CompanyIdentificationLayout,
  InviteTeamMates,
} from "src/components";

const Invite = () => {
  return (
    <CompanyIdentificationLayout>
      <CompanyIdentificationCard>
        <InviteTeamMates />
      </CompanyIdentificationCard>
    </CompanyIdentificationLayout>
  );
};

export default Invite;
