import { renderTestId } from "src/utils/HelperFunctions";
import {
  CompaniesHeader,
  CompanyListItem,
  DeleteCompanyModal,
  Loader,
} from "src/components";
import { useGetCompaniesQuery, useGetTenantsQuery } from "src/store/serviceApi";
import TenantListItem from "src/components/organisms/Companies/TenantListItem";

const CompaniesPage = () => {
  const { data: companiesData, isLoading } = useGetCompaniesQuery();
  const { data: tenants } = useGetTenantsQuery();

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-full">
        <Loader variant="blue" />
      </div>
    );
  if (!companiesData) return null;

  return (
    <main
      {...renderTestId("companies-template")}
      className="flex flex-col w-full md:mx-0 md:mt-6 md:mb-auto self-stretch md:p-6 p-4"
    >
      <div className="flex flex-col md:p-6 p-4 items-center gap-6 rounded-xl md:rounded-[20px] bg-contrast self-stretch md:w-[40vw] md:min-w-[735px] md:m-auto md:max-h-[60vh] relative">
        <CompaniesHeader />
        <div className="flex self-stretch overflow-auto scrollbar-thumb-primary/50 scrollbar-track-primary/10 scrollbar-thumb-rounded-full scrollbar-thin scrollbar-w-1 scrollbar-gutter min-h-[35%] gap-4 flex-col items-start">
          {companiesData
            ?.filter((c) => !c.isDeleted)
            .map((company, index) => (
              <CompanyListItem key={index} companyItem={company} />
            ))}
          {companiesData
            ?.filter((c) => c.isDeleted)
            .map((company, index) => (
              <CompanyListItem key={index} companyItem={company} />
            ))}
          {tenants
            ?.filter(
              (tenant, index) =>
                !companiesData?.find((c) => c.tenantId === tenant.id)
            )
            ?.map((tenant, index) => (
              <TenantListItem key={index} tenantItem={tenant} />
            ))}
        </div>
      </div>
      <DeleteCompanyModal />
    </main>
  );
};
export default CompaniesPage;
