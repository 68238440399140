import { useMemo, useState } from "react";
import { Tab, P, ModuleButton } from "src/components";
import { ClaimType } from "../ModuleButton/ModuleButton";
import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data?: DataElement[];
  variant?: TabsVariant;
}

interface DataElement {
  label: string;
  content: React.ReactNode | string;
  onClick?: () => void;
}

type TabsVariant = "Tab" | "Segment" | "Claim" | "Registry";

const Tabs: React.FC<Props> = ({ data, variant = "Tab", className }: Props) => {
  const [activeTab, SetActiveTab] = useState(0);
  const tabContent = useMemo(
    () => data && data[activeTab]?.content,
    [data, activeTab]
  );
  if (!data) {
    return null;
  }

  const handleTab = (i: number) => {
    const tabAction = data[i].onClick;
    if (tabAction) tabAction();

    SetActiveTab(i);
  };

  const variants = {
    Tab: "gap-x-2",
    Segment:
      "p-1 bg-contrast border-1 gap-x-1 border-font-main/[0.1] rounded-[8px]",
    Claim: "self-stretch gap-6",
  };

  return (
    <div className="flex flex-col w-full md:w-auto justify-start items-start gap-y-4">
      <div
        className={cn(
          "flex flex-row justify-start items-start self-stretch md:w-auto w-full overflow-auto scrollbar-none",
          variants[variant as keyof typeof variants],
          className
        )}
      >
        {data?.map(({ label, content }, index) =>
          variant === "Tab" || variant === "Segment" ? (
            <Tab
              className="w-full md:w-auto"
              key={`tab-${index}-${label}`}
              onClick={(e) => {
                e.stopPropagation();
                handleTab(index);
              }}
              isActive={index === activeTab}
              title={label}
              variant={variant}
            />
          ) : (
            <ModuleButton
              key={`tab-${index}-${label}`}
              onClick={(e) => {
                e.stopPropagation();
                handleTab(index);
              }}
              isActive={index === activeTab}
              title={label}
              phase={variant === "Claim" ? (label as ClaimType) : undefined}
              variant={variant}
            />
          )
        )}
      </div>
      {tabContent && (
        <div className="w-full">
          {typeof tabContent === "string" ? <P>{tabContent}</P> : tabContent}
        </div>
      )}
    </div>
  );
};

export default Tabs;
