import React from "react";
import { useLocation } from "react-router-dom";
import { P, SurveySteps } from "src/components";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { renderTestId } from "src/utils/HelperFunctions";

interface Props {
  children: React.ReactNode;
}

const CompanyIdentificationLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  return (
    <div
      {...renderTestId("company-identification-layout")}
      className="md:flex-1 flex flex-col h-[90vh] md:grid gap-6 grid-cols-1 md:grid-cols-6 gap-x-6 md:px-10 px-0 pb-10 items-start md:items-center"
    >
      <div className="md:w-auto w-full col-span-1 md:col-span-2 flex flex-col items-start md:flex-row justify-start md:items-center">
        <div className="px-3 w-full overflow-x-auto scrollbar-none">
          <SurveySteps
            steps={
              [
                location.state?.navigatedFromCompanies
                  ? undefined
                  : {
                      status: "done",
                      children: <P>Adatok kitöltése</P>,
                    },
                {
                  status: location.pathname.includes(
                    INTERNAL_ROUTE.COMPANY_IDENTIFICATION
                  )
                    ? "active"
                    : location.pathname.includes(INTERNAL_ROUTE.INVITE)
                    ? "done"
                    : "inactive",
                  children: <P>Cég azonosítása</P>,
                },
                {
                  status: location.pathname.includes(INTERNAL_ROUTE.INVITE)
                    ? "active"
                    : "inactive",
                  children: <P>További személyek meghívása</P>,
                },
              ] as React.ComponentProps<typeof SurveySteps>["steps"]
            }
          />
        </div>
      </div>
      <div className="col-span-1 md:col-span-4 xl:col-span-3 2xl:col-span-2 flex md:justify-center justify-between md:h-auto h-full md:self-auto self-stretch items-center">
        {children}
      </div>
    </div>
  );
};

export default CompanyIdentificationLayout;
