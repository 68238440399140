// TODO: egyelőre ezt itt hagyom mint organizmus, de egy sima Card molekula kéne legyen
// a Card-ot is refaktolni kéne mert jobb lenne a class-variance-authority-t használni hozzá
// a normál kártyához képest ez egy picit eltér, de felveheténk akár egy olyan variantot
// hogy Panel és az ezt render-elné

interface Props {
  children: React.ReactNode;
}

const CompanyIdentificationCard: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className="flex flex-col self-stretch gap-y-6 md:p-10 p-4 w-full md:rounded-[20px] bg-contrast">
        {children}
      </div>
    </>
  );
};

export default CompanyIdentificationCard;
