import axios, { AxiosRequestConfig, AxiosResponse, isAxiosError } from "axios";

const baseUrl = process.env.REACT_APP_EXCHANGERATE_BASE_URL;

const getInstance = () => {
  const defaultOptions: AxiosRequestConfig = {
    baseURL: baseUrl,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    transformResponse: [],
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

const fetchExchangeRates = async () => {
  let url_ = baseUrl + "/api/exchangerate/current";
  url_ = url_.replace(/[?&]$/, "");

  let options_: AxiosRequestConfig = {
    method: "GET",
    url: url_,
    headers: {
      Accept: "application/json",
    },
  };

  return getInstance()
    .request(options_)
    .catch((_error: any) => {
      if (isAxiosError(_error) && _error.response) {
        return _error.response;
      } else {
        throw _error;
      }
    })
    .then((_response: AxiosResponse) => {
      return _response;
    });
};

export default fetchExchangeRates;
