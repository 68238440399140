import React, { useState, useEffect, useRef, CSSProperties } from "react";
import cn from "classnames";
import { Field } from "react-final-form";
import { Icon, P } from "src/components";
import Select, {
  CSSObjectWithLabel,
  OptionsOrGroups,
  PropsValue,
  StylesConfig,
} from "react-select";

interface SelectOption {
  value: string;
  label: string;
}

interface Props extends React.SelectHTMLAttributes<HTMLDivElement> {
  options: SelectOption[];
  onChangeSelect?: (value: any, meta: any) => void;
  variant?: "small" | "normal";
  defaultSelectValue?: SelectOption;
}

const SelectInput: React.FC<Props> = ({
  className,
  name,
  options,
  onChangeSelect,
  variant = "normal",
  defaultSelectValue,
  ...rest
}) => {
  const variants = {
    small: "40px",
    normal: "48px",
  };

  const customControlStyles: CSSProperties = {
    color: "#121212",
    borderColor: "rgba(18, 18, 18, 0.10)",
    borderRadius: "12px",
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: "Space Grotesk",
    cursor: "pointer",
    height: variants[variant],
  };

  const selectStyle: StylesConfig<any, false> = {
    control: (provided, state) => {
      return {
        ...provided,
        ...customControlStyles,
      };
    },
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided) => ({
      ...provided,
      cursor: "pointer",
      opacity: 0.6,
    }),
  };

  return (
    <Select
      defaultValue={defaultSelectValue as any}
      onChange={onChangeSelect}
      options={options as any}
      menuPortalTarget={document.body}
      styles={selectStyle}
      isSearchable={false}
      {...rest}
    />
  );
};

export default SelectInput;
