import { ReactComponent as PayeeLogo } from "src/assets/landing/main/payee-logo.svg";
import { ReactComponent as Img1 } from "src/assets/landing/payee/payeelanding_illu.svg";
import { ReactComponent as Img10 } from "src/assets/landing/payee/payeelanding_illu.svg";
import { ReactComponent as Img2 } from "src/assets/landing/payee/payee2.svg";
import { ReactComponent as Img3 } from "src/assets/landing/payee/payee3.svg";
import { ReactComponent as Img4 } from "src/assets/landing/payee/payee4.svg";
import { ReactComponent as Checkmark } from "src/assets/landing/flexibill/checkmark.svg";
import { Loader, P } from "src/components";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

const PayeeLandingPage = () => {
  const { inProgress } = useMsal();

  return inProgress !== InteractionStatus.None ? (
    <div className="my-auto">
      <Loader variant="blue" />
    </div>
  ) : (
    <main className="bg-contrast self-stretch w-full content-center items-center md:justify-center md:items-center">
      <div className="bg-contrast flex flex-col items-center gap-6 md:gap-10 px-5 md:px-20 py-2 md:py-10 md:max-w-[1280px] md:m-auto">
        <div
          className="md:flex py-3 justify-between mt-5 items-center  self-stretch rounded-4xl 
                        bg-gradient-to-r from-[#FF8989]/0 to-[#FF8989]/10"
        >
          <div className="w-1/2">
            <div className="hidden md:ml-10 md:m-10 md:flex items-start flex-col self-stretch gap-2 md:gap-6 ">
              <P className="font-700 text-3xl md:text-[56px] md:leading-[64px] text-[#020626]/70 ">
                Ügyvédi és jogi követeléskezelési megoldások
              </P>
              <div className="flex items-center gap-4">
                <P className="text-[#020626]/70 md:font-700 md:text-xl">
                  Partnerünk a
                </P>
                <PayeeLogo className="w-24 md:w-auto" />
              </div>
            </div>
          </div>
          <Img1 className="right-0 md:w-auto md:h-[450px] w-full h-full" />
        </div>
        <div className="flex md:hidden items-start flex-col self-stretch gap-2 md:gap-6 ">
          <P className="font-700 text-3xl md:text-5xl text-[#020626]/70 md:w-2/4">
            Ügyvédi és jogi követeléskezelési megoldások
          </P>
          <div className="flex items-center gap-4">
            <P className="text-[#020626]/70 md:font-700 md:text-xl">
              Partnerünk a
            </P>
            <PayeeLogo className="w-24 md:w-auto" />
          </div>
        </div>

        <div className="flex flex-wrap justify-start self-stretch md:gap-0 md:w-2/4">
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] ">
            <P className="text-[#020626]/70 text-2xl md:text-4xl font-700">
              Nem fizetett határidőre a vevőd?
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              Itt a megoldás, közvetlenül Adatpont fiókodban! A
              követeléskezeléssel valószínűleg te sem szeretsz foglalkozni, néha
              mégis muszáj. Intézd el a lehető leggyorsabban, teljesen online!{" "}
              <b>Szakjogászi, ügyvédi belsős csapattal dolgozunk,</b> neked nem
              kell ügyvédekkel beszélni egyáltalán. A fiókodba összeszedtük egy
              helyre a lehetőségeidet, és az ügyindítás is gyerekjáték lesz. A
              követeléskezelési lépéseket a Payee nemzetközi hálózatának
              köszönhetően egy helyi, az adott országot és annak{" "}
              <b>jogi sajátosságait jól ismerő szakjogászi csapat végzi.</b>
            </P>
          </div>
          <div></div>
        </div>

        <div className="flex flex-wrap md:flex-nowrap content-center">
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] md:w-1/2 md:pr-5">
            <P className="text-[#020626]/70 text-2xl md:text-4xl font-700 ">
              Jobb lesz a cashflow-d!
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              Ha időben lépsz velünk, több pénzed áramlik be vállalkozásodba
            </P>
            <Img2 className="w-full h-full md:w-auto md:h-auto" />
          </div>
          <div className="flex flex-col gap-6 items-center md:w-1/2 md:pl-5">
            <div className="flex p-4 md:p-8 flex-col items-start gap-2 self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-2">
                <Checkmark />
                <P className="font-700">Kevesebb bosszantó számla</P>
              </div>
              <P className=" text-sm opacity-80 text-[#020626]">
                Ha csak ígérget, de nem fizet, akkor is lesz gyors eszközöd
                online
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-2 self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-2">
                <Checkmark />
                <P className="font-700">Élő nyomon követés</P>
              </div>
              <P className=" text-sm opacity-80 text-[#020626]">
                Naprakész leszel a státusz és teendőd kapcsán, értesítünk
                emailben a lényegről
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-2 self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-2">
                <Checkmark />
                <P className="font-700">24 órás átfutás</P>
              </div>
              <P className=" text-sm opacity-80 text-[#020626]">
                A számla feltöltése és adatmegadás után egy napon belül
                elindítjuk
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-2 self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-2">
                <Checkmark />
                <P className="font-700">24/7 Elérhetőség</P>
              </div>
              <P className=" text-sm opacity-80 text-[#020626]">
                Az online alkalmazások általában 24/7 elérhetőek, ami azt
                jelenti, hogy az ügyfelek bármikor benyújthatják
                dokumentumaikat, követhetik az ügyüket vagy kapcsolatba
                léphetnek az ügyvéddel. Ez növeli a rugalmasságot és kényelmet a
                felhasználók számára.
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-2 self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-2">
                <Checkmark />
                <P className="font-700">Adatbiztonság és Titoktartás</P>
              </div>
              <P className=" text-sm opacity-80 text-[#020626]">
                A megbízható online alkalmazások általában magas szintű
                adatbiztonságot nyújtanak. Az elektronikus dokumentumok kezelése
                során szigorú titoktartási előírásokat betartva segít
              </P>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center gap-2 md:gap-6 self-stretch">
          <P className="text-[#020626]/70 text-2xl md:text-4xl font-700 text-left self-stretch">
            Jogi lépés folyamata
          </P>
          <div className="flex items-start flex-col md:flex-row gap-6 self-stretch">
            <div className="flex p-4 md:p-8 flex-col items-start gap-4 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-4">
                <div className="flex w-8 h-8 p-3 justify-center items-center gap-2 rounded-[39px] bg-primary">
                  <P className="text-white text-center font-500">1</P>
                </div>
                <P className="text-xl font-700 text-[#020626]/70">
                  Adatmegadás és fizetés
                </P>
              </div>
              <P className="text-sm opacity-80">
                Az Adatponton pár kattintással elindíthatod a folyamatot,
                behúzzuk a számláidat és előtöltjük a lehető legtöbb adatot
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-4 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-4">
                <div className="flex w-8 h-8 p-3 justify-center items-center gap-2 rounded-[39px] bg-primary">
                  <P className="text-white text-center font-500">2</P>
                </div>
                <P className="text-xl font-700 text-[#020626]/70">
                  24 órán belül elindul az ügy
                </P>
              </div>
              <P className="text-sm opacity-80">
                A Payee algoritmusai ellenőrzik az adatokat és ha minden
                rendben, akkor elkészíti a kérelmet, majd ügyvédi csapatával
                beadják a hatóságokhoz
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-4 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-4">
                <div className="flex w-8 h-8 p-3 justify-center items-center gap-2 rounded-[39px] bg-primary">
                  <P className="text-white text-center font-500">3</P>
                </div>
                <P className="text-xl font-700 text-[#020626]/70">
                  Követés a fiókodban
                </P>
              </div>
              <P className="text-sm opacity-80">
                Automatikus státuszok a fiókodban, minden lényeges eseményről
                értesítünk, nem kell fejben tartanod vagy vezetned a
                fejleményeket
              </P>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center self-stretch gap-2">
          <div className="md:w-1/2 md:flex justify-start md:pr-5">
            <Img3 className="h-full w-full md:h-auto md:w-auto" />
          </div>
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] md:w-1/2">
            <P className="text-[#020626]/70  text-2xl md:text-4xl font-700 ">
              Legyél pénzednél az Adatpont segítségével
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              A Payee integráció révén a felhasználók könnyen és hatékonyan
              kezelhetik kintlévőségeiket és indíthatnak digitális
              követeléskezelést az Adatpont felületéről, így optimalizálva a
              cash flow-t és növelve a likviditást.
            </P>
          </div>
        </div>

        <div className="flex flex-wrap items-center self-stretch gap-2">
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] md:w-1/2 md:pr-5">
            <P className="text-[#020626]/70 text-2xl md:text-4xl font-700 ">
              Partnerünk, a Payee
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              Magyarország első digitális követeléskezelő platformját azért
              hoztuk létre, hogy ne legyen kifizetetlen számlád
            </P>
          </div>
          <div className="md:w-1/2 md:flex justify-start md:pl-5">
            <Img4 className="h-full w-full md:h-auto md:w-auto" />
          </div>
        </div>
      </div>
    </main>
  );
};
export default PayeeLandingPage;
