export const cardConfig = {
  "4f89bd1c-f8d0-40de-be6b-21c95f97bd4e": {
    title: "12 havi árbevétel",
    testId: "income-card",
    modalId: "income-modal",
    modalTitle: "12 havi árbevétel",
    infoText:
      "A kiválasztott 12 hónapban realizált bevételek összege a programban kiállított számlák alapján.",
    icon: "money",
  },
  "4af4d499-4063-4bc9-b860-2755933bff03": {
    title: "Kintlévőség és követeléskezelés",
    testId: "accounts-receivable-management-card",
    modalId: "accounts-receivable-management-modal",
    modalTitle: "Kintlévőség és követeléskezelés",
    infoText: "A lejárt számlák és aktuális kintlévőségek összege.",
    icon: "law",
  },
  "de591baa-7e2c-4991-b2bf-23891ae00123": {
    title: "Szállítói tartozás",
    testId: "supplier-debt-card",
    modalId: "supplier-debt-modal",
    modalTitle: "Szállítói tartozás",
    infoText:
      "Az Ügyvitelben rögzített, ki nem egyenlített bejövő számlák összege.",
    icon: "card-with-flag",
  },
  "21e504e6-eb5f-4228-a671-63bac94cbaa4": {
    title: "Pénzkészlet",
    testId: "money-stock-card",
    modalId: "money-stock-modal",
    modalTitle: "Pénzkészlet",
    infoText:
      "Az Ügyvitelben vezetett házipénztárak és bankszámlák aktuális pénzkészletének egyenlege.",
    icon: "coins",
  },
  "d142537b-d201-4a9f-a89f-9fd1a1452ea2": {
    title: "Kiállított számlák",
    testId: "closed-invoices-card",
    modalId: "closed-invoices-modal",
    modalTitle: "Kiállított számlák termékenként",
    infoText:
      "Termékenként látható, hogy az adott termék hány darab számlán szerepel milyen összértékben.",
    icon: "cards",
  },
  "8e305142-4a61-4b56-ad0d-f95660948a86": {
    title: "Hitelkeret",
    testId: "credit-limit-card",
    modalId: "credit-limit-modal",
    modalTitle: "Hitelkeret",
    infoText: "Az Ügyvitelben beállított hitelkeret alapján.",
    icon: "money",
  },
  "3943b20c-a7ea-4bc2-b0c9-3e777aff70eb": {
    title: "Minimum készlet alatti termékek",
    testId: "minstock-products-card",
    modalId: "minstock-products--modal",
    modalTitle: "Minimum készlet alatti termékek",
    infoText:
      "Azon termékek listája, melyek aktuális készlet mennyisége a beállított minimális mennyiség alatt van.",
    icon: "store",
  },
  "ecb8665c-817c-41ce-8ea9-1ba5cd22c546": {
    title: "Nyitott rendelések",
    testId: "open-orders-card",
    modalId: "open-orders-modal",
    modalTitle: "Nyitott rendelések",
    infoText:
      "Az Ügyvitelben nyitott állapotú vevői rendelések darabszáma és azok összértéke.",
    icon: "cart",
  },
  "cb398bf0-0e2b-47af-a017-7cbe156d80e1": {
    title: "Készletérték",
    testId: "stock-value-card",
    modalId: "",
    modalTitle: "",
    infoText: "A raktárkészleten lévő termékek aktuális készletértéke.",
    icon: "store",
  },
  "242e51be-eca8-4663-9472-ea4cac347b64": {
    title: "Nyitott árajánlatok",
    testId: "open-bids-card",
    modalId: "open-bids-modal",
    modalTitle: "Nyitott árajánlatok",
    infoText: "Nyitott árajánlatok darabszáma és azok nettó összege.",
    icon: "deal",
  },
};
