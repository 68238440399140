import { ReactComponent as Img1 } from "src/assets/landing/main/mainlanding_illu.svg";
import { ReactComponent as Img2 } from "src/assets/landing/main/mockup.svg";
import { ReactComponent as Img3 } from "src/assets/landing/main/Illustration.svg";
import { ReactComponent as Img4 } from "src/assets/landing/main/flexibill.svg";
import { ReactComponent as Img5 } from "src/assets/landing/main/payee.svg";
import { ReactComponent as Img6 } from "src/assets/landing/main/payee-logo.svg";
import { ReactComponent as Img7 } from "src/assets/landing/main/bill_digitalis_hor_color 1.svg";
import { ReactComponent as Img8 } from "src/assets/landing/main/kulcs-user.svg";
import { ReactComponent as KsLogo } from "src/assets/landing/main/Frame.svg";
import { Button, Icon, P, Tag } from "src/components";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { useMsal, useIsAuthenticated, withMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { handleLogin, handleSignUp } from "src/utils/auth/AuthActions";
import { useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";

const MainLandingPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    window.location.hash &&
      window.scrollTo({
        top:
          (document.querySelector(window.location.hash) as HTMLElement)
            ?.offsetTop - 100,
        behavior: "auto",
      });
  }, []);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      navigate(INTERNAL_ROUTE.DASHBOARD);
    }
  }, [inProgress, isAuthenticated]);

  const handleGetUgyvitelClick = () => {
    const link =
      "https://www.kulcs-soft.hu/termekek/szamlazo-keszletkezelo-program?utm_source=landig&utm_medium=web&utm_campaign=KScta&utm_date=20240717";
    window?.open(link, "_blank");
  };

  return (
    <main className="bg-contrast self-stretch w-full content-center items-center md:justify-center md:items-center">
      <div className="bg-contrast flex flex-col items-center gap-6 md:gap-10 px-5 md:px-20 py-2 md:py-10 md:max-w-[1280px] md:m-auto">
        <div className="flex flex-col gap-2 md:hidden self-stretch justify-between">
          <Button
            className="w-full md:w-auto !p-3 border border-transparent"
            onClick={handleSignUp}
          >
            Regisztrálok
          </Button>
          <Button
            className="w-full md:w-auto !p-3 border border-primary"
            onClick={handleLogin}
            variant="Secondary"
          >
            Bejelentkezés
          </Button>

          <Button
            className="w-full md:w-auto !p-3 border border-transparent"
            onClick={handleGetUgyvitelClick}
            variant="Secondary"
          >
            Érdekel a Kulcs-Ügyvitel
          </Button>
        </div>
        <div className="md:flex py-3 gap-10 items-center  self-stretch rounded-4xl bg-gradient-to-r from-[#7568F6]/0 to-[#7568F6]/10">
          <div className="md:w-1/2 ">
            <div className="hidden md:ml-10 md:m-10 md:flex items-start flex-col self-stretch gap-2 md:gap-6 ">
              <Tag className="rounded-[32px]" color="purple">
                Kulcs-Ügyvitel felhasználóknak!
              </Tag>
              <P className="font-700 text-3xl md:text-[56px] md:leading-[64px] text-[#020626]/70">
                Kövesd nyomon vállalkozásod pénzügyeit
              </P>
              <P className="text-[#020626]/80 opacity-80 md:text-lg">
                Az Adatpont segítségével vállalkozásod fontos pénzügyi adatait
                és kimutatásait tekintheted át egyszerűen, valós időben. Ez a
                kiegészítő szolgáltatás kifejezetten a Kulcs-Ügyvitel
                felhasználóknak készült, és az onnan származó adatokat jeleníti
                meg.
              </P>
              <div className="flex self-stretch justify-between">
                <Button className="w-full md:w-auto" onClick={handleSignUp}>
                  Regisztrálok
                </Button>
                <Button
                  className="w-full md:w-auto"
                  onClick={handleGetUgyvitelClick}
                  variant="Secondary"
                >
                  Érdekel a Kulcs-Ügyvitel
                </Button>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 justify-end flex ">
            <Img1 className="md:w-auto md:h-auto h-full w-full" />
          </div>
        </div>
        <div className="flex md:hidden items-start flex-col self-stretch gap-2 md:gap-6">
          <Tag
            className="rounded-[32px] w-full text-center !font-500"
            color="purple"
          >
            Kulcs-Ügyvitel felhasználóknak!
          </Tag>
          <P className="font-700 text-3xl md:text-[56px] md:leading-[64px] text-[#020626]/70">
            Kövesd nyomon vállalkozásod pénzügyeit
          </P>
          <P className="text-[#020626]/80 opacity-80 md:text-lg">
            Az Adatpont segítségével vállalkozásod fontos pénzügyi adatait és
            kimutatásait tekintheted át egyszerűen, valós időben. Ez a
            kiegészítő szolgáltatás kifejezetten a Kulcs-Ügyvitel
            felhasználóknak készült, és az onnan származó adatokat jeleníti meg.
          </P>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-start gap-6 py-4 md:py-0">
          <div className="flex p-8 flex-col items-start gap-2 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
            <div className="flex md:block gap-2 md:space-y-2">
              <Icon icon="stars" variant="Container" />
              <P className="md:self-stretch text-[#020626]/70 text-xl font-700">
                Ingyenes és Rugalmas Használat
              </P>
            </div>
            <P className="text-[#0206260]/70 opacity-80">
              Az Adatpont alkalmazás használata ingyenes, könnyedén össze tudod
              kapcsolni a már meglévő Kulcs-Ügyvitel szoftvereddel. Ezáltal
              könnyedén kihasználhatod az Adatpont előnyeit extra költségek
              nélkül, optimalizálva pénzügyi folyamataidat.
            </P>
          </div>
          <div className="flex p-8 flex-col items-start gap-2 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
            <div className="flex md:block gap-2 md:space-y-2">
              <Icon icon="chart" variant="Container" />
              <P className="self-stretch text-[#020626]/70 text-xl font-700">
                Gyors és Kompakt Kimutatások
              </P>
            </div>
            <P className="text-[#0206260]/70 opacity-80">
              Az Adatpont segítségével gyorsan és könnyen hozzáférhetsz valós
              idejű kimutatásokhoz az árbevételről, készletről, és egyéb
              pénzügyi mutatókról, így hatékonyabban tervezheted és irányíthatod
              vállalkozásod pénzügyeit.
            </P>
          </div>
          <div className="flex p-8 flex-col items-start gap-2 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
            <div className="flex md:block gap-2 md:space-y-2">
              <Icon icon="card-list" variant="Container" />
              <P className="self-stretch text-[#020626]/70 text-xl font-700">
                Felhasználóbarát Irányítópult
              </P>
            </div>
            <P className="text-[#0206260]/70 opacity-80">
              Az intuitív és felhasználóbarát felület lehetővé teszi számodra,
              hogy egyszerűen navigálj és értelmezd az üzleti adatokat,
              minimalizálva a döntéshozatali folyamatokat és növelve a
              hatékonyságot.
            </P>
          </div>
          <div className="flex p-8 flex-col items-start gap-2 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
            <div className="flex md:block gap-2 md:space-y-2">
              <Icon icon="cards" variant="Container" />
              <P className="self-stretch text-[#020626]/70 text-xl font-700">
                Integrált Követeléskezelés és Faktoring
              </P>
            </div>
            <P className="text-[#0206260]/70 opacity-80">
              Adatpont nem csupán egy pénzügyi kimutatásokat biztosító
              alkalmazás; integrált követeléskezelési és faktoring
              szolgáltatásokat is nyújt, segít a gyorsabb kifizetések elérésében
              és az adminisztratív terhek csökkentésében
            </P>
          </div>
        </div>
        <div className="flex flex-col items-center self-stretch gap-2 py-5 md:py-10">
          <span id="dashboard-info"></span>
          <P className="text-primary text-sm md:text-base font-700 tracking-[1px]">
            ISMERD MEG A FUNKCIÓKAT
          </P>
          <P className="font-700 text-2xl md:text-5xl text-[#020626]/70 tracking-[-0.5px] text-center">
            Kövesd vállalkozásod aktuális teljesítményét az Adatpont
            segítségével
          </P>
        </div>
        <div className="md:flex items-center p-4 md:p-10 space-y-5 md:gap-0 md:self-stretch rounded-4xl bg-primary/5">
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] md:w-1/2 md:pr-5">
            <P className="text-[#020626]/70 text-xl md:text-4xl font-700 ">
              A dashboard, ahol egy helyen minden elérhető
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              A Kulcs-Ügyvitel szoftverből származó adatokat intuitív és
              átlátható irányítópulton jelenítjük meg, amely naprakészen mutatja
              az üzleti döntéshozatalhoz nélkülözhetetlen pénzügyi
              információkat. A vezetők és döntéshozók számára elérhető
              adatkártyákon olyan kulcsfontosságú mutatók láthatók, mint az
              árbevétel, kintlévőségek, szállítói tartozások, kiállított
              számlák, hitelkeret, minimum készlet alatti termékek, nyitott
              rendelések, készletérték, nyitott ajánlatok és pénzkészlet.
              Lehetővé téve számukra, hogy gyors és megfontolt üzleti döntéseket
              hozzanak.
            </P>
          </div>
          <div className="flex md:w-1/2 justify-end">
            <Img2 className="w-full h-full md:h-auto md:w-auto md:pl-5" />
          </div>
        </div>
        <div className="flex flex-wrap items-center content-center md:gap-0 py-4 md:px-6">
          <div className="md:w-1/2 md:flex justify-start md:pr-5">
            <Img3 className="w-full h-full md:h-auto md:w-auto" />
          </div>
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] md:w-1/2 md:pl-5">
            <P className="text-[#020626]/70 text-xl md:text-4xl font-700 ">
              Testreszabható felület
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              Döntsd el, hogy mely adatkártyák jelenjenek meg az irányítópulton
              és milyen sorrendben. Így a felület pontosan az igényeidhez fog
              illeszkedni. Az Adatpont több cég kezelését is lehetővé teszi,
              kényelmesen tudsz váltani a vállalkozásaid felületei között,
              mindezt egyetlen kattintással. A cégekhez tartozó adatkártyákat
              kollégáid számára is könnyen hozzáférhetővé tudod tenni, támogatva
              az együttműködést és a hatékony munkavégzést.
            </P>
          </div>
          <div id="flexibill-info" className="w-full h-[1px]" />
        </div>
        <div className="flex flex-wrap items-center content-center md:gap-0 py-4 md:px-6">
          <Img4 className="block md:hidden w-full h-full md:h-auto md:w-auto mb-3" />
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] md:w-1/2 md:pr-5">
            <P className="text-[#020626]/70 text-xl md:text-4xl font-700 ">
              Faktorálás - Gyorsabb kifizetés, nagyobb eredményesség{" "}
            </P>
            <div className="flex items-center gap-4">
              <P className="">Partnerünk a </P>
              <Img7 />
            </div>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              A Flexibill faktorálási szolgáltatás integrálása az Adatpont
              felületére lehetővé teszi számodra, hogy gyorsan és rugalmasan
              kezeld kintlévőségeidet, minimalizáld a kockázatokat, és növeld a
              likviditást.
            </P>
            <Tag
              className="!font-500 !px-6 !py-4 !rounded-3xl !bg-primary text-white cursor-pointer"
              onClick={() =>
                window?.open(
                  `${process.env.REACT_APP_SELF_BASE_URL}${INTERNAL_ROUTE.FLEXIBILL}`,
                  "_blank"
                )
              }
            >
              A Flexibillről
            </Tag>
          </div>{" "}
          <div className="md:w-1/2 md:flex justify-end">
            <Img4 className="hidden md:block w-full h-full md:h-auto md:w-auto md:pl-5" />
          </div>
        </div>
        <div className="flex flex-wrap items-center content-center md:gap-0 py-4 md:px-6">
          <div className="md:w-1/2 md:flex justify-start md:pr-5">
            <Img5 className="w-full h-full md:h-auto md:w-auto md:mb-0 mb-3" />
          </div>
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] md:w-1/2 md:pl-5">
            <P className="text-[#020626]/70 text-xl md:text-4xl font-700 ">
              Jogi követeléskezelési lépések indítása online{" "}
            </P>
            <div className="flex items-center gap-4">
              <P className="">Partnerünk a </P>
              <Img6 />
            </div>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              Ha egy vevőd tartozik, és te már mindent megpróbáltál, de még
              mindig nem fizet, akkor van egy jó hírünk! Az Adatpont fiókodból
              azonnal elindíthatod a jogi lépéseket, hogy mielőbb pénzednél
              legyél, és még ügyvéddel sem kell beszélned ehhez.
            </P>
            <Tag
              className="!font-500 !px-6 !py-4 !rounded-3xl !bg-primary text-white cursor-pointer"
              onClick={() =>
                window?.open(
                  `${process.env.REACT_APP_SELF_BASE_URL}${INTERNAL_ROUTE.PAYEE}`,
                  "_blank"
                )
              }
            >
              A Payee-ről
            </Tag>
          </div>
        </div>
        <div className="h-auto w-full self-stretch md:px-6">
          <div style={{ padding: "53.75% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/941908742?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Adatpont-reg"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <div className="flex flex-wrap items-center content-center bg-primary/5 rounded-4xl self-stretch p-4 md:p-10">
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] md:w-1/2 md:pr-5">
            <KsLogo />
            <P className="text-[#020626]/70 text-xl md:text-4xl font-700 ">
              Kulcs-Felhasználó{" "}
            </P>

            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              Fedezd fel a könnyű és biztonságos hozzáférést a Kulcs-Soft
              szolgáltatáshoz a Kulcs-Felhasználóval!
            </P>
            <Button
              className="w-full md:w-auto"
              onClick={() =>
                window?.open(
                  `${process.env.REACT_APP_SELF_BASE_URL}${INTERNAL_ROUTE.KULCSUSER}`,
                  "_blank"
                )
              }
            >
              A Kulcs-Felhasználóról{" "}
            </Button>
          </div>
          <div className="md:flex justify-end md:w-1/2 md:pl-5">
            <Img8 className="w-full h-full md:h-auto md:w-auto" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default withMsal(MainLandingPage);
