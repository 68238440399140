import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import {
  ModalData,
  setModalClose,
  useModalData,
} from "src/store/slices/modalSlice";
import { Overlay, P, Button } from "src/components";
import { ReactComponent as XIcon } from "src/assets/icons/result-fail.svg";
import { renderTestId } from "src/utils/HelperFunctions";
import { useCompany } from "src/store/slices/companySlice";
import { useToast } from "src/components/_ui/toast/use-toast";
import { useDeleteUserMutation } from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const MODAL_ID = "delete-user-modal";

const DeleteUserModal: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const modalData = useModalData();
  const { company } = useCompany();
  const { toast } = useToast();

  const [deleteUser] = useDeleteUserMutation();

  const handleSubmit = async () => {
    await deleteUser({
      companyId: company?.id ?? "",
      id: (modalData as ModalData).id,
    })
      .then((res) => {
        if (!(res as any).data) {
          toast({
            title: "Sikeresen eltávolítottad a felhasználót",
            variant: "success",
          });
          closeModal();
        } else
          toast({
            title: "A felhasználó eltávolítása sikertelen",
            variant: "destructive",
          });
      })
      .catch((error) => {
        console.log("DELETE USER MODAL ERROR", error);
        toast({
          title: "A felhasználó eltávolítása sikertelen",
          variant: "destructive",
        });
      });
  };

  const closeModal = () => dispatch(setModalClose(MODAL_ID));

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      className="md:w-[640px]"
      title="Felhasználó eltávolítása"
      variant="Custom"
      size="sm"
    >
      <div className="flex !content-center gap-6 flex-col items-center flex-wrap">
        <XIcon />
        <div className="flex justify-center items-center gap-2 self-stretch">
          <P className="!font-700">{`${modalData?.familyName ?? ""} ${
            modalData?.givenName ?? ""
          }`}</P>
        </div>
        <div className="flex justify-center items-center gap-2 self-stretch text-center">
          <P>
            Biztos vagy benne, hogy el szeretnéd távolítani a következő
            felhasználót?
          </P>
        </div>

        <div className="flex content-center md:flex-nowrap flex-wrap-reverse justify-center items-center w-full md:gap-6 gap-3 self-stretch text-center">
          <Button
            {...renderTestId("cancel-button")}
            variant="Secondary"
            onClick={closeModal}
            className="w-full"
          >
            <P className="text-primary !font-500 text-base">Mégse</P>
          </Button>

          <Button
            {...renderTestId("remove-button")}
            variant="Primary"
            onClick={handleSubmit}
            className="w-full"
          >
            <P className="text-white !font-500 text-base">Eltávolítás</P>
          </Button>
        </div>
      </div>
    </Overlay>
  );
};
export default DeleteUserModal;
