import cn from "classnames";
import { P } from "src/components";
import { getDisplayValue } from "src/utils/HelperFunctions";
import { DataPointApplicationFeaturesCardsQueriesDataResponse } from "src/store/serviceApi";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: DataPointApplicationFeaturesCardsQueriesDataResponse | null | undefined;
  isEmpty?: boolean;
  size?: "sm" | "md";
}

const DataLabel: React.FC<Props> = ({ data, isEmpty = false, size = "md" }) => {
  const textVariants = {
    sm: "md:text-xl text-lg",
    md: "md:text-4xl text-2xl",
    default: "",
  };

  const isCurrencyOrUom = (string: string) =>
    string.toLowerCase().startsWith("currency") ||
    string.toLowerCase().startsWith("uom");

  const displayValue = (
    size: "sm" | "md",
    values?: string[][] | null,
    header?: string,
    index?: number
  ): string => {
    if (
      !values ||
      !values[0] ||
      index === undefined ||
      !header ||
      !data?.values ||
      !data?.values[0]
    ) {
      return "";
    }
    if (isEmpty && values[0].length > index + 1) {
      const uom = values[0][index + 1];
      return uom === "Ft"
        ? size === "sm"
          ? "0 000 000"
          : "000 000 000"
        : size === "sm"
        ? "0"
        : "000";
    }
    return !isCurrencyOrUom(header) && data?.values
      ? getDisplayValue(data.values[0][index], values[0][index + 1], true)
      : data.values[0][index];
  };

  return (
    <div className={cn("flex flex-col items-start gap-2 self-stretch ")}>
      {data?.displayText && (
        <P
          className={cn(
            "font-500 !text-xs/[12px] text-primary/60 line-clamp-1"
          )}
        >
          {data?.displayText}
        </P>
      )}
      <div
        className={cn(
          "flex",
          "items-baseline",
          `gap-${size === "sm" ? "1" : "2"}`,
          "self-stretch"
        )}
      >
        {data?.headers?.map((header: string, index: number) => (
          <P
            key={index}
            className={cn(
              "!font-700",
              textVariants[
                !isCurrencyOrUom(header) && size === "md"
                  ? "md"
                  : isCurrencyOrUom(header) || size === "sm"
                  ? "sm"
                  : "default"
              ],
              {
                "opacity-40": isCurrencyOrUom(header),
              },
              data?.values &&
                data?.values[0] &&
                +data.values[0][index] === 0 &&
                isEmpty &&
                "!text-primary/[0.1]"
            )}
          >
            {displayValue(size, data.values, header, index)}
          </P>
        ))}
      </div>
    </div>
  );
};
export default DataLabel;
