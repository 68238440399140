import React from "react";
import cn from "classnames";
import { Icon, P } from "src/components";

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {}

const ErrorMessage: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <div className="flex flex-row gap-x-2 justify-end items-center">
      <P
        className={cn(
          "!text-font-main/[0.6]",
          "!text-[12px]",
          "!leading-4",
          className
        )}
        {...rest}
      >
        {children}
      </P>
      <Icon icon="alert" className="text-warning" />
    </div>
  );
};

export default ErrorMessage;
