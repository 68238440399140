import type { ColumnDef } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { P, ReactTable } from "../..";
import { renderTestId } from "src/utils/HelperFunctions";

interface Props {
  frequency?: "daily" | "weekly" | "monthly" | "quarterly" | "yearly";
}

type Data = {
  product: string;
  invoices: number;
  sum: number;
};

type Frequencies = {
  daily: Data[];
  weekly: Data[];
  monthly: Data[];
  quarterly: Data[];
  yearly: Data[];
};

const defaultData: Frequencies = {
  daily: [
    {
      product: "Termék 1",
      invoices: 12,
      sum: 4345061,
    },
    {
      product: "Termék 2",
      invoices: 7,
      sum: 1345061,
    },
    {
      product: "Termék 3",
      invoices: 4,
      sum: 4345061,
    },
  ],
  weekly: [
    {
      product: "Termék 1",
      invoices: 34,
      sum: 24345061,
    },
    {
      product: "Termék 2",
      invoices: 23,
      sum: 31345061,
    },
    {
      product: "Termék 3",
      invoices: 14,
      sum: 14345061,
    },
  ],
  monthly: [
    {
      product: "Termék 1",
      invoices: 122,
      sum: 544345061,
    },
    {
      product: "Termék 2",
      invoices: 37,
      sum: 421345061,
    },
    {
      product: "Termék 3",
      invoices: 44,
      sum: 442345061,
    },
  ],
  quarterly: [
    {
      product: "Termék 1",
      invoices: 1332,
      sum: 3534345061,
    },
    {
      product: "Termék 2",
      invoices: 744,
      sum: 2421345061,
    },
    {
      product: "Termék 3",
      invoices: 234,
      sum: 4345042461,
    },
  ],
  yearly: [
    {
      product: "Termék 1",
      invoices: 34312,
      sum: 345354345061,
    },
    {
      product: "Termék 2",
      invoices: 5332,
      sum: 34531345061,
    },
    {
      product: "Termék 3",
      invoices: 4533,
      sum: 35343345061,
    },
  ],
};

const columnHelper = createColumnHelper<Data>();

const columns = [
  columnHelper.accessor("product", {
    header: () => "Termék",
    cell: (row) => <P>{row.getValue()}</P>,
  }),
  columnHelper.accessor("invoices", {
    header: () => "Számlák száma",
    cell: (row) => <P>{row.getValue()}</P>,
  }),
  columnHelper.accessor("sum", {
    header: () => <div className="text-right">Érték</div>,
    cell: (row) => (
      <P className="text-right">
        <span className="font-bold mr-1">
          {row.getValue().toLocaleString("hu-HU", { minimumFractionDigits: 0 })}
        </span>
        Ft
      </P>
    ),
  }),
] as ColumnDef<unknown, any>[];

const InvoicesTable: React.FC<Props> = ({ frequency = "daily" }) => {
  const [data, setData] = useState(() => [...defaultData[frequency]]);

  useEffect(() => {
    setData(() => [...defaultData[frequency]]);
  }, [frequency]);

  return (
    <ReactTable
      {...renderTestId(`invoices-table-${frequency}`)}
      data={data}
      columns={columns}
      sticky
    />
  );
};

export default InvoicesTable;
