import React from "react";
import cn from "classnames";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Icon, P } from "src/components";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  id?: any;
  icon?: React.ComponentProps<typeof Icon>["icon"];
  isLocked: boolean;
  isAdded?: boolean;
  isAbsolutelyLocked?: boolean;
  onClick: () => void;
}

const DataCardListItem = ({
  className,
  children,
  id,
  icon = "x",
  isLocked,
  isAdded,
  isAbsolutelyLocked = false,
  onClick,
  ...rest
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cn(
        "cursor-pointer flex pt-2 pr-4 pb-2 pl-3 rounded-xl justify-between items-center gap-1 border",
        {
          "border border-primary/20 bg-contrast !cursor-default":
            isAbsolutelyLocked,
          "border-transparent bg-primary/5": !isAbsolutelyLocked && isAdded,
          "border-2 border-dashed border-primary/30 bg-white cursor-default":
            !isAdded,
        },
        className
      )}
      {...rest}
    >
      <div className={cn("flex", "gap-x-2", "items-center")}>
        <Icon
          icon={isLocked ? "lock" : "movable-cards"}
          size={16}
          className="text-black/40"
        />
        <div className="flex gap-x-2 p-1 rounded-[6px] bg-primary/10">
          <Icon icon={icon} size={16} />
        </div>
        <P className="text-sm md:text-base font-medium line-clamp-1">
          {children}
        </P>
      </div>
      {!isAbsolutelyLocked && (
        <Button
          variant="Default"
          className={cn("flex")}
          onClick={() => onClick()}
        >
          {isAdded !== undefined && (
            <Icon
              color={!isAdded === true ? "#007AFF" : "#FF0F00"}
              icon={!isAdded ? "plus" : "minus"}
              size={16}
            />
          )}
        </Button>
      )}
    </div>
  );
};

export default DataCardListItem;
