import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import {
  ModalData,
  setModalClose,
  useModalData,
} from "src/store/slices/modalSlice";
import { Overlay, P, Button } from "src/components";
import { ReactComponent as InfoIcon } from "src/assets/icons/info-illustration.svg";
import { useCompany } from "src/store/slices/companySlice";
import { renderTestId } from "src/utils/HelperFunctions";
import { useToast } from "src/components/_ui/toast/use-toast";
import { useResendInviteMutation } from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const MODAL_ID = "resend-invitation-modal";

const ResendInvitationModal: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const closeModal = () => dispatch(setModalClose(MODAL_ID));
  const modalData = useModalData();
  const { company } = useCompany();
  const { toast } = useToast();

  const [resendInvitation] = useResendInviteMutation();

  const handleSubmit = async () => {
    await resendInvitation({
      companyId: company?.id ?? "",
      id: (modalData as ModalData)?.id,
    })
      .then((res) => {
        if (!(res as any).data) {
          toast({
            title: "Sikeresen újra küldted a meghívót",
            variant: "success",
          });
          closeModal();
        } else
          toast({
            title: "A meghívó újraküldése sikertelen",
            variant: "destructive",
          });
      })
      .catch((error) => {
        console.log("RESEND INVITAION MODAL ERROR", error);
        toast({
          title: "A meghívó újraküldése sikertelen",
          variant: "destructive",
        });
      });
  };

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      className="md:w-[640px]"
      title="Meghívó újraküldése"
      variant="Custom"
      size="sm"
    >
      <div className="flex !content-center gap-6 flex-col items-center flex-wrap">
        <InfoIcon />
        <div className="flex justify-center items-center gap-2 self-stretch">
          <P className="font-700">{modalData?.email}</P>
          <P className="opacity-60">részére</P>
        </div>
        <div className="flex justify-center items-center gap-2 self-stretch text-center">
          <P>Biztos vagy benne, hogy újra szeretnéd küldeni a meghívást?</P>
        </div>

        <div className="flex md:flex-nowrap flex-wrap-reverse content-center justify-center items-center w-full md:gap-6 gap-3 self-stretch text-center">
          <Button
            {...renderTestId("cancel-button")}
            onClick={closeModal}
            variant="Secondary"
            className="w-full"
          >
            <P className="text-primary font-500">Mégse</P>
          </Button>

          <Button
            {...renderTestId("resend-button")}
            variant="Primary"
            onClick={handleSubmit}
            className="w-full"
          >
            <P className="text-white font-500">Újraküldés</P>
          </Button>
        </div>
      </div>
    </Overlay>
  );
};
export default ResendInvitationModal;
