import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  Fieldset,
  H1,
  H2,
  Input,
  P,
  Reset,
  Submit,
  TextBox,
} from "src/components";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { useValidationSchema } from "src/hooks/useValidationSchema";
import { teamMateInvitationValidationSchema } from "src/validation/schemes";
import { useToast } from "src/components/_ui/toast/use-toast";
import {
  DataPointApplicationFeaturesTenantsDtosTenantDto,
  useAddInviteMutation,
} from "src/store/serviceApi";

interface Invitations {
  name: DataPointApplicationFeaturesTenantsDtosTenantDto["name"];
  tenantId: DataPointApplicationFeaturesTenantsDtosTenantDto["id"];
  email: string;
}

const InviteTeamMates = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tenants: DataPointApplicationFeaturesTenantsDtosTenantDto[] =
    location.state?.tenants;
  const { toast } = useToast();

  const initialValues = useMemo(() => {
    const invitations: Invitations[] = tenants.map((tenant: any) => {
      return {
        name: tenant.name,
        tenantId: tenant.id,
        email: "",
      };
    });

    return {
      invitations,
    };
  }, [tenants]);

  const validate = useValidationSchema(teamMateInvitationValidationSchema);

  const [addInvite] = useAddInviteMutation();

  const sendInvitation = async ({
    invitations,
  }: {
    invitations: Invitations[];
  }) => {
    if (invitations.length > 0) {
      for (const { tenantId, email } of invitations) {
        if (email && tenantId) {
          const payload = {
            body: {
              invitations: [
                {
                  tenantId: tenantId,
                  emails: [email],
                },
              ],
            },
          };

          await addInvite(payload)
            .then((res) => {
              if (!(res as any).data) {
                toast({
                  title: "Sikeresen meghívtad kollégá(i)dat",
                  variant: "success",
                });
                navigate(INTERNAL_ROUTE.ONBOARDING);
              } else
                toast({
                  title: "A kollégá(i)d meghívása sikertelen",
                  variant: "destructive",
                });
            })
            .catch((error) => {
              console.log("ADD INVITE ERROR", error);
              toast({
                title: "A kollégá(i)d meghívása sikertelen",
                variant: "destructive",
              });
            });
        } else {
          console.log("MISSING DATA", { tenantId, email });
        }
      }
    }
  };

  const handleReset = () => {
    navigate(INTERNAL_ROUTE.ONBOARDING);
  };

  return (
    <>
      <H1 className="text-primary font-bold text-center">
        További személyek meghívása
      </H1>
      <P className="text-center">
        Hívd meg kollegáidat, hogy hozzáférjenek az Adatpont szolgáltatásban
        szereplő adatokhoz.
      </P>
      <Form
        onSubmit={sendInvitation}
        initialValues={initialValues}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className="flex flex-col gap-y-4">
              <FieldArray name="invitations">
                {({ fields, ...rest }) =>
                  fields.map((name, index) => (
                    <TextBox key={name} variant="Info">
                      <H2 className="mb-3 font-normal">
                        {initialValues.invitations[index].name}
                      </H2>
                      <Fieldset label="Email">
                        <Input
                          name={`${name}.email`}
                          placeholder="kisserika@gmail.com"
                        />
                        <Input name={`${name}.tenantId`} type="hidden" />
                      </Fieldset>
                    </TextBox>
                  ))
                }
              </FieldArray>
              <div className="flex md:flex-nowrap flex-wrap-reverse justify-end gap-4">
                <Reset className="md:w-1/2 w-full">Kihagyom</Reset>
                <Submit className="md:w-1/2 w-full">Mehet</Submit>
              </div>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};

export default InviteTeamMates;
