import { ReactComponent as LoadingSpinnerWhite } from "src/assets/loaders/white-loader.svg";
import { ReactComponent as LoadingSpinnerBlue } from "src/assets/loaders/blue-loader.svg";
import { H2 } from "src/components";
import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  message?: string;
  variant?: "white" | "blue";
}

const Loader: React.FC<Props> = ({
  message = "Az oldal betöltése folyamatban... Egy kis türelmet szeretnénk kérni, amíg betöltődik",
  variant = "blue",
}) => {
  const variants = {
    white: { fontColor: "contrast", loaderSvg: <LoadingSpinnerWhite /> },
    blue: { fontColor: "primary", loaderSvg: <LoadingSpinnerBlue /> },
  };

  return (
    <div className="flex flex-col gap-y-4 justify-center items-center">
      <span className="animate-spin">
        {variants[variant as keyof typeof variants].loaderSvg}
      </span>
      <H2
        className={cn(
          `text-${variants[variant as keyof typeof variants].fontColor}`,
          "text-[22px] leading-ks-32 font-700 text-center"
        )}
      >
        {message}
      </H2>
    </div>
  );
};

export default Loader;
