import React, { useState } from "react";
import cn from "classnames";
import { Icon, Input } from "src/components";

interface Props extends React.ComponentProps<typeof Input> {}

const Password: React.FC<Props> = ({ className, type = "text", ...rest }) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <div className="relative">
      <div
        className="absolute w-5 h-5 left-4 top-3.5"
        onClick={() => setVisible((s) => !s)}
      >
        <Icon icon="lock" className="text-primary" />
      </div>

      <Input
        type={isVisible ? "text" : "password"}
        className={cn("!px-12", className)}
        {...rest}
      />

      <div
        className="absolute w-5 h-5 right-4 top-3.5 cursor-pointer"
        onClick={() => setVisible((s) => !s)}
      >
        <Icon icon={isVisible ? "eye" : "eye-off"} className="text-primary" />
      </div>
    </div>
  );
};

export default Password;
