import { ReactComponent as FlexibillLogo } from "src/assets/landing/main/bill_digitalis_hor_color 1.svg";
import { ReactComponent as Img1 } from "src/assets/landing/flexibill/flexibill_illu.svg";
import { ReactComponent as Img2 } from "src/assets/landing/flexibill/flexibill2.svg";
import { ReactComponent as Img3 } from "src/assets/landing/flexibill/flexibill3.svg";
import { ReactComponent as Checkmark } from "src/assets/landing/flexibill/checkmark.svg";
import { Loader, P } from "src/components";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

const FlexibillLandingPage = () => {
  const { inProgress } = useMsal();

  return inProgress !== InteractionStatus.None ? (
    <div className="my-auto">
      <Loader variant="blue" />
    </div>
  ) : (
    <main className="bg-contrast self-stretch w-full content-center items-center md:justify-center md:items-center">
      <div className="bg-contrast flex flex-col items-center gap-6 md:gap-10 px-5 md:px-20 py-2 md:py-10 md:max-w-[1280px] md:m-auto">
        <div
          className="md:flex py-3 justify-between mt-5 items-center  self-stretch rounded-4xl 
                        bg-gradient-to-r from-[#7568F6]/0 to-[#7568F6]/10"
        >
          <div className="w-1/2">
            <div className="hidden md:ml-10 md:m-10 md:flex items-start flex-col self-stretch gap-2 md:gap-6 ">
              <P className="font-700 text-3xl md:text-[56px] md:leading-[64px] text-[#020626]/70 ">
                Faktorálás egyszerűen
              </P>
              <div className="flex items-center gap-4">
                <P className="text-[#020626]/70 md:font-700 md:text-xl">
                  Partnerünk a
                </P>
                <FlexibillLogo className="w-24 md:w-auto" />
              </div>
            </div>
          </div>
          <Img1 className=" right-0 md:w-auto md:h-[450px]  w-full h-full" />
        </div>
        <div className="flex md:hidden items-start flex-col self-stretch gap-2 md:gap-6 ">
          <P className="font-700 text-3xl md:text-5xl text-[#020626]/70 md:w-2/4">
            Faktorálás egyszerűen
          </P>
          <div className="flex items-center gap-4">
            <P className="text-[#020626]/70 md:font-700 md:text-xl">
              Partnerünk a
            </P>
            <FlexibillLogo className="w-24 md:w-auto" />
          </div>
        </div>

        <div className="flex flex-wrap justify-start self-stretch md:gap-0 md:w-1/2">
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] ">
            <P className="text-[#020626]/70 text-2xl md:text-4xl font-700">
              Mi az a faktorálás?
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              Az üzleti életben megszokott, hogy a teljesítés után kiállított
              számlát akár csak 30, 60 vagy 90 nappal később fizetik ki. A
              faktorálás ezt a várakozási időt egy napra csökkenti: a faktorcég
              megelőlegezi a számla értékének jellemzően 90%-át, majd a vevő már
              neki fizet. Ekkor a fennmaradó összeget is továbbutalja a díjával
              csökkentve. Így a fizetési határidő a számláinknál 1 napra
              csökken, a vevőink mégis ráérnek később fizetni, ez pedig
              mindkettő cég likviditását támogatja.
            </P>
          </div>
          <div></div>
        </div>

        <div className="flex flex-wrap content-center">
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] md:w-1/2 md:pr-5">
            <P className="text-[#020626]/70 text-2xl md:text-4xl font-700">
              Miért jó?
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              A faktorálás során a faktorcég gyakorlatilag megvásárolja a
              számlánkat: megelőlegezi annak összegét, a fedezet pedig maga a
              számla mögött szunnyadó munka értéke. Így nem kell várni a
              bevételre, hanem a teljesítés után azonnal nálunk lesz a pénzünk.
              Bár ügyfeleink nagy része időben fizet, akkor is várni kell a
              teljesítésre, míg faktorálásnál mi döntjük el, melyik számla
              összegét és mikor szeretnénk megkapni, ez pedig nagy szabadságot
              jelent cégünk cash-flow-tervezésében. Ha készpénzre van
              szükségünk, a számlánk értékét előbb kapjuk meg, így nem kell
              hitelhez folyamodnunk.
            </P>
          </div>
          <div className="flex flex-col gap-6 items-center md:w-1/2 md:pl-5">
            <div className="flex p-4 md:p-8 flex-col items-start gap-2 self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-2">
                <Checkmark />
                <P className="font-700">Gyors likviditás</P>
              </div>
              <P className=" text-sm opacity-80 text-[#020626]">
                Gyorsan, a dokumentumok benyújtását követően néhány napon belül
                pénzhez juthat.
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-2 self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-2">
                <Checkmark />
                <P className="font-700">
                  Magyar nemzeti bank felügyelete alatt
                </P>
              </div>
              <P className=" text-sm opacity-80 text-[#020626]">
                A finanszírozás tervezhető, kiszámítható, a szolgáltatások díjai
                kalkulálhatóak, nincsenek rejtett költségek.
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-2 self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-2">
                <Checkmark />
                <P className="font-700">0–24 elérhető likviditás</P>
              </div>
              <P className=" text-sm opacity-80 text-[#020626]">
                Átlátható, gyors és kényelmes módja a számláinak faktorálására,
                melyet akár mobileszközéről is nyomon követhet
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-2 self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex items-center gap-2">
                <Checkmark />
                <P className="font-700">Jobb pénzügyi rugalmasság</P>
              </div>
              <P className=" text-sm opacity-80 text-[#020626]">
                Megnő a hitelképesség, a beszállító likviditása nő.
              </P>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center md:gap-8 self-stretch">
          <P className="text-[#020626]/70 text-2xl md:text-4xl font-700 text-left self-stretch">
            Így működik a gyakorlatban
          </P>
          <div className="flex flex-col md:flex-row justify-center items-start gap-6 py-4 md:py-0">
            <div className="flex p-4 md:p-8 flex-col items-start gap-2 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex w-8 h-8 p-3 justify-center items-center gap-2 rounded-[39px] bg-primary">
                <P className="text-white text-center font-500">1</P>
              </div>
              <P className="opacity-80">
                Néhány alap céges információ megadását követően{" "}
                <b>máris megtudod, hogy ez pontosan mennyibe kerül neked.</b>
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-4 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex w-8 h-8 p-3 justify-center items-center gap-2 rounded-[39px] bg-primary">
                <P className="text-white text-center font-500">2</P>
              </div>
              <P className="opacity-80">
                Amennyiben tetszik az indikatív ajánlatunk, máris elkezdheted{" "}
                <b>feltölteni a vállalkozásod és vevőd adatait</b> digitálisan!
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-4 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex w-8 h-8 p-3 justify-center items-center gap-2 rounded-[39px] bg-primary">
                <P className="text-white text-center font-500">3</P>
              </div>
              <P className="opacity-80">
                Vevőminősítést követően elkészül a kockázatkezelés és a döntés a
                finanszírozásról. Pozitív elbírálás esetén és a digitális
                szerződést követően máris{" "}
                <b>folyósítjuk neked a számlák bruttó értékének max 90%-át.</b>
              </P>
            </div>

            <div className="flex p-4 md:p-8 flex-col items-start gap-4 flex-[1_0_0] self-stretch rounded-4xl bg-[#FAFCFF]">
              <div className="flex w-8 h-8 p-3 justify-center items-center gap-2 rounded-[39px] bg-primary">
                <P className="text-white text-center font-500">4</P>
              </div>
              <P className="opacity-80">
                Ismételd minden számla kiállításkor, és{" "}
                <b>másnap a számládon van a finanszírozott összeg!</b>
              </P>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center self-stretch md:gap-0 gap-2">
          <div className="flex justify-start md:w-1/2 md:pr-5">
            <Img2 className="h-full w-full md:h-auto md:w-auto" />
          </div>
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0]  md:w-1/2 md:pl-5">
            <P className="text-[#020626]/70  text-2xl md:text-4xl font-700 ">
              Faktorálás az Adatpont segítségével
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              A Flexibill faktorálási szolgáltatás integrálása az Adatpont
              platformjába lehetővé teszi számodra, hogy gyorsan és rugalmasan
              kezeld kintlévőségeidet, minimalizáld a kockázatokat, és növeld a
              likviditást.
            </P>
          </div>
        </div>

        <div className="flex flex-wrap items-center self-stretch gap-2 md:gap-0 ">
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] md:w-1/2 md:pr-5">
            <P className="text-[#020626]/70 text-2xl md:text-4xl font-700 ">
              Partnerünk, a Flexibill
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              A Flexibill a legnagyobb nem banki faktorcég, a BÁV Faktor
              digitális faktoring platformja, a Kulcs-Soft integrációs partnere.
              Segítségével online, bosszantó és időrabló papírforma nélkül
              válthatjuk hamarabb készpénzre számláinkat, nem kell megvárni, míg
              ügyfeleink utalnak. Bár a számlák többségét időben kifizetik, még
              azelőtt is hozzájuthatunk pénzünkhöz: nincs szükség kivárni a
              hosszú fizetési határidőt, így a pénzünk nem a vevőnknél áll majd.
            </P>
          </div>
          <div className="flex justify-end  md:w-1/2 md:pl-5">
            <Img3 className="h-full w-full md:h-auto md:w-auto" />
          </div>
        </div>

        <div className="w-full h-full overflow-hidden self-stretch rounded-4xl">
          <iframe
            className="w-full md:min-h-[645px] min-h-[655px]"
            src="https://ajanlat.kulcs-soft.hu/adatpont-flexibill"
            title="instapage"
          />
        </div>
      </div>
    </main>
  );
};

export default FlexibillLandingPage;
