import { P, ReactTable } from "src/components";
import { dateFormatOptions, monthLabels } from "src/utils/constants/Variants";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { getDisplayValue, renderTestId } from "src/utils/HelperFunctions";
import { DataPointApplicationFeaturesModalsQueriesModalResponse } from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: DataPointApplicationFeaturesModalsQueriesModalResponse;
}

type Data = {
  year: string;
  month: string;
  vouchernumber?: string;
  name?: string;
  voucherdate?: string;
  validitydate?: string;
  value?: string;
  currency?: string;
  isDivider: boolean;
};

type YearMonthGroup = {
  year: string;
  months: MonthGroup[];
};

type MonthGroup = {
  month: string;
  data: Data[];
};

const columnHelper = createColumnHelper<Data>();

const columns = [
  columnHelper.accessor("vouchernumber", {
    header: () => "Bizonylatszám",
    cell: (row) => (
      <P className="md:text-base text-sm md:whitespace-normal whitespace-nowrap">
        {row.getValue() ??
          `${row.row.original.year} ${
            monthLabels[row.row.original.month]?.long
          }`}
      </P>
    ),
  }),
  columnHelper.accessor("name", {
    header: () => "Név",
    size: 20,
    cell: (row) => (
      <P className="line-clamp-1 break-all">
        {row.getValue()}
        <span className="tooltip-contents">{row.getValue()}</span>
      </P>
    ),
  }),
  columnHelper.accessor("voucherdate", {
    header: () => "Kelte",
    cell: (row) => (
      <P className="md:text-base text-sm md:whitespace-normal whitespace-nowrap">
        {row.getValue()}
      </P>
    ),
  }),
  columnHelper.accessor("validitydate", {
    header: () => "Érvényesség",
    cell: (row) => (
      <P className="md:text-base text-sm md:whitespace-normal whitespace-nowrap">
        {row.getValue()}
      </P>
    ),
  }),
  columnHelper.accessor("value", {
    header: () => <div className="text-right">Összeg</div>,
    cell: (row) => (
      <div className="flex gap-2 justify-end pl-2 py-1">
        <P className="md:text-base text-sm font-700">
          {getDisplayValue(row.getValue(), row.row.original.currency)}
        </P>
        <P className="opacity-60 md:text-base text-sm font-500">
          {row.row.original.currency}
        </P>
      </div>
    ),
  }),
] as ColumnDef<unknown, any>[];

const OpenBidsModalTable: React.FC<Props> = ({ data }) => {
  const [groupedData, setGroupedData] = useState<Data[] | undefined>();

  const groupData = () => {
    if (!data || !data.data || !data.data[0] || !data.data[0].values) return [];

    const groupedData: Data[] = data.data[0].values.map((item) => ({
      year: item[0],
      month: item[1],
      vouchernumber: item[2],
      name: item[3],
      voucherdate: new Date(item[4]).toLocaleDateString(
        "hu-HU",
        dateFormatOptions
      ),
      validitydate: new Date(item[5]).toLocaleDateString(
        "hu-HU",
        dateFormatOptions
      ),
      value: item[6],
      currency: item[7],
      isDivider: false,
    }));
    const yearMonthGroups = groupedData.reduce((acc, currentItem) => {
      const { year, month } = currentItem;
      let yearEntry = acc.find((entry) => entry.year === year);
      if (!yearEntry) {
        yearEntry = { year, months: [] };
        acc.push(yearEntry);
      }
      let monthEntry = yearEntry.months.find((entry) => entry.month === month);
      if (!monthEntry) {
        monthEntry = { month, data: [] };
        yearEntry.months.push(monthEntry);
      }
      monthEntry.data.push(currentItem);
      return acc;
    }, [] as YearMonthGroup[]);

    let result: Data[] = [];

    yearMonthGroups.forEach((group) => {
      group.months.forEach((month) => {
        result.push({
          year: month.data[0].year,
          month: month.data[0].month,
          isDivider: true,
        } as Data);
        month.data.forEach((element) => {
          result.push(element);
        });
      });
    });

    return result;
  };

  useEffect(() => {
    if (data && data.data) setGroupedData(groupData());
  }, [data]);

  return (
    <ReactTable
      {...renderTestId("open-bids-table")}
      data={groupedData ?? []}
      columns={columns}
      variant="Collapsible"
      sticky
    />
  );
};
export default OpenBidsModalTable;
