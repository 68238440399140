import { useEffect, useState } from "react";
import { Marker, P, ReactTable } from "src/components";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { getDisplayValue } from "src/utils/HelperFunctions";
import { DataPointApplicationFeaturesCardsQueriesDataResponse } from "src/store/serviceApi";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data?: DataPointApplicationFeaturesCardsQueriesDataResponse | null;
}

type Data = {
  name: string;
  expired: number;
  unexpired: number;
  sum: number;
  currency: string;
};

const columnHelper = createColumnHelper<Data>();

const columns = [
  columnHelper.accessor("name", {
    header: () => "Név",
    cell: (row) => (
      <P className="md:text-base text-sm md:line-clamp-1 break-all">
        {row.getValue()}
        <span className="tooltip-contents">{row.getValue()}</span>
      </P>
    ),
  }),
  columnHelper.accessor("expired", {
    header: () => (
      <div className="md:text-base text-sm justify-end text-orange flex gap-1">
        Lejárt <Marker color="orange" />
      </div>
    ),
    cell: (row) => (
      <div className="flex gap-2 justify-end p-1">
        <P className="md:text-base text-sm font-700">
          {getDisplayValue(row.getValue(), row.row.original.currency)}
        </P>

        <P className="md:text-base text-sm opacity-60 font-500">
          {row.row.original.currency}
        </P>
      </div>
    ),
  }),
  columnHelper.accessor("unexpired", {
    header: () => (
      <div className="justify-end text-green flex gap-1">
        Nem lejárt <Marker color="green" />
      </div>
    ),
    cell: (row) => (
      <div className="flex gap-2 justify-end pr-2">
        <P className="md:text-base text-sm font-700">
          {getDisplayValue(row.getValue(), row.row.original.currency)}
        </P>

        <P className="opacity-60 md:text-base text-sm font-500">
          {row.row.original.currency}
        </P>
      </div>
    ),
  }),
  columnHelper.accessor("sum", {
    header: () => <div className="text-right">Összesen</div>,
    cell: (row) => (
      <div className="flex gap-2 justify-end p-1 md:max-w-full max-w-44 whitespace-normal">
        <P className="md:text-base text-sm font-700">
          {getDisplayValue(row.getValue(), row.row.original.currency)}
        </P>

        <P className="opacity-60 md:text-base text-sm font-500">
          {row.row.original.currency}
        </P>
      </div>
    ),
  }),
] as ColumnDef<unknown, any>[];

const SupplierDebtModalTable: React.FC<Props> = ({ data }) => {
  const [groupedData, setGroupedData] = useState<Data[] | undefined>();

  useEffect(() => {
    if (data && data.values && data.values[0]) {
      const groupedResult: Data[] = data.values.reduce(
        (acc: Data[], current: any) => {
          const [name, expired, unexpired, sum, currency] = current;

          acc.push({
            name,
            expired: Number(expired),
            unexpired: Number(unexpired),
            sum: Number(sum),
            currency,
          });

          return acc;
        },
        []
      );
      setGroupedData(groupedResult);
    }
  }, [data]);

  return (
    <ReactTable
      data={groupedData ?? []}
      columns={columns}
      variant="Collapsible"
      sticky
    />
  );
};
export default SupplierDebtModalTable;
