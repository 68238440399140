import { useDispatch } from "react-redux";
import { Overlay, P, SelectInput } from "src/components";
import { setSelectedCompany, useCompany } from "src/store/slices/companySlice";
import { AppDispatch } from "src/store/store";
import { renderTestId } from "src/utils/HelperFunctions";
import {
  DataPointDomainCompaniesCompany,
  useGetCompaniesQuery,
} from "src/store/serviceApi";
import cn from "classnames";
import { useIsAuthenticated } from "@azure/msal-react";
import { SingleValue } from "react-select";
import { useEffect } from "react";
import { setModalClose } from "src/store/slices/modalSlice";

interface Props {}

const MODAL_ID = "change-company-modal";

const ChangeCompanyOverlay: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { company } = useCompany();
  const isAuthenticated = useIsAuthenticated();
  const { data: companiesData, isLoading: companiesIsLoading } =
    useGetCompaniesQuery(undefined, { skip: Boolean(!isAuthenticated) });

  const changeSelectedCompany = (id: string) => {
    dispatch(setModalClose(MODAL_ID));
    const selectedCompany =
      companiesData?.find(
        (c: DataPointDomainCompaniesCompany) => c.id === id
      ) ?? undefined;
    if (selectedCompany) {
      dispatch(setSelectedCompany(selectedCompany));
    }
  };

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      variant="Custom"
      title="Cég kiválasztása"
      id={MODAL_ID}
    >
      <div className="flex flex-col gap-y-6 justify-between overflow-x-hidden">
        <div className="flex flex-col gap-y-4 mr-2 md:mr-0">
          {companiesData &&
            companiesData?.filter((c) => !c.isDeleted).length > 0 && (
              <div className="w-full py-4 px-1 self-center">
                {companiesData?.filter((c) => !c.isDeleted).length > 1 ? (
                  <SelectInput
                    {...renderTestId("default-company-select")}
                    defaultSelectValue={
                      {
                        label: company?.name ?? "",
                        value: company?.id ?? "",
                      } as any
                    }
                    onChangeSelect={(
                      option: SingleValue<string>,
                      meta: any
                    ) => {
                      changeSelectedCompany((option as any).value);
                    }}
                    options={
                      companiesData
                        ?.filter((c) => !c.isDeleted && c.id !== company?.id)
                        .map((c: DataPointDomainCompaniesCompany) => ({
                          value: c.id ?? "",
                          label: c.name ?? "",
                        })) as any
                    }
                  />
                ) : (
                  <P className="font-500 md:text-lg text-black/80">
                    {company?.name ?? ""}
                  </P>
                )}
              </div>
            )}
        </div>
      </div>
    </Overlay>
  );
};

export default ChangeCompanyOverlay;
