import React from "react";
import { Button } from "src/components";
import cn from "classnames";

interface Props extends React.ComponentProps<typeof Button> {}

const Reset: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <Button
      {...rest}
      type="reset"
      variant="Secondary"
      className={cn("flex-1", className)}
    >
      {children}
    </Button>
  );
};

export default Reset;
