import type { DataPointApplicationFeaturesModalsQueriesModalResponse } from "src/store/serviceApi";
import { useEffect, useState } from "react";
import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import { P, ReactTable } from "src/components";
import { getDisplayValue, renderTestId } from "src/utils/HelperFunctions";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: DataPointApplicationFeaturesModalsQueriesModalResponse;
}

type Data = {
  product: string;
  minValue: string;
  minValueUom: string;
  difference: string;
  differenceUom: string;
  currentValue: string;
  currentValueUom: string;
};

const columnHelper = createColumnHelper<Data>();

const columns = [
  columnHelper.accessor("product", {
    header: () => "Termék",
    size: 30,
    cell: (row) => (
      <P className="line-clamp-1 break-all">
        {row.getValue()}
        <span className="tooltip-contents">{row.getValue()}</span>
      </P>
    ),
  }),
  columnHelper.accessor("minValue", {
    header: () => "Minimum mennyiség",
    size: 20,
    cell: (row) => (
      <P className="">{`${getDisplayValue(
        row.getValue(),
        row.row.original.minValueUom
      )} ${row.row.original.minValueUom}`}</P>
    ),
  }),
  columnHelper.accessor("difference", {
    header: () => "Különbözet",

    cell: (row) => (
      <P className="md:pr-0 pr-5">
        {`${getDisplayValue(row.getValue(), row.row.original.differenceUom)} ${
          row.row.original.differenceUom
        }`}
      </P>
    ),
  }),
  columnHelper.accessor("currentValue", {
    header: () => <div className="text-right">Aktuális mennyiség</div>,

    cell: (row) => (
      <P className="max-w-44 whitespace-normal font-700 text-right pl-2 py-1">
        {`${getDisplayValue(
          row.getValue(),
          row.row.original.currentValueUom
        )} ${row.row.original.currentValueUom}`}
      </P>
    ),
  }),
] as ColumnDef<unknown, any>[];

const MinimumStockProductsModalTable: React.FC<Props> = ({ data }) => {
  const [groupedData, setGroupedData] = useState<Data[] | undefined>();

  const groupData = (): Data[] => {
    if (!data || !data.data || !data.data[0] || !data.data[0].values) return [];

    const groupedData: Data[] = data.data[0].values.map((item) => ({
      product: item[0],
      minValue: item[1],
      minValueUom: item[2],
      difference: item[3],
      differenceUom: item[4],
      currentValue: item[5],
      currentValueUom: item[6],
    }));
    return groupedData;
  };

  useEffect(() => {
    if (data && data.data) {
      setGroupedData(groupData());
    }
  }, [data]);

  return (
    <div className="flex flex-col items-start gap-4 self-stretch">
      <P className="md:text-base text-sm font-500 text-primary">
        TOP 10 minimum készlet alatti termékek
      </P>
      <div className="contents self-stretch w-full overflow-auto pb-1 mr-1 scrollbar-thumb-primary/50 scrollbar-track-primary/10 scrollbar-thumb-rounded-full scrollbar-thin scrollbar-w-1 ">
        <ReactTable
          variant="Collapsible"
          {...renderTestId("minimum-stock-products-table")}
          data={groupedData ?? []}
          columns={columns}
          sticky
        />
      </div>
    </div>
  );
};

export default MinimumStockProductsModalTable;
