import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { toast } from "src/components/_ui/toast/use-toast";
import { statuses } from "src/utils/statuses";

interface DatapointErrorResponse {
  data: {
    ErrorCodes?: string[] | null;
    ValidationErrors: {
      code?: string | null;
      message?: string | null;
    }[];
    errors: {
      [key: number]: string[];
      status?: number | null;
      title?: string | null;
      type?: string | null;
    };
  };
  status: number;
}

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!");
      console.log("rtkQueryErrorLogger action", action);

      const { status } = action.payload as FetchBaseQueryError;

      if (typeof status === "number") {
        const error = action.error as DatapointErrorResponse;

        toast({
          title: "Hiba történt!",
          description:
            "data" in error
              ? error.data.errors[error.status].map((e, index) => {
                  let { message } = JSON.parse(e);
                  if (
                    message ===
                    "There is already an existing company with this tax number which did not registered via Kulcs-Soft"
                  ) {
                    message =
                      "Van aktív fiókod a Flexibill rendszerében. Kérjük, használd azt.";
                  }
                  return <p key={index}>{message}</p>;
                })
              : statuses[status].message,
          variant: "destructive",
        });
      }
    }

    return next(action);
  };
