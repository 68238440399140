import React, { ComponentProps } from "react";
import { useFormState } from "react-final-form";
import cn from "classnames";
import { Button } from "src/components";
import { renderTestId } from "src/utils/HelperFunctions";

interface Props extends ComponentProps<typeof Button> {}

const Submit: React.FC<Props> = ({
  children,
  disabled,
  className,
  ...rest
}) => {
  const formState = useFormState();

  return (
    <Button
      {...renderTestId("submit-button")}
      {...rest}
      type="submit"
      isLoading={formState.submitting}
      disabled={
        (formState.touched && formState.invalid) ||
        formState.pristine ||
        formState.submitting ||
        disabled
      }
      className={cn(
        {
          "pointer-events-none cursor-not-allowed":
            (formState.touched && formState.invalid) || formState.pristine,
        },
        className
      )}
    >
      {children}
    </Button>
  );
};

export default Submit;
