import React from "react";
import cn from "classnames";
import { Icon, Label } from "src/components";

interface Props extends React.FieldsetHTMLAttributes<HTMLFieldSetElement> {
  hint?: string;
  label?: string;
}

const Fieldset: React.FC<Props> = ({
  children,
  className,
  hint,
  label,
  ...rest
}) => {
  return (
    <fieldset className={cn("", className)} {...rest}>
      <div className="flex justify-start items-center gap-x-2 mb-2">
        <Label>{label}</Label>
        {hint && (
          <span
            className="flex justify-center items-center w-5 h-5"
            data-tooltip-id="tooltip"
            data-tooltip-content={hint}
          >
            <Icon size={18} icon="info" className="text-primary" />
          </span>
        )}
      </div>
      {children}
    </fieldset>
  );
};

export default Fieldset;
