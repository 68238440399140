import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalClose } from "src/store/slices/modalSlice";
import { Overlay, P, Button } from "src/components";
import { ReactComponent as StepsWithProductNumber } from "src/assets/company-identification/withproductnumber.svg";
import { ReactComponent as StepsWithoutProductNumber } from "src/assets/company-identification/withoutproductnumber.svg";
import { renderTestId } from "src/utils/HelperFunctions";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const MODAL_ID = "company-identification-info-modal";

const CompanyIdentificationInfoModal = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const closeModal = () =>
    dispatch(setModalClose("company-identification-info-modal"));

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      title="Cég azonosításának folyamata"
      variant="Custom"
      size="xl"
      className="min-w-[450px]"
    >
      <P className="mb-4 text-[17px] leading-[20px]">
        Az Adatpont alkalmazás használatához érvényes Kulcs-Soft licensz
        szükséges. A cég azonosítása termékszám megadásával végezhető el.
      </P>
      <div className="flex items-center justify-center">
        <StepsWithProductNumber className="pointer-events-none w-full h-full" />
      </div>
      <div className="flex items-center justify-center">
        <StepsWithoutProductNumber className="pointer-events-none w-full h-full" />
      </div>
      <div className="flex items-center justify-center mt-4">
        <Button {...renderTestId("submit-button")} onClick={closeModal}>
          Rendben, tovább
        </Button>
      </div>
    </Overlay>
  );
};
export default CompanyIdentificationInfoModal;
