export const Links = {
  General: {
    TermsAndConditions: "https://r.ks.hu/adatpont-aszf",
    PrivacyPolicy: "https://r.ks.hu/adapont-adatvedelem",
    KnowledgeCenter: "https://r.ks.hu/adatpont-tudasbazis",
    Help: "https://r.ks.hu/adatpont-ugyfeleinknek",
    Faktoralas: "",
    Koveteleskezeles: "",
    KulcsUser: "",
  },
  Payee: {
    ModuleInfo:
      "https://r.ks.hu/melyik-termeket-valasszam-payee",
    TermsAndConditions: "https://r.ks.hu/adatkezelesi-aszf-payee",
    PrivacyPolicy: "https://r.ks.hu/payee-privacy-policy",
    MoreInfo: "https://r.ks.hu/payee",
    AfterRegistration:
      "https://r.ks.hu/mi-tortenik-az-adatmegadas-utan",
  },
  Flexibill: {
    TermsAndConditions: "https://r.ks.hu/flexibill-faktoring",
    PrivacyPolicy: "https://r.ks.hu/adatvedelmi-iranyelveink",
    MoreInfo: "https://r.ks.hu/flexibill-faktoring",
  },
};
