import { combineReducers, configureStore } from "@reduxjs/toolkit";
import cardsReducer from "src/store/slices/cardsSlice";
import userReducer from "src/store/slices/userSlice";
import companyReducer from "src/store/slices/companySlice";
import modalReducer from "src/store/slices/modalSlice";
import invoicesReducer from "src/store/slices/invoicesSlice";
import incomeCardReducer from "src/store/slices/incomeCardSlice";
import userAccessesReducer from "src/store/slices/userAccessesSlice";
import exchangeRatesReducer from "src/store/slices/exchangeRatesSlice";
import { serviceApi } from "src/store/serviceApi";
import { rtkQueryErrorLogger } from "./errorHandler";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { thunk } from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [serviceApi.reducerPath, 'modal'],
};

const combinedReducers = combineReducers({
  [serviceApi.reducerPath]: serviceApi.reducer,
  cards: cardsReducer,
  company: companyReducer,
  exchangeRates: exchangeRatesReducer,
  incomeCard: incomeCardReducer,
  invoices: invoicesReducer,
  user: userReducer,
  userAccesses: userAccessesReducer,
  modal: modalReducer,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(rtkQueryErrorLogger, serviceApi.middleware, thunk),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
