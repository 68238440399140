import cn from "classnames";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: "underline" | "normal";
}

const A: React.FC<Props> = ({
  variant = "normal",
  children,
  className = "",
  ...rest
}: Props) => {
  const variants = {
    underline: "underline",
    normal: "no-underline",
  };
  return (
    <a
      target="_blank"
      className={cn(
        "text-primary cursor-pointer active:text-deep-blue hover:text-deep-blue leading-snug",
        variants[variant],
        className
      )}
      {...rest}
    >
      {children}
    </a>
  );
};

export default A;
