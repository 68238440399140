import { useModal } from "src/store/slices/modalSlice";
import {
  Overlay,
  Tabs,
  IncomeModalTable,
  ErrorDisplay,
  Loader,
} from "src/components";
import { useEffect, useState } from "react";
import { useCompany } from "src/store/slices/companySlice";
import { useCard } from "src/store/slices/cardsSlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import cn from "classnames";
import { isModalDataEmpty, renderTestId } from "src/utils/HelperFunctions";
import { useGetModalQuery } from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const CARD_ID = "4f89bd1c-f8d0-40de-be6b-21c95f97bd4e";
const MODAL_ID = cardConfig[CARD_ID].modalId;

const IncomeModal: React.FC<Props> = () => {
  const [activeTab, setActiveTab] = useState<"Hónap" | "Nap">("Hónap");
  const { company } = useCompany();
  const card = useCard(CARD_ID);

  const modal = useModal(MODAL_ID);
  const {
    data: modalData,
    isLoading,
    isFetching,
  } = useGetModalQuery({
    id: card?.modal ?? 0,
    companyId: company?.id ?? "",
  });

  useEffect(() => {
    if (modal === false) setActiveTab("Hónap");
  }, [modal]);

  if (!modalData) return null;

  const changeActiveTab = (tab: "Hónap" | "Nap") =>
    activeTab !== tab && setActiveTab(tab);

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      className={cn("md:h-[75%] md:min-w-[700px]")}
      title={cardConfig[CARD_ID].modalTitle}
      id={MODAL_ID}
      variant="Overlay"
      updatedAt={new Date(modalData.updatedAt as string)}
      headerAdditional={
        <Tabs
          variant="Segment"
          data={[
            {
              label: "Hónap",
              content: "",
              onClick: () => changeActiveTab("Hónap"),
            },
            {
              label: "Nap",
              content: "",
              onClick: () => changeActiveTab("Nap"),
            },
          ]}
        />
      }
      icon={cardConfig[CARD_ID].icon}
      size="sm"
    >
      {isLoading || isFetching ? (
        <Loader variant={"blue"} />
      ) : isModalDataEmpty(modalData.data) ? (
        <ErrorDisplay />
      ) : (
        <div className="contents mb-1 mr-1 mt-4">
          <IncomeModalTable data={modalData} activeTab={activeTab} />
        </div>
      )}
    </Overlay>
  );
};
export default IncomeModal;
