import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalClose, useModalData } from "src/store/slices/modalSlice";
import { Overlay, P, Button } from "src/components";
import { ReactComponent as XIcon } from "src/assets/icons/result-fail.svg";
import { useCompany } from "src/store/slices/companySlice";
import { renderTestId } from "src/utils/HelperFunctions";
import { useToast } from "src/components/_ui/toast/use-toast";
import { useDeleteInviteMutation } from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const MODAL_ID = "revoke-invitation-modal";

const RevokeInvitationModal: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { company } = useCompany();
  const modalData = useModalData();

  const { toast } = useToast();

  const [deleteInvite] = useDeleteInviteMutation();

  const closeModal = () => dispatch(setModalClose(MODAL_ID));

  const handleSubmit = async () => {
    await deleteInvite({
      companyId: company?.id ?? "",
      id: modalData?.id,
    })
      .then((res) => {
        if (!(res as any).data) {
          toast({
            title: "Sikeresen visszavontad a meghívást",
            variant: "success",
          });
          closeModal();
        } else
          toast({
            title: "A meghívás visszavonása sikertelen",
            variant: "destructive",
          });
      })
      .catch((error) => {
        toast({
          title: "A meghívás visszavonása sikertelen",
          variant: "destructive",
        });
        console.log("REVOKE INVITATION MODAL ERROR", error);
      });
  };

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      className="md:w-[640px]"
      title="Meghívás visszavonása"
      variant="Custom"
      size="sm"
    >
      <div className="flex !content-center gap-6 flex-col items-center flex-wrap">
        <XIcon />
        <div className="flex justify-center items-center gap-2 self-stretch">
          <P className="!font-700">{modalData?.email}</P>
          <P className="opacity-60">részére</P>
        </div>
        <div className="flex justify-center items-center gap-2 self-stretch text-center">
          <P>Biztos vagy benne, hogy vissza szeretnéd vonni a meghívást?</P>
        </div>

        <div className="flex content-center justify-center items-center w-full gap-6 self-stretch text-center">
          <Button
            {...renderTestId("cancel-button")}
            onClick={closeModal}
            variant="Secondary"
            className="w-full"
          >
            <P className="text-primary !font-500 text-base">Mégse</P>
          </Button>

          <Button
            {...renderTestId("revoke-button")}
            variant="Primary"
            onClick={handleSubmit}
            className="w-full"
          >
            <P className="text-white !font-500 text-base">Visszavonás</P>
          </Button>
        </div>
      </div>
    </Overlay>
  );
};
export default RevokeInvitationModal;
