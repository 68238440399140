import type { SurveyStepContent } from "src/components/molecules/SurveySteps/SurveySteps";
import {
  ActionButton,
  Avatar,
  Button,
  Buttons,
  Callout,
  Card,
  CardHeader,
  CollapsibleTableDemo,
  DataCardListItem,
  Dropdown,
  ErrorMessage,
  FormDemo,
  H1,
  H2,
  H3,
  Icon,
  IconButton,
  InvoicesTable,
  Label,
  Marker,
  ModuleButton,
  P,
  Separator,
  SurveySteps,
  Tab,
  Tabs,
  Tag,
  TextBox,
  Tooltip,
  OverlayDemo,
  PayeePackagesModalDemo,
  CompanyIdentificationInfoModalDemo,
  IncomeModalDemo,
  InvoicesSelectorDemo,
  IncomeCard,
  AccountsReceivableManagementModal,
  AccountsReceivableManagementCard,
  OpenBidsCard,
  OpenOrdersCard,
  StockValueCard,
  MoneyStockCard,
  SupplierDebtCard,
  ClosedInvoicesCard,
  CreditLimitCard,
  MinimumStockProductsCard,
} from "src/components";
import { icons } from "src/components/atoms/Icon/Icon";
import { v4 as uuidv4 } from "uuid";
import ClosedInvoicesModal from "../../organisms/ClosedInvoices/ClosedInvoicesModal/ClosedInvoicesModal";
import { setModalOpen } from "src/store/slices/modalSlice";
import { AppDispatch } from "src/store/store";
import { useDispatch } from "react-redux";
import OpenBidsModal from "../../organisms/OpenBids/OpenBidsModal/OpenBidsModal";
import SupplierDebtModal from "../../organisms/SupplierDebt/SupplierDebtModal/SupplierDebtModal";
import OpenOrdersModal from "../../organisms/OpenOrders/OpenOrdersModal/OpenOrdersModal";

const StyleGuide = () => {
  const dispatch = useDispatch<AppDispatch>();
  const tooltipId = uuidv4();
  const tabData = [
    { label: "Tab 1#", content: "Tab 1" },
    { label: "Tab 2#", content: "Tab 2" },
    { label: "Tab 3#", content: "Tab 3" },
  ];

  const tabDataModuleClaims = [
    { label: "Soft", content: "Soft" },
    { label: "Medium", content: "Medium" },
    { label: "Hard", content: "HardCore 👨”🎤🤘🎸" },
  ];

  const tabDataModuleRegistry = [
    { label: "Van termékszámom", content: "Van termékszámom" },
    { label: "Nincs termékszámom", content: "Nincs termékszámom" },
  ];

  return (
    <div className="w-[1200px] max-w-full mx-auto p-4 bg-white rounded-large">
      <H1 className="mb-4">Typography</H1>
      <H1>H1 - Lorem ipsum dolor</H1>
      <Separator className="my-2 opacity-30" />
      <H2>H2 - Odio asperiores dalentini</H2>
      <Separator className="my-2 opacity-30" />
      <H3>H3 - Fugit beatae veritatis eius</H3>
      <Separator className="my-2 opacity-30" />
      <P>
        Paragraph - Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Quisquam laboriosam voluptatem eos, rem quae assumenda porro laudantium
        suscipit, ipsa excepturi consectetur odit a illum ea omnis nulla qui.
        Error, accusantium.
      </P>
      <Separator className="my-2 opacity-30" />
      <Label>Form label</Label>
      <Separator className="my-2 opacity-30" />
      <Separator className="my-8 opacity-30" />
      <H1 className="mb-4">React Table</H1>
      <InvoicesTable />
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Collapsible React Table</H1>
      <CollapsibleTableDemo />
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Avatar</H1>
      <div className="bg-gray-100 p-4 rounded-3xl">
        <Avatar familyName="Nagy" givenName="Gácsa" />
      </div>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Button</H1>
      <div className="flex flex-row gap-x-2">
        <Button
          onClick={() => {
            console.log("Default gomb");
          }}
          variant="Primary"
        >
          Gomb 1
        </Button>
        <Button variant="Secondary">Gomb 2 normál</Button>
        <Button variant="Secondary">
          <P className="text-font-main text-16-24">Gomb 2 sötét</P>
        </Button>
        <Button variant="Small">
          <P>Gomb 3</P>
          <Icon icon="plus" />
        </Button>
        <Button variant="Inner">
          <Icon icon="flexibill" />
          <P>Gomb 4</P>
        </Button>
        <Button variant="Small">
          <Icon icon="flexibill" />
        </Button>
      </div>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Buttons</H1>
      <Buttons>
        <Button
          onClick={() => {
            console.log("Default gomb");
          }}
          variant="Primary"
        >
          Gomb 1
        </Button>
        <Button variant="Secondary">Gomb 2 normál</Button>
      </Buttons>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Module Buttons</H1>
      <div className="flex flex-row gap-x-2 mb-2">
        <ModuleButton isActive />
        <ModuleButton phase="Medium" />
        <ModuleButton phase="Hard" />
      </div>
      <div className="flex flex-row gap-x-2">
        <ModuleButton variant="Registry" title="Van termékszámom" isActive />
        <ModuleButton variant="Registry" title="Nincs termékszámom" />
      </div>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Icons</H1>
      <div className="flex gap-x-4 mb-2">
        {icons?.map((icon, index) => (
          <Icon icon={icon} color="#bada55" key={`icon-component-${index}`} />
        ))}
      </div>
      <div className="flex gap-x-4 mb-2">
        <Icon icon="flexibill" />
        <Icon icon="flexibill2" color="green" />
        <Icon icon="flexibill2" color="orange" />
        <Icon icon="payee" />
        <Icon icon="payee" color="green" />
        <Icon icon="payee" color="orange" />
        <Icon icon="user" color="#bada55" />
      </div>
      <div className="flex gap-x-4">
        <Icon icon="money" variant="Container" />
        <Icon icon="law" variant="Container" />
      </div>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Icon Button</H1>
      <div className="flex gap-x-4">
        <IconButton />
        <IconButton variant="Round" />
      </div>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Action Button</H1>
      <ActionButton
        items={[
          {
            name: "Meghívás újraküldése",
            onClick: () => null,
          },
          {
            name: "Meghívás visszavonása",
            onClick: () => null,
          },
          {
            name: "Felhasználó eltávolítása",
            onClick: () => null,
            className: "!text-destructive !bg-destructive/[0.05]",
          },
        ]}
      />
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Tabs</H1>
      <div className="flex flex-row gap-x-2 bg-gray-100 p-4 rounded-3xl">
        <Tab
          title="Összeg"
          onClick={() => {
            console.log("Tab simple!");
          }}
        />
        <Tab variant="Segment" title="Összeg 2" />
      </div>
      <Separator className="my-4 opacity-30" />
      <H2>Sima Tab</H2>
      <Tabs data={tabData} />

      <Separator className="my-4 opacity-30" />
      <H2>Szegmens Tab</H2>
      <Tabs variant="Segment" data={tabData} />

      <Separator className="my-4 opacity-30" />
      <H2>Követeléskezelési Speciális Tab</H2>
      <Tabs variant="Claim" data={tabDataModuleClaims} />

      <Separator className="my-4 opacity-30" />
      <H2>Regisztrációs Speciális Tab</H2>
      <Tabs variant="Registry" data={tabDataModuleRegistry} />
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Dropdown</H1>
      <Dropdown
        label="Click me"
        items={[
          {
            name: "Item 1",
            onClick: () => null,
          },
          {
            name: "Item 2",
            onClick: () => null,
          },
          {
            name: "Item 3",
            onClick: () => null,
          },
        ]}
      />
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Form Demo</H1>
      <FormDemo />
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Highlighted Card</H1>
      <Card
        header={
          <CardHeader
            title="Kintlévőség és követeléskezelés"
            subtitle="asdad"
            info="Lorem ipsum dolor sit amet"
            icon="card-recurring"
          />
        }
        content="Content will be here"
        variant="highlighted"
      />
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Default Card</H1>
      <Card
        header={
          <CardHeader
            title="Kintlévőség és követeléskezelés"
            subtitle="asdad"
            info="Lorem ipsum dolor sit amet"
            icon="card-recurring"
            additional={["2023. aug. 27.", "2023. aug. 28.", "2023. aug. 29."]}
          />
        }
        content="Content will be here"
      />
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Callouts</H1>
      <div className="flex flex-col gap-y-4">
        <Callout>
          Fontos, hogy a kiválasztott számláknak egyetlen közös jogviszonyhoz
          kell kapcsolódniuk. Erre a jogszabályi megfelelés miatt van szükség.
        </Callout>
        <Callout variant="info">
          Fontos, hogy a kiválasztott számláknak egyetlen közös jogviszonyhoz
          kell kapcsolódniuk. Erre a jogszabályi megfelelés miatt van szükség.
        </Callout>
        <Callout variant="success">
          Fontos, hogy a kiválasztott számláknak egyetlen közös jogviszonyhoz
          kell kapcsolódniuk. Erre a jogszabályi megfelelés miatt van szükség.
        </Callout>
        <Callout variant="warning">
          Fontos, hogy a kiválasztott számláknak egyetlen közös jogviszonyhoz
          kell kapcsolódniuk. Erre a jogszabályi megfelelés miatt van szükség.
        </Callout>
        <Callout variant="destructive">
          Fontos, hogy a kiválasztott számláknak egyetlen közös jogviszonyhoz
          kell kapcsolódniuk. Erre a jogszabályi megfelelés miatt van szükség.
        </Callout>
      </div>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">TextBox</H1>
      <TextBox>
        <P>
          <strong>Helyetted kommunikálunk</strong> a nemfizető vevőiddel. Ez egy
          hangnemváltás a vevőd felé, és nagyobb eséllyel fizet majd.
        </P>
      </TextBox>
      <Separator className="my-8 opacity-30" />
      <TextBox variant="Info">
        <P>
          <strong>Helyetted kommunikálunk</strong> a nemfizető vevőiddel. Ez egy
          hangnemváltás a vevőd felé, és nagyobb eséllyel fizet majd.
        </P>
      </TextBox>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Error Message</H1>
      <ErrorMessage>Nincs megjelenítendő adat</ErrorMessage>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Tooltips / Floaters</H1>
      <div className="flex w-full">
        <div className="basis-1/2">
          <H2>Global tooltip</H2>

          <div
            className="inline"
            data-tooltip-id="tooltip"
            data-tooltip-content="Pénztárak és bankok forintosított összes értéke"
          >
            <Icon icon="cart" />
          </div>
          <Separator className="my-2 opacity-30" />
          <H2>Global floater</H2>

          <div
            className="inline"
            data-tooltip-id="floater"
            data-tooltip-content="Pénztárak és bankok forintosított összes értéke"
          >
            <Icon icon="cards" />
          </div>
          <Separator className="my-2 opacity-30" />
          <H2>Custom colored variant tooltip</H2>

          <div
            className="inline"
            data-tooltip-id={`light-color-custom-tooltip-with-children-${tooltipId}`}
          >
            <Icon icon="user" />
          </div>
          <Tooltip
            id={`light-color-custom-tooltip-with-children-${tooltipId}`}
            variant="light"
          >
            <div className="flex flex-col items-start gap-3">
              <span className="font-400 text-base opacity-75">
                2023. Ýprilis
              </span>
              <div className="flex items-center gap-2 self-stretch">
                <span className="font-700 text-base">28 345 068</span>
                <span className="font-700 text-xs opacity-60">Ft</span>
              </div>
            </div>
          </Tooltip>
          <Separator className="my-2 opacity-30" />
          <H2>Custom colored variant tooltip</H2>

          <div className="flex gap-2.5">
            <div
              className="border-dashed border-2 border-primary/30 p-12"
              data-tooltip-id={`open-icon-custom-floater-with-children-${tooltipId}`}
            >
              HOVER FOR OPEN
            </div>
            <Tooltip
              id={`open-icon-custom-floater-with-children-${tooltipId}`}
              float
            >
              <div className="flex gap-x-3 justify-center items-center">
                <Icon icon="expand" size={16} />
                <span>Mutass többet</span>
              </div>
            </Tooltip>
          </div>
          <div className="flex gap-2.5">
            <div
              className="border-dashed border-2 border-primary/30 p-12"
              data-tooltip-id={`close-icon-custom-floater-with-children-${tooltipId}`}
            >
              HOVER FOR CLOSE
            </div>
            <Tooltip
              id={`close-icon-custom-floater-with-children-${tooltipId}`}
              float
            >
              <div className="flex gap-x-3 justify-center items-center">
                <Icon icon="shrink" size={16} />
                <span>Mutass kevesebbet</span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Markers</H1>
      <div className="flex gap-2.5 bg-gray-100 p-4 rounded-3xl">
        <Marker color="orange" />
        <Marker color="green" />
        <Marker />
      </div>
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Survey Steps</H1>
      <SurveySteps
        steps={
          [
            {
              status: "done",
              children: <P>{"Adatok kitöltése"}</P>,
            },
            {
              status: "active",
              children: <P>{"Cég azonosítása"}</P>,
            },
            {
              status: "inactive",
              children: <P>{"További személyek meghívása"}</P>,
            },
          ] as SurveyStepContent[]
        }
      />
      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Tags</H1>

      <div className="flex flex-col gap-y-2">
        <Tag color="green">
          <Icon icon="checkmark" size={16} /> Rendelkezik szerződéssel
        </Tag>
        <Tag color="green">Folyamatban</Tag>
        <Tag color="primary">Lezárult</Tag>
        <Tag>Ügyindítás 3 perc alatt</Tag>
        <Tag color="orange">Ügyindítás 3 perc alatt</Tag>
      </div>

      <Separator className="my-8 opacity-30" />

      <H1 className="mb-4">Datacard list item</H1>
      <div
        style={{
          width: "479px",
          margin: "10px",
          display: "grid",
          gridGap: "10px",
        }}
      >
        <H2>Hozzáadott kártyák</H2>

        <DataCardListItem
          isLocked={true}
          isAdded={undefined}
          icon="money"
          onClick={() => {}}
        >
          Kintlévőség és követeléskezelés
        </DataCardListItem>
        <DataCardListItem
          isLocked={true}
          isAdded={true}
          icon="money"
          onClick={() => {}}
        >
          Szállítói tartozás
        </DataCardListItem>

        <DataCardListItem
          isLocked={false}
          isAdded={true}
          icon="money"
          onClick={() => {}}
        >
          Nyitott árajánlatok
        </DataCardListItem>

        <H2>Hozzáadható kártyák</H2>
        <DataCardListItem
          isLocked={true}
          isAdded={false}
          icon="money"
          onClick={() => {}}
        >
          Pénzkészlet
        </DataCardListItem>
      </div>

      <Separator className="my-8 opacity-30" />
      <H1>Overlay DEMO</H1>
      <OverlayDemo />

      <Separator className="opacity-30" />
      <div className="flex w-full">
        <div className="basis-1/2">
          <p style={{ alignSelf: "center", margin: "10px" }}>Modal</p>
          <div
            style={{
              width: "479px",
              margin: "10px",
              display: "grid",
              gridGap: "10px",
            }}
          >
            <Button onClick={() => dispatch(setModalOpen("open-bids-modal"))}>
              Nyitott árajánlatok modal
            </Button>
            <OpenBidsModal />
            <Button onClick={() => dispatch(setModalOpen("open-orders-modal"))}>
              Nyitott rendelések modal
            </Button>
            <OpenOrdersModal />
            <Button
              onClick={() =>
                dispatch(setModalOpen("accounts-receivable-management-modal"))
              }
            >
              Kintlévőség és követeléskezelés
            </Button>
            <AccountsReceivableManagementModal />
            <ClosedInvoicesModal />
            <Button
              onClick={() => {
                dispatch(setModalOpen("closed-invoices-modal"));
              }}
            >
              Kiállított számlák overlay
            </Button>

            <SupplierDebtModal span={4} />
            <Button
              onClick={() => {
                dispatch(setModalOpen("supplier-debt-modal"));
              }}
            >
              Szállítói tartozás overlay
            </Button>
            <PayeePackagesModalDemo />
            <InvoicesSelectorDemo />
            <CompanyIdentificationInfoModalDemo />
            <IncomeModalDemo />
          </div>
        </div>
        <div className="w-full">
          <p style={{ alignSelf: "center", margin: "10px" }}>Árbevétel</p>
          <IncomeCard />
        </div>
      </div>
      <Separator />
      <p style={{ alignSelf: "center", margin: "10px" }}>Kártyák</p>
      <div className="flex w-full">
        <div className="basis-1/2">
          <div className="w-[437px] h-[257px]">
            <OpenBidsCard />
          </div>
        </div>
        <div className="basis-1/2">
          <div className="w-[437px] h-[257px]">
            <OpenOrdersCard />
          </div>
        </div>
      </div>
      <p style={{ alignSelf: "center", margin: "10px" }}>{""}</p>
      <div className="flex w-full">
        <div className="basis-1/2">
          <div className="w-[437px] h-[257px]">
            <StockValueCard />
          </div>
        </div>
        <div className="basis-1/2">
          <div className="w-[437px] h-[354px]">
            <MoneyStockCard />
          </div>
        </div>
      </div>

      <p style={{ alignSelf: "center", margin: "10px" }}>{""}</p>
      <div className="flex w-full">
        <div className="basis-1/2">
          <div className="w-[437px] h-[354px]">
            <AccountsReceivableManagementCard span={2} />
          </div>
        </div>
        <div className="basis-1/2">
          <div className="w-[437px] h-[354px]">
            <SupplierDebtCard span={2} />
          </div>
        </div>
      </div>

      <p style={{ alignSelf: "center", margin: "10px" }}>{""}</p>
      <div className="flex w-full">
        <div className="basis-1/2">
          <div className="w-[437px] h-[285px]">
            <ClosedInvoicesCard />
          </div>
        </div>
        <div className="basis-1/2">
          <div className="w-[437px] h-[285px]">
            <CreditLimitCard />
          </div>
        </div>
      </div>

      <p style={{ alignSelf: "center", margin: "10px" }}>{""}</p>
      <div className="flex w-full">
        <div className="basis-1/2">
          <div className="w-[437px] h-[285px]">
            <MinimumStockProductsCard />
          </div>
        </div>
      </div>

      <div className="w-full">
        <p style={{ alignSelf: "center", margin: "10px" }}>Árbevétel</p>
        <IncomeCard span={6} />
      </div>
    </div>
  );
};

export default StyleGuide;
