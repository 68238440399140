import { InteractionStatus } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import {
  CompanyIdentificationByThirdPartyCancelled,
  CompanyIdentificationCard,
  CompanyIdentificationLayout,
  Loader,
} from "src/components";

import { INTERNAL_ROUTE } from "src/utils/constants/Enums";

const CompanyIdentificationByThirdPartyCancelPage = () => {
  const { inProgress } = useMsal();

  return inProgress === InteractionStatus.None ? (
    <>
      <AuthenticatedTemplate>
        <CompanyIdentificationLayout>
          <CompanyIdentificationCard>
            <CompanyIdentificationByThirdPartyCancelled />
          </CompanyIdentificationCard>
        </CompanyIdentificationLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to={INTERNAL_ROUTE.WELCOME} />
      </UnauthenticatedTemplate>
    </>
  ) : (
    <div className="my-auto">
      <Loader variant="blue" />
    </div>
  );
};

export default CompanyIdentificationByThirdPartyCancelPage;
