import cn from "classnames";
import { Icon } from "src/components";
import { renderTestId } from "src/utils/HelperFunctions";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  isLoading?: boolean;
}

type ButtonVariants = "Primary" | "Secondary" | "Small" | "Inner" | "Default";

const Button: React.FC<Props> = ({
  variant = "Primary",
  onClick,
  children,
  className,
  isLoading = false,
  ...rest
}: Props) => {
  const baseClasses = [
    "transition",
    "hover:ease-out",
    "duration-300",
    "font-500",
    "text-nowrap",
  ];
  const variants = {
    Primary: [
      "py-4",
      "px-6",
      "bg-primary",
      "hover:bg-deep-blue",
      "disabled:bg-pale-blue",
      "text-contrast",
      "text-16-24",
      "font-500",
      "rounded-[16px]",
    ],
    Secondary: [
      "py-4",
      "px-6",
      "hover:bg-pale-blue",
      "bg-block",
      "text-primary",
      "text-16-24",
      "font-500",
      "rounded-[16px]",
    ],
    Small: [
      "p-3",
      "bg-contrast",
      "hover:text-primary/[0.5]",
      "disabled:text-pale-blue",
      "text-font-main",
      "text-14-20",
      "font-500",
      "rounded-[8px]",
      "border-font-main/[0.1]",
      "hover:border-primary/[0.5]",
      "border",
      "flex",
      "gap-2.5",
      "items-center",
    ],
    Inner: [
      "p-2",
      "bg-primary/[0.05]",
      "hover:text-font-main/[0.8]",
      "disabled:text-pale-blue",
      "text-font-main",
      "text-16-24",
      "rounded-[10px]",
      "flex",
      "gap-2.5",
      "items-center",
    ],
    Default: className ? [...className!.split(" ")] : [],
  };

  return (
    <button
      {...renderTestId("button")}
      onClick={onClick}
      className={cn(baseClasses, variants[variant], className)}
      {...rest}
    >
      {isLoading ? (
        <div className="animate-spin">
          <Icon icon="loader" />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
