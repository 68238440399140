import fetchExchangeRates from "src/api/fetchExchangeRateClient";

export const getExchangeRatesService = async () => {
  try {
    const exchangeRateData = await fetchExchangeRates();
    return JSON.parse(exchangeRateData.data)?.Rates as Record<string, number>;
  } catch (error) {
    console.error(error);
  }
};
