import type { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "src/utils/auth/AuthProvider";
import { INTERNAL_ROUTE } from "../constants/Enums";

export const silentLogin = async (instance: IPublicClientApplication) => {
  await instance.initialize();

  const { pathname } = window.location;

  if (pathname === INTERNAL_ROUTE.COMPANY_IDENTIFICATION_BY_THIRD_PARTY) {
    loginRequest.redirectStartPage =
      INTERNAL_ROUTE.COMPANY_IDENTIFICATION_BY_THIRD_PARTY;
  }

  if (
    pathname === INTERNAL_ROUTE.START ||
    pathname === INTERNAL_ROUTE.WELCOME
  ) {
    loginRequest.redirectStartPage = INTERNAL_ROUTE.DASHBOARD;
  }

  instance
    .ssoSilent(loginRequest)
    .then((res) => instance.setActiveAccount(res.account))
    .catch((error) => {
      console.log(error);
    });
};
