import React from "react";
import cn from "classnames";
import { Field } from "react-final-form";
import { P } from "src/components";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input: React.FC<Props> = ({
  className,
  name,
  type = "text",
  ...rest
}) => {
  return (
    <Field name={name as string}>
      {({ input, meta }) => {
        return (
          <div>
            <input
              type={type}
              className={cn(
                "w-full",
                "px-4",
                "py-3.5",
                "outline-none",
                "rounded-large",
                "text-base",
                "border",
                "duration-300",
                "transition-colors",
                "border-black/10",
                "text-black",
                "placeholder:text-black/50",
                "focus:border-blue-500",
                { "border-destructive": meta.touched && meta.error },
                className
              )}
              {...input}
              {...rest}
            />
            {meta.touched && meta.error && (
              <P className="text-xs mt-1 ml-4 text-destructive">{meta.error}</P>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default Input;
