import { DataPointApplicationFeaturesTenantCreationRequestsCommandCreateTenantCreationRequestCommand } from "src/store/serviceApi";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CompanyIdentificationCard,
  CompanyIdentificationInvitationSuccess,
  CompanyIdentificationInvitationFailed,
  CompanyIdentificationLayout,
} from "src/components";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";

const CompanyIdentificationByThirdPartyResult = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION, {
        state: {
          navigatedFromCompanies: location.state?.navigatedFromCompanies,
        },
      });
    }
  }, [location.state]);

  const emailTo: DataPointApplicationFeaturesTenantCreationRequestsCommandCreateTenantCreationRequestCommand["emailTo"] =
    location.state?.emailTo;

  return (
    <CompanyIdentificationLayout>
      <CompanyIdentificationCard>
        {emailTo && emailTo !== "" ? (
          <CompanyIdentificationInvitationSuccess emailTo={emailTo} />
        ) : (
          <CompanyIdentificationInvitationFailed />
        )}
      </CompanyIdentificationCard>
    </CompanyIdentificationLayout>
  );
};

export default CompanyIdentificationByThirdPartyResult;
