import cn from "classnames";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  variant?: TabVariants;
  isActive?: boolean;
}

type TabVariants = "Tab" | "Segment" | "Pagination";

const Tab: React.FC<Props> = ({
  title = "",
  variant = "Tab",
  onClick,
  isActive,
  className,
  ...rest
}) => {
  const baseClasses = [
    "transition",
    "transition-all",
    "hover:ease-out",
    "duration-300",
    "py-1",
    "text-center",
    "font-primary",
    "font-400",
    "font-16-24",
    "hover:bg-primary",
    "hover:text-contrast",
    "hover:font-500",
  ];
  const variants = {
    Tab: "px-4 md:text-base text-sm rounded-full bg-primary/10 text-primary",
    TabActive:
      "px-4 md:text-base text-sm rounded-full text-contrast/[1.0] bg-primary/[1.0] font-500",
    Segment: "md:px-3 px-1 md:text-base text-sm rounded-[6px] bg-contrast",
    SegmentActive:
      "px-3 md:text-base text-sm rounded-[6px] text-contrast/[1.0] bg-primary/[1.0] font-500",
    Pagination:
      "!p-0 !w-8 !h-1 !bg-primary/[0.1] !rounded-full hover:!h-2 !duration-100",
    PaginationActive:
      "!p-0 !w-8 !h-1 !bg-primary !rounded-full hover:!h-2 !duration-100",
  };

  return (
    <button
      onClick={onClick}
      className={cn(
        baseClasses,
        variants[
          isActive ? (`${variant}Active` as keyof typeof variants) : variant
        ],
        isActive && "text-contrast/[1.0] bg-primary/[1.0] font-500",
        className
        // "z-50"
      )}
      {...rest}
    >
      {title}
    </button>
  );
};

export default Tab;
