import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getExchangeRatesService } from "../services/exchangeRatesService";

interface ExchangeRateState {
  exchangeRates?: Record<string, number>;
}

const initialState: ExchangeRateState = {
  exchangeRates: {},
};

const exchangeRatesSlice = createSlice({
  name: "exchangeRates",
  initialState,
  reducers: {
    setExchangeRates: (
      state,
      action: PayloadAction<Record<string, number>>
    ) => {
      state.exchangeRates = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExchangeRates.fulfilled, (state, action) => {
      state.exchangeRates = action.payload;
    });
  },
});

export const getExchangeRates = createAsyncThunk(
  "exchangeRates/getExchangeRates",
  async () => getExchangeRatesService()
);

export const { setExchangeRates } = exchangeRatesSlice.actions;

export const useExchangeRates = () => {
  const exchangeRates = useSelector((state: RootState) => state.exchangeRates);
  return exchangeRates;
};

export default exchangeRatesSlice.reducer;
