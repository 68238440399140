import type { AppDispatch } from "src/store/store";
import { useDispatch } from "react-redux";
import { renderTestId } from "src/utils/HelperFunctions";

import {
  AccountsReceivableManagementModal,
  Card,
  CardContent,
  CardContentPieChartWithLabels,
  CardHeader,
  P,
} from "src/components";
import { getDateLabel } from "src/utils/HelperFunctions";
import cn from "classnames";
import { useCard } from "src/store/slices/cardsSlice";
import { setModalOpen, useModal } from "src/store/slices/modalSlice";
import { Fragment } from "react";
import { cardConfig } from "src/utils/constants/CardConfig";
import { getCardOrEmptyState } from "src/utils/transformers/cardDataTransformer";
import { useToast } from "src/components/_ui/toast/use-toast";

const CARD_ID = "4af4d499-4063-4bc9-b860-2755933bff03";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  span?: 1 | 2 | 3 | 4 | 6;
}

const AccountsReceivableManagementCard: React.FC<Props> = ({ span = 2 }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isOpen = useModal(cardConfig[CARD_ID].modalId) ?? false;
  const { toast } = useToast();

  const openModal = () => {
    if (!isEmpty) {
      if (window?.innerWidth < 768)
        toast({
          title: "A kártya részletes nézete nem elérhető mobilon.",
          description: "Kérünk nyisd meg webböngészőben!",
          variant: "warning",
        });
      else dispatch(setModalOpen(cardConfig[CARD_ID].modalId));
    }
  };

  const { isEmpty, card, isNotLoaded } = getCardOrEmptyState(
    CARD_ID,
    useCard(CARD_ID)
  );

  return (
    <Fragment>
      <Card
        {...renderTestId(cardConfig[CARD_ID].testId)}
        onClick={openModal}
        header={
          <CardHeader
            title={cardConfig[CARD_ID].title}
            subtitle={
              <div className={cn("flex", "items-center", "gap-0.5")}>
                <P className={cn("text-xs", "font-400", "opacity-70")}>
                  Frissítve
                </P>
                <P className={cn("text-xs", "font-500", "opacity-80")}>
                  {getDateLabel(card.updatedAt)}
                </P>
              </div>
            }
            info={cardConfig[CARD_ID].infoText}
            icon={cardConfig[CARD_ID].icon}
          />
        }
        content={
          <CardContent isLocked isEmpty={isEmpty} isNotLoaded={isNotLoaded}>
            <CardContentPieChartWithLabels
              data={card.data}
              span={span}
              colors={[
                ["orange", "primary"],
                ["#FF6301", "#007AFF"],
              ]}
              isEmpty={isEmpty}
            />
          </CardContent>
        }
        span={span}
        variant="highlighted"
        isEmpty={isEmpty}
      />
      {!isEmpty && isOpen && <AccountsReceivableManagementModal span={span} />}
    </Fragment>
  );
};

export default AccountsReceivableManagementCard;
