import {
  createElement,
  CSSProperties,
  SVGProps,
  JSXElementConstructor,
} from "react";

type IconSetItem = {
  attrs?: Object[];
  paths: string[];
  width?: number;
};

type IconSelection = {
  order: number;
  name: string;
  prevSize?: number;
};

type IconSet = {
  icons: IconSetItem[];
  selection: IconSelection[];
};

export interface IconProps extends SVGProps<SVGElement> {
  icon: string;
  size?: string | number;
  title?: string;
  disableFill?: boolean;
  removeInlineStyle?: boolean;
  native?: boolean;
  SvgComponent?: JSXElementConstructor<any>;
  PathComponent?: JSXElementConstructor<any>;
  style?: CSSProperties;
}

interface IcoMoonProps extends IconProps {
  iconSet: IconSet;
}

const initialStyle: CSSProperties = {
  display: "inline-block",
  stroke: "currentColor",
  fill: "currentColor",
};

const IcoMoon = ({
  iconSet,
  icon,
  size,
  title,
  disableFill,
  removeInlineStyle,
  native,
  SvgComponent,
  PathComponent,
  ...props
}: IcoMoonProps) => {
  if (!iconSet || !icon) return null;

  const currentIcon = iconSet.selection.findIndex(({ name }) => name === icon);

  if (!currentIcon || !iconSet.icons[currentIcon]) return null;

  if (native) {
    initialStyle.display = "flex";
    initialStyle.flexDirection = "row";
    initialStyle.flexWrap = "wrap";
  }

  const computedStyle = {
    ...(removeInlineStyle ? {} : initialStyle),
    ...(size ? { width: size, height: size } : {}),
    ...(props.style || {}),
  };

  const viewBox = `0 0 ${iconSet.icons[currentIcon]?.width || 1024} 1024`;

  const children = iconSet.icons[currentIcon].paths.map((path, index) => {
    const attrs = iconSet.icons[currentIcon].attrs?.[index];

    const pathProps = {
      d: path,
      key: icon + index,
      ...(!disableFill && attrs ? attrs : {}),
    };

    return createElement(PathComponent || "path", pathProps);
  });

  if (title && !native) {
    children.push(createElement("title", { key: title }, title));
  }

  return createElement(
    SvgComponent || "svg",
    { ...props, viewBox, style: computedStyle },
    children
  );
};

export const iconList = (iconSet: IconSet) => {
  if (!iconSet || !Array.isArray(iconSet.selection)) return null;

  return iconSet.selection.map(({ name }) => name);
};

export default IcoMoon;
