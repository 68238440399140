import React from "react";
import { P } from "src/components";
import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "Default" | "Info";
}

const TextBox: React.FC<Props> = ({
  children,
  variant,
  className,
  ...rest
}) => {
  return (
    <div
      className={cn(
        "border border-primary/10 p-4 rounded-2xl",
        {
          "bg-primary/5": variant === "Info",
        },
        className
      )}
      {...rest}
    >
      <P>{children}</P>
    </div>
  );
};

export default TextBox;
