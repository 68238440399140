import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ReactComponent as Img1 } from "src/assets/landing/kulcs-user/ksuser_illu.svg";
import { Loader, P } from "src/components";

const KulcsUserLandingPage = () => {
  const { inProgress } = useMsal();

  return inProgress !== InteractionStatus.None ? (
    <div className="my-auto">
      <Loader variant="blue" />
    </div>
  ) : (
    <main className="bg-contrast self-stretch w-full content-center items-center md:justify-center md:items-center">
      <div className="bg-contrast flex flex-col items-center gap-6 md:gap-10 px-5 md:px-20 py-2 md:py-10 md:max-w-[1280px] md:m-auto">
        <div
          className="md:flex py-3 justify-between mt-5 items-center gap-10 self-stretch rounded-4xl 
      bg-gradient-to-r from-[#FE6F0E]/0 to-[#FE6F0E]/10"
        >
          <div className="w-1/2">
            <P className="hidden md:ml-10 md:block font-700 md:text-[56px] md:leading-[64px] text-[#020626]/70 ">
              Kulcs-Felhasználó
            </P>
          </div>
          <Img1 className=" right-0 md:w-auto md:h-[450px] w-full h-full mr-10" />
        </div>
        <P className="block md:hidden self-stretch font-700 text-3xl text-[#020626]/70 ">
          Kulcs-Felhasználó
        </P>
        <div className="flex flex-wrap justify-start self-stretch gap-10 md:w-2/4">
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] ">
            <P className="text-[#020626]/70 text-2xl md:text-4xl font-700 ">
              Mit kell tudnom a Kulcs-Felhasználóról?
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg ">
              Ez egyfajta digitális kulcs (felhasználónév, jelszó páros), amely
              lehetővé teszi, hogy egyszerűen és biztonságosan jelentkezz be
              különböző Kulcs-Soft weboldalakra és alkalmazásokba, anélkül, hogy
              mindenhol külön felhasználónevet és jelszót kellene megadnod.
            </P>
          </div>
          <div></div>
        </div>

        <div className="flex justify-start self-stretch gap-10">
          <div className="hidden md:block w-1/2 self-stretch"></div>
          <div className="flex flex-col items-start gap-2 md:gap-6 md:w-1/2">
            <P className="text-[#020626]/70 text-2xl md:text-4xl font-700 ">
              Hogyan tudom használni?
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              Egyszerűen regisztrálsz egy Kulcs-Felhasználót például az Adatpont
              felületére, amit később más Kulcs-Soft termékekbe való belépésre
              is lehet használni.
            </P>
          </div>
        </div>

        <div className="flex flex-wrap justify-start self-stretch gap-10 md:w-2/4">
          <div className="flex flex-col items-start gap-2 md:gap-6 flex-[1_0_0] ">
            <P className="text-[#020626]/70 text-2xl md:text-4xl font-700 ">
              Miért jó ez nekem?
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              A Kulcs-Felhasználó nemcsak biztonságos, hanem kényelmes is, mivel
              nincs szükség minden felületre külön felhasználót regisztrálnod.
              Illetve ez a megoldás minimalizálja az elfelejtett
              felhasználónevekből, jelszavakból adódó problémákat.
            </P>
          </div>
          <div></div>
        </div>

        <div className="flex justify-start self-stretch gap-10">
          <div className="hidden md:block w-1/2 self-stretch"></div>
          <div className="flex flex-col items-start gap-2 md:gap-6 md:w-1/2">
            <P className="text-[#020626]/70 text-2xl md:text-4xl font-700 ">
              Mely szolgáltatások elérhetők Kulcs-Felhasználóval?
            </P>
            <P className="text-[#020626]/70 opacity-80 md:text-lg">
              Egyelőre Kulcs-Üzlet és Adatpont szolgáltatásunk elérhető
              Kulcs-Felhasználóval, viszont a jövőben ez több termékünkre
              kiterjed majd.
            </P>
          </div>
        </div>
      </div>
    </main>
  );
};

export default KulcsUserLandingPage;
