import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import {
  DataPointApplicationFeaturesCardsQueriesCardResponse,
  serviceApi,
} from "../serviceApi";

interface CardsState {
  cards?: DataPointApplicationFeaturesCardsQueriesCardResponse[];
}

const initialState: CardsState = {
  cards: undefined,
};

const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    setCards: (
      state,
      action: PayloadAction<
        DataPointApplicationFeaturesCardsQueriesCardResponse[]
      >
    ) => {
      state.cards = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      serviceApi.endpoints.getCards.matchFulfilled,
      (state, action) => {
        if (!action.payload || !action.payload.cards) {
          state.cards = undefined;
        } else {
          state.cards = action.payload.cards;
        }
      }
    );
  },
});

export const useCard = (
  id: DataPointApplicationFeaturesCardsQueriesCardResponse["id"]
): DataPointApplicationFeaturesCardsQueriesCardResponse => {
  const { cards } = useCards();

  const card = cards?.find(
    (c: DataPointApplicationFeaturesCardsQueriesCardResponse) => c?.id === id
  );
  return card ? card : {};
};

export const useCards = () => {
  const cards = useSelector((state: RootState) => state.cards);
  return cards;
};

export const useCardsSeparately = () => {
  const cards = useSelector((state: RootState) => state.cards);
  return {
    primaryCards: cards?.cards
      ? cards.cards.filter(
          (card: DataPointApplicationFeaturesCardsQueriesCardResponse) =>
            card.isLocked
        )
      : [],
    secondaryCards: cards?.cards
      ? cards.cards.filter(
          (card: DataPointApplicationFeaturesCardsQueriesCardResponse) =>
            !card.isLocked
        )
      : [],
  };
};

export const { setCards } = cardsSlice.actions;

export default cardsSlice.reducer;
