import cn from "classnames";
import { Marker, P } from "src/components";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  phase?: ClaimType;
  isActive?: boolean;
  variant?: ModuleVariants;
  title?: string;
}

type ModuleVariants = "Claim" | "Registry";
export type ClaimType = "Soft" | "Medium" | "Hard";

const claimStyles = {
  Soft: ["Fizetési felszólítás", "bg-lemon", "bg-lemon/[0.1]"],
  Medium: ["Ügyvédi levél", "bg-orange", "bg-orange/[0.1]"],
  Hard: ["Fizetési meghagyás", "bg-coral", "bg-coral/[0.1]"],
};

const ModuleButton: React.FC<Props> = ({
  className,
  children,
  variant = "Claim",
  phase = "Soft",
  title,
  isActive,
  onClick,
  ...rest
}: Props) => {
  const baseClasses =
    "flex flex-col flex-1 justify-center items-center gap-1.5 rounded-[16px] py-5 px-6";

  return (
    <button
      className={cn(
        baseClasses,
        { "flex-[1_0_0]": variant === "Claim" },
        isActive
          ? "text-font-main border-2 border-primary"
          : "text-font-main/[0.6] border-2 border-primary/[0.1] hover:border-primary/[0.5]",
        className
      )}
      onClick={onClick}
      type="button"
      {...rest}
    >
      {variant === "Claim" ? (
        <>
          <div
            className={cn(
              "flex rounded-[8px] gap-2 px-2 pt-1 pb-[3px] text-[18px] leading-ks-24 font-700 items-center justify-center",
              claimStyles[phase][2]
            )}
          >
            <Marker variant="round" className={claimStyles[phase][1]} />
            <span>{phase}</span>
          </div>
          <P
            className={`font-400 md:text-base text-sm ${
              isActive ? "text-font-main" : "text-font-main/[0.6]"
            }`}
          >
            {claimStyles[phase][0]}
          </P>
        </>
      ) : (
        <span
          className={cn(
            "md:text-lg text-sm font-700 items-center justify-center",
            `${isActive ? "text-font-main" : "text-font-main/[0.6]"}`
          )}
        >
          {title}
        </span>
      )}
    </button>
  );
};

export default ModuleButton;
