import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import {
  DataPointApplicationFeaturesUsersQueriesDtosUserDtoRead,
  DataPointDomainCompaniesCompany,
  serviceApi,
} from "../serviceApi";

interface UserState {
  user?: DataPointApplicationFeaturesUsersQueriesDtosUserDtoRead;
  companies?: DataPointDomainCompaniesCompany[];
  users?: DataPointApplicationFeaturesUsersQueriesDtosUserDtoRead[];
}

const initialState: UserState = {
  user: undefined,
  companies: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<DataPointApplicationFeaturesUsersQueriesDtosUserDtoRead>
    ) => {
      state.user = action.payload;
    },
    setOnboardDone: (state) => {
      if (state.user) {
        state.user.onboarded = true;
      }
    },
    setCompanies: (
      state,
      action: PayloadAction<DataPointDomainCompaniesCompany[]>
    ) => {
      state.companies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        serviceApi.endpoints.getMe.matchFulfilled,
        (
          state,
          action: PayloadAction<DataPointApplicationFeaturesUsersQueriesDtosUserDtoRead>
        ) => {
          state.user = action.payload;
        }
      )
      .addMatcher(
        serviceApi.endpoints.getCompanies.matchFulfilled,
        (state, action: PayloadAction<DataPointDomainCompaniesCompany[]>) => {
          state.companies = action.payload;
        }
      );
  },
});

export const { setUser, setOnboardDone, setCompanies } = userSlice.actions;

export const useUser = () => {
  const user = useSelector((state: RootState) => state.user);
  return user;
};

export default userSlice.reducer;
