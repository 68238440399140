import type { AppDispatch } from "src/store/store";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import {
  Card,
  CardContent,
  CardContentPieChartWithLabels,
  CardHeader,
  P,
  SupplierDebtModal,
} from "src/components";
import { getDateLabel, renderTestId } from "src/utils/HelperFunctions";
import { useCard } from "src/store/slices/cardsSlice";
import { setModalOpen, useModal } from "src/store/slices/modalSlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import { getCardOrEmptyState } from "src/utils/transformers/cardDataTransformer";

const CARD_ID = "de591baa-7e2c-4991-b2bf-23891ae00123";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  span?: 1 | 2 | 3 | 4 | 6;
}

const SupplierDebtCard: React.FC<Props> = ({ span = 2 }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isOpen = useModal(cardConfig[CARD_ID].modalId) ?? false;
  const { isEmpty, card, isNotLoaded } = getCardOrEmptyState(
    CARD_ID,
    useCard(CARD_ID)
  );

  if (!card) return null;
  const openModal = () =>
    !isEmpty && dispatch(setModalOpen(cardConfig[CARD_ID].modalId));

  return (
    <Fragment>
      <Card
        {...renderTestId(cardConfig[CARD_ID].testId)}
        onClick={openModal}
        header={
          <CardHeader
            title={cardConfig[CARD_ID].title}
            subtitle={
              <div className={cn("flex", "items-center", "gap-0.5")}>
                <P className={cn("text-xs", "font-400", "opacity-70")}>
                  Frissítve
                </P>
                <P className={cn("text-xs", "font-500", "opacity-80")}>
                  {getDateLabel(card?.updatedAt)}
                </P>
              </div>
            }
            info={cardConfig[CARD_ID].infoText}
            icon={cardConfig[CARD_ID].icon}
          />
        }
        content={
          <CardContent
            isLocked
            isEmpty={isEmpty}
            isNotLoaded={isNotLoaded}
            className="h-full"
          >
            <CardContentPieChartWithLabels
              data={card?.data}
              span={span}
              colors={[
                ["orange", "green"],
                ["#FF6301", "#008F87"],
              ]}
              isEmpty={isEmpty}
            />
          </CardContent>
        }
        span={span}
        variant="highlighted"
        isEmpty={isEmpty}
      />
      {isOpen && <SupplierDebtModal span={span} />}
    </Fragment>
  );
};

export default SupplierDebtCard;
