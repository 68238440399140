import {
  Overlay,
  CreditLimitModalTable,
  ErrorDisplay,
  Loader,
} from "src/components";
import { useCompany } from "src/store/slices/companySlice";
import { useCard } from "src/store/slices/cardsSlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import { isModalDataEmpty, renderTestId } from "src/utils/HelperFunctions";
import { useGetModalQuery } from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const CARD_ID = "8e305142-4a61-4b56-ad0d-f95660948a86";
const MODAL_ID = cardConfig[CARD_ID].modalId;

const CreditLimitModal: React.FC<Props> = () => {
  const { company } = useCompany();
  const card = useCard(CARD_ID);

  const {
    data: modalData,
    isLoading,
    isFetching,
  } = useGetModalQuery({
    id: card?.modal ?? 0,
    companyId: company?.id ?? "",
  });

  if (!modalData) return null;

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      className="md:min-w-[640px] md:max-h-[70%] md:max-w-[750px]"
      title={cardConfig[CARD_ID].modalTitle}
      id={MODAL_ID}
      variant="Overlay"
      updatedAt={new Date(modalData.updatedAt as string)}
      icon={cardConfig[CARD_ID].icon}
      size="xl"
    >
      {isLoading || isFetching ? (
        <Loader variant={"blue"} />
      ) : isModalDataEmpty(modalData.data) ? (
        <ErrorDisplay />
      ) : (
        <CreditLimitModalTable data={modalData} />
      )}
    </Overlay>
  );
};
export default CreditLimitModal;
