import { msalInstance } from "../..";
import { INTERNAL_ROUTE, LOCAL_STORAGE_KEY } from "../constants/Enums";
import { loginRequest } from "./AuthProvider";

export const handleLogin = async () => {
  const req =
    window.location.pathname ===
    INTERNAL_ROUTE.COMPANY_IDENTIFICATION_BY_THIRD_PARTY
      ? {
          ...loginRequest,
          redirectUri:
            process.env.REACT_APP_SELF_BASE_URL +
            INTERNAL_ROUTE.COMPANY_IDENTIFICATION_BY_THIRD_PARTY,
        }
      : loginRequest;

  await msalInstance
    .handleRedirectPromise()
    .then(() => {
      msalInstance.loginRedirect(req).catch(() => {
        localStorage.clear();
        sessionStorage.clear();
      });
    })
    .catch((error) => {
      // user does not exist -> when trying to log in with a gmail account that is not registered in b2c
      if (error.errorMessage.includes("AADB2C99002"))
        console.log(error.errorMessage);
      //megszakított password change
      else if (error.errorMessage.includes("AADB2C90091"))
        window.location.replace("/");
      else if (error.errorMessage.includes("AADB2C90077")) handleLogout();
      else console.log(error.errorMessage);
    });
};

export const handleLogout = async () => {
  const logoutRequest = {
    account: msalInstance.getActiveAccount(),
    postLogoutRedirectUri: process.env.REACT_APP_SELF_BASE_URL + "/welcome",
  };

  // dispatch logout majd ide
  localStorage.clear();
  localStorage.removeItem(LOCAL_STORAGE_KEY.DB_ID);
  await msalInstance.logoutRedirect(logoutRequest).catch((err) => {
    console.log(err);
  });
};

export const handleSignUp = () => {
  const signupRequest = {
    scopes: [
      "openid",
      "offline_access",
      `${process.env.REACT_APP_AUDIENCE}/DataPoint.All`,
    ],
    authority:
      ("https://kulcssoftextidentity.b2clogin.com/kulcssoftextidentity.onmicrosoft.com/" +
        process.env.REACT_APP_B2C_SIGNUP_FLOW +
        "") as string,
    redirectStartPage:
      process.env.REACT_APP_SELF_BASE_URL + "/company-identification",
  };
  msalInstance.loginRedirect(signupRequest).catch((e) => {
    console.error(e);
  });
};

export const acquireToken = async () => {
  const request = {
    ...loginRequest,
    account: msalInstance.getAllAccounts()[0],
  };
  return msalInstance
    .acquireTokenSilent(request)
    .then((response) => {
      return response.accessToken;
    })
    .catch(() => {
      msalInstance.acquireTokenRedirect(request).catch((error) => {
        handleLogout();
      });
    });
};
