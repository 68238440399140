import {
  formatSimpleNumbers,
  getDisplayValue,
} from "src/utils/HelperFunctions";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { monthLabels } from "src/utils/constants/Variants";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: string[][] | undefined;
  span?: 1 | 2 | 3 | 4 | 6;
}

const IncomeChart: React.FC<Props> = ({ data, span = 4 }) => {
  const [series, setSeries] = useState<number[] | undefined>();
  const [years, setYears] = useState<string[] | undefined>();
  const [xAxisLabels, setXAxisLabels] = useState<string[] | undefined>();
  const [currency, setCurrency] = useState<string | undefined>();

  const monthConvertionLabels: any = {
    "Jan.": "Január",
    "Febr.": "Február",
    "Márc.": "Március",
    "Ápr.": "Április",
    "Máj.": "Május",
    "Jún.": "Június",
    "Júl.": "Július",
    "Aug.": "Augusztus",
    "Szept.": "Szeptember",
    "Okt.": "Október",
    "Nov.": "November",
    "Dec.": "December",
  };

  useEffect(() => {
    if (data) {
      const months = data.map((d) => d[1]);
      const labels = months.map((m) =>
        span < 6 ? monthLabels[m].short : monthLabels[m].long
      );
      setXAxisLabels(labels);

      const values = data.map((d) => Number(d[2]));
      setSeries(values);
      setYears(data.map((d) => d[0]));
      if (data[0]) setCurrency(data[0][3]);
    }
  }, [data]);

  var options = {
    chart: {
      defaultLocale: "hu",
      locales: [
        {
          name: "hu",
        },
      ] as ApexLocale[],
      foreColor: "#373d3f",
      toolbar: {
        show: false,
      },
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "16px",
        endingShape: "rounded",
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#fff0bc40"],
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: "#F0721C",
            opacity: 1,
          },

          {
            offset: 64,
            color: "#FFD964",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#fff0bc40",
            opacity: 1,
          },
        ],
      },
    },
    xaxis: {
      categories: xAxisLabels ?? [],
    },
    yaxis: {
      labels: {
        formatter: (value: any) => formatSimpleNumbers(value),
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        var data = getDisplayValue(
          w.globals.initialSeries[seriesIndex].data[dataPointIndex],
          currency
        );
        var shortLabel = xAxisLabels ? xAxisLabels[dataPointIndex] : "";
        var longLabel =
          (span < 6 ? monthConvertionLabels[shortLabel] : shortLabel) ?? "";
        var year = years ? years[dataPointIndex] : "";

        return (
          "<div class='chart-tooltip'>" +
          "<div class='label'>" +
          year +
          ". " +
          longLabel +
          "</div>" +
          "<div class='value-container'>" +
          "<div class='value'>" +
          data +
          "</div>" +
          "<div class='currency'>" +
          currency +
          "</div>" +
          "</div>" +
          "</div>"
        );
      },
    },
  };

  return (
    <Chart
      type="bar"
      options={options as any}
      series={[
        {
          name: "",
          data: series ?? [],
        },
      ]}
      height={156}
    />
  );
};

export default IncomeChart;
