import React, { Fragment } from "react";
import { Icon, IconButton, P } from "src/components";
import { renderTestId } from "src/utils/HelperFunctions";
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  additional?: React.ReactNode;
  icon?: React.ComponentProps<typeof Icon>["icon"];
  subtitle?: React.ReactNode;
  title: string;
  variant?: modalHeaderVariant;
  closeModal?: () => void;
}

type modalHeaderVariant = "Overlay" | "Custom" | "Profile-menu";

const ModalHeader: React.FC<Props> = ({
  additional,
  icon,
  subtitle,
  title,
  variant = "Overlay",
  closeModal,
}) => {
  return (
    <Fragment>
      <div
        {...renderTestId("modal-header")}
        className="flex justify-between items-start"
      >
        {variant === "Overlay" ? (
          <div className="flex items-center gap-x-3">
            {icon && <Icon icon={icon} variant="Container" />}
            <div>
              <P className="line-clamp-1 font-500">{title}</P>
              {typeof subtitle === "string" ? (
                <P className="text-xs text-black/50">{subtitle}</P>
              ) : (
                subtitle
              )}
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-x-4">
            <P className="font-700 text-base md:text-2xl">{title}</P>
            {subtitle}
          </div>
        )}

        <div className="flex justify-end items-center gap-x-2 text-[13px]">
          {additional && (
            <div className="hidden md:flex gap-x-3 relative top-[1px] text-black/60">
              {Array.isArray(additional)
                ? additional.map((a) => <div>{a}</div>)
                : additional}
            </div>
          )}
          {variant === "Profile-menu" ? (
            <IconButton
              {...renderTestId("close-button")}
              size="small"
              variant="Round"
              onClick={closeModal}
            />
          ) : (
            <IconButton
              {...renderTestId("close-button")}
              size="small"
              onClick={closeModal}
            />
          )}
        </div>
      </div>
      {additional && (
        <div className="flex md:hidden gap-x-3 relative top-[1px] text-black/60">
          {Array.isArray(additional)
            ? additional.map((a) => <div>{a}</div>)
            : additional}
        </div>
      )}
    </Fragment>
  );
};

export default ModalHeader;
