import { Navigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { InteractionStatus } from "@azure/msal-browser";
import { GeneralLoader } from "src/components";

export default function Auth({ children }: { children: React.ReactNode }) {
  const { inProgress } = useMsal();

  return inProgress === InteractionStatus.None ? (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to={INTERNAL_ROUTE.WELCOME} />
      </UnauthenticatedTemplate>
    </>
  ) : (
    <GeneralLoader isOpen />
  );
}
