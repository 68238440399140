import React from "react";
import { H1, P } from "src/components";
import { useGetRegrequestsQuery } from "src/store/serviceApi";

const CompanyIdentificationByThirdParty = () => {
  const { data: regrequestsData } = useGetRegrequestsQuery();
  const regrequest =
    regrequestsData && regrequestsData.find((data) => data.state === "Sent");

  return (
    <>
      <H1 className="text-primary font-bold text-center">Cég azonosítása</H1>
      <P className="text-center">
        {regrequest?.from?.fullName} ({regrequest?.from?.email}) kérelmet
        küldött az Adatpont szolgáltatás megkezdéséhez. Ehhez termékszám és
        adószám megadása szükséges. Az adatok megadása után a cég azonosítása
        megtörténik.
      </P>
    </>
  );
};

export default CompanyIdentificationByThirdParty;
