import * as yup from "yup";

export const companyIdentificationValidationInvitationSchema = yup
  .object()
  .shape({
    emailTo: yup
      .string()
      .email("Nem megfelelő formátum.")
      .required("Kötelező mező!"),
  });

export const teamMateInvitationValidationSchema = yup.object().shape({
  invitations: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .email("Nem megfelelő formátum.")
        .required("Kötelező mező!"),
    })
  ),
});

export const userInvitationScheme = yup.object().shape({
  email: yup.string().email("Hibás email!").required("Kötelező mező!"),
});

export const companyIdentificationConfirmationSchema = yup.object().shape({
  termsAndConditionsAccepted: yup
    .boolean()
    .oneOf([true], "El kell fogadnia a felhasználási feltételeket."),
  privacyPolicyAccepted: yup
    .boolean()
    .oneOf([true], "El kell fogadnia az adatvédelmi irányelveket."),
});

export const companyIdentificationValidationSchema = yup.object().shape({
  subscriptions: yup.array().of(
    yup.object().shape({
      productKey: yup.string().required("Kötelező mező!"),
      taxNumber: yup
        .string()
        .min(8)
        .required("Az adószámnak legalább 8 karakter hosszúnak kell lenni!"),
    })
  ),
});

export const demoValidationScheme = yup.object().shape({
  email: yup
    .string()
    .email("Nem megfelelő formátum.")
    .required("Kötelező mező!"),
  password: yup
    .string()
    .min(5)
    .required(
      (ctx) =>
        `A "${ctx.path}" mezőnek legalább 5 karakter hosszúnak kell lenni!`
    ),
  withFieldset: yup.string().required("Kötelező mező!"),
  tickbox: yup.boolean().oneOf([true], "Kötelező mező!"),
  language: yup.string().required("Kérlek válasz egy opciót!"),
});

export const startPayeeValidationScheme = yup.object().shape({
  tickbox1: yup.boolean().oneOf([true], ""),
  tickbox2: yup.boolean().oneOf([true], ""),
});

export const startFactoringValidationScheme = yup.object().shape({
  tickbox: yup.boolean().oneOf([true], ""),
});
