import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { DataPointDomainTenantsInvitationStatus, DataPointDomainUsersUserRole } from "../serviceApi";

export type UserAccess = {
  id?: string;
  objectId?: string;
  givenName?: string | null;
  familyName?: string | null;
  status?: DataPointDomainTenantsInvitationStatus | null;
  email?: string | null;
  role?: DataPointDomainUsersUserRole | null;
  onboarded?: boolean;
  createdAt?: Date | null;
};

interface UserState {
  userAccesses?: UserAccess[];
}

const initialState: UserState = {
  userAccesses: [],
};

const userAccessesSlice = createSlice({
  name: "user-accesses",
  initialState,
  reducers: {
    setUserAccesses: (state, action: PayloadAction<UserAccess[]>) => {
      state.userAccesses = action.payload;
    },
  },
});

export const { setUserAccesses } = userAccessesSlice.actions;

export const useUserAccesses = () => {
  const userAccesses = useSelector((state: RootState) => state.userAccesses);
  return userAccesses;
};

export default userAccessesSlice.reducer;
