import React from "react";
import cn from "classnames";
import { Field } from "react-final-form";
import { P } from "src/components";

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {}

const Radio: React.FC<Props> = ({ className, name, ...rest }) => {
  return (
    <Field name={name as string}>
      {({ input, meta }) => (
        <div className="flex">
          <input
            className={cn(
              "relative",
              "h-6",
              "w-6",
              "shrink-0",
              "appearance-none",
              "rounded-full",
              "border-2",
              "border-blue-500",
              "after:absolute",
              "after:left-0",
              "after:top-0",
              "after:h-full",
              "after:w-full",
              "after:bg-center",
              "after:bg-no-repeat",
              "after:content-['']",
              "checked:bg-blue-500",
              "focus:outline-none",
              "cursor-pointer",
              "after:bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzE5XzMzNzU1KSI+CjxwYXRoIGQ9Ik0zLjMzMzAxIDguMDAwMzNMNi42NjYzNCAxMS4zMzM3TDEzLjMzMyA0LjY2Njk5IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMTlfMzM3NTUiPgo8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==')]",
              { "border-destructive": meta.touched && meta.error },
              className
            )}
            {...input}
            {...rest}
            type="radio"
          />
          {meta.touched && meta.error && (
            <P className="mt-1 ml-4 text-destructive">{meta.error}</P>
          )}
        </div>
      )}
    </Field>
  );
};

export default Radio;
