import {
  DataPointApplicationFeaturesCardsQueriesCardResponse,
  DataPointApplicationFeaturesCardsQueriesDataResponse,
} from "src/store/serviceApi";
import emptyState from "src/assets/empty-states/empty-states.json";

export const getCardOrEmptyState = (
  cardId: string,
  card?: DataPointApplicationFeaturesCardsQueriesCardResponse
): {
  isEmpty: boolean;
  card: DataPointApplicationFeaturesCardsQueriesCardResponse;
  isNotLoaded?: boolean;
} => {
  // helper function in order to check are data sets should be represented as empty data?
  const _isEmpty = (
    data?: DataPointApplicationFeaturesCardsQueriesDataResponse[] | null
  ) => {
    if (!data || data.length === 0) {
      // Empty State A
      return { isEmpty: true, noDataLoaded: true };
    }
    if (
      data &&
      data.length > 0 &&
      data.every(
        (d: DataPointApplicationFeaturesCardsQueriesDataResponse) =>
          d.headers && d.values
      ) &&
      (data.every(
        (d: DataPointApplicationFeaturesCardsQueriesDataResponse) =>
          d.values?.length === 0
      ) ||
        data
          .flatMap(
            (d: DataPointApplicationFeaturesCardsQueriesDataResponse) =>
              d.values
          )
          .flatMap((d?: string[] | null) => d ? d : "" )
          .map((d?: string) => d && d.replace(/,/g, "."))
          .filter((d?: string) => d && !isNaN(+d))
          .every((d?: string) => d && d === "0"))
    ) {
      // Empty State B
      return { isEmpty: true, noDataLoaded: false };
    }
    // not empty AKA normal data state
    return { isEmpty: false, noDataLoaded: false };
  };

  const { isEmpty, noDataLoaded } = _isEmpty(card?.data ?? []);
  if (isEmpty || !card) {
    const emptyCard = emptyState.cards
      .map((card: any) => ({
        updatedAt: undefined,
        ...card,
      }))
      .find(
        (card: DataPointApplicationFeaturesCardsQueriesCardResponse) =>
          card.id === cardId
      ) as DataPointApplicationFeaturesCardsQueriesCardResponse;
    return { isEmpty, card: emptyCard, isNotLoaded: noDataLoaded };
  } else {
    return { isEmpty: false, card: card ?? {}, isNotLoaded: false };
  }
};
