import React, { HtmlHTMLAttributes } from "react";
import { Cell, flexRender, Header } from "@tanstack/react-table";
import cn from "classnames";

interface TableCellProps extends HtmlHTMLAttributes<HTMLTableCellElement> {
  header: Header<unknown, unknown>;
  sticky: boolean | undefined;
  variant: string;
}

const ReactTableHeadCell: React.FC<TableCellProps> = ({
  header,
  sticky,
  variant,
  className,
}) => {
  return (
    <th
      key={header.id}
      className={cn(
        sticky &&
          "md:last-of-type:relative last-of-type:sticky last-of-type:right-0 last:shadow-[0_0.5rem_0.5em_rgb(0,0,0,0.4)] md:last:shadow-none",
        variant === "Default"
          ? "px-0 py-1 text-left"
          : "text-left md:p-5 p-3 last-of-type:pr-5 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl text-primary bg-[#f5f9fe] font-400 text-sm",
        header.colSpan,
        className
      )}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
    </th>
  );
};

export default ReactTableHeadCell;
