import { useDispatch } from "react-redux";
import {
  DataCardListItem,
  DNDContainer,
  IconType,
  Overlay,
  P,
} from "src/components";
import {
  useCompany,
  toggleCardVisibility,
} from "src/store/slices/companySlice";
import { AppDispatch } from "src/store/store";
import { cardTypesById } from "src/utils/constants/CardTypes";
import { cardConfig } from "src/utils/constants/CardConfig";
import { renderTestId } from "src/utils/HelperFunctions";
import {
  DataPointDomainUserSettingsCardSetting,
  useGetCardsQuery,
  useGetUserSettingsMeQuery,
  useUpdateUserSettingsMeMutation,
} from "src/store/serviceApi";
import cn from "classnames";

interface Props {}

const MODAL_ID = "card-sorting-modal";

const EditDatacardOverlay: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { company, userSetting } = useCompany();
  const { data: dataGetUserSettingsMe, refetch: refetchUserSettingsMe } =
    useGetUserSettingsMeQuery(
      { companyId: company?.id ?? "" },
      { skip: Boolean(!company) }
    );
  const [updateUserSettings] = useUpdateUserSettingsMeMutation();
  const { refetch: refetchCards } = useGetCardsQuery({
    companyId: company?.id || "",
  });
  const primaryCardSettings = dataGetUserSettingsMe?.cardSettings?.filter(
    (setting: DataPointDomainUserSettingsCardSetting) =>
      setting.isVisible && setting.cardId && cardTypesById[setting.cardId] <= 4
  );

  const invincibleCards = dataGetUserSettingsMe?.cardSettings?.filter(
    (setting: DataPointDomainUserSettingsCardSetting) => !setting.isVisible
  );

  const handleUpdateUserSetting = async (
    cardSettings: DataPointDomainUserSettingsCardSetting[]
  ) => {
    await updateUserSettings({
      companyId: company?.id ?? "",
      body: {
        cardSettings: cardSettings,
      },
    })
      .then(() => {
        refetchCards()
          .then(() => {
            console.log("CARDS REFETCHED SUCCESSFULLY");
          })
          .catch((error) => {
            console.log("REFETCH CARDS ERROR", error);
          });
      })
      .catch((error) => {
        console.log("UPDATE USER SETTINGS ERROR", error);
      });
  };

  const handleClick = async (
    cardSetting: DataPointDomainUserSettingsCardSetting
  ) => {
    dispatch(toggleCardVisibility(cardSetting.cardId));

    const cardSettings = JSON.parse(
      JSON.stringify(dataGetUserSettingsMe?.cardSettings ?? [])
    );

    const updatedCardSettings = cardSettings.map(
      (c: DataPointDomainUserSettingsCardSetting) => {
        if (c.cardId === cardSetting.cardId) {
          c.isVisible = !c.isVisible;
        }
        return c;
      }
    );

    handleUpdateUserSetting(updatedCardSettings);
  };

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      variant="Custom"
      title="Adatkártyák szerkesztése"
      id="Adatkártyák szerkesztése"
      onClose={() => {
        handleUpdateUserSetting(
          JSON.parse(JSON.stringify(userSetting?.cardSettings ?? []))
        );
      }}
    >
      <div className="flex flex-col gap-y-6 justify-between overflow-x-hidden">
        <div className="flex flex-col gap-y-4 mr-2 md:mr-0">
          <div {...renderTestId("complete-card-list")}>
            <P className="uppercase text-12 font-400 leading-5 text-font-main/[0.6]">
              Hozzáadott kártyák
            </P>
            <ul className="flex flex-col gap-y-3">
              {primaryCardSettings?.map(
                (
                  primaryCardSetting: DataPointDomainUserSettingsCardSetting
                ) => {
                  const card =
                    cardConfig[
                      (primaryCardSetting?.cardId ??
                        "") as keyof typeof cardConfig
                    ];
                  return (
                    <DataCardListItem
                      {...renderTestId(
                        `completed-card-${primaryCardSetting.cardId}`
                      )}
                      key={primaryCardSetting.cardId}
                      children={card.title}
                      icon={card.icon}
                      isAbsolutelyLocked={
                        card &&
                        [1, 2].includes(
                          cardTypesById[primaryCardSetting.cardId ?? ""]
                        )
                          ? true
                          : false
                      }
                      isLocked={true}
                      isAdded={primaryCardSetting.isVisible}
                      onClick={() => handleClick(primaryCardSetting)}
                    />
                  );
                }
              )}
            </ul>
          </div>

          <DNDContainer />

          <div {...renderTestId("additional-card-list")}>
            <P
              className={cn(
                "uppercase text-12 font-400 leading-5 text-font-main/[0.6]",
                { hidden: invincibleCards?.length === 0 }
              )}
            >
              Hozzáadható adatkártyák
            </P>
            <ul className="flex flex-col gap-y-3">
              {invincibleCards &&
                invincibleCards.map(
                  (cardSetting: DataPointDomainUserSettingsCardSetting) => {
                    const card =
                      cardConfig[
                        (cardSetting?.cardId ?? "") as keyof typeof cardConfig
                      ];
                    return (
                      <DataCardListItem
                        {...renderTestId(
                          `additional-card-${cardSetting.cardId}`
                        )}
                        key={cardSetting?.cardId}
                        children={card.title}
                        icon={card.icon as IconType}
                        isLocked={cardTypesById[cardSetting?.cardId ?? ""] < 5}
                        isAdded={false}
                        isAbsolutelyLocked={
                          [1, 2].includes(
                            cardTypesById[cardSetting?.cardId ?? ""]
                          )
                            ? true
                            : false
                        }
                        onClick={() => handleClick(cardSetting)}
                      />
                    );
                  }
                )}
            </ul>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default EditDatacardOverlay;
