import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { Loader } from "src/components";
import { handleLogout } from "src/utils/auth/AuthActions";

const LogoutPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === "none" && isAuthenticated) handleLogout();
  }, [isAuthenticated, inProgress]);

  if (inProgress !== "none") return <Loader variant={"blue"} />;
  else return <div></div>;
};

export default LogoutPage;
