import React, { HTMLAttributes } from "react";

import cn from "classnames";
import { ErrorMessage } from "src/components";

interface Props extends HTMLAttributes<HTMLDivElement> {
  isLocked?: boolean;
  isEmpty?: boolean;
  isNotLoaded?: boolean;
}

const CardContent: React.FC<Props> = ({
  isLocked,
  isEmpty = false,
  isNotLoaded = false,
  className,
  children,
}) => {
  return (
    <>
      {isEmpty && (
        <ErrorMessage className="py-4">
          {isNotLoaded
            ? "Sajnos még nem sikerült betölteni az adatokat"
            : "Nincs megjelenítendő adat"}
        </ErrorMessage>
      )}
      <div
        className={cn(
          "flex",
          { "p-3": isLocked },
          `bg-contrast${isLocked ? "/60" : ""}`,
          "rounded-[10px]",
          "self-stretch",
          "flex-col",
          className
        )}
      >
        {children}
      </div>
    </>
  );
};

export default CardContent;
