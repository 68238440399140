import React from "react";
import { Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  A,
  Checkbox,
  CompanyIdentificationModal,
  H1,
  P,
  Reset,
  Submit,
  TextBox,
} from "src/components";
import { useValidationSchema } from "src/hooks/useValidationSchema";
import { companyIdentificationConfirmationSchema } from "src/validation/schemes";
import SuccessIcon from "src/assets/icons/result-success.svg";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { useToast } from "src/components/_ui/toast/use-toast";
import { Links } from "src/utils/constants/Links";
import {
  DataPointApplicationFeaturesTenantsCommandsCreateTenantCommand,
  DataPointApplicationFeaturesTenantsDtosTenantDto,
  IdentifyTenantApiResponse,
  useAddTenantMutation,
} from "src/store/serviceApi";

const initialValues = {
  termsAndConditionsAccepted: false,
  privacyPolicyAccepted: false,
};

interface Props {
  result?: IdentifyTenantApiResponse[];
  flowCode?: DataPointApplicationFeaturesTenantsCommandsCreateTenantCommand["flowCode"];
}

const CompanyIdentificationConfirmed: React.FC<Props> = ({
  result,
  flowCode = null,
}) => {
  const validate = useValidationSchema(companyIdentificationConfirmationSchema);
  const navigate = useNavigate();
  const [addTenant] = useAddTenantMutation();
  const { toast } = useToast();
  const location = useLocation();
  const links = Links.General;

  const handleNavigate = (
    tenants: DataPointApplicationFeaturesTenantsDtosTenantDto[]
  ) => {
    navigate(INTERNAL_ROUTE.INVITE, {
      replace: true,
      state: {
        tenants,
        navigatedFromCompanies: location.state?.navigatedFromCompanies,
      },
    });
  };

  const handleConfirm = async ({
    termsAndConditionsAccepted,
    privacyPolicyAccepted,
  }: typeof initialValues) => {
    let tenants: DataPointApplicationFeaturesTenantsDtosTenantDto[] = [];

    if (result && result.length > 0) {
      for (const { productKey, taxNumber } of result) {
        await addTenant({
          body: {
            productKey,
            taxNumber,
            privacyPolicyAccepted,
            termsAndConditionsAccepted,
            flowCode,
          },
        })
          .unwrap()
          .then((response) => {
            tenants.push(
              response as DataPointApplicationFeaturesTenantsDtosTenantDto
            );
          })
          .catch((error) => {
            console.log("COMPANY IDENTIFICATION CONFIRMED", error);
          });
      }
    }

    if (tenants.length > 0) {
      handleNavigate(tenants);

      toast({
        title: `A ${
          tenants.length > 1 ? "termékkulcsokat" : "termékkulcsot"
        } sikeresen azonosítottuk!`,
        variant: "success",
      });
    } else {
      toast({
        title: "Hiba történt!",
        variant: "destructive",
      });
    }
  };

  const handleReset = () => {
    navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION, {
      state: { navigatedFromCompanies: location.state?.navigatedFromCompanies },
    });
  };

  return (
    <>
      <div className="flex flex-col gap-y-6 items-center justify-between">
        <img
          src={SuccessIcon}
          alt="Sikeres cégazonosítás"
          className="w-[120px] h-[120px]"
        />

        <H1 className="text-primary font-bold">Sikeres cégazonosítás</H1>

        <div className="flex flex-col self-stretch gap-y-2">
          {result &&
            result.map(
              (item, index) =>
                !item.isExists && (
                  <TextBox
                    key={`identified-company-item-${index}`}
                    variant="Info"
                    className="text-center"
                    data-tooltip-id="tooltip"
                    data-tooltip-content={item.productKey}
                  >
                    {item.companyName}
                  </TextBox>
                )
            )}
        </div>

        <P className="text-sm text-center">
          A sikeres cégazonosítás után a Kulcs-Ügyvitel adatai szinkronizálásra
          kerülnek az Adatpont szolgáltatással.
        </P>

        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleConfirm}
        >
          {({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              onReset={handleReset}
              className="w-full"
            >
              <div className="flex flex-col gap-y-6">
                <div className="flex items-center gap-x-2">
                  <Checkbox name="termsAndConditionsAccepted" />
                  <div className="self-stretch flex gap-x-2 flex-wrap">
                    <P className="whitespace-nowrap">
                      Elolvastam és elfogadom az
                    </P>{" "}
                    <A
                      className="whitespace-nowrap white"
                      href={links.TermsAndConditions}
                    >
                      Általános Szerződési feltételeket
                    </A>
                  </div>
                </div>
                <div className="flex items-center gap-x-2">
                  <Checkbox name="privacyPolicyAccepted" />
                  <div className="self-stretch flex gap-x-2 flex-wrap">
                    <P className="whitespace-nowrap">
                      Elolvastam és elfogadom az
                    </P>{" "}
                    <A className="whitespace-nowrap" href={links.PrivacyPolicy}>
                      Adatvédelmi Tájékoztatót
                    </A>
                  </div>
                </div>
                <div className="flex w-full gap-x-2.5">
                  <Reset className="w-1/2">Mégse</Reset>
                  <Submit className="w-1/2">Tovább</Submit>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
      <CompanyIdentificationModal />
    </>
  );
};

export default CompanyIdentificationConfirmed;
