import { useLocation, useNavigate } from "react-router-dom";
import { Button, H1, P } from "src/components";
import FailedIcon from "src/assets/icons/result-fail.svg";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";

const CompanyIdentificationUnconfirmed = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-col h-full items-center justify-between gap-y-6">
      <div className="flex flex-col gap-y-6 items-center m-auto">
        <img
          src={FailedIcon}
          alt="Sikertelen cégazonosítás"
          className="w-[120px] h-[120px]"
        />

        <H1 className="text-primary font-bold">Sikertelen cégazonosítás</H1>

        <P className="text-sm text-center">
          Sajnos nem megfelelő a termékszám, vagy lejárt a licensz
        </P>
      </div>
      <Button
        className="md:w-auto w-full"
        onClick={() =>
          navigate(INTERNAL_ROUTE.COMPANY_IDENTIFICATION, {
            state: {
              navigatedFromCompanies: location.state?.navigatedFromCompanies,
            },
          })
        }
      >
        Vissza a termékazonosításhoz
      </Button>
    </div>
  );
};

export default CompanyIdentificationUnconfirmed;
