import { ReactPortal } from "src/components";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import {
  setModalClose,
  useModal,
} from "src/store/slices/modalSlice";
import { Loader } from "src/components";
import { renderTestId } from "src/utils/HelperFunctions";

const MODAL_ID = "general-loader";

interface Props {
  isOpen?: boolean;
}

const GeneralLoader = ({isOpen = false}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const modal = useModal(MODAL_ID);

  return (
    <>
      {(modal || isOpen) && (
        <ReactPortal wrapperId={MODAL_ID}>
          <div
            {...renderTestId("general-loader")}
            className={cn(
              "fixed",
              "inset-0",
              "z-50",
              "max-h-screen",
              "w-screen",
              "overflow-y-auto",
              "overflow-x-hidden",
              "bg-primary/50",
              "backdrop-filter",
              "backdrop-blur-none"
            )}
            onClick={() => {
              dispatch(setModalClose(MODAL_ID));
            }}
          >
            <div className="flex justify-center items-center h-screen">
              <Loader variant="white" />
            </div>
          </div>
        </ReactPortal>
      )}
    </>
  );
};

export default GeneralLoader;
