import cn from "classnames";
import { Icon, IconType } from "src/components";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconName?: IconType;
  variant?: iconButtonVariant;
  size?: "small" | "normal";
}

type iconButtonVariant = "Default" | "Round";

const IconButton: React.FC<Props> = ({
  iconName = "x",
  onClick,
  variant = "Default",
  size = "normal",
  children,
  className,
  ...rest
}: Props) => {
  const baseClasses = ["bg-contrast", "text-primary"];
  const sizeVariants = {
    small: "h-10 w-10",
    normal: "h-12 w-12",
  };
  return (
    <button
      onClick={onClick}
      className={cn(
        baseClasses,
        "flex",
        "items-center",
        "justify-center",
        sizeVariants[size],
        variant === "Round"
          ? "rounded-full border border-primary/[0.1]"
          : "rounded-[10px] border border-font-main/[0.1]"
      )}
      {...rest}
    >
      <Icon icon={iconName ? iconName : "x"} className={className} />
    </button>
  );
};

export default IconButton;
