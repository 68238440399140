import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant?: MarkerVariants;
  color?: string;
}

type MarkerVariants = "legend" | "round" | "default";

const Marker: React.FC<Props> = ({
  variant = "legend",
  color = "bg-primary/[0.6]",
  className,
  ...rest
}) => {
  const variants = {
    legend: ["rounded-small", "w-3", "h-3"],
    round: ["rounded-full", "w-2", "h-2"],
    default: [],
  };
  return (
    <div
      className={cn(
        "self-center",
        variants[variant],
        { [`bg-${color}`]: color },
        className
      )}
      {...rest}
    />
  );
};

export default Marker;
