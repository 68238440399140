import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { AddressOData, InvoiceOData } from "src/utils/transformers/invoiceDataTransformer";
import { serviceApi } from "../serviceApi";

type InvoicesState = {
  invoices?: InvoiceOData[];
  selectedActionCase?: {
    selectedCustomer?: {
      customerTaxNumber: string;
      name: string;
      address?: AddressOData;
    };
    action?: "factoring" | "payee";
    selectedInvoicesIds?: string[];
    selectedInvoicesGueIds?: string[];
  };
};

const initialState: InvoicesState = {
  invoices: undefined,
  selectedActionCase: undefined,
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setInvoices: (state, action: PayloadAction<InvoiceOData[]>) => {
      state.invoices = action.payload;
    },
    resetInvoices: (state) => {
      state.invoices = undefined;
    },
    setSelectedCompanyByInvoiceName: (
      state,
      action: PayloadAction<{
        invoiceNumber: string;
        action: "factoring" | "payee";
      }>
    ) => {
      const invoice = state.invoices?.find(
        (invoice: InvoiceOData) =>
          invoice.InvoiceNumber === action.payload.invoiceNumber
      );
      if (
        !state.selectedActionCase ||
        state.selectedActionCase?.selectedCustomer?.customerTaxNumber !==
          invoice?.Customer.TaxNumber ||
        state.selectedActionCase?.action !== action.payload.action
      ) {
        state.selectedActionCase = {};
      }
      state.selectedActionCase.selectedCustomer = {
        customerTaxNumber: invoice?.Customer.TaxNumber ?? "",
        name: invoice?.Customer.Name ?? "",
        address: invoice?.Customer.Address ?? undefined,
      };
      state.selectedActionCase.action = action.payload.action;
    },
    setSelectedCompanyByCompanyName: (
      state,
      action: PayloadAction<{
        customerName: string;
        customerTaxNumber: string;
        address: AddressOData;
        action: "factoring" | "payee";
      }>
    ) => {
      
      state.selectedActionCase = {
        selectedCustomer: {
          customerTaxNumber: action.payload.customerTaxNumber,
          name: action.payload.customerName,
          address: action.payload.address,
        },
        action: action.payload.action,
      };
    },
    setSelectedInvoiceIds: (state, action: PayloadAction<string[]>) => {
      if (state.selectedActionCase) {
        state.selectedActionCase.selectedInvoicesIds = action.payload;
      }
    },
    setSelectedInvoiceGueIds: (state, action: PayloadAction<string[]>) => {
      if (state.selectedActionCase) {
        state.selectedActionCase.selectedInvoicesGueIds = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(serviceApi.endpoints.getInvoicesOdata.matchPending, () => {
        console.log("Loading invoices");
      })
  },
});

export const {
  setInvoices,
  resetInvoices,
  setSelectedCompanyByInvoiceName,
  setSelectedCompanyByCompanyName,
  setSelectedInvoiceIds,
  setSelectedInvoiceGueIds,
} = invoicesSlice.actions;

export const useInvoices = (companyId?: string) => {
  const state = useSelector((state: RootState) => state.invoices);
  if (companyId) {
    const filteredInvoices = state.invoices?.filter(
      (i: InvoiceOData) => i.CompanyId === companyId
    );
    return {
      invoices: filteredInvoices,
      selectedActionCase: {
        selectedCompany: state.selectedActionCase?.selectedCustomer,
        action: state.selectedActionCase?.action,
        selectedInvoicesIds: state.selectedActionCase?.selectedInvoicesIds,
        selectedInvoicesGueIds: state.selectedActionCase?.selectedInvoicesGueIds
      },
    };
  }

  return state;
};

export const useInvoicesByCompany = () => {
  const selectedCompany = useSelector(
    (state: RootState) => state.invoices.selectedActionCase?.selectedCustomer
  );

  const filteredInvoices = useSelector(
    (state: RootState) => state.invoices.invoices
  );

  console.log("useInvoicesByCompany: ", filteredInvoices); 
  return filteredInvoices && selectedCompany?.customerTaxNumber
    ? filteredInvoices.filter(
        (invoice) =>
          invoice.Customer.TaxNumber === selectedCompany.customerTaxNumber
      )
    : filteredInvoices?.filter(
        (invoice) =>
          invoice.Customer.Name === selectedCompany?.name &&
          invoice.Customer.Address?.City === selectedCompany?.address?.City &&
          invoice.Customer.Address?.Street ===
            selectedCompany?.address?.Street &&
          invoice.Customer.Address?.Number === selectedCompany?.address?.Number
      );
};

export const useSelectedInvoicesIds = () => {
  const selectedInvoiceIds = useSelector(
    (state: RootState) =>
      state.invoices.selectedActionCase?.selectedInvoicesIds ?? []
  );
  return selectedInvoiceIds;
};

export const useSelectedInvoicesGueIds = () => {
  const selectedInvoiceGueIds = useSelector(
    (state: RootState) =>
      state.invoices.selectedActionCase?.selectedInvoicesGueIds ?? []
  );
  return selectedInvoiceGueIds;
};

export const useSelectedCompany = () => {
  const company = useSelector(
    (state: RootState) => state.invoices.selectedActionCase?.selectedCustomer
  );
  return company;
};

export default invoicesSlice.reducer;
