import { AppDispatch } from "src/store/store";
import { useDispatch } from "react-redux";
import { P, Button, Icon } from "src/components";
import { setModalOpen } from "src/store/slices/modalSlice";
import { renderTestId } from "src/utils/HelperFunctions";

const EmptyUserAccessesMessage = () => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="flex justify-center items-center self-stretch h-full bg-[#007aff08] rounded-4xl">
      <div className="flex flex-col items-center justify-center gap-4 p-4 m-auto w-[370px]">
        <P className="text-primary/80 text-center text-sm !text-[20px] !font-700">
          Jelenleg csak te vagy itt
        </P>
        <P className="text-primary text-center text-sm">
          Adj hozzá további személyeket az Adatpont használatához ezen a cégen
          belül
        </P>
        <Button
          {...renderTestId("invite-user-button")}
          variant="Small"
          className="flex items-center gap-2"
          onClick={() => dispatch(setModalOpen("invite-user-modal"))}
        >
          <P className="!font-500 !text-sm text-primary">
            Új személy meghívása
          </P>
          <Icon icon="plus" className="text-primary" size={16} />
        </Button>
      </div>
    </div>
  );
};

export default EmptyUserAccessesMessage;
