import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Tab, P } from "src/components";
import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data?: DataElement[];
  pageFromParent?: number;
  setPageFromParent?: Dispatch<SetStateAction<number>>;
}

interface DataElement {
  label: string;
  content: React.ReactNode | string;
  onClick?: () => void;
}

const PaginationTabs: React.FC<Props> = ({
  data,
  pageFromParent: page,
  className,
  setPageFromParent,
}: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabContent = useMemo(
    () => data && data[activeTab]?.content,
    [data, activeTab]
  );

  useEffect(() => {
    if (page) {
      setActiveTab(page);
    }
  }, [page]);

  useEffect(() => {
    if (setPageFromParent) {
      setPageFromParent(activeTab);
    }
  }, [activeTab, setPageFromParent]);

  if (!data) {
    return null;
  }

  const handleTab = (i: number) => {
    const tabAction = data[i].onClick;
    if (tabAction) {
      tabAction();
    }
    setActiveTab(i);
  };

  /* const nextPage = () => {
    if (activeTab < data.length) {
      setActiveTab(activeTab + 1);
    }
  }; */

  return (
    <div className="flex flex-col justify-end items-end gap-y-6">
      {tabContent && (
        <div className="w-full">
          {typeof tabContent === "string" ? <P>{tabContent}</P> : tabContent}
        </div>
      )}
      <div
        className={cn(
          "flex flex-row justify-center items-center",
          "h-[12px] gap-x-1 px-2",
          "w-full",
          className
        )}
      >
        {data?.map(({ label }, index) => (
          <Tab
            variant="Pagination"
            key={`page-tab-${index}-${label}`}
            onClick={() => handleTab(index)}
            isActive={index === activeTab}
          />
        ))}
      </div>
    </div>
  );
};

export default PaginationTabs;
