import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {}

const Paragraph: React.FC<Props> = ({
  children,
  className = "",
  ...rest
}: Props) => {
  return (
    <p className={cn("leading-snug", className)} {...rest}>
      {children}
    </p>
  );
};

export default Paragraph;
