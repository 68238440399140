import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {}

const H3: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <h3
      className={cn("font-primary", "text-xs", "md:text-sm", className)}
      {...rest}
    >
      {children}
    </h3>
  );
};

export default H3;
