export const backgroundColorVariants = {
  primary: "bg-primary",
  "primary-01": "bg-primary/10",
  green: "bg-green",
  "green-01": "bg-green/10",
  orange: "bg-orange",
  "orange-01": "bg-orange/10",
  white: "bg-white",
  black: "bg-black",
  light: "bg-black/10",
};

export const textColorVariants = {
  primary: "text-primary",
  "primary-01": "text-primary/10",
  green: "text-green",
  "green-01": "text-green/10",
  orange: "text-orange",
  "orange-01": "text-orange/10",
  white: "text-white",
  black: "text-black",
};

export const fontSizeVariants = {
  sm: "text-xs font-medium",
  md: "text-base font-normal",
};

export const monthLabels: any = {
  "1": {
    short: "Jan.",
    long: "Január",
  },
  "2": {
    short: "Febr.",
    long: "Február",
  },
  "3": {
    short: "Márc.",
    long: "Március",
  },
  "4": {
    short: "Ápr.",
    long: "Április",
  },
  "5": {
    short: "Máj.",
    long: "Május",
  },
  "6": {
    short: "Jún.",
    long: "Június",
  },
  "7": {
    short: "Júl.",
    long: "Július",
  },
  "8": {
    short: "Aug.",
    long: "Augusztus",
  },
  "9": {
    short: "Szept.",
    long: "Szeptember",
  },
  "10": {
    short: "Okt.",
    long: "Október",
  },
  "11": {
    short: "Nov.",
    long: "November",
  },
  "12": {
    short: "Dec.",
    long: "December",
  },
};

export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};
