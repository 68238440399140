// Icon.tsx
import IcoMoon, {
  IconProps,
  iconList,
} from "src/components/_ui/react-icomoon-datapoint";
import iconSet from "src/assets/icons/datapoint-iconset.json";
import cn from "classnames";

export const icons = iconList(iconSet);

interface Props extends IconProps {
  variant?: "Default" | "Container" | "SmallContainer";
}

const Icon: React.FC<Props> = ({
  icon = "alert",
  size = 24,
  variant = "Default",
  ...rest
}) =>
  variant !== "Default" ? (
    <div
      className={cn(
        "flex justify-center items-center bg-primary/[0.1] h-fit w-fit",
        variant === "SmallContainer"
          ? "p-1.5 rounded-[6px]"
          : "p-3 rounded-[10px]"
      )}
    >
      <IcoMoon iconSet={iconSet} icon={icon} size={size} {...rest} />
    </div>
  ) : (
    <IcoMoon iconSet={iconSet} icon={icon} size={size} {...rest} />
  );

export default Icon;
